#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 8781, 8465, 8183, 8729, 8598, 9641, 10205, 10192, 10918, 12187 ]
    - [ 8928, 7960, 8075, 8266, 8389, 9096, 10095, 11008, 11434, 12778 ]
    - [ 8254, 7908, 7552, 8287, 8686, 9625, 10511, 11102, 11173, 11900 ]
    - [ 8657, 7643, 7930, 8065, 8367, 9113, 9973, 10359, 11920, 12780 ]
    - [ 8478, 7765, 8472, 8190, 8559, 9263, 9815, 10558, 11463, 12369 ]
    - [ 8648, 8093, 8263, 8230, 8426, 9081, 10610, 10703, 11384, 12304 ]
    - [ 8589, 8235, 7393, 8504, 8265, 9380, 9651, 9753, 11934, 12071 ]
    - [ 8583, 7679, 7852, 8324, 8389, 9590, 10153, 10724, 11987, 12690 ]
    - [ 8901, 8178, 8679, 8966, 8480, 9527, 10055, 10581, 11254, 12724 ]
    - [ 8842, 8898, 7987, 8043, 8568, 9569, 10363, 10195, 12214, 12216 ]
  2.0:
    - [ 4057, 3105, 3148, 3267, 3176, 3181, 3309, 3213, 3220, 3231 ]
    - [ 4147, 3298, 3166, 3063, 3053, 3072, 3177, 3191, 3211, 3232 ]
    - [ 3957, 3272, 3144, 3246, 3055, 3285, 3298, 3416, 3223, 3125 ]
    - [ 3754, 3288, 3152, 3066, 2944, 3273, 3302, 3187, 3215, 3114 ]
    - [ 4144, 3193, 3045, 2939, 3045, 2950, 3080, 3202, 2885, 3225 ]
    - [ 4356, 3270, 3144, 3044, 3156, 3181, 3282, 3201, 3103, 3214 ]
    - [ 4046, 3267, 2937, 3157, 3361, 3058, 3195, 3187, 3204, 3347 ]
    - [ 3951, 3277, 3347, 3050, 3293, 3188, 3394, 3301, 3090, 3222 ]
    - [ 4253, 3279, 3030, 3156, 3263, 3296, 3080, 3203, 3205, 3241 ]
    - [ 4054, 3282, 3143, 3041, 3163, 3069, 3098, 3308, 3098, 3220 ]
  3.0:
    - [ 3720, 2861, 2613, 2719, 2728, 2731, 2748, 2757, 2765, 2778 ]
    - [ 3639, 2845, 2612, 2616, 2622, 2634, 2539, 2656, 2545, 2567 ]
    - [ 3613, 2744, 2610, 2612, 2620, 2525, 2536, 2649, 2548, 2561 ]
    - [ 3813, 2716, 2602, 2612, 2627, 2629, 2637, 2655, 2659, 2674 ]
    - [ 3715, 2823, 2611, 2611, 2513, 2519, 2533, 2542, 2547, 2557 ]
    - [ 3529, 2748, 2603, 2618, 2519, 2629, 2641, 2639, 2654, 2666 ]
    - [ 3812, 2836, 2592, 2605, 2634, 2522, 2644, 2644, 2552, 2667 ]
    - [ 3516, 2748, 2619, 2615, 2626, 2628, 2745, 2750, 2652, 2663 ]
    - [ 4019, 2736, 2617, 2513, 2511, 2626, 2839, 2755, 2657, 2772 ]
    - [ 3507, 2822, 2599, 2609, 2628, 2522, 2634, 2648, 2548, 2663 ]
  4.0:
    - [ 3722, 2620, 2495, 2488, 2490, 2399, 2402, 2407, 2409, 2415 ]
    - [ 3502, 2731, 2391, 2384, 2385, 2396, 2400, 2409, 2406, 2419 ]
    - [ 3511, 2609, 2387, 2392, 2497, 2497, 2404, 2406, 2513, 2528 ]
    - [ 3611, 2724, 2489, 2493, 2492, 2392, 2506, 2403, 2610, 2416 ]
    - [ 3598, 2620, 2490, 2386, 2491, 2395, 2510, 2409, 2408, 2422 ]
    - [ 3496, 2657, 2496, 2389, 2494, 2603, 2613, 2620, 2620, 2634 ]
    - [ 3606, 2635, 2500, 2483, 2389, 2499, 2408, 2508, 2406, 2421 ]
    - [ 3537, 2534, 2496, 2386, 2491, 2396, 2405, 2408, 2405, 2421 ]
    - [ 3605, 2616, 2392, 2488, 2493, 2394, 2402, 2407, 2411, 2416 ]
    - [ 3605, 2626, 2395, 2490, 2397, 2497, 2402, 2523, 2521, 2529 ]
  5.0:
    - [ 3728, 2519, 2389, 2381, 2390, 2394, 2404, 2308, 2305, 2421 ]
    - [ 3408, 2634, 2399, 2487, 2391, 2392, 2294, 2409, 2405, 2416 ]
    - [ 3409, 2641, 2296, 2386, 2387, 2397, 2399, 2403, 2406, 2420 ]
    - [ 3499, 2622, 2391, 2387, 2387, 2602, 2405, 2410, 2416, 2424 ]
    - [ 3507, 2827, 2493, 2493, 2391, 2395, 2400, 2405, 2515, 2417 ]
    - [ 3510, 2536, 2488, 2393, 2390, 2498, 2404, 2299, 2406, 2420 ]
    - [ 3402, 2630, 2390, 2484, 2391, 2297, 2407, 2406, 2304, 2416 ]
    - [ 3618, 2625, 2490, 2485, 2387, 2388, 2503, 2406, 2407, 2418 ]
    - [ 3940, 2621, 2397, 2382, 2386, 2400, 2408, 2399, 2409, 2320 ]
    - [ 3531, 2639, 2393, 2390, 2390, 2394, 2397, 2510, 2407, 2414 ]
  6.0:
    - [ 3522, 2647, 2492, 2385, 2389, 2396, 2403, 2407, 2408, 2427 ]
    - [ 3834, 2527, 2390, 2491, 2494, 2395, 2407, 2509, 2514, 2421 ]
    - [ 3511, 2624, 2394, 2384, 2386, 2402, 2407, 2405, 2405, 2311 ]
    - [ 3399, 2527, 2501, 2493, 2388, 2502, 2403, 2408, 2408, 2428 ]
    - [ 3612, 2517, 2395, 2392, 2385, 2392, 2401, 2401, 2406, 2516 ]
    - [ 3411, 2618, 2394, 2387, 2393, 2393, 2407, 2408, 2418, 2426 ]
    - [ 3502, 2639, 2388, 2384, 2385, 2406, 2511, 2302, 2405, 2308 ]
    - [ 3609, 2619, 2398, 2386, 2385, 2389, 2305, 2402, 2308, 2419 ]
    - [ 3395, 2617, 2496, 2382, 2386, 2287, 2396, 2313, 2406, 2310 ]
    - [ 3507, 2513, 2400, 2483, 2389, 2287, 2395, 2410, 2312, 2322 ]
  7.0:
    - [ 3624, 2534, 2389, 2385, 2382, 2395, 2401, 2399, 2407, 2415 ]
    - [ 3318, 2724, 2391, 2392, 2387, 2505, 2510, 2512, 2519, 2538 ]
    - [ 3614, 2551, 2395, 2389, 2489, 2394, 2393, 2405, 2406, 2312 ]
    - [ 3507, 2640, 2394, 2285, 2391, 2294, 2301, 2409, 2408, 2422 ]
    - [ 3613, 2624, 2390, 2386, 2284, 2287, 2293, 2406, 2401, 2420 ]
    - [ 3715, 2545, 2389, 2386, 2285, 2395, 2298, 2303, 2414, 2312 ]
    - [ 3724, 2727, 2385, 2382, 2490, 2395, 2397, 2403, 2307, 2413 ]
    - [ 3503, 2620, 2392, 2484, 2388, 2395, 2398, 2510, 2305, 2415 ]
    - [ 3407, 2719, 2392, 2386, 2386, 2393, 2404, 2402, 2411, 2416 ]
    - [ 3513, 2623, 2386, 2381, 2390, 2393, 2407, 2403, 2405, 2308 ]
  8.0:
    - [ 3612, 2642, 2402, 2388, 2284, 2394, 2399, 2400, 2404, 2310 ]
    - [ 3511, 2619, 2493, 2384, 2386, 2290, 2399, 2403, 2409, 2312 ]
    - [ 3504, 2630, 2397, 2386, 2392, 2294, 2302, 2406, 2306, 2315 ]
    - [ 3505, 2527, 2396, 2386, 2389, 2287, 2299, 2402, 2406, 2317 ]
    - [ 3601, 2522, 2395, 2394, 2286, 2287, 2401, 2506, 2309, 2308 ]
    - [ 3597, 2523, 2293, 2395, 2387, 2397, 2301, 2404, 2300, 2414 ]
    - [ 3413, 2517, 2394, 2388, 2287, 2287, 2401, 2401, 2304, 2313 ]
    - [ 3502, 2622, 2397, 2290, 2386, 2395, 2403, 2297, 2402, 2309 ]
    - [ 3499, 2618, 2387, 2387, 2285, 2288, 2298, 2303, 2306, 2412 ]
    - [ 3594, 2517, 2391, 2393, 2388, 2292, 2396, 2408, 2403, 2417 ]
  9.0:
    - [ 3393, 2523, 2397, 2385, 2397, 2294, 2396, 2299, 2404, 2432 ]
    - [ 3399, 2523, 2491, 2388, 2288, 2286, 2301, 2314, 2310, 2310 ]
    - [ 3599, 2518, 2394, 2394, 2387, 2288, 2399, 2306, 2405, 2323 ]
    - [ 3509, 2522, 2505, 2387, 2390, 2301, 2399, 2407, 2322, 2330 ]
    - [ 3391, 2625, 2393, 2288, 2389, 2409, 2397, 2300, 2324, 2328 ]
    - [ 3498, 2514, 2402, 2291, 2383, 2289, 2307, 2308, 2298, 2417 ]
    - [ 3395, 2529, 2393, 2400, 2284, 2401, 2402, 2296, 2298, 2415 ]
    - [ 3494, 2520, 2291, 2947, 2284, 2290, 2400, 2302, 2315, 2333 ]
    - [ 3416, 2519, 2300, 2285, 2385, 2288, 2295, 2319, 2309, 2313 ]
    - [ 3508, 2615, 2391, 2384, 2292, 2389, 2412, 2304, 2321, 2334 ]
  10.0:
    - [ 3602, 2516, 2391, 2493, 2285, 2288, 2297, 2404, 2305, 2315 ]
    - [ 3505, 2622, 2390, 2384, 2387, 2287, 2399, 2298, 2411, 2315 ]
    - [ 3520, 2534, 2488, 2386, 2395, 2294, 2297, 2509, 2407, 2317 ]
    - [ 3508, 2517, 2391, 2282, 2289, 2288, 2296, 2301, 2307, 2521 ]
    - [ 3522, 2451, 2392, 2283, 2296, 2289, 2295, 2302, 2311, 2318 ]
    - [ 3501, 2528, 2393, 2386, 2285, 2289, 2295, 2299, 2414, 2311 ]
    - [ 3614, 2631, 2493, 2392, 2285, 2286, 2403, 2300, 2308, 2314 ]
    - [ 3602, 2521, 2289, 2283, 2392, 2292, 2399, 2298, 2303, 2416 ]
    - [ 3498, 2530, 2293, 2388, 2284, 2391, 2295, 2197, 2302, 2315 ]
    - [ 3598, 2628, 2285, 2279, 2388, 2291, 2294, 2302, 2404, 2316 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 8208, 5816, 4397, 4530, 4248, 4057, 3856, 4218, 4182, 4014 ]
    - [ 7756, 5407, 4550, 3844, 3916, 3989, 3985, 4331, 3991, 3958 ]
    - [ 7987, 5348, 4849, 4096, 4094, 4008, 3858, 4085, 3909, 3973 ]
    - [ 8316, 5216, 4827, 3842, 4059, 4158, 4148, 4291, 4076, 3895 ]
    - [ 8076, 5421, 4799, 4693, 4290, 4046, 4007, 3978, 3892, 3988 ]
    - [ 8328, 5115, 4388, 3487, 4076, 4419, 4200, 3840, 4010, 4225 ]
    - [ 8238, 5589, 4574, 3738, 3966, 4011, 3746, 4178, 3867, 3999 ]
    - [ 8937, 5313, 4528, 4020, 3945, 3960, 3986, 3854, 3846, 3836 ]
    - [ 7452, 4941, 4710, 3924, 4068, 4103, 4043, 4390, 4002, 4150 ]
    - [ 7820, 5454, 4744, 4070, 4074, 4051, 4336, 4262, 3907, 4128 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 4561, 3843, 3691, 3804, 3725, 3823, 3715, 3703, 3657, 3691 ]
    - [ 4699, 3830, 3780, 3669, 3574, 3695, 3831, 4031, 3886, 3718 ]
    - [ 4912, 3826, 3628, 3583, 3799, 3900, 3645, 3645, 3713, 3665 ]
    - [ 4990, 3934, 3569, 3808, 3525, 3834, 3852, 3728, 3721, 3900 ]
    - [ 4676, 3780, 3694, 3664, 3593, 3617, 3661, 3861, 3632, 3777 ]
    - [ 4505, 3813, 3728, 3818, 3810, 3868, 3779, 3739, 3790, 3824 ]
    - [ 4798, 3791, 3757, 3735, 3856, 3476, 3736, 3694, 3908, 3649 ]
    - [ 4579, 3916, 3636, 3711, 3564, 3861, 3746, 3727, 3859, 3781 ]
    - [ 4712, 3823, 3790, 3852, 3911, 3749, 3695, 3766, 3945, 3911 ]
    - [ 4579, 3813, 3693, 3608, 3815, 3641, 3720, 3801, 3841, 3665 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5456, 4650, 4775, 4956, 4862, 4774, 5004, 4663, 5009, 4931 ]
    - [ 5341, 4705, 4629, 4571, 4853, 4914, 4942, 4721, 5122, 4994 ]
    - [ 5326, 4822, 4676, 4753, 4977, 4923, 4933, 5057, 4956, 4958 ]
    - [ 5456, 4677, 4563, 4722, 4968, 4957, 5255, 4924, 4943, 5402 ]
    - [ 5286, 4778, 4903, 5030, 4873, 5090, 4852, 4872, 4864, 4908 ]
    - [ 5586, 4596, 4820, 4813, 4936, 4715, 4957, 4965, 4912, 4979 ]
    - [ 5504, 4909, 4822, 4579, 4807, 4690, 4951, 5004, 5079, 5130 ]
    - [ 5443, 4813, 4601, 4703, 5089, 4934, 5148, 4890, 4834, 5203 ]
    - [ 5641, 4834, 4796, 4840, 5076, 5113, 4945, 4900, 4990, 5183 ]
    - [ 5673, 4719, 4757, 4650, 5002, 4849, 4818, 4737, 4999, 5106 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 10648, 9725, 10940, 9472, 10521, 9921, 11290, 11103, 11434, 9863 ]
    - [ 10604, 9651, 10386, 10700, 10366, 9615, 11267, 10738, 10552, 10470 ]
    - [ 9940, 9458, 10331, 9495, 10547, 10294, 9904, 11055, 10656, 10490 ]
    - [ 10075, 10165, 9821, 10518, 11256, 11218, 10902, 11307, 10800, 10480 ]
    - [ 10424, 10196, 10966, 10583, 10385, 11013, 10585, 11080, 10619, 10158 ]
    - [ 9971, 9044, 9222, 8768, 11654, 10716, 11039, 11046, 12463, 10822 ]
    - [ 9937, 9432, 9800, 9972, 10999, 11037, 11586, 10879, 10550, 10841 ]
    - [ 10367, 10189, 9450, 10433, 12017, 10672, 11471, 11348, 12985, 11387 ]
    - [ 10126, 9506, 9551, 9902, 10454, 10287, 11372, 11040, 11118, 11007 ]
    - [ 9542, 9465, 11079, 10432, 10860, 10043, 10557, 11246, 10721, 10558 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 10343, 10594, 10595, 10438, 10582, 11068, 12172, 12075, 12888, 13846 ]
    - [ 10193, 10334, 10592, 11013, 10677, 10821, 11399, 12155, 12398, 13129 ]
    - [ 10370, 10187, 10290, 10292, 10888, 10820, 11557, 12358, 13372, 13875 ]
    - [ 10014, 10448, 10611, 11001, 10619, 10981, 11602, 11943, 12403, 13514 ]
    - [ 11142, 11629, 11264, 11892, 11994, 12736, 13897, 13597, 14990, 15825 ]
    - [ 10219, 10381, 11158, 11233, 10444, 11008, 11967, 12607, 12805, 13894 ]
    - [ 10545, 10150, 10129, 10697, 10799, 11217, 11753, 12457, 12970, 13972 ]
    - [ 9957, 10226, 10228, 10152, 10291, 11259, 11682, 12160, 12494, 13600 ]
    - [ 10335, 10603, 10494, 10675, 10870, 10907, 11802, 12083, 12844, 13857 ]
    - [ 10111, 10579, 10693, 10835, 10563, 11181, 11723, 11812, 12495, 13630 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 7157, 6593, 6841, 7005, 6886, 6342, 6738, 7044, 7913, 8574 ]
    - [ 7248, 6922, 7022, 6677, 6765, 6716, 7131, 7635, 8696, 8935 ]
    - [ 6920, 6855, 6411, 7090, 6995, 6477, 7160, 7245, 7958, 9361 ]
    - [ 7167, 6967, 6697, 6755, 6883, 6402, 7111, 7484, 7362, 9316 ]
    - [ 6826, 6677, 6683, 6810, 6977, 6752, 7329, 6995, 7792, 9153 ]
    - [ 6695, 7332, 6837, 6736, 7081, 6725, 7087, 7295, 7740, 8891 ]
    - [ 7123, 7424, 6536, 6878, 6957, 6608, 7751, 7363, 8301, 8944 ]
    - [ 6906, 6946, 6387, 6553, 6564, 6933, 6815, 6805, 7776, 8478 ]
    - [ 7052, 6872, 7003, 6848, 6827, 6673, 7258, 7913, 8109, 9198 ]
    - [ 7033, 7082, 6980, 6632, 6829, 6035, 7049, 7033, 8271, 8889 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 59108, 57002, 57239, 57392, 58273 ]
    - [ 58442, 56687, 58069, 56988, 57264 ]
    - [ 58859, 58261, 57673, 56976, 57607 ]
    - [ 60216, 56761, 56502, 57166, 58436 ]
    - [ 58737, 56116, 56197, 57072, 56248 ]
    - [ 57171, 57795, 57953, 56604, 57684 ]
    - [ 58433, 58032, 56633, 56052, 57070 ]
    - [ 58857, 55673, 56683, 57883, 58238 ]
    - [ 58331, 57360, 56847, 56673, 57206 ]
    - [ 57470, 58108, 57675, 56348, 55655 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5713, 4902, 4765, 4585, 4736 ]
    - [ 5570, 4748, 4664, 4735, 4623 ]
    - [ 5494, 4902, 4944, 5102, 4864 ]
    - [ 5400, 4745, 4689, 4805, 4761 ]
    - [ 5504, 4553, 4590, 4504, 4638 ]
    - [ 5482, 4764, 4818, 4800, 5010 ]
    - [ 5684, 4895, 4548, 4587, 4701 ]
    - [ 5470, 4565, 4456, 4474, 4632 ]
    - [ 5407, 4775, 4657, 4479, 4522 ]
    - [ 5581, 4758, 4546, 4817, 4568 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4800, 4410, 4647, 4562, 4630 ]
    - [ 4697, 4621, 4431, 4576, 4740 ]
    - [ 5008, 4521, 4545, 4469, 4518 ]
    - [ 4789, 4530, 4435, 4354, 4399 ]
    - [ 4906, 4411, 4537, 4472, 4615 ]
    - [ 4906, 4623, 4757, 4664, 4730 ]
    - [ 4689, 4519, 4446, 4460, 4616 ]
    - [ 4809, 4935, 4653, 4797, 4611 ]
    - [ 4898, 4420, 4548, 4462, 4387 ]
    - [ 5004, 4523, 4530, 4457, 4710 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 12540, 7049, 5009, 4305, 4617 ]
    - [ 12438, 7361, 7323, 6305, 6916 ]
    - [ 12524, 7115, 7245, 7111, 7024 ]
    - [ 12698, 7917, 4294, 4352, 4480 ]
    - [ 12728, 7752, 4469, 4444, 4543 ]
    - [ 12900, 7614, 7409, 7350, 6908 ]
    - [ 12517, 7454, 4330, 4267, 4337 ]
    - [ 12251, 6910, 5076, 4354, 4335 ]
    - [ 12383, 7448, 7469, 4444, 4294 ]
    - [ 12394, 7609, 7395, 7206, 6451 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 4879, 3581, 3296, 3378, 3530 ]
    - [ 4572, 3500, 3604, 3288, 3387 ]
    - [ 4271, 3597, 3293, 3380, 3495 ]
    - [ 4573, 3508, 3506, 3499, 3525 ]
    - [ 4382, 3272, 3385, 3268, 3397 ]
    - [ 4482, 3419, 3191, 3293, 3404 ]
    - [ 4570, 3359, 3275, 3172, 3305 ]
    - [ 4469, 3315, 3285, 3168, 3408 ]
    - [ 4479, 3372, 3398, 3264, 3288 ]
    - [ 4673, 3414, 3390, 3274, 3412 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4912, 3839, 3842, 3625, 3943 ]
    - [ 5056, 4075, 3935, 3841, 3740 ]
    - [ 5033, 4056, 3718, 3812, 3852 ]
    - [ 4849, 3961, 3806, 3596, 3949 ]
    - [ 5150, 4060, 3725, 3394, 3729 ]
    - [ 5157, 3832, 3738, 3510, 3619 ]
    - [ 5025, 3943, 3620, 3822, 3821 ]
    - [ 5156, 3837, 3926, 3716, 3634 ]
    - [ 4731, 3946, 3822, 3837, 3816 ]
    - [ 5244, 3771, 3497, 3712, 3729 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4662, 3694, 3264, 3370, 3178 ]
    - [ 4473, 3500, 3263, 3577, 3381 ]
    - [ 4469, 3809, 3359, 3361, 3384 ]
    - [ 4661, 3508, 3276, 3356, 3475 ]
    - [ 4268, 3418, 3484, 3361, 3394 ]
    - [ 4584, 3379, 3470, 3380, 3390 ]
    - [ 4475, 3396, 3460, 3362, 3602 ]
    - [ 4053, 3405, 3467, 3274, 3387 ]
    - [ 4345, 3708, 3370, 3160, 3480 ]
    - [ 4268, 3692, 3380, 3160, 3268 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5082, 4685, 4126, 4046, 4178 ]
    - [ 5142, 4162, 4065, 3840, 3962 ]
    - [ 5049, 4172, 4039, 4163, 4170 ]
    - [ 4949, 4169, 3960, 3963, 3975 ]
    - [ 5192, 4062, 4043, 3954, 4187 ]
    - [ 5274, 4368, 4052, 4357, 4059 ]
    - [ 5073, 4162, 3948, 4064, 4182 ]
    - [ 4858, 4178, 3943, 4038, 4183 ]
    - [ 4959, 4178, 4050, 3961, 4288 ]
    - [ 5270, 4584, 4057, 4085, 3965 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 4001, 3156, 2941, 3156, 2950 ]
    - [ 3987, 3369, 3044, 2934, 3051 ]
    - [ 3906, 3158, 2932, 3054, 3066 ]
    - [ 3896, 3149, 2830, 2941, 3163 ]
    - [ 4028, 2978, 3037, 3259, 3059 ]
    - [ 3690, 3154, 3155, 2939, 3166 ]
    - [ 3919, 3206, 2928, 2823, 3044 ]
    - [ 3905, 3263, 3040, 3045, 3173 ]
    - [ 3917, 3034, 2938, 3056, 3154 ]
    - [ 3700, 3156, 3049, 2937, 3152 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 4497, 3640, 3487, 3385, 3403, 3421, 3314, 3326, 3339, 3471 ]
    - [ 4600, 3520, 3284, 3376, 3394, 3307, 3316, 3333, 3338, 3362 ]
    - [ 4602, 3534, 3293, 3382, 3294, 3306, 3426, 3436, 3340, 3366 ]
    - [ 4594, 3424, 3179, 3304, 3291, 3424, 3320, 3330, 3339, 3467 ]
    - [ 4384, 3526, 3379, 3378, 3309, 3309, 3431, 3224, 3437, 3474 ]
    - [ 4796, 3522, 3402, 3379, 3304, 3413, 3435, 3340, 3462, 3359 ]
    - [ 4491, 3630, 3493, 3275, 3508, 3425, 3421, 3341, 3350, 3364 ]
    - [ 4393, 3527, 3283, 3486, 3400, 3421, 3322, 3441, 3451, 3476 ]
    - [ 4599, 3439, 3391, 3388, 3406, 3410, 3327, 3330, 3351, 3391 ]
    - [ 4709, 3643, 3397, 3383, 3287, 3314, 3318, 3325, 3451, 3367 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 10925, 7032, 6116, 5059, 4963, 4961, 5042, 5057, 5125, 5137 ]
    - [ 10911, 7066, 6196, 5710, 5098, 5178, 5250, 5439, 4916, 5301 ]
    - [ 11895, 7351, 6136, 5176, 4977, 5065, 5322, 4995, 5098, 5707 ]
    - [ 10664, 6995, 6103, 5101, 5286, 5292, 5021, 5097, 5298, 5408 ]
    - [ 10601, 7010, 6197, 4711, 4736, 5270, 5184, 4808, 5083, 5708 ]
    - [ 10834, 7170, 6127, 5206, 5024, 4904, 4775, 5088, 5106, 5172 ]
    - [ 11962, 7655, 5935, 5748, 5148, 5216, 4894, 5126, 5083, 5374 ]
    - [ 11409, 7068, 6313, 4557, 4874, 5398, 5293, 5026, 5303, 5205 ]
    - [ 11052, 7201, 6335, 4781, 4983, 4827, 5145, 5203, 5297, 5061 ]
    - [ 13274, 5966, 5950, 4609, 5727, 6014, 5411, 5315, 4705, 5194 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 9761, 8458, 8879, 8507, 8591, 9039, 9593, 9358, 9983, 10700 ]
    - [ 10182, 9082, 8616, 9050, 9110, 9094, 9928, 9502, 10071, 11004 ]
    - [ 9534, 8489, 8732, 9089, 8799, 9421, 9928, 9851, 10050, 11004 ]
    - [ 10209, 9361, 8394, 9041, 9363, 9121, 9716, 9960, 10105, 11118 ]
    - [ 10637, 9259, 8982, 8761, 9152, 8751, 9477, 9569, 10565, 11703 ]
    - [ 10452, 8795, 9044, 8956, 9219, 9101, 9643, 9747, 9690, 10730 ]
    - [ 10286, 8647, 8888, 9231, 9510, 9183, 10113, 9930, 10253, 11522 ]
    - [ 10683, 9014, 8690, 8892, 8498, 9290, 9048, 9737, 10127, 10764 ]
    - [ 10206, 9158, 8622, 8904, 8948, 9205, 9413, 9868, 9890, 10528 ]
    - [ 10185, 8825, 8805, 9213, 9283, 9080, 9956, 10069, 10041, 10470 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 14799, 10225, 8092, 6716, 8284, 8787, 7485, 8049, 7971, 8429 ]
    - [ 13516, 10450, 7110, 6600, 9691, 7457, 7124, 7321, 7852, 8084 ]
    - [ 14973, 11482, 7640, 6936, 8012, 8507, 7590, 7811, 7782, 9298 ]
    - [ 15364, 9295, 9459, 7201, 8269, 7656, 7458, 7797, 7776, 8106 ]
    - [ 14806, 10053, 7126, 7224, 7417, 7407, 7297, 6758, 7343, 6974 ]
    - [ 17045, 9787, 6604, 6156, 7084, 7445, 7180, 7408, 7694, 8000 ]
    - [ 16259, 10672, 8100, 6871, 7090, 8151, 7202, 7797, 7719, 8529 ]
    - [ 14771, 10389, 6730, 7808, 8431, 8469, 7617, 7859, 8070, 8140 ]
    - [ 13467, 10297, 8295, 8276, 7853, 7916, 7360, 7644, 8373, 7800 ]
    - [ 17122, 10312, 7397, 6317, 7224, 7488, 7675, 7510, 7113, 7857 ]
