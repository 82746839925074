#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [8, 107, 5/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [7, 6682, 6/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (3893342000/582600.0)]
BAL: [9, 294, 3/20, 0, 34, 2204, nominal aaload per usec]
BAS: [9, 90, 2/20, 0, 1, 126, nominal aastore per usec]
BEF: [5, 4, 11/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [8, 6389, 4/20, 0, 527, 32087, nominal getfield per usec]
BPF: [9, 807, 3/20, 0, 83, 3863, nominal putfield per usec]
BUB: [3, 21, 15/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 2, 15/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [9, 114, 3/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (16/14)']
GCC: [9, 13484, 3/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [9, 114, 3/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (16/14)']
GCP: [10, 78, 1/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (26582/34002)]
GLK: [8, 7, 5/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (14/13)]
GMD: [1, 13, 20/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [0, 13, 20/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [1, 17, 20/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [10, 7638, 1/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (45086/582.6)']
GTO: [8, 285, 4/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [0, 0, 22/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [1, 13, 20/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 12, 12/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [1, 13, 20/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [9, 14, 4/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [7, 19, 8/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 4, 13/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [5, 6, 12/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [1, 1, 20/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [6, 101, 10/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [3, 13, 17/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [7, 29, 7/22, 5, 23, 41, nominal backend bound (memory) ( 56781921488.0 / 65119411049.7) * 34 )]
UBP: [5, 39, 12/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.03995009046388817 * 645729094.9 ) / ( 645729094.9 + 12950700.0 )']
UBR: [4, 785, 15/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.03995009046388817 * 12950700.0 ) / ( 645729094.9 + 12950700.0 ) )']
UBS: [5, 39, 12/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 123293064141.6 - 98788324552.6 ) / ( 6 * 102230638714.7 ) )]
UDC: [9, 20, 4/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 2073981279.4/ 100495939033.4 )]
UDT: [8, 441, 5/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 44374137.3 / 100495939033.4 )]
UIP: [1, 98, 21/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 100495939033.4/102230638714.7)]
ULL: [8, 5045, 5/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 263348882.8) / 100495939033.4 ) )]
USB: [7, 34, 8/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 209450444571.7 / ( 6 * 102099819029.2 ) )]
USC: [6, 97, 9/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 60636351214.2 / ( 6 * 103458920686.0 ) )]
USF: [8, 36, 6/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 221583511259.3/( 6 * 102230638714.7) )]
