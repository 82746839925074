#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [4, 42, 13/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [0, 54, 20/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (369147344/6815200.0)]
BAL: [1, 0, 18/20, 0, 34, 2204, nominal aaload per usec]
BAS: [4, 0, 13/20, 0, 1, 126, nominal aastore per usec]
BEF: [5, 4, 11/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [1, 26, 19/20, 0, 527, 32087, nominal getfield per usec]
BPF: [1, 10, 18/20, 0, 83, 3863, nominal putfield per usec]
BUB: [5, 34, 11/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [5, 4, 10/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [1, 24, 21/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (7/29)']
GCC: [0, 31, 22/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [1, 24, 21/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (7/29)']
GCP: [1, 0, 21/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (137/69079)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (29/29)]
GMD: [4, 29, 14/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [2, 29, 17/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [7, 29, 8/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [3, 29, 17/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [1, 0, 21/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (6860.3/6815.2)']
GTO: [1, 12, 18/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [1, 72, 20/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [0, 1, 22/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [10, 7, 2/22, 1, 3, 8, nominal execution time (sec)]
PFS: [1, 0, 21/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [0, 1, 22/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [8, 8, 6/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [2, 0, 19/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 19/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [2, 3, 19/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [3, 0, 16/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [1, 1, 20/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [0, 2, 22/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [1, 1, 20/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [2, 19, 18/22, 5, 23, 41, nominal backend bound (memory) ( 456923791.2 / 617589447.1) * 27 )]
UBP: [9, 89, 4/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.09066601868188921 * 10762529.3 ) / ( 10762529.3 + 147577.0 )']
UBR: [7, 1226, 8/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.09066601868188921 * 147577.0 ) / ( 10762529.3 + 147577.0 ) )']
UBS: [9, 90, 4/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 3266794999.9 - 2699103923.6 ) / ( 6 * 1043557230.8 ) )]
UDC: [5, 11, 13/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 24365539.3/ 2133487112.3 )]
UDT: [4, 96, 15/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 206399.9 / 2133487112.3 )]
UIP: [7, 204, 7/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 2133487112.3/1043557230.8)]
ULL: [2, 1558, 18/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 1508114.1) / 2133487112.3 ) )]
USB: [4, 27, 14/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 2126339311.9 / ( 6 * 1273352646.7 ) )]
USC: [0, 1, 22/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 13381610.6 / ( 6 * 1243235310.5 ) )]
USF: [7, 32, 8/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 2004236438.2/( 6 * 1043557230.8) )]
