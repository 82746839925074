#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 15620, 12960, 12022, 12143, 12250, 12697, 13416, 13242, 12840, 13695 ]
    - [ 14637, 12894, 12132, 12060, 12317, 16666, 12308, 12738, 13094, 12351 ]
    - [ 15216, 12337, 15045, 12510, 13400, 12890, 12111, 12532, 12195, 12649 ]
    - [ 14911, 12782, 14909, 16461, 13295, 13375, 12936, 12680, 12742, 12244 ]
    - [ 14291, 12315, 12197, 12299, 12139, 12688, 12167, 15210, 12900, 12479 ]
    - [ 14179, 12267, 12068, 12209, 12618, 12164, 13106, 12302, 12607, 12761 ]
    - [ 15160, 12786, 12351, 12726, 12585, 12515, 12770, 13314, 12864, 12351 ]
    - [ 15727, 12429, 12330, 12637, 12930, 12764, 12363, 12237, 12262, 13407 ]
    - [ 14577, 12628, 12219, 12269, 12919, 13389, 12556, 16368, 13790, 13167 ]
    - [ 14460, 16145, 12126, 12147, 13002, 12234, 12150, 12823, 14680, 13649 ]
  2.0:
    - [ 3545, 2794, 2607, 2604, 2619, 2632, 2605, 2615, 2624, 2618 ]
    - [ 3810, 2888, 2672, 2671, 2678, 2667, 2666, 2688, 2683, 2681 ]
    - [ 3650, 3202, 2655, 2671, 2666, 2653, 2666, 2637, 2658, 2642 ]
    - [ 3716, 2871, 2648, 2683, 2656, 2661, 2652, 2668, 2664, 2682 ]
    - [ 3919, 3102, 2666, 2656, 2680, 2662, 2655, 2657, 2679, 2656 ]
    - [ 3758, 2800, 2698, 2688, 2685, 2690, 2673, 2667, 2683, 2657 ]
    - [ 3662, 2964, 2670, 2640, 2647, 2645, 2653, 2667, 2651, 2652 ]
    - [ 4001, 3008, 2681, 2668, 2673, 2667, 2687, 2688, 2680, 2689 ]
    - [ 3733, 2911, 2630, 2624, 2628, 2614, 2637, 2627, 2629, 2622 ]
    - [ 3762, 2928, 2692, 2680, 2689, 2694, 2698, 2682, 2709, 2686 ]
  3.0:
    - [ 3783, 2865, 2658, 2623, 2633, 2612, 2616, 2625, 2647, 2636 ]
    - [ 3744, 2823, 2671, 2630, 2650, 2628, 2645, 2647, 2640, 2641 ]
    - [ 3626, 2920, 2638, 2629, 2668, 2655, 2661, 2660, 2698, 2653 ]
    - [ 3811, 2893, 2641, 2666, 2669, 2630, 2630, 2641, 2632, 2655 ]
    - [ 3680, 2902, 2696, 2723, 2672, 2658, 2688, 2679, 2675, 2665 ]
    - [ 3615, 2921, 2599, 2645, 2720, 2649, 2640, 2629, 2622, 2755 ]
    - [ 3844, 2879, 2644, 2658, 2641, 2653, 2649, 2630, 2650, 2659 ]
    - [ 3586, 2920, 2655, 2656, 2669, 2674, 2647, 2660, 2694, 2681 ]
    - [ 3848, 2917, 2608, 2647, 2628, 2605, 2614, 2636, 2613, 2631 ]
    - [ 3812, 2806, 2609, 2591, 2622, 2554, 2600, 2573, 2594, 2598 ]
  4.0:
    - [ 3666, 2862, 2652, 2664, 2655, 2668, 2651, 2657, 2659, 2667 ]
    - [ 3671, 2880, 2642, 2597, 2630, 2618, 2608, 2636, 2617, 2626 ]
    - [ 3795, 2806, 2616, 2626, 2608, 2636, 2642, 2623, 2612, 2626 ]
    - [ 3539, 2825, 2654, 2623, 2593, 2628, 2640, 2640, 2628, 2635 ]
    - [ 3617, 2916, 2668, 2634, 2645, 2642, 2645, 2646, 2651, 2632 ]
    - [ 3676, 2968, 2666, 2634, 2662, 2644, 2653, 2661, 2657, 2670 ]
    - [ 3766, 3002, 2647, 2634, 2643, 2653, 2627, 2635, 2644, 2626 ]
    - [ 3817, 2977, 2649, 2670, 2676, 2623, 2690, 2641, 2642, 2670 ]
    - [ 3843, 2932, 2684, 2647, 2678, 2671, 2663, 2678, 2668, 2649 ]
    - [ 3718, 2861, 2654, 2669, 2659, 2659, 2657, 2662, 2664, 2659 ]
  5.0:
    - [ 3489, 3073, 2690, 2684, 2698, 2624, 2686, 2684, 2637, 2681 ]
    - [ 3760, 2881, 2668, 2653, 2636, 2651, 2654, 2646, 2642, 2655 ]
    - [ 3582, 2859, 2608, 2629, 2598, 2607, 2602, 2609, 2610, 2615 ]
    - [ 3749, 2890, 2588, 2584, 2571, 2587, 2599, 2609, 2600, 2589 ]
    - [ 3582, 3065, 2658, 2651, 2639, 2662, 2634, 2655, 2652, 2669 ]
    - [ 3740, 2999, 2615, 2604, 2601, 2658, 2609, 2604, 2599, 2600 ]
    - [ 3927, 2893, 2646, 2636, 2631, 2617, 2630, 2629, 2621, 2637 ]
    - [ 3681, 3064, 2620, 2657, 2632, 2625, 2623, 2635, 2640, 2633 ]
    - [ 3608, 3049, 2628, 2628, 2637, 2635, 2630, 2640, 2626, 2629 ]
    - [ 3623, 2930, 2638, 2650, 2639, 2641, 2662, 2655, 2645, 2642 ]
  6.0:
    - [ 3836, 3023, 2714, 2701, 2681, 2685, 2692, 2725, 2699, 2704 ]
    - [ 3680, 3105, 2645, 2653, 2636, 2614, 2659, 2645, 2648, 2662 ]
    - [ 3802, 2802, 2623, 2623, 2645, 2621, 2636, 2642, 2626, 2628 ]
    - [ 3736, 2965, 2636, 2638, 2634, 2613, 2642, 2640, 2639, 2644 ]
    - [ 3781, 3006, 2698, 2689, 2710, 2701, 2680, 2686, 2671, 2700 ]
    - [ 3607, 3051, 2574, 2564, 2568, 2584, 2594, 2579, 2569, 2575 ]
    - [ 3605, 2991, 2630, 2607, 2626, 2592, 2609, 2585, 2594, 2621 ]
    - [ 3588, 2868, 2635, 2640, 2629, 2636, 2624, 2638, 2627, 2643 ]
    - [ 3806, 3015, 2685, 2691, 2671, 2684, 2679, 2732, 2662, 2670 ]
    - [ 3605, 2938, 2630, 2623, 2605, 2624, 2624, 2618, 2618, 2617 ]
  7.0:
    - [ 3744, 2901, 2606, 2634, 2599, 2610, 2618, 2601, 2614, 2611 ]
    - [ 3676, 2902, 2576, 2567, 2574, 2576, 2573, 2558, 2551, 2577 ]
    - [ 3776, 2870, 2652, 2673, 2662, 2673, 2676, 2675, 2667, 2663 ]
    - [ 3771, 2811, 2589, 2606, 2579, 2596, 2584, 2577, 2577, 2594 ]
    - [ 3691, 2776, 2582, 2587, 2588, 2586, 2594, 2588, 2590, 2581 ]
    - [ 3759, 2944, 2628, 2635, 2644, 2617, 2684, 2634, 2634, 2612 ]
    - [ 3926, 2923, 2665, 2675, 2665, 2657, 2655, 2629, 2630, 2624 ]
    - [ 3708, 2979, 2649, 2658, 2649, 2637, 2656, 2673, 2666, 2661 ]
    - [ 3683, 2900, 2628, 2652, 2628, 2620, 2636, 2626, 2617, 2640 ]
    - [ 3788, 2896, 2589, 2604, 2596, 2602, 2580, 2590, 2588, 2595 ]
  8.0:
    - [ 3591, 3039, 2616, 2618, 2606, 2603, 2593, 2612, 2619, 2593 ]
    - [ 3761, 2827, 2585, 2590, 2588, 2589, 2588, 2578, 2583, 2582 ]
    - [ 3792, 2730, 2626, 2604, 2602, 2599, 2613, 2618, 2622, 2632 ]
    - [ 3685, 2911, 2614, 2610, 2614, 2611, 2604, 2624, 2602, 2615 ]
    - [ 3720, 2869, 2658, 2632, 2636, 2654, 2650, 2633, 2641, 2622 ]
    - [ 3618, 2814, 2632, 2624, 2603, 2614, 2621, 2638, 2625, 2627 ]
    - [ 3669, 2851, 2575, 2602, 2572, 2573, 2586, 2562, 2564, 2576 ]
    - [ 3891, 2868, 2638, 2682, 2655, 2636, 2670, 2660, 2650, 2676 ]
    - [ 3909, 2855, 2675, 2651, 2640, 2649, 2638, 2684, 2681, 2672 ]
    - [ 3770, 2898, 2609, 2615, 2585, 2608, 2607, 2615, 2600, 2606 ]
  9.0:
    - [ 3699, 3036, 2637, 2627, 2616, 2624, 2634, 2657, 2620, 2621 ]
    - [ 3818, 2778, 2607, 2596, 2594, 2594, 2585, 2577, 2606, 2594 ]
    - [ 3654, 2951, 2666, 2645, 2662, 2663, 2665, 2636, 2655, 2666 ]
    - [ 3881, 3025, 2624, 2627, 2635, 2635, 2641, 2656, 2644, 2647 ]
    - [ 3651, 2895, 2613, 2609, 2607, 2601, 2599, 2603, 2616, 2608 ]
    - [ 3695, 2739, 2597, 2617, 2578, 2595, 2620, 2621, 2611, 2624 ]
    - [ 3516, 2770, 2595, 2583, 2577, 2570, 2584, 2585, 2576, 2606 ]
    - [ 3612, 2924, 2655, 2649, 2656, 2655, 2632, 2617, 2638, 2653 ]
    - [ 3580, 2712, 2573, 2589, 2590, 2564, 2586, 2587, 2576, 2578 ]
    - [ 3535, 2789, 2624, 2627, 2629, 2616, 2619, 2624, 2614, 2625 ]
  10.0:
    - [ 3697, 2953, 2637, 2639, 2620, 2618, 2602, 2631, 2636, 2634 ]
    - [ 3738, 2959, 2666, 2670, 2648, 2647, 2646, 2678, 2628, 2672 ]
    - [ 3856, 2955, 2588, 2584, 2574, 2580, 2595, 2607, 2590, 2584 ]
    - [ 3648, 2736, 2603, 2601, 2584, 2580, 2577, 2607, 2591, 2587 ]
    - [ 3652, 2899, 2592, 2592, 2594, 2604, 2590, 2589, 2585, 2590 ]
    - [ 3856, 2852, 2695, 2682, 2690, 2685, 2670, 2695, 2700, 2697 ]
    - [ 3760, 2989, 2610, 2613, 2605, 2621, 2609, 2605, 2626, 2608 ]
    - [ 3626, 2967, 2702, 2661, 2704, 2671, 2681, 2682, 2692, 2673 ]
    - [ 3645, 2750, 2586, 2612, 2599, 2605, 2602, 2589, 2583, 2585 ]
    - [ 3599, 2966, 2607, 2601, 2611, 2602, 2603, 2599, 2605, 2596 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 10305, 8431, 8090, 8078, 8086, 8089, 8108, 8094, 8093, 8083 ]
    - [ 11112, 8544, 8101, 8086, 8132, 8074, 8073, 8136, 8043, 8099 ]
    - [ 10356, 8284, 8049, 8047, 8044, 8007, 8044, 8039, 8083, 8042 ]
    - [ 10743, 8404, 8064, 8085, 8096, 8082, 8110, 8051, 8079, 8072 ]
    - [ 10384, 8298, 8055, 8027, 8022, 8034, 8049, 8010, 8019, 8062 ]
    - [ 10698, 8558, 8076, 8038, 8095, 8068, 8050, 8057, 8099, 8061 ]
    - [ 10463, 8334, 8073, 8090, 8088, 8053, 8069, 8079, 8087, 8064 ]
    - [ 10741, 8454, 8059, 8091, 8073, 8085, 8066, 8096, 8110, 8090 ]
    - [ 10706, 8501, 8082, 8079, 8101, 8100, 8137, 8062, 8099, 8119 ]
    - [ 10895, 8417, 8096, 8082, 8118, 8122, 8065, 8114, 8086, 8085 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3588, 2827, 2584, 2577, 2586, 2578, 2594, 2586, 2567, 2574 ]
    - [ 3776, 2760, 2562, 2554, 2554, 2574, 2575, 2578, 2573, 2561 ]
    - [ 3441, 2849, 2573, 2595, 2572, 2589, 2584, 2574, 2599, 2599 ]
    - [ 3559, 2886, 2571, 2580, 2595, 2575, 2590, 2570, 2554, 2620 ]
    - [ 3458, 2768, 2653, 2640, 2620, 2623, 2624, 2645, 2624, 2622 ]
    - [ 3527, 2875, 2557, 2532, 2569, 2568, 2562, 2541, 2553, 2583 ]
    - [ 3661, 2793, 2564, 2578, 2558, 2566, 2564, 2576, 2554, 2577 ]
    - [ 3516, 2848, 2609, 2634, 2596, 2633, 2599, 2614, 2600, 2603 ]
    - [ 3620, 2940, 2657, 2640, 2664, 2641, 2675, 2651, 2643, 2664 ]
    - [ 3546, 2901, 2587, 2597, 2570, 2569, 2571, 2574, 2578, 2581 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3578, 3008, 2768, 2784, 2752, 2738, 2785, 2779, 2781, 2781 ]
    - [ 3892, 2827, 2765, 2759, 2753, 2747, 2752, 2762, 2736, 2744 ]
    - [ 3771, 2851, 2722, 2709, 2719, 2714, 2710, 2734, 2733, 2728 ]
    - [ 3898, 3059, 2686, 2708, 2721, 2700, 2731, 2684, 2703, 2702 ]
    - [ 3675, 3026, 2685, 2727, 2720, 2715, 2679, 2663, 2697, 2696 ]
    - [ 3658, 3109, 2762, 2772, 2794, 2775, 2789, 2785, 2777, 2795 ]
    - [ 3701, 2969, 2667, 2710, 2720, 2678, 2731, 2710, 2721, 2724 ]
    - [ 3532, 2883, 2712, 2720, 2716, 2700, 2708, 2747, 2715, 2740 ]
    - [ 3719, 3070, 2696, 2692, 2697, 2669, 2689, 2698, 2693, 2703 ]
    - [ 3820, 2964, 2738, 2729, 2744, 2753, 2742, 2744, 2733, 2745 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 8264, 7775, 7499, 7840, 7049, 7558, 7225, 6499, 7535, 7691 ]
    - [ 8855, 7390, 7189, 7082, 7116, 6972, 7289, 7039, 6720, 7731 ]
    - [ 8816, 7596, 7561, 7178, 7938, 7107, 6938, 6778, 6783, 7933 ]
    - [ 8328, 7695, 6631, 6870, 7537, 7088, 7326, 6853, 6876, 7720 ]
    - [ 8182, 7577, 7271, 7719, 7452, 7369, 7009, 7905, 7300, 7630 ]
    - [ 7290, 7308, 7652, 7936, 7456, 7293, 7371, 7440, 7367, 7309 ]
    - [ 7928, 7546, 7722, 7199, 7670, 7317, 7520, 7584, 7765, 7312 ]
    - [ 8575, 7518, 7187, 7259, 7369, 8709, 6906, 7330, 7894, 7245 ]
    - [ 7749, 6819, 7396, 6574, 6831, 6541, 7676, 7130, 7682, 7265 ]
    - [ 8139, 8018, 7649, 7624, 6784, 7243, 7126, 6940, 8019, 6971 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 4128, 3433, 3261, 3259, 3277, 3254, 3294, 3289, 3296, 3271 ]
    - [ 4147, 3624, 3286, 3286, 3269, 3276, 3276, 3281, 3268, 3262 ]
    - [ 4176, 3738, 3468, 3458, 3453, 3465, 3432, 3463, 3444, 3459 ]
    - [ 4391, 3467, 3278, 3250, 3270, 3217, 3249, 3224, 3219, 3265 ]
    - [ 4042, 3567, 3273, 3288, 3284, 3252, 3219, 3296, 3261, 3237 ]
    - [ 4107, 3609, 3265, 3252, 3278, 3277, 3220, 3224, 3278, 3298 ]
    - [ 4275, 3815, 3528, 3508, 3474, 3437, 3446, 3510, 3490, 3510 ]
    - [ 4234, 3594, 3287, 3238, 3264, 3227, 3250, 3233, 3281, 3244 ]
    - [ 4212, 3357, 3228, 3228, 3265, 3237, 3238, 3284, 3196, 3261 ]
    - [ 4192, 3645, 3218, 3293, 3248, 3252, 3295, 3279, 3238, 3268 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 4162, 3421, 3117, 3144, 3081, 3105, 3091, 3111, 3089, 3094 ]
    - [ 4049, 3513, 3310, 3329, 3320, 3243, 3308, 3268, 3263, 3254 ]
    - [ 4100, 3329, 3082, 3104, 3110, 3073, 3101, 3131, 3111, 3118 ]
    - [ 4138, 3355, 3207, 3165, 3193, 3185, 3167, 3164, 3176, 3201 ]
    - [ 4190, 3498, 3331, 3328, 3285, 3284, 3255, 3235, 3271, 3282 ]
    - [ 4084, 3274, 3095, 3094, 3109, 3139, 3132, 3146, 3133, 3149 ]
    - [ 3877, 3384, 3172, 3175, 3131, 3071, 3135, 3129, 3102, 3124 ]
    - [ 3948, 3482, 3278, 3245, 3219, 3261, 3247, 3243, 3215, 3239 ]
    - [ 4024, 3233, 3108, 3102, 3149, 3087, 3127, 3071, 3110, 3073 ]
    - [ 4078, 3524, 3226, 3244, 3283, 3244, 3246, 3244, 3249, 3269 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 192522, 195951, 197066, 197042, 195462 ]
    - [ 195046, 195990, 196707, 196675, 190670 ]
    - [ 191882, 190839, 189701, 191831, 195904 ]
    - [ 190753, 191815, 195138, 193211, 195760 ]
    - [ 194596, 192925, 195636, 195492, 193712 ]
    - [ 192434, 191149, 191222, 192897, 191556 ]
    - [ 193856, 196917, 194773, 192246, 193179 ]
    - [ 194004, 196740, 192629, 195730, 197423 ]
    - [ 192047, 194252, 192661, 194071, 195220 ]
    - [ 193131, 192187, 191170, 190766, 193246 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 12006, 11506, 11500, 11510, 11487 ]
    - [ 11980, 11576, 11490, 11525, 11532 ]
    - [ 12180, 11719, 11732, 11758, 11689 ]
    - [ 12006, 11494, 11509, 11524, 11512 ]
    - [ 12227, 11801, 11678, 11704, 11746 ]
    - [ 12052, 11545, 11648, 11530, 11583 ]
    - [ 11991, 11505, 11463, 11454, 11458 ]
    - [ 12108, 11598, 11525, 11554, 11542 ]
    - [ 11982, 11534, 11471, 11506, 11602 ]
    - [ 11954, 11446, 11460, 11425, 11497 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 8984, 8786, 8820, 8853, 8907 ]
    - [ 9005, 8814, 8816, 8809, 8797 ]
    - [ 8961, 8821, 8873, 8785, 8828 ]
    - [ 9005, 8837, 8854, 8826, 8814 ]
    - [ 9001, 8839, 8850, 8823, 8845 ]
    - [ 8948, 8823, 8843, 8780, 8781 ]
    - [ 8991, 8802, 8788, 8764, 8792 ]
    - [ 9016, 8783, 8841, 8798, 8882 ]
    - [ 9058, 8848, 8872, 8838, 8779 ]
    - [ 8981, 8825, 8833, 8833, 8822 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 14219, 8926, 8683, 8700, 8708 ]
    - [ 14163, 8885, 8736, 8713, 8740 ]
    - [ 14228, 8852, 8714, 8735, 8699 ]
    - [ 14151, 8858, 8732, 8730, 8672 ]
    - [ 14247, 8867, 8659, 8662, 8681 ]
    - [ 13976, 8932, 8755, 8727, 8712 ]
    - [ 14183, 8883, 8689, 8673, 8643 ]
    - [ 14247, 8903, 8699, 8705, 8715 ]
    - [ 13876, 8838, 8679, 8661, 8704 ]
    - [ 14215, 8893, 8709, 8721, 8714 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3884, 2871, 2696, 2663, 2687 ]
    - [ 4006, 2953, 2780, 2791, 2785 ]
    - [ 4012, 2939, 2740, 2736, 2726 ]
    - [ 3788, 2913, 2725, 2763, 2728 ]
    - [ 3794, 2977, 2757, 2770, 2745 ]
    - [ 3877, 2933, 2747, 2743, 2764 ]
    - [ 3920, 3026, 2715, 2716, 2715 ]
    - [ 3901, 2993, 2732, 2726, 2730 ]
    - [ 3845, 2977, 2722, 2712, 2724 ]
    - [ 3811, 2932, 2729, 2704, 2715 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3948, 3130, 2835, 2818, 2828 ]
    - [ 4108, 3108, 2825, 2797, 2809 ]
    - [ 4177, 3080, 2840, 2823, 2807 ]
    - [ 3932, 3199, 2797, 2806, 2815 ]
    - [ 3864, 3113, 2791, 2808, 2831 ]
    - [ 4083, 3135, 2847, 2847, 2825 ]
    - [ 3911, 3177, 2853, 2829, 2832 ]
    - [ 4176, 3159, 2810, 2824, 2841 ]
    - [ 3876, 3132, 2807, 2810, 2816 ]
    - [ 4129, 3213, 2853, 2848, 2844 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3688, 2920, 2724, 2696, 2732 ]
    - [ 3695, 2857, 2615, 2610, 2629 ]
    - [ 3788, 2883, 2661, 2671, 2670 ]
    - [ 3769, 2969, 2636, 2649, 2648 ]
    - [ 3718, 2743, 2590, 2604, 2629 ]
    - [ 3812, 3208, 2691, 2719, 2686 ]
    - [ 3717, 2964, 2694, 2707, 2716 ]
    - [ 3545, 3003, 2681, 2685, 2684 ]
    - [ 3592, 2985, 2655, 2633, 2634 ]
    - [ 3602, 2965, 2667, 2653, 2673 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4011, 3239, 2974, 2952, 2957 ]
    - [ 4145, 3247, 2963, 2961, 2977 ]
    - [ 4050, 3179, 2994, 2976, 2966 ]
    - [ 3942, 3297, 2992, 3001, 2999 ]
    - [ 4151, 3121, 2920, 2911, 2921 ]
    - [ 4087, 3218, 2941, 2924, 2924 ]
    - [ 3971, 3185, 2910, 2898, 2908 ]
    - [ 3936, 3291, 2947, 2970, 2965 ]
    - [ 4101, 3193, 2907, 2910, 2874 ]
    - [ 3973, 3135, 2914, 2937, 2939 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3028, 2580, 2308, 2337, 2352 ]
    - [ 3270, 2623, 2316, 2335, 2325 ]
    - [ 3164, 2511, 2350, 2317, 2335 ]
    - [ 3234, 2556, 2303, 2274, 2297 ]
    - [ 3282, 2592, 2369, 2379, 2394 ]
    - [ 3069, 2424, 2302, 2299, 2298 ]
    - [ 3273, 2525, 2344, 2333, 2318 ]
    - [ 3141, 2712, 2342, 2350, 2327 ]
    - [ 3282, 2466, 2362, 2331, 2350 ]
    - [ 3105, 2539, 2294, 2342, 2311 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 5150, 4197, 3863, 3909, 3871, 3871, 3867, 3906, 3867, 3907 ]
    - [ 5203, 4222, 3874, 3860, 3889, 3857, 3868, 3906, 3888, 3886 ]
    - [ 5023, 4354, 3955, 3936, 3942, 3932, 3947, 3938, 3946, 3947 ]
    - [ 4993, 4244, 3920, 3932, 3948, 3914, 3897, 3941, 3894, 3962 ]
    - [ 4980, 4342, 3861, 3847, 3878, 3886, 3858, 3861, 3869, 3861 ]
    - [ 4905, 4353, 3861, 3893, 3878, 3866, 3890, 3875, 3874, 3893 ]
    - [ 4969, 4332, 3877, 3867, 3864, 3861, 3855, 3888, 3850, 3905 ]
    - [ 5008, 4197, 3892, 3844, 3876, 3914, 3872, 3865, 3865, 3862 ]
    - [ 5272, 4350, 3854, 3867, 3869, 3878, 3841, 3865, 3849, 3852 ]
    - [ 4992, 4195, 3944, 3932, 3939, 3937, 3932, 3926, 3975, 3922 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 14011, 11272, 10844, 10827, 10873, 10917, 10877, 10839, 10871, 10904 ]
    - [ 14080, 11550, 11004, 11022, 10949, 10988, 11001, 10990, 11019, 11009 ]
    - [ 14383, 11434, 10993, 11026, 11011, 11008, 10969, 10983, 11025, 10967 ]
    - [ 14365, 11329, 11012, 11006, 10972, 10979, 11012, 10952, 11054, 11089 ]
    - [ 14756, 11495, 10890, 10787, 10787, 10826, 10857, 10829, 10821, 10861 ]
    - [ 14523, 11428, 11044, 10968, 10989, 11026, 11018, 10988, 11049, 11006 ]
    - [ 13732, 11407, 10853, 10822, 10860, 10825, 10850, 10853, 10898, 10873 ]
    - [ 14571, 11358, 11041, 11055, 11035, 11013, 10965, 10981, 10985, 10997 ]
    - [ 14519, 11434, 11006, 11023, 11020, 11017, 10983, 10997, 10991, 10994 ]
    - [ 13894, 11241, 10844, 10869, 10897, 10833, 10827, 10832, 10838, 10860 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 6117, 5395, 5237, 5236, 5259, 5199, 5210, 5237, 5224, 5253 ]
    - [ 6227, 5495, 5393, 5359, 5354, 5412, 5362, 5336, 5355, 5398 ]
    - [ 6150, 5534, 5388, 5345, 5409, 5344, 5336, 5342, 5325, 5374 ]
    - [ 6181, 5562, 5359, 5391, 5345, 5362, 5386, 5358, 5374, 5355 ]
    - [ 6150, 5513, 5355, 5336, 5374, 5355, 5372, 5354, 5360, 5334 ]
    - [ 6291, 5511, 5373, 5371, 5367, 5380, 5380, 5371, 5328, 5366 ]
    - [ 6228, 5585, 5394, 5397, 5397, 5415, 5387, 5400, 5404, 5433 ]
    - [ 6288, 5462, 5396, 5420, 5364, 5364, 5395, 5389, 5341, 5361 ]
    - [ 6101, 5531, 5373, 5345, 5390, 5397, 5368, 5339, 5335, 5392 ]
    - [ 6266, 5335, 5230, 5262, 5217, 5216, 5246, 5232, 5230, 5255 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 19873, 17793, 17272, 17304, 17280, 17275, 17287, 17318, 17208, 17242 ]
    - [ 19837, 17918, 17289, 17329, 17358, 17331, 17186, 17340, 17227, 17322 ]
    - [ 20334, 17789, 17458, 17403, 17389, 17340, 17338, 17404, 17333, 17264 ]
    - [ 19917, 17533, 17280, 17192, 17203, 17213, 17346, 17198, 17121, 17161 ]
    - [ 20139, 17582, 17124, 17150, 17172, 17100, 17167, 17127, 17080, 17122 ]
    - [ 20005, 17697, 17254, 17266, 17291, 17276, 17190, 17303, 17162, 17155 ]
    - [ 20052, 17574, 17172, 17156, 17163, 17160, 17140, 17158, 17104, 17197 ]
    - [ 20003, 17957, 17475, 17344, 17360, 17347, 17216, 17397, 17269, 17347 ]
    - [ 20150, 17565, 17156, 17166, 17172, 17178, 17163, 17200, 17116, 17108 ]
    - [ 20166, 17484, 17182, 17120, 17148, 17156, 17254, 17204, 17096, 17094 ]
