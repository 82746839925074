#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 3957, 3893, 3526, 3527, 3839, 3815, 3817, 3748, 3782, 3584 ]
    - [ 3879, 3968, 3926, 3873, 3768, 3604, 3845, 3867, 3552, 3863 ]
    - [ 3824, 3907, 3833, 3486, 3841, 3733, 3606, 3780, 3731, 3848 ]
    - [ 4046, 3873, 3927, 3872, 3935, 3618, 3824, 3777, 3487, 3867 ]
    - [ 3719, 3888, 3531, 3521, 3787, 3847, 3838, 3762, 3795, 3880 ]
    - [ 3886, 3899, 3908, 3918, 3619, 3883, 3831, 3738, 3826, 3822 ]
    - [ 4028, 3950, 3923, 3628, 3933, 3950, 3717, 3775, 3804, 3893 ]
    - [ 4070, 3925, 3884, 3795, 3936, 3907, 3794, 3750, 3826, 3885 ]
    - [ 3834, 3617, 3696, 3693, 3663, 3707, 3781, 3768, 3773, 3882 ]
    - [ 3911, 3897, 3841, 3868, 3877, 3839, 3848, 3788, 3493, 3826 ]
  2.0:
    - [ 3177, 3430, 3024, 3384, 3352, 3370, 3396, 3326, 3304, 3107 ]
    - [ 3191, 3371, 3043, 3347, 3256, 3335, 3292, 3325, 3341, 3446 ]
    - [ 3516, 3347, 3047, 3324, 3326, 3204, 3205, 3311, 3334, 3405 ]
    - [ 3269, 3312, 3348, 3366, 3231, 2806, 3349, 3322, 3366, 3060 ]
    - [ 3522, 3032, 3400, 3360, 3341, 3353, 3349, 3291, 3371, 3115 ]
    - [ 3186, 3378, 3328, 3380, 3348, 3224, 3334, 3367, 3321, 3413 ]
    - [ 3509, 3367, 3188, 3196, 3311, 3385, 3155, 3194, 3030, 3412 ]
    - [ 3223, 3338, 3046, 3338, 3349, 3391, 3201, 3310, 3338, 3443 ]
    - [ 3159, 3367, 3206, 3336, 3324, 2795, 3269, 3343, 3371, 3109 ]
    - [ 3454, 3343, 3366, 3382, 3362, 3209, 3373, 3346, 3355, 3425 ]
  3.0:
    - [ 3303, 3342, 3144, 3222, 3146, 2998, 3261, 3235, 3339, 3047 ]
    - [ 3316, 3351, 2995, 3305, 3329, 3342, 3341, 3336, 3335, 3344 ]
    - [ 3428, 3330, 2991, 3275, 3237, 3259, 3260, 3260, 3335, 3387 ]
    - [ 3211, 3358, 3344, 3323, 3297, 3157, 3328, 3250, 3335, 3043 ]
    - [ 3132, 3327, 3315, 3301, 3256, 3314, 3251, 3146, 3322, 3315 ]
    - [ 3307, 3338, 3248, 3014, 3260, 3321, 3267, 3236, 3292, 3372 ]
    - [ 3398, 3262, 3297, 2978, 3311, 3146, 3299, 3232, 3120, 3316 ]
    - [ 3381, 3200, 2979, 3267, 3306, 3244, 3246, 3237, 3307, 3381 ]
    - [ 3434, 3177, 3056, 3310, 3320, 3258, 3271, 3247, 3258, 3209 ]
    - [ 3484, 3175, 3000, 3307, 3156, 3157, 3320, 3175, 3252, 3216 ]
  4.0:
    - [ 3466, 3285, 3309, 3314, 3335, 3289, 3337, 3244, 3324, 3327 ]
    - [ 3372, 3199, 3232, 3299, 3328, 3329, 3253, 3226, 3303, 3220 ]
    - [ 3456, 3184, 3333, 3302, 3305, 3282, 3245, 3250, 3251, 3402 ]
    - [ 3355, 3298, 3025, 3304, 3333, 3348, 3163, 3281, 3340, 3218 ]
    - [ 3381, 3270, 2970, 3261, 2978, 2970, 3300, 3231, 3316, 3280 ]
    - [ 3322, 3360, 3327, 2990, 3327, 3155, 3306, 3290, 3333, 3345 ]
    - [ 3346, 3162, 3290, 2962, 3112, 3098, 3231, 3239, 3277, 3317 ]
    - [ 3484, 3304, 3351, 3322, 3349, 3171, 3296, 3263, 3350, 3338 ]
    - [ 3319, 3333, 3307, 3306, 3336, 3009, 3256, 3319, 3334, 3217 ]
    - [ 3366, 3277, 3306, 3283, 3320, 3250, 3286, 3317, 3317, 3377 ]
  5.0:
    - [ 3464, 3268, 3141, 3299, 3334, 3322, 3242, 3238, 3130, 3209 ]
    - [ 3128, 3343, 3321, 3293, 3000, 3152, 3321, 3239, 3339, 3317 ]
    - [ 3039, 3297, 3002, 3314, 3374, 3333, 3272, 3269, 3332, 3234 ]
    - [ 2896, 3326, 3329, 3006, 3267, 3151, 3271, 3310, 3247, 3399 ]
    - [ 3408, 3364, 3189, 3299, 3349, 3338, 3160, 3259, 3286, 3226 ]
    - [ 3418, 3168, 3259, 3274, 2969, 2982, 3337, 3233, 3311, 3309 ]
    - [ 3425, 3185, 2994, 3300, 3335, 3312, 3133, 3179, 3272, 3218 ]
    - [ 3419, 3290, 3252, 3296, 3153, 3138, 3329, 3257, 3322, 3212 ]
    - [ 3139, 3346, 3344, 3325, 3358, 3303, 3281, 3285, 3349, 3084 ]
    - [ 3393, 3202, 2988, 3313, 3267, 3328, 3260, 3273, 3332, 3060 ]
  6.0:
    - [ 2909, 3263, 3253, 3282, 3319, 3243, 3298, 3310, 3306, 3391 ]
    - [ 3457, 3025, 3290, 3314, 3351, 3358, 3294, 3281, 3335, 3357 ]
    - [ 3321, 3306, 3012, 3312, 3333, 3314, 3142, 3251, 3340, 3210 ]
    - [ 3437, 3276, 3252, 2987, 3324, 3149, 3286, 3233, 3329, 3357 ]
    - [ 3151, 3342, 3335, 3295, 3337, 2988, 3348, 3312, 3331, 3330 ]
    - [ 3307, 3291, 3169, 3291, 3332, 3268, 3148, 3240, 3257, 3204 ]
    - [ 3413, 3352, 2991, 3291, 3011, 2991, 3320, 3246, 3315, 3339 ]
    - [ 3306, 3337, 3019, 3294, 3340, 3262, 3314, 3302, 3330, 3322 ]
    - [ 2914, 3286, 3350, 3312, 3338, 3144, 3334, 3255, 3316, 3320 ]
    - [ 3491, 3291, 3000, 3300, 3007, 2987, 3267, 3238, 3278, 3223 ]
  7.0:
    - [ 3463, 3347, 3145, 3302, 3307, 3268, 3251, 3226, 3299, 3313 ]
    - [ 3432, 3194, 3342, 3319, 3355, 3156, 3344, 3331, 3338, 3424 ]
    - [ 3489, 3319, 3295, 3013, 3340, 3329, 3332, 3308, 3350, 3351 ]
    - [ 2902, 3179, 3140, 3261, 3306, 3307, 3237, 3226, 3303, 3050 ]
    - [ 3492, 3355, 3178, 3022, 3298, 3025, 3295, 3360, 3334, 3403 ]
    - [ 3383, 3318, 2984, 3310, 3321, 3152, 3149, 3172, 3324, 3327 ]
    - [ 3470, 3179, 2995, 3307, 3319, 3241, 3237, 3241, 3135, 3304 ]
    - [ 3475, 3197, 3348, 3004, 3175, 3310, 3328, 3257, 3328, 3357 ]
    - [ 3443, 3273, 2996, 3288, 3136, 3288, 3242, 3234, 3292, 3307 ]
    - [ 3455, 3258, 3300, 3321, 3353, 3331, 3254, 3257, 3317, 3348 ]
  8.0:
    - [ 3288, 3188, 3333, 3013, 3165, 3324, 3317, 3294, 3348, 3358 ]
    - [ 3424, 3305, 3163, 2970, 3062, 2747, 3256, 3262, 3313, 3341 ]
    - [ 3346, 3299, 3281, 2992, 3168, 3339, 3257, 3318, 3329, 3346 ]
    - [ 3253, 3302, 3273, 3295, 3277, 2977, 3309, 3231, 3309, 3196 ]
    - [ 3162, 3347, 3011, 3307, 3362, 3169, 3158, 3250, 3324, 3064 ]
    - [ 3399, 3176, 3276, 3001, 3316, 3266, 3266, 3321, 3246, 3332 ]
    - [ 3416, 3351, 3290, 3333, 3343, 3247, 3289, 3270, 3280, 3201 ]
    - [ 3449, 3006, 3324, 3293, 3320, 3275, 3263, 3258, 3302, 3384 ]
    - [ 3482, 3189, 3254, 3291, 3352, 3342, 3165, 3011, 3337, 3339 ]
    - [ 3143, 3246, 2978, 3281, 3275, 3240, 3114, 3230, 3298, 3293 ]
  9.0:
    - [ 3484, 3338, 2980, 3324, 3327, 2996, 3312, 3290, 3327, 3391 ]
    - [ 3457, 3281, 3310, 3291, 2990, 2989, 3306, 3248, 3264, 3220 ]
    - [ 3498, 3196, 2999, 3297, 3310, 3322, 3319, 3321, 3314, 3363 ]
    - [ 3455, 3356, 3003, 3313, 3331, 2986, 3249, 3246, 3153, 3209 ]
    - [ 3421, 3322, 3001, 2992, 3269, 3266, 3297, 3271, 3329, 3322 ]
    - [ 3404, 3271, 3286, 3290, 3306, 3295, 3281, 3295, 3328, 3314 ]
    - [ 3462, 3337, 3239, 3293, 3323, 3277, 3320, 3257, 3349, 3336 ]
    - [ 3383, 3317, 2983, 3303, 3335, 3257, 3266, 3231, 3260, 3223 ]
    - [ 3449, 3199, 3329, 2997, 3327, 3332, 3323, 3280, 3262, 3332 ]
    - [ 3462, 3304, 3306, 3319, 3331, 3292, 3321, 3345, 3352, 3412 ]
  10.0:
    - [ 3317, 3328, 3151, 3301, 3325, 2984, 3244, 3251, 3316, 3212 ]
    - [ 3405, 3172, 3331, 3322, 3321, 3329, 3322, 3331, 3329, 3390 ]
    - [ 3388, 3271, 3320, 3297, 3334, 3293, 3252, 3247, 3277, 3391 ]
    - [ 3404, 3257, 3298, 3288, 3294, 3300, 3234, 3170, 3299, 3227 ]
    - [ 3454, 3019, 3160, 3007, 3343, 3152, 3256, 3247, 3340, 3408 ]
    - [ 3455, 3348, 3150, 3311, 3272, 3173, 3292, 3285, 3318, 3333 ]
    - [ 3467, 3271, 3003, 3296, 3251, 3317, 3256, 3237, 3272, 3047 ]
    - [ 3396, 3295, 3186, 3283, 3349, 3276, 3309, 3312, 3355, 3324 ]
    - [ 3387, 3258, 3146, 2997, 3315, 3323, 3250, 3301, 3324, 3382 ]
    - [ 2900, 3353, 3318, 3305, 3342, 3188, 3283, 3273, 3355, 3387 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 5167, 4165, 4021, 3990, 3968, 3952, 3949, 3956, 3964, 3976 ]
    - [ 5017, 4115, 3907, 3936, 3917, 3899, 3891, 3945, 3916, 3883 ]
    - [ 4972, 4147, 3953, 3875, 3922, 3913, 3906, 3928, 3887, 3899 ]
    - [ 4864, 4143, 3926, 3908, 3921, 3846, 3881, 3884, 3876, 3881 ]
    - [ 4971, 4198, 3928, 3981, 3964, 3978, 4012, 3961, 3934, 3945 ]
    - [ 5020, 4137, 3984, 4009, 3963, 3943, 3952, 3968, 3943, 3971 ]
    - [ 4901, 4083, 3911, 3934, 3944, 3912, 3876, 3914, 3910, 3853 ]
    - [ 4920, 4110, 4000, 3957, 3947, 3946, 3896, 3907, 3902, 3948 ]
    - [ 5037, 4168, 3945, 3889, 3856, 3868, 3872, 3927, 3866, 3901 ]
    - [ 5010, 4159, 3933, 3913, 3995, 3932, 3968, 4019, 3941, 3913 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3327, 3306, 3298, 3340, 3286, 3038, 3279, 3268, 3360, 3359 ]
    - [ 3475, 3375, 3025, 3331, 3359, 3374, 3318, 3317, 3357, 3274 ]
    - [ 3524, 3293, 3168, 3301, 3299, 3282, 3273, 3263, 3346, 3356 ]
    - [ 3500, 3359, 3026, 3339, 3366, 3210, 3293, 3278, 3347, 3352 ]
    - [ 3450, 3327, 3356, 3343, 3378, 3374, 3284, 3307, 3379, 3361 ]
    - [ 3182, 3324, 3203, 3369, 3370, 3366, 3295, 3282, 3334, 3365 ]
    - [ 3531, 3398, 3390, 3331, 3360, 3365, 3286, 3274, 3354, 3244 ]
    - [ 3331, 3339, 3346, 3350, 3372, 3365, 3312, 3314, 3361, 3393 ]
    - [ 3504, 3375, 3384, 3351, 3345, 3337, 3376, 3340, 3380, 3096 ]
    - [ 3468, 3336, 3216, 3330, 3371, 3364, 3306, 3312, 3349, 3428 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3338, 3202, 3176, 3340, 3346, 3298, 3334, 3270, 3355, 3398 ]
    - [ 3167, 3347, 3155, 3308, 3268, 3166, 3230, 3275, 3279, 3375 ]
    - [ 3154, 3205, 3169, 3024, 3358, 3174, 3350, 3317, 3340, 3406 ]
    - [ 3471, 3300, 3180, 3308, 3348, 3271, 3275, 3239, 3349, 3252 ]
    - [ 3136, 3192, 3161, 2993, 2998, 3281, 3254, 3274, 3340, 3410 ]
    - [ 3486, 3181, 3009, 3350, 3366, 3364, 3185, 3282, 3373, 3354 ]
    - [ 3470, 3297, 3001, 3310, 3326, 3267, 3148, 3257, 3331, 3340 ]
    - [ 3507, 3359, 3184, 3317, 3167, 3335, 3158, 3267, 3332, 3070 ]
    - [ 3155, 3342, 3189, 2990, 3347, 3159, 3264, 3279, 3331, 3392 ]
    - [ 3457, 3182, 2971, 3300, 3303, 3157, 3267, 3325, 3339, 3377 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 3257, 3173, 3136, 3258, 3183, 3425, 3159, 3402, 3473, 3352 ]
    - [ 2953, 3408, 3340, 3342, 3242, 3384, 3336, 3470, 3371, 3507 ]
    - [ 2997, 3355, 3349, 3421, 3428, 3426, 3440, 3394, 3438, 3297 ]
    - [ 3556, 3431, 3441, 3439, 3385, 3364, 3356, 3374, 3250, 3518 ]
    - [ 3067, 3330, 3442, 3233, 3429, 3472, 3416, 3247, 3450, 3524 ]
    - [ 3337, 3297, 3415, 3395, 3463, 3444, 3401, 3379, 3427, 3520 ]
    - [ 3225, 3450, 3343, 3363, 3433, 3354, 3366, 3295, 3477, 3510 ]
    - [ 3507, 3438, 3338, 3394, 3239, 2843, 3439, 3416, 3471, 3413 ]
    - [ 3490, 3208, 3320, 3441, 3273, 3353, 3335, 3372, 3483, 3557 ]
    - [ 3559, 3365, 3047, 3409, 3436, 3423, 3317, 3320, 3374, 3475 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 10674, 9957, 10100, 10041, 10012 ]
    - [ 10040, 10089, 10064, 10063, 10093 ]
    - [ 10400, 10187, 10015, 10059, 9987 ]
    - [ 10273, 10113, 10177, 10086, 10192 ]
    - [ 10186, 10112, 9957, 10063, 10027 ]
    - [ 10113, 10083, 10032, 10014, 10197 ]
    - [ 10099, 10053, 10148, 10283, 10013 ]
    - [ 10080, 10116, 9985, 9975, 10012 ]
    - [ 10104, 10024, 10053, 10199, 9975 ]
    - [ 10273, 9958, 10052, 10048, 10107 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3838, 3633, 3629, 3473, 3630 ]
    - [ 3853, 3572, 3587, 3580, 3599 ]
    - [ 3679, 3623, 3538, 3619, 3610 ]
    - [ 3809, 3605, 3583, 3575, 3597 ]
    - [ 3846, 3634, 3451, 3436, 3612 ]
    - [ 3862, 3543, 3594, 3629, 3586 ]
    - [ 3817, 3576, 3600, 3579, 3626 ]
    - [ 3659, 3601, 3469, 3415, 3573 ]
    - [ 3855, 3641, 3542, 3454, 3594 ]
    - [ 3769, 3627, 3621, 3433, 3591 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3713, 3618, 3611, 3605, 3671 ]
    - [ 3651, 3624, 3614, 3635, 3637 ]
    - [ 3612, 3603, 3539, 3571, 3610 ]
    - [ 3614, 3610, 3441, 3531, 3528 ]
    - [ 3703, 3578, 3602, 3599, 3663 ]
    - [ 3715, 3603, 3623, 3580, 3678 ]
    - [ 3631, 3586, 3603, 3617, 3686 ]
    - [ 3694, 3605, 3558, 3590, 3626 ]
    - [ 3656, 3639, 3610, 3586, 3680 ]
    - [ 3681, 3644, 3543, 3610, 3658 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6055, 4075, 3794, 3938, 3564 ]
    - [ 6130, 4053, 3894, 3941, 3266 ]
    - [ 6167, 4041, 3807, 3986, 3461 ]
    - [ 6177, 4080, 3824, 3967, 3277 ]
    - [ 5946, 4090, 3932, 4069, 3268 ]
    - [ 6183, 4067, 3932, 4002, 3538 ]
    - [ 6001, 4096, 3653, 3926, 3505 ]
    - [ 6179, 4076, 3785, 3954, 3431 ]
    - [ 6018, 4072, 3947, 3966, 3522 ]
    - [ 6177, 4025, 3789, 3857, 3543 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3608, 3410, 3330, 3389, 3436 ]
    - [ 3203, 3439, 3094, 3470, 3305 ]
    - [ 3544, 3407, 3332, 3402, 3429 ]
    - [ 3046, 3424, 3363, 3420, 3394 ]
    - [ 3640, 3380, 3232, 3398, 3420 ]
    - [ 3571, 3403, 3309, 3431, 3401 ]
    - [ 3486, 3381, 3391, 3357, 3380 ]
    - [ 3454, 3399, 3402, 3398, 3384 ]
    - [ 3483, 3394, 3250, 3404, 3411 ]
    - [ 3578, 3434, 3245, 3420, 3399 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3478, 3423, 3433, 3257, 3475 ]
    - [ 3615, 3396, 3349, 3236, 3488 ]
    - [ 3321, 3438, 3090, 3350, 3418 ]
    - [ 3561, 3291, 3348, 3116, 3404 ]
    - [ 3337, 3289, 3417, 3392, 3507 ]
    - [ 3548, 3446, 3338, 3088, 3448 ]
    - [ 3618, 3130, 3436, 3109, 3492 ]
    - [ 3611, 3404, 3271, 2827, 3417 ]
    - [ 3488, 3376, 3475, 3344, 3433 ]
    - [ 3595, 3157, 2880, 3406, 3456 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3505, 3392, 3059, 3105, 3367 ]
    - [ 3561, 3435, 3093, 3354, 3479 ]
    - [ 3518, 3433, 3396, 3392, 3309 ]
    - [ 3552, 3101, 3429, 3458, 3131 ]
    - [ 3402, 3095, 3219, 3340, 3422 ]
    - [ 3518, 3340, 3361, 3050, 3405 ]
    - [ 3448, 3389, 3263, 3350, 3416 ]
    - [ 3563, 3354, 3071, 3059, 3436 ]
    - [ 3418, 3100, 3390, 3250, 3134 ]
    - [ 3490, 3409, 3251, 3401, 3456 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3685, 3523, 3349, 3139, 3643 ]
    - [ 2982, 3145, 3490, 3494, 3568 ]
    - [ 3525, 3566, 3515, 3122, 3646 ]
    - [ 3691, 3524, 3299, 3138, 3565 ]
    - [ 3655, 3126, 3366, 3464, 3607 ]
    - [ 3581, 3489, 3558, 3530, 3385 ]
    - [ 3698, 3551, 3103, 3559, 3628 ]
    - [ 3487, 3359, 3306, 3564, 3602 ]
    - [ 3719, 3609, 3136, 3133, 3643 ]
    - [ 3475, 3560, 3475, 3478, 3548 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2854, 2838, 2588, 2840, 2619 ]
    - [ 3034, 2892, 2916, 2718, 2634 ]
    - [ 3032, 2948, 2740, 2729, 2880 ]
    - [ 3009, 2888, 2554, 2726, 2884 ]
    - [ 2980, 2884, 2822, 2815, 2908 ]
    - [ 2859, 2923, 2804, 2586, 2880 ]
    - [ 2457, 2548, 2831, 2879, 2791 ]
    - [ 2845, 2774, 2938, 2879, 2969 ]
    - [ 3028, 2755, 2828, 2900, 2962 ]
    - [ 3029, 2886, 2572, 2813, 2901 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 2646, 2492, 2520, 2458, 2475, 2482, 2578, 2508, 2407, 2445 ]
    - [ 2723, 2481, 2515, 2512, 2494, 2482, 2540, 2480, 2463, 2497 ]
    - [ 2646, 2483, 2505, 2476, 2519, 2499, 2528, 2467, 2466, 2594 ]
    - [ 2693, 2499, 2508, 2448, 2471, 2432, 2512, 2569, 2529, 2612 ]
    - [ 2679, 2576, 2491, 2475, 2451, 2539, 2430, 2605, 2419, 2540 ]
    - [ 2777, 2484, 2491, 2511, 2539, 2485, 2491, 2523, 2480, 2556 ]
    - [ 2685, 2551, 2543, 2500, 2518, 2435, 2501, 2421, 2487, 2577 ]
    - [ 2741, 2515, 2428, 2492, 2462, 2472, 2464, 2470, 2498, 2434 ]
    - [ 2734, 2530, 2444, 2477, 2426, 2468, 2436, 2436, 2453, 2550 ]
    - [ 2743, 2513, 2388, 2528, 2390, 2488, 2511, 2458, 2430, 2601 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 4836, 3471, 3201, 3187, 3153, 3143, 3121, 3145, 3124, 3139 ]
    - [ 4869, 3464, 3237, 3178, 3187, 3150, 3170, 3184, 3219, 3165 ]
    - [ 4899, 3486, 3186, 3176, 3221, 3172, 3161, 3165, 3129, 3165 ]
    - [ 4772, 3437, 3195, 3242, 3205, 3156, 3183, 3200, 3166, 3151 ]
    - [ 4786, 3396, 3222, 3187, 3213, 3114, 3171, 3163, 3196, 3145 ]
    - [ 4772, 3422, 3197, 3165, 3139, 3141, 3122, 3138, 3109, 3131 ]
    - [ 4826, 3380, 3221, 3195, 3191, 3192, 3180, 3143, 3145, 3158 ]
    - [ 4667, 3513, 3199, 3181, 3202, 3162, 3153, 3167, 3128, 3130 ]
    - [ 4990, 3558, 3193, 3190, 3208, 3176, 3187, 3165, 3158, 3178 ]
    - [ 4870, 3505, 3194, 3201, 3189, 3195, 3160, 3150, 3170, 3154 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 4968, 4659, 4677, 4630, 4717, 4617, 4646, 4650, 4566, 4657 ]
    - [ 4982, 4692, 4689, 4783, 4654, 4689, 4607, 4692, 4658, 4748 ]
    - [ 4709, 4509, 4527, 4440, 4500, 4500, 4604, 4496, 4586, 4542 ]
    - [ 5016, 4669, 4701, 4704, 4689, 4638, 4694, 4703, 4739, 4809 ]
    - [ 5056, 4698, 4697, 4669, 4671, 4717, 4797, 4732, 4679, 4795 ]
    - [ 4928, 4608, 4644, 4696, 4624, 4616, 4615, 4576, 4625, 4785 ]
    - [ 4993, 4755, 4703, 4728, 4663, 4680, 4655, 4724, 4589, 4767 ]
    - [ 5041, 4765, 4684, 4716, 4690, 4719, 4784, 4710, 4793, 4820 ]
    - [ 4941, 4667, 4651, 4686, 4624, 4620, 4713, 4628, 4609, 4763 ]
    - [ 4983, 4794, 4857, 4831, 4716, 4774, 4713, 4744, 4799, 4823 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 8152, 6543, 6201, 6162, 6151, 6074, 6172, 6122, 6143, 6078 ]
    - [ 8159, 6347, 6027, 6083, 5992, 5971, 5930, 5971, 5946, 5945 ]
    - [ 8205, 6333, 6005, 6043, 5925, 5969, 5915, 5997, 5952, 5942 ]
    - [ 8149, 6411, 6034, 6084, 6028, 6047, 6010, 5981, 5966, 6007 ]
    - [ 8227, 6309, 5950, 6073, 5986, 5970, 5899, 5940, 5953, 5956 ]
    - [ 8032, 6340, 6091, 6064, 6015, 5955, 6011, 6007, 6038, 5968 ]
    - [ 8122, 6356, 6235, 6170, 6120, 6120, 6130, 6130, 6116, 6118 ]
    - [ 8163, 6658, 6139, 6114, 6040, 6032, 6057, 6014, 6039, 6035 ]
    - [ 8231, 6363, 6087, 6098, 5963, 6005, 5960, 6004, 6048, 5971 ]
    - [ 8163, 6314, 6069, 6114, 5965, 5967, 5992, 5937, 5943, 5963 ]
