#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 76056, 41895, 42173, 60942, 60797, 41047, 46260, 37859, 43346, 39400 ]
    - [ 77421, 63556, 62230, 41876, 42680, 58957, 60884, 41493, 38719, 39094 ]
    - [ 77540, 45969, 38730, 39347, 39146, 43543, 48784, 61245, 59217, 42382 ]
    - [ 80076, 42780, 38394, 58910, 44660, 38187, 37933, 60334, 43374, 38861 ]
    - [ 76153, 46188, 62926, 61049, 60991, 42803, 41780, 58970, 59817, 61379 ]
    - [ 78667, 39690, 57955, 38277, 54528, 45215, 60738, 43489, 37983, 40117 ]
    - [ 77852, 48753, 62259, 47214, 38681, 42715, 49127, 42017, 44999, 62012 ]
    - [ 75742, 39956, 42616, 50921, 60590, 39807, 45583, 50452, 36952, 40553 ]
    - [ 76341, 48210, 38580, 61264, 38016, 51384, 38387, 60608, 40819, 38496 ]
    - [ 76824, 45067, 38597, 38101, 39105, 44282, 61235, 39572, 43296, 49953 ]
  2.0:
    - [ 5120, 3707, 3659, 3625, 3607, 3650, 3626, 3615, 3625, 3651 ]
    - [ 5191, 3748, 3699, 3664, 3622, 3628, 3617, 3627, 3619, 3636 ]
    - [ 5203, 3747, 3718, 3658, 3646, 3677, 3636, 3630, 3626, 3656 ]
    - [ 5201, 3761, 3699, 3659, 3623, 3658, 3631, 3606, 3593, 3652 ]
    - [ 5167, 3737, 3662, 3642, 3612, 3615, 3618, 3608, 3624, 3629 ]
    - [ 5226, 3769, 3709, 3655, 3619, 3647, 3617, 3623, 3617, 3644 ]
    - [ 5172, 3760, 3660, 3640, 3622, 3644, 3629, 3632, 3619, 3642 ]
    - [ 5153, 3748, 3677, 3633, 3620, 3628, 3616, 3623, 3601, 3629 ]
    - [ 5189, 3769, 3719, 3639, 3603, 3620, 3631, 3626, 3626, 3635 ]
    - [ 5173, 3755, 3692, 3639, 3634, 3663, 3634, 3627, 3628, 3658 ]
  3.0:
    - [ 4821, 3509, 3438, 3422, 3414, 3426, 3427, 3418, 3413, 3426 ]
    - [ 4792, 3487, 3435, 3430, 3425, 3415, 3420, 3422, 3418, 3440 ]
    - [ 4809, 3480, 3436, 3431, 3426, 3424, 3423, 3422, 3413, 3437 ]
    - [ 4831, 3485, 3436, 3427, 3431, 3424, 3422, 3408, 3418, 3424 ]
    - [ 4779, 3475, 3440, 3429, 3420, 3420, 3421, 3421, 3421, 3420 ]
    - [ 4768, 3479, 3443, 3432, 3426, 3413, 3422, 3416, 3413, 3422 ]
    - [ 4794, 3490, 3436, 3435, 3420, 3430, 3424, 3413, 3416, 3426 ]
    - [ 4742, 3471, 3429, 3425, 3420, 3414, 3415, 3429, 3414, 3429 ]
    - [ 4762, 3500, 3425, 3431, 3421, 3422, 3416, 3427, 3407, 3416 ]
    - [ 4768, 3485, 3430, 3427, 3423, 3417, 3424, 3416, 3423, 3425 ]
  4.0:
    - [ 4793, 3476, 3424, 3413, 3409, 3403, 3408, 3412, 3399, 3412 ]
    - [ 4771, 3462, 3418, 3415, 3410, 3411, 3412, 3408, 3398, 3410 ]
    - [ 4785, 3460, 3425, 3416, 3412, 3414, 3404, 3414, 3401, 3407 ]
    - [ 4778, 3465, 3425, 3419, 3410, 3408, 3407, 3406, 3409, 3407 ]
    - [ 4775, 3460, 3414, 3411, 3407, 3410, 3404, 3433, 3400, 3415 ]
    - [ 4775, 3469, 3431, 3421, 3408, 3409, 3413, 3402, 3400, 3411 ]
    - [ 4766, 3468, 3420, 3426, 3411, 3419, 3417, 3411, 3398, 3410 ]
    - [ 4758, 3470, 3426, 3416, 3415, 3437, 3403, 3413, 3423, 3415 ]
    - [ 4764, 3474, 3420, 3417, 3413, 3407, 3410, 3416, 3408, 3410 ]
    - [ 4777, 3483, 3420, 3417, 3405, 3411, 3403, 3414, 3410, 3413 ]
  5.0:
    - [ 4780, 3455, 3416, 3411, 3409, 3398, 3400, 3398, 3396, 3403 ]
    - [ 4753, 3455, 3426, 3398, 3398, 3402, 3393, 3399, 3390, 3399 ]
    - [ 4754, 3463, 3412, 3435, 3399, 3397, 3393, 3393, 3396, 3399 ]
    - [ 4756, 3473, 3420, 3409, 3407, 3406, 3401, 3397, 3396, 3407 ]
    - [ 4750, 3460, 3413, 3423, 3404, 3394, 3400, 3397, 3390, 3399 ]
    - [ 4754, 3449, 3414, 3406, 3398, 3394, 3398, 3393, 3396, 3399 ]
    - [ 4717, 3451, 3408, 3407, 3396, 3397, 3389, 3388, 3394, 3396 ]
    - [ 4763, 3459, 3408, 3409, 3403, 3403, 3396, 3397, 3395, 3396 ]
    - [ 4745, 3449, 3412, 3405, 3393, 3394, 3401, 3393, 3402, 3399 ]
    - [ 4780, 3457, 3417, 3401, 3407, 3402, 3398, 3399, 3392, 3408 ]
  6.0:
    - [ 4772, 3451, 3407, 3403, 3393, 3396, 3390, 3386, 3391, 3393 ]
    - [ 4756, 3445, 3413, 3400, 3391, 3396, 3394, 3390, 3396, 3391 ]
    - [ 4757, 3453, 3407, 3395, 3396, 3392, 3394, 3392, 3383, 3385 ]
    - [ 4776, 3456, 3402, 3405, 3397, 3394, 3391, 3392, 3386, 3402 ]
    - [ 4748, 3451, 3409, 3399, 3388, 3393, 3392, 3388, 3384, 3396 ]
    - [ 4751, 3448, 3413, 3402, 3395, 3393, 3394, 3391, 3384, 3390 ]
    - [ 4735, 3463, 3403, 3396, 3391, 3390, 3389, 3390, 3388, 3390 ]
    - [ 4753, 3446, 3409, 3396, 3392, 3394, 3386, 3396, 3386, 3399 ]
    - [ 4729, 3479, 3413, 3401, 3386, 3396, 3391, 3392, 3390, 3396 ]
    - [ 4775, 3449, 3409, 3398, 3396, 3399, 3395, 3401, 3395, 3393 ]
  7.0:
    - [ 4791, 3450, 3401, 3393, 3410, 3387, 3388, 3390, 3375, 3395 ]
    - [ 4722, 3451, 3407, 3396, 3396, 3397, 3390, 3394, 3389, 3391 ]
    - [ 4756, 3456, 3412, 3397, 3397, 3398, 3394, 3386, 3385, 3388 ]
    - [ 4757, 3460, 3403, 3393, 3392, 3384, 3386, 3387, 3382, 3381 ]
    - [ 4757, 3441, 3405, 3398, 3395, 3392, 3389, 3393, 3388, 3391 ]
    - [ 4789, 3442, 3404, 3399, 3393, 3393, 3389, 3385, 3392, 3391 ]
    - [ 4772, 3452, 3391, 3395, 3387, 3388, 3384, 3382, 3382, 3384 ]
    - [ 4744, 3456, 3403, 3399, 3392, 3393, 3384, 3390, 3393, 3409 ]
    - [ 4792, 3451, 3403, 3405, 3392, 3391, 3398, 3386, 3389, 3397 ]
    - [ 4745, 3453, 3411, 3396, 3386, 3387, 3389, 3392, 3392, 3394 ]
  8.0:
    - [ 4776, 3433, 3396, 3388, 3381, 3372, 3378, 3379, 3374, 3377 ]
    - [ 4761, 3444, 3394, 3390, 3392, 3386, 3375, 3380, 3377, 3375 ]
    - [ 4736, 3443, 3402, 3385, 3387, 3377, 3380, 3382, 3372, 3378 ]
    - [ 4750, 3443, 3398, 3402, 3389, 3381, 3387, 3379, 3375, 3383 ]
    - [ 4771, 3438, 3396, 3386, 3381, 3390, 3384, 3384, 3388, 3384 ]
    - [ 4752, 3428, 3401, 3387, 3379, 3384, 3381, 3375, 3377, 3377 ]
    - [ 4741, 3432, 3398, 3389, 3386, 3381, 3376, 3370, 3377, 3386 ]
    - [ 4726, 3440, 3394, 3395, 3391, 3379, 3379, 3381, 3383, 3382 ]
    - [ 4823, 3439, 3406, 3392, 3382, 3377, 3382, 3382, 3373, 3382 ]
    - [ 4746, 3444, 3396, 3394, 3385, 3385, 3374, 3386, 3376, 3381 ]
  9.0:
    - [ 4724, 3457, 3398, 3384, 3387, 3386, 3380, 3375, 3372, 3376 ]
    - [ 4720, 3430, 3388, 3385, 3378, 3380, 3374, 3380, 3376, 3380 ]
    - [ 4752, 3459, 3391, 3390, 3379, 3378, 3379, 3383, 3371, 3375 ]
    - [ 4738, 3437, 3388, 3391, 3387, 3378, 3377, 3370, 3372, 3375 ]
    - [ 4733, 3439, 3392, 3387, 3383, 3380, 3381, 3377, 3372, 3380 ]
    - [ 4782, 3434, 3390, 3387, 3378, 3376, 3382, 3373, 3382, 3377 ]
    - [ 4725, 3433, 3389, 3382, 3381, 3386, 3381, 3381, 3370, 3377 ]
    - [ 4727, 3434, 3392, 3384, 3381, 3376, 3374, 3373, 3376, 3375 ]
    - [ 4716, 3431, 3391, 3377, 3375, 3374, 3375, 3378, 3381, 3375 ]
    - [ 4729, 3442, 3392, 3378, 3374, 3372, 3366, 3375, 3374, 3382 ]
  10.0:
    - [ 4737, 3440, 3390, 3381, 3383, 3377, 3374, 3380, 3378, 3372 ]
    - [ 4741, 3440, 3397, 3381, 3377, 3378, 3367, 3369, 3370, 3370 ]
    - [ 4724, 3446, 3386, 3382, 3381, 3378, 3376, 3383, 3376, 3376 ]
    - [ 4756, 3435, 3396, 3384, 3379, 3379, 3382, 3376, 3376, 3380 ]
    - [ 4750, 3428, 3392, 3381, 3377, 3379, 3376, 3372, 3370, 3379 ]
    - [ 4769, 3438, 3393, 3385, 3387, 3383, 3380, 3375, 3374, 3370 ]
    - [ 4779, 3431, 3388, 3388, 3380, 3379, 3378, 3373, 3375, 3370 ]
    - [ 4724, 3423, 3391, 3411, 3379, 3377, 3374, 3373, 3372, 3372 ]
    - [ 4741, 3440, 3389, 3380, 3374, 3378, 3378, 3372, 3383, 3388 ]
    - [ 4770, 3438, 3397, 3379, 3384, 3376, 3377, 3372, 3371, 3374 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 110373, 104558, 103837, 103570, 103616, 103582, 103571, 103744, 103513, 103529 ]
    - [ 110269, 104471, 103943, 103700, 103608, 103573, 103608, 103627, 103541, 103381 ]
    - [ 110236, 104603, 103846, 103516, 103586, 103492, 103590, 103504, 103369, 103470 ]
    - [ 110520, 104544, 103766, 103643, 103558, 103430, 103703, 103530, 103505, 103556 ]
    - [ 110526, 104565, 103837, 103751, 103574, 103506, 103431, 103783, 103448, 103605 ]
    - [ 110399, 104371, 103709, 103687, 103465, 103492, 103469, 103570, 103380, 103402 ]
    - [ 110191, 104565, 103798, 103702, 103664, 103380, 103477, 103664, 103334, 103666 ]
    - [ 110071, 104339, 103752, 103719, 103515, 103437, 103425, 103486, 103452, 103504 ]
    - [ 110235, 104610, 103752, 103658, 103634, 103524, 103442, 103548, 103593, 103414 ]
    - [ 110078, 104430, 103853, 103684, 103521, 103523, 103528, 103508, 103529, 103554 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 4955, 3539, 3438, 3429, 3428, 3431, 3424, 3422, 3414, 3429 ]
    - [ 4958, 3476, 3444, 3438, 3428, 3430, 3435, 3416, 3424, 3423 ]
    - [ 4866, 3476, 3434, 3426, 3427, 3426, 3418, 3420, 3404, 3437 ]
    - [ 4972, 3551, 3449, 3429, 3427, 3421, 3420, 3419, 3420, 3443 ]
    - [ 4931, 3481, 3431, 3425, 3429, 3421, 3416, 3429, 3411, 3429 ]
    - [ 4934, 3487, 3436, 3432, 3429, 3419, 3433, 3418, 3408, 3438 ]
    - [ 4936, 3559, 3466, 3430, 3482, 3423, 3484, 3424, 3408, 3435 ]
    - [ 4843, 3481, 3431, 3427, 3425, 3415, 3413, 3409, 3425, 3431 ]
    - [ 5002, 3476, 3441, 3429, 3419, 3425, 3430, 3416, 3412, 3433 ]
    - [ 4911, 3589, 3440, 3438, 3429, 3424, 3428, 3426, 3420, 3441 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5156, 3794, 3728, 3712, 3690, 3661, 3696, 3689, 3685, 3674 ]
    - [ 5186, 3782, 3723, 3700, 3648, 3650, 3670, 3640, 3669, 3684 ]
    - [ 5213, 3768, 3730, 3708, 3679, 3685, 3661, 3647, 3658, 3665 ]
    - [ 5232, 3778, 3687, 3676, 3692, 3669, 3689, 3631, 3644, 3695 ]
    - [ 5216, 3782, 3749, 3688, 3678, 3689, 3664, 3641, 3640, 3691 ]
    - [ 5158, 3803, 3727, 3693, 3704, 3726, 3668, 3684, 3664, 3690 ]
    - [ 5150, 3775, 3693, 3717, 3683, 3685, 3669, 3653, 3671, 3682 ]
    - [ 5212, 3769, 3720, 3677, 3684, 3707, 3646, 3660, 3642, 3654 ]
    - [ 5202, 3780, 3722, 3714, 3701, 3690, 3689, 3667, 3654, 3676 ]
    - [ 5201, 3774, 3721, 3692, 3688, 3718, 3667, 3695, 3658, 3698 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 10799, 9037, 8687, 8788, 8882, 8947, 9030, 8891, 8892, 8864 ]
    - [ 10658, 8845, 9103, 8788, 9135, 9005, 8917, 9226, 8780, 9021 ]
    - [ 11145, 9226, 8821, 8975, 9163, 9074, 9063, 9125, 8837, 8885 ]
    - [ 10967, 9010, 8893, 8870, 8957, 9221, 8928, 9004, 8906, 9142 ]
    - [ 11007, 8905, 8857, 9014, 8656, 8970, 9139, 9172, 8556, 8767 ]
    - [ 11251, 9139, 9020, 9200, 9158, 9209, 9066, 9293, 9111, 9230 ]
    - [ 10861, 8756, 8623, 8837, 8570, 9089, 8950, 8843, 8979, 9096 ]
    - [ 10976, 8876, 9065, 8702, 9034, 8587, 8895, 9029, 8501, 9203 ]
    - [ 10872, 8774, 8764, 8905, 9101, 9040, 9015, 8951, 8929, 8978 ]
    - [ 11071, 8816, 8801, 8826, 9103, 8819, 8949, 9034, 8919, 9092 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 19826, 10530, 10510, 10505, 10523 ]
    - [ 20206, 10559, 10524, 10548, 10551 ]
    - [ 19991, 10563, 10525, 10530, 10542 ]
    - [ 20084, 10568, 10538, 10529, 10548 ]
    - [ 20102, 10514, 10512, 10494, 10527 ]
    - [ 20329, 10682, 10665, 10644, 10657 ]
    - [ 20152, 10538, 10504, 10523, 10534 ]
    - [ 20048, 10559, 10517, 10560, 10548 ]
    - [ 20190, 10602, 10559, 10565, 10577 ]
    - [ 20200, 10584, 10534, 10546, 10590 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7116, 4542, 4038, 4041, 4078 ]
    - [ 7152, 4590, 4047, 4041, 4083 ]
    - [ 7320, 4634, 4053, 4211, 4542 ]
    - [ 7055, 4514, 4045, 4046, 4092 ]
    - [ 7327, 4593, 4064, 4194, 4525 ]
    - [ 7098, 4584, 4075, 4046, 4083 ]
    - [ 7190, 4768, 4530, 4061, 4091 ]
    - [ 7123, 4601, 4050, 4048, 4078 ]
    - [ 7117, 4546, 4055, 4074, 4089 ]
    - [ 7150, 4612, 4054, 4052, 4093 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5164, 3995, 3991, 3981, 4023 ]
    - [ 5145, 4025, 3994, 3982, 3992 ]
    - [ 5144, 3993, 4006, 3976, 4040 ]
    - [ 5132, 3999, 3964, 3966, 3982 ]
    - [ 5171, 3994, 3983, 3969, 4019 ]
    - [ 5142, 3994, 3978, 3977, 4021 ]
    - [ 5150, 4024, 3982, 3989, 4001 ]
    - [ 5153, 4014, 4034, 4004, 3996 ]
    - [ 5144, 4005, 4002, 3968, 4030 ]
    - [ 5155, 4001, 3977, 3981, 4029 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 31195, 10915, 8518, 8521, 3907 ]
    - [ 27652, 10819, 10346, 10516, 10129 ]
    - [ 29872, 11230, 10484, 8970, 8424 ]
    - [ 28165, 11138, 10627, 10553, 9986 ]
    - [ 31300, 10819, 9274, 10314, 9742 ]
    - [ 27956, 11210, 10760, 10218, 10096 ]
    - [ 29962, 10454, 9977, 9777, 9426 ]
    - [ 29668, 10748, 10251, 10431, 9889 ]
    - [ 27737, 10933, 10258, 10337, 9934 ]
    - [ 29615, 10993, 10662, 10410, 9625 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5476, 3716, 3674, 3649, 3654 ]
    - [ 5461, 3727, 3641, 3645, 3635 ]
    - [ 5476, 3703, 3659, 3651, 3658 ]
    - [ 5472, 3696, 3666, 3657, 3655 ]
    - [ 5449, 3732, 3670, 3665, 3651 ]
    - [ 5433, 3721, 3679, 3655, 3674 ]
    - [ 5458, 3720, 3669, 3649, 3654 ]
    - [ 5455, 3699, 3669, 3666, 3664 ]
    - [ 5454, 3712, 3662, 3656, 3656 ]
    - [ 5492, 3718, 3667, 3653, 3655 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 6329, 4132, 4032, 3966, 3981 ]
    - [ 6399, 4123, 3963, 3986, 3969 ]
    - [ 6232, 4115, 4017, 3959, 3967 ]
    - [ 6356, 4163, 4014, 3991, 3955 ]
    - [ 6325, 4115, 3982, 3938, 3955 ]
    - [ 6343, 4143, 4034, 3969, 3935 ]
    - [ 6375, 4120, 3947, 3954, 3936 ]
    - [ 6297, 4115, 4020, 3940, 3945 ]
    - [ 6387, 4152, 3984, 3946, 3937 ]
    - [ 6318, 4125, 4002, 3957, 3970 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5200, 3751, 3663, 3639, 3666 ]
    - [ 5192, 3777, 3681, 3633, 3639 ]
    - [ 5180, 3763, 3707, 3643, 3648 ]
    - [ 5214, 3769, 3732, 3635, 3639 ]
    - [ 5154, 3758, 3703, 3628, 3641 ]
    - [ 5166, 3713, 3661, 3618, 3631 ]
    - [ 5169, 3718, 3647, 3640, 3636 ]
    - [ 5184, 3739, 3687, 3632, 3654 ]
    - [ 5183, 3728, 3673, 3653, 3655 ]
    - [ 5202, 3744, 3715, 3631, 3629 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5532, 3903, 3824, 3713, 3723 ]
    - [ 5471, 3823, 3742, 3708, 3731 ]
    - [ 5517, 3902, 3872, 3735, 3721 ]
    - [ 5543, 3899, 3786, 3732, 3736 ]
    - [ 5556, 3831, 3752, 3696, 3717 ]
    - [ 5542, 3867, 3733, 3721, 3729 ]
    - [ 5542, 3890, 3754, 3714, 3702 ]
    - [ 5525, 3917, 3782, 3746, 3741 ]
    - [ 5547, 3901, 3796, 3714, 3708 ]
    - [ 5529, 3883, 3814, 3713, 3746 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 4939, 3706, 3623, 3603, 3572 ]
    - [ 4919, 3671, 3587, 3552, 3573 ]
    - [ 4918, 3658, 3592, 3570, 3553 ]
    - [ 4889, 3629, 3541, 3537, 3552 ]
    - [ 4940, 3648, 3616, 3568, 3559 ]
    - [ 4934, 3691, 3640, 3574, 3569 ]
    - [ 4922, 3647, 3581, 3557, 3556 ]
    - [ 4914, 3635, 3561, 3561, 3561 ]
    - [ 4902, 3674, 3617, 3559, 3562 ]
    - [ 4927, 3672, 3620, 3569, 3553 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 5769, 3997, 3886, 3853, 3831, 3860, 3867, 3849, 3826, 3872 ]
    - [ 5773, 4013, 3968, 3874, 3860, 3883, 3855, 3856, 3854, 3879 ]
    - [ 5753, 4001, 3883, 3859, 3822, 3846, 3850, 3837, 3858, 3875 ]
    - [ 5751, 3996, 3891, 3869, 3852, 3870, 3840, 3835, 3846, 3878 ]
    - [ 5772, 4027, 3954, 3881, 3869, 3847, 3848, 3852, 3847, 3864 ]
    - [ 5772, 3994, 3911, 3870, 3848, 3850, 3864, 3859, 3855, 3857 ]
    - [ 5767, 4000, 3892, 3861, 3866, 3862, 3841, 3870, 3859, 3872 ]
    - [ 5807, 3996, 3935, 3862, 3854, 3881, 3863, 3847, 3844, 3854 ]
    - [ 5773, 3989, 3921, 3849, 3837, 3863, 3872, 3828, 3848, 3867 ]
    - [ 5772, 4017, 3969, 3868, 3884, 3863, 3853, 3855, 3857, 3890 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 117052, 109329, 108280, 108043, 108137, 107874, 107900, 107939, 107910, 108054 ]
    - [ 117485, 109414, 108580, 108273, 108146, 108186, 108019, 108144, 108090, 108097 ]
    - [ 117160, 109433, 108363, 108010, 107801, 107899, 107855, 107942, 107966, 107837 ]
    - [ 117261, 109163, 108514, 108028, 107990, 107972, 107935, 107795, 107789, 108007 ]
    - [ 117390, 109402, 108542, 108064, 107851, 107889, 107880, 107892, 107904, 107902 ]
    - [ 117279, 109411, 108337, 108368, 108077, 108198, 108079, 107965, 108134, 107925 ]
    - [ 117277, 109510, 108582, 108238, 108196, 108053, 107995, 108024, 107911, 108314 ]
    - [ 117545, 109468, 108397, 108073, 108004, 107995, 107829, 107902, 107954, 108046 ]
    - [ 116996, 109153, 108489, 108085, 108071, 107786, 107969, 108085, 107808, 107905 ]
    - [ 117595, 109509, 108455, 108095, 108081, 108001, 107967, 107990, 108183, 108022 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 8020, 5200, 4986, 4886, 4720, 4687, 4671, 4663, 4672, 4727 ]
    - [ 8196, 5282, 4948, 4882, 4741, 4698, 4714, 4719, 4688, 4764 ]
    - [ 8112, 5200, 4980, 4917, 4750, 4652, 4646, 4660, 4644, 4716 ]
    - [ 8123, 5250, 5201, 4990, 4849, 4771, 4745, 4723, 4742, 4793 ]
    - [ 8147, 5088, 4964, 4983, 4847, 4777, 4717, 4720, 4736, 4784 ]
    - [ 8055, 5191, 4942, 4974, 4798, 4698, 4689, 4674, 4683, 4781 ]
    - [ 8102, 5037, 4912, 4897, 4773, 4692, 4669, 4673, 4673, 4744 ]
    - [ 8125, 5227, 5053, 4964, 4719, 4660, 4667, 4657, 4660, 4707 ]
    - [ 8134, 5193, 4966, 4959, 4768, 4696, 4692, 4678, 4692, 4755 ]
    - [ 8135, 5225, 4995, 4922, 4748, 4665, 4672, 4661, 4664, 4723 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 132248, 120645, 119354, 118797, 118712, 118484, 118592, 118401, 118704, 118466 ]
    - [ 132277, 120749, 119280, 118806, 118957, 118888, 118813, 118538, 118603, 118775 ]
    - [ 131553, 120271, 118870, 118378, 118563, 118272, 118327, 118108, 118225, 118203 ]
    - [ 131969, 120460, 119124, 118811, 118480, 118449, 118544, 118529, 118624, 118677 ]
    - [ 132338, 120699, 119245, 118750, 118610, 118836, 118481, 118439, 118449, 118580 ]
    - [ 132054, 120818, 119480, 119156, 118886, 118854, 118756, 118593, 118794, 118845 ]
    - [ 132630, 120794, 119194, 119054, 118683, 118641, 118557, 118548, 118492, 118505 ]
    - [ 131985, 120543, 119146, 118736, 118720, 118514, 118363, 118315, 118429, 118535 ]
    - [ 132667, 120823, 119486, 118946, 118759, 118865, 118929, 118674, 118803, 118707 ]
    - [ 132122, 120446, 119184, 118799, 118476, 118444, 118433, 118567, 118210, 118520 ]
