import {useParams} from "react-router";
import * as React from "react";

import {BMNav} from "../components/BMNav";
import {G1Hfac, Hfac2000} from "../components/Perf";


export function BenchmarkPerf() {
    let {bm} = useParams();


    return (<div>
        <h1 className="text-4xl">{bm}</h1>
        <BMNav bm={bm!}/>
        <div className={"flex flex-col xl:flex-row"}>
            <div className={"w-full xl:w-1/2 max-w-screen-sm"}>
                <p>G1</p>
                <G1Hfac bm={bm!} className={"w-full h-screen max-h-96"}/>
            </div>
            <div className={"w-full xl:w-1/2 max-w-screen-sm"}>
                <p>2.0x heap</p>
                <Hfac2000 bm={bm!} className={"w-full h-screen max-h-96"}/>
            </div>
        </div>
    </div>);
}