#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [7, 84, 6/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [8, 88, 4/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [3, 1043, 14/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (7376655824/7068300.0)]
BAL: [1, 0, 18/20, 0, 34, 2204, nominal aaload per usec]
BAS: [4, 0, 13/20, 0, 1, 126, nominal aastore per usec]
BEF: [10, 29, 1/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [0, 0, 20/20, 0, 527, 32087, nominal getfield per usec]
BPF: [0, 0, 20/20, 0, 83, 3863, nominal putfield per usec]
BUB: [0, 1, 20/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [0, 0, 20/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [2, 83, 19/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (113/135)']
GCC: [5, 997, 11/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [1, 77, 20/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (105/135)']
GCP: [5, 2, 12/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1846/79571)]
GLK: [7, 1, 8/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (135/133)]
GMD: [7, 135, 8/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [4, 139, 12/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [5, 13, 12/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [7, 167, 7/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [2, 16, 18/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (8248.5/7068.3)']
GTO: [5, 52, 11/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [9, 349, 4/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [9, 224, 3/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [10, 8, 1/22, 1, 3, 8, nominal execution time (sec)]
PFS: [9, 18, 4/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [9, 224, 3/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [6, 6, 9/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [7, 23, 7/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 5, 12/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [5, 5, 13/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [3, 0, 16/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 3, 11/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [5, 92, 12/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [8, 36, 5/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [5, 25, 11/22, 5, 23, 41, nominal backend bound (memory) ( 25303293669.2 / 29336857247.1) * 29 )]
UBP: [9, 97, 3/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.09802487330365696 * 509621626.6 ) / ( 509621626.6 + 5221292.7 )']
UBR: [5, 994, 13/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.09802487330365696 * 5221292.7 ) / ( 509621626.6 + 5221292.7 ) )']
UBS: [9, 98, 3/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 115219735534.5 - 86086262528.0 ) / ( 6 * 49534150576.0 ) )]
UDC: [5, 11, 13/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 999943968.2/ 88329660879.9 )]
UDT: [7, 283, 8/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 25009527.1 / 88329660879.9 )]
UIP: [6, 178, 10/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 88329660879.9/49534150576.0)]
ULL: [6, 3108, 10/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 136166929.6) / 88329660879.9 ) )]
USB: [5, 29, 11/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 92493640182.5 / ( 6 * 52760025057.3 ) )]
USC: [3, 30, 16/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 9296504803.5 / ( 6 * 51338474298.0 ) )]
USF: [5, 30, 11/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 89506370915.4/( 6 * 49534150576.0) )]
