#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [8, 110, 4/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [9, 160, 2/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [5, 2737, 10/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (7145721232/2610100.0)]
BAL: [10, 2204, 1/20, 0, 34, 2204, nominal aaload per usec]
BAS: [6, 1, 9/20, 0, 1, 126, nominal aastore per usec]
BEF: [9, 12, 3/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [9, 9217, 3/20, 0, 527, 32087, nominal getfield per usec]
BPF: [3, 43, 15/20, 0, 83, 3863, nominal putfield per usec]
BUB: [1, 8, 19/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [1, 1, 18/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [9, 113, 4/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (199/175)']
GCC: [6, 1262, 10/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [8, 108, 6/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (189/175)']
GCP: [5, 2, 12/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (867/31488)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (175/175)]
GMD: [8, 175, 5/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [8, 1183, 5/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [10, 141, 2/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [8, 179, 6/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [6, 382, 10/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (12588.3/2610.1)']
GTO: [4, 38, 12/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [7, 276, 7/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [10, 323, 1/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [7, 3, 8/22, 1, 3, 8, nominal execution time (sec)]
PFS: [6, 14, 10/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [10, 323, 1/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [3, 1, 16/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [4, 5, 14/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [7, 10, 7/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [6, 9, 9/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [8, 112, 6/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [8, 35, 6/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [2, 19, 18/22, 5, 23, 41, nominal backend bound (memory) ( 18096394041.3 / 35305544857.0) * 38 )]
UBP: [0, 5, 22/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.005723408808666395 * 35595321.7 ) / ( 35595321.7 + 5000206.3 )']
UBR: [3, 704, 16/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.005723408808666395 * 5000206.3 ) / ( 35595321.7 + 5000206.3 ) )']
UBS: [0, 5, 22/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 148628404953.6 - 146339760368.9 ) / ( 6 * 66645731043.9 ) )]
UDC: [1, 3, 20/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 482034690.0/ 156204242454.9 )]
UDT: [1, 45, 20/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 7177668.1 / 156204242454.9 )]
UIP: [9, 234, 4/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 156204242454.9/66645731043.9)]
ULL: [3, 1746, 17/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 139858504.4) / 156204242454.9 ) )]
USB: [8, 38, 6/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 151886170861.2 / ( 6 * 66061549769.2 ) )]
USC: [9, 192, 4/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 77168604325.3 / ( 6 * 66921907069.5 ) )]
USF: [0, 4, 22/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 17877020157.8/( 6 * 66645731043.9) )]
