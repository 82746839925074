#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
GCA: [5, 101, 11/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (94/93)']
GCC: [4, 774, 15/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [6, 100, 9/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (93/93)']
GCP: [4, 1, 15/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (952/49567)]
GLK: [8, 6, 6/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (93/87)]
GMD: [5, 91, 11/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [6, 229, 9/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [9, 75, 4/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [5, 115, 11/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GMV: [0, 371, 3/3, 371, 1123, 20641, nominal minimum heap size (MB) for vlarge size configuration (with compressed pointers)]
GSS: [5, 284, 11/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (1696.4/441.7)']
PCC: [9, 400, 3/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [7, 117, 7/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [7, 16, 8/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [7, 117, 7/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [5, 2, 12/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [10, 40, 1/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [6, 6, 10/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [5, 8, 11/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 2, 4/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [7, 5, 8/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [10, 147, 2/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [7, 34, 8/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [5, 23, 12/22, 5, 23, 41, nominal backend bound (memory) ( 5739506688.0 / 6226479499.4) * 26 )]
UBP: [8, 73, 6/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.07412435051161784 * 63484887.0 ) / ( 63484887.0 + 945034.0 )']
UBR: [5, 1087, 12/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.07412435051161784 * 945034.0 ) / ( 63484887.0 + 945034.0 ) )']
UBS: [8, 74, 6/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 20919967982.0 - 16737662533.9 ) / ( 6 * 9403804596.0 ) )]
UDC: [7, 17, 7/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 263133743.9/ 15355587136.4 )]
UDT: [7, 385, 7/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 5926700.5 / 15355587136.4 )]
UIP: [5, 163, 11/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 15355587136.4/9403804596.0)]
ULL: [5, 2645, 12/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 20012416.8) / 15355587136.4 ) )]
USB: [4, 26, 15/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 16789683774.2 / ( 6 * 10721402452.2 ) )]
USC: [5, 52, 12/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 3382599272.7 / ( 6 * 10706074412.6 ) )]
USF: [7, 35, 7/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 20216431575.5/( 6 * 9403804596.0) )]
