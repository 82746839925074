#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 7214, 6881, 6870, 6865, 6869, 6859, 6859, 6860, 6860, 6947 ]
    - [ 7219, 6880, 6870, 6870, 6866, 6864, 6862, 6860, 6859, 6946 ]
    - [ 7200, 6877, 6866, 6873, 6865, 6859, 6861, 6858, 6858, 6861 ]
    - [ 7208, 6877, 6898, 6866, 6870, 6862, 6861, 6949, 6862, 6859 ]
    - [ 7216, 6884, 6873, 6867, 6867, 6868, 6863, 6860, 6864, 6946 ]
    - [ 7217, 6882, 6901, 6863, 6860, 6863, 6864, 6860, 6857, 6868 ]
    - [ 7222, 6881, 6870, 6871, 6867, 6860, 6861, 6862, 6864, 6948 ]
    - [ 7198, 6878, 6870, 6867, 6868, 6861, 6862, 6860, 6860, 6945 ]
    - [ 7221, 6889, 6870, 6873, 6868, 6861, 6864, 6861, 6862, 6949 ]
    - [ 7199, 6877, 6867, 6873, 6865, 6858, 6858, 6857, 6857, 6953 ]
  2.0:
    - [ 7161, 6839, 6833, 6833, 6827, 6826, 6827, 6823, 6828, 6826 ]
    - [ 7190, 6842, 6837, 6832, 6832, 6827, 6827, 6828, 6826, 6825 ]
    - [ 7163, 6838, 6831, 6830, 6826, 6813, 6823, 6825, 6822, 6821 ]
    - [ 7181, 6842, 6835, 6832, 6821, 6829, 6819, 6827, 6820, 6828 ]
    - [ 7186, 6841, 6836, 6833, 6829, 6819, 6829, 6831, 6818, 6826 ]
    - [ 7188, 6841, 6836, 6831, 6831, 6830, 6827, 6827, 6827, 6827 ]
    - [ 7181, 6840, 6834, 6832, 6829, 6828, 6826, 6829, 6827, 6827 ]
    - [ 7186, 6840, 6836, 6832, 6833, 6824, 6827, 6831, 6825, 6827 ]
    - [ 7182, 6841, 6840, 6826, 6830, 6831, 6827, 6828, 6827, 6824 ]
    - [ 7179, 6843, 6836, 6834, 6826, 6824, 6826, 6830, 6824, 6827 ]
  3.0:
    - [ 7187, 6837, 6829, 6827, 6825, 6823, 6822, 6821, 6820, 6821 ]
    - [ 7184, 6835, 6828, 6820, 6823, 6823, 6823, 6822, 6820, 6820 ]
    - [ 7187, 6836, 6829, 6827, 6818, 6822, 6823, 6824, 6815, 6819 ]
    - [ 7168, 6834, 6828, 6819, 6822, 6819, 6818, 6819, 6815, 6818 ]
    - [ 7160, 6835, 6827, 6824, 6820, 6821, 6817, 6821, 6818, 6819 ]
    - [ 7190, 6835, 6829, 6827, 6821, 6817, 6821, 6821, 6819, 6821 ]
    - [ 7166, 6834, 6825, 6823, 6820, 6814, 6819, 6820, 6817, 6819 ]
    - [ 7166, 6835, 6827, 6823, 6823, 6816, 6820, 6814, 6817, 6813 ]
    - [ 7191, 6835, 6830, 6822, 6825, 6817, 6822, 6822, 6816, 6820 ]
    - [ 7190, 6836, 6828, 6827, 6825, 6820, 6822, 6819, 6820, 6821 ]
  4.0:
    - [ 7171, 6835, 6826, 6825, 6823, 6817, 6820, 6814, 6819, 6819 ]
    - [ 7167, 6834, 6828, 6824, 6823, 6818, 6820, 6820, 6815, 6819 ]
    - [ 7188, 6836, 6829, 6828, 6824, 6817, 6822, 6823, 6816, 6820 ]
    - [ 7186, 6836, 6828, 6822, 6825, 6818, 6822, 6815, 6821, 6822 ]
    - [ 7184, 6836, 6824, 6827, 6810, 6823, 6808, 6823, 6822, 6822 ]
    - [ 7186, 6836, 6830, 6823, 6822, 6822, 6823, 6824, 6819, 6820 ]
    - [ 7191, 6838, 6829, 6826, 6823, 6817, 6822, 6817, 6820, 6822 ]
    - [ 7177, 6834, 6826, 6818, 6814, 6819, 6814, 6819, 6814, 6819 ]
    - [ 7186, 6837, 6828, 6827, 6816, 6823, 6814, 6821, 6819, 6820 ]
    - [ 7166, 6834, 6826, 6827, 6825, 6822, 6822, 6820, 6813, 6818 ]
  5.0:
    - [ 7187, 6834, 6827, 6824, 6824, 6821, 6822, 6818, 6817, 6820 ]
    - [ 7185, 6834, 6825, 6823, 6823, 6824, 6821, 6820, 6818, 6817 ]
    - [ 7194, 6834, 6830, 6825, 6824, 6823, 6821, 6823, 6821, 6820 ]
    - [ 7163, 6832, 6822, 6825, 6823, 6818, 6820, 6817, 6819, 6816 ]
    - [ 7187, 6835, 6828, 6820, 6812, 6821, 6810, 6823, 6818, 6820 ]
    - [ 7165, 6833, 6827, 6824, 6821, 6820, 6820, 6820, 6812, 6815 ]
    - [ 7187, 6834, 6833, 6818, 6823, 6823, 6821, 6820, 6819, 6820 ]
    - [ 7166, 6832, 6827, 6823, 6824, 6820, 6821, 6818, 6818, 6823 ]
    - [ 7189, 6839, 6829, 6818, 6822, 6822, 6811, 6821, 6818, 6822 ]
    - [ 7186, 6834, 6826, 6828, 6822, 6821, 6815, 6821, 6822, 6817 ]
  6.0:
    - [ 7166, 6833, 6827, 6824, 6814, 6819, 6811, 6817, 6810, 6815 ]
    - [ 7187, 6833, 6827, 6823, 6815, 6821, 6823, 6820, 6821, 6817 ]
    - [ 7167, 6830, 6860, 6822, 6811, 6818, 6822, 6819, 6817, 6816 ]
    - [ 7164, 6830, 6825, 6823, 6820, 6817, 6820, 6816, 6819, 6813 ]
    - [ 7185, 6836, 6827, 6826, 6820, 6821, 6821, 6819, 6820, 6818 ]
    - [ 7185, 6835, 6827, 6824, 6822, 6816, 6822, 6813, 6818, 6812 ]
    - [ 7165, 6832, 6826, 6824, 6820, 6814, 6820, 6813, 6817, 6811 ]
    - [ 7166, 6831, 6825, 6821, 6819, 6814, 6819, 6812, 6816, 6811 ]
    - [ 7187, 6833, 6828, 6825, 6821, 6816, 6821, 6813, 6821, 6820 ]
    - [ 7187, 6834, 6827, 6826, 6814, 6816, 6821, 6820, 6819, 6819 ]
  7.0:
    - [ 7162, 6834, 6826, 6822, 6822, 6820, 6819, 6819, 6817, 6816 ]
    - [ 7184, 6833, 6826, 6825, 6822, 6819, 6821, 6818, 6818, 6816 ]
    - [ 7217, 6833, 6828, 6825, 6822, 6820, 6821, 6818, 6817, 6818 ]
    - [ 7197, 6834, 6828, 6825, 6823, 6819, 6822, 6819, 6817, 6821 ]
    - [ 7181, 6833, 6827, 6824, 6822, 6820, 6820, 6820, 6816, 6820 ]
    - [ 7199, 6833, 6827, 6826, 6824, 6816, 6820, 6813, 6813, 6812 ]
    - [ 7182, 6832, 6826, 6825, 6812, 6821, 6815, 6820, 6811, 6815 ]
    - [ 7164, 6829, 6827, 6822, 6821, 6818, 6820, 6817, 6816, 6818 ]
    - [ 7166, 6833, 6823, 6820, 6817, 6818, 6819, 6819, 6815, 6816 ]
    - [ 7186, 6838, 6828, 6823, 6819, 6820, 6822, 6815, 6819, 6815 ]
  8.0:
    - [ 7184, 6832, 6826, 6825, 6823, 6815, 6823, 6813, 6821, 6812 ]
    - [ 7166, 6832, 6825, 6822, 6820, 6817, 6818, 6819, 6818, 6818 ]
    - [ 7189, 6834, 6826, 6825, 6822, 6821, 6810, 6821, 6818, 6817 ]
    - [ 7191, 6833, 6828, 6824, 6823, 6819, 6822, 6820, 6821, 6821 ]
    - [ 7164, 6832, 6825, 6818, 6820, 6820, 6813, 6819, 6816, 6815 ]
    - [ 7166, 6830, 6825, 6823, 6820, 6813, 6819, 6812, 6817, 6811 ]
    - [ 7184, 6833, 6826, 6819, 6820, 6821, 6821, 6820, 6816, 6819 ]
    - [ 7165, 6832, 6824, 6823, 6821, 6819, 6813, 6818, 6816, 6822 ]
    - [ 7163, 6831, 6825, 6817, 6819, 6817, 6818, 6815, 6815, 6817 ]
    - [ 7197, 6832, 6826, 6826, 6823, 6820, 6821, 6818, 6818, 6816 ]
  9.0:
    - [ 7188, 6835, 6826, 6825, 6823, 6815, 6820, 6813, 6817, 6812 ]
    - [ 7195, 6834, 6829, 6826, 6822, 6821, 6818, 6820, 6816, 6817 ]
    - [ 7166, 6831, 6825, 6822, 6820, 6816, 6818, 6815, 6816, 6817 ]
    - [ 7185, 6833, 6826, 6824, 6820, 6815, 6821, 6812, 6819, 6811 ]
    - [ 7165, 6831, 6824, 6821, 6820, 6819, 6814, 6817, 6811, 6817 ]
    - [ 7167, 6832, 6824, 6823, 6818, 6819, 6813, 6818, 6818, 6815 ]
    - [ 7165, 6832, 6825, 6824, 6819, 6817, 6819, 6817, 6816, 6816 ]
    - [ 7186, 6834, 6826, 6818, 6816, 6815, 6823, 6812, 6818, 6813 ]
    - [ 7186, 6832, 6828, 6826, 6820, 6812, 6820, 6820, 6817, 6816 ]
    - [ 7167, 6833, 6822, 6822, 6819, 6819, 6813, 6819, 6810, 6822 ]
  10.0:
    - [ 7167, 6832, 6825, 6821, 6820, 6813, 6818, 6819, 6816, 6816 ]
    - [ 7165, 6833, 6826, 6823, 6819, 6818, 6819, 6819, 6818, 6815 ]
    - [ 7184, 6834, 6827, 6824, 6822, 6820, 6815, 6819, 6818, 6818 ]
    - [ 7185, 6833, 6825, 6823, 6822, 6821, 6815, 6821, 6817, 6819 ]
    - [ 7166, 6831, 6825, 6821, 6820, 6819, 6817, 6818, 6812, 6815 ]
    - [ 7166, 6833, 6825, 6822, 6820, 6819, 6814, 6820, 6819, 6818 ]
    - [ 7188, 6834, 6826, 6824, 6812, 6819, 6815, 6820, 6813, 6818 ]
    - [ 7165, 6831, 6824, 6822, 6818, 6817, 6818, 6816, 6818, 6817 ]
    - [ 7164, 6831, 6825, 6822, 6819, 6817, 6817, 6818, 6816, 6816 ]
    - [ 7186, 6834, 6824, 6825, 6813, 6817, 6820, 6819, 6817, 6820 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 7868, 7030, 6942, 6922, 6941, 6878, 6899, 6869, 6905, 6921 ]
    - [ 7866, 7041, 6971, 6943, 6939, 6864, 6874, 6922, 6927, 6874 ]
    - [ 7913, 7033, 6954, 6961, 6879, 6876, 6896, 6928, 6927, 6871 ]
    - [ 7822, 7048, 6957, 6968, 6901, 6875, 6924, 6935, 6938, 6871 ]
    - [ 7911, 7039, 6957, 6919, 6909, 6905, 6936, 6926, 6869, 6903 ]
    - [ 7842, 7028, 6967, 6983, 6910, 6863, 6880, 6911, 6924, 6876 ]
    - [ 7842, 7036, 6986, 6941, 6871, 6873, 6952, 6891, 6936, 6929 ]
    - [ 7851, 7043, 6966, 6934, 6933, 6913, 6877, 6918, 6930, 6879 ]
    - [ 7854, 7042, 6970, 6922, 6944, 6881, 6867, 6919, 6944, 6870 ]
    - [ 7864, 7031, 6939, 6951, 6860, 6862, 6872, 6928, 6918, 6890 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 7157, 6841, 6832, 6829, 6828, 6829, 6828, 6829, 6826, 6827 ]
    - [ 7177, 6841, 6836, 6832, 6829, 6827, 6828, 6831, 6827, 6827 ]
    - [ 7175, 6842, 6838, 6831, 6831, 6830, 6828, 6828, 6829, 6829 ]
    - [ 7177, 6842, 6837, 6833, 6829, 6830, 6830, 6830, 6827, 6829 ]
    - [ 7177, 6842, 6838, 6830, 6829, 6831, 6829, 6830, 6826, 6830 ]
    - [ 7177, 6842, 6837, 6833, 6830, 6831, 6830, 6832, 6828, 6829 ]
    - [ 7155, 6838, 6834, 6830, 6830, 6827, 6826, 6829, 6824, 6825 ]
    - [ 7158, 6840, 6837, 6831, 6833, 6828, 6828, 6828, 6826, 6826 ]
    - [ 7175, 6842, 6839, 6826, 6831, 6828, 6830, 6828, 6826, 6827 ]
    - [ 7158, 6838, 6835, 6827, 6828, 6825, 6826, 6828, 6825, 6825 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 7182, 6845, 6837, 6836, 6830, 6830, 6829, 6828, 6825, 6828 ]
    - [ 7178, 6845, 6860, 6836, 6829, 6833, 6832, 6829, 6827, 6827 ]
    - [ 7180, 6846, 6827, 6835, 6833, 6831, 6829, 6827, 6824, 6831 ]
    - [ 7178, 6844, 6837, 6836, 6834, 6830, 6833, 6826, 6830, 6831 ]
    - [ 7181, 6847, 6828, 6833, 6832, 6830, 6828, 6829, 6827, 6818 ]
    - [ 7182, 6845, 6839, 6838, 6829, 6832, 6833, 6830, 6829, 6829 ]
    - [ 7162, 6846, 6838, 6828, 6831, 6819, 6832, 6830, 6815, 6830 ]
    - [ 7188, 6845, 6854, 6836, 6833, 6834, 6831, 6831, 6827, 6830 ]
    - [ 7161, 6846, 6836, 6834, 6831, 6831, 6830, 6829, 6818, 6827 ]
    - [ 7179, 6844, 6840, 6835, 6832, 6829, 6831, 6827, 6827, 6830 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 7240, 6884, 6865, 6859, 6860, 6853, 6864, 6856, 6873, 6871 ]
    - [ 7236, 6893, 6879, 6867, 6866, 6860, 6859, 6870, 6866, 6941 ]
    - [ 7210, 6879, 6872, 6860, 6854, 6862, 6861, 6860, 6850, 6959 ]
    - [ 7242, 6882, 6877, 6870, 6872, 6863, 6869, 6857, 6857, 6875 ]
    - [ 7237, 6887, 6866, 6860, 6855, 6876, 6859, 6850, 6853, 6952 ]
    - [ 7226, 6893, 6893, 6855, 6862, 6874, 6861, 6838, 6849, 6847 ]
    - [ 7229, 6875, 6874, 6863, 6860, 6867, 6859, 6853, 6849, 6875 ]
    - [ 7217, 6884, 6861, 6851, 6871, 6873, 6856, 6857, 6842, 6981 ]
    - [ 7229, 6889, 6871, 6866, 6851, 6879, 6855, 6850, 6846, 6858 ]
    - [ 7259, 6870, 6868, 6858, 6863, 6857, 6857, 6858, 6862, 6863 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 7169, 6855, 6843, 6843, 6837, 6826, 6838, 6830, 6821, 6839 ]
    - [ 7196, 6857, 6840, 6839, 6826, 6838, 6840, 6841, 6838, 6840 ]
    - [ 7197, 6856, 6849, 6842, 6842, 6841, 6840, 6835, 6828, 6843 ]
    - [ 7206, 6856, 6851, 6847, 6840, 6840, 6840, 6830, 6828, 6837 ]
    - [ 7194, 6856, 6860, 6841, 6840, 6842, 6842, 6833, 6830, 6838 ]
    - [ 7203, 6855, 6851, 6846, 6840, 6842, 6839, 6835, 6831, 6839 ]
    - [ 7184, 6856, 6840, 6840, 6832, 6835, 6840, 6843, 6838, 6839 ]
    - [ 7201, 6853, 6852, 6847, 6842, 6841, 6842, 6833, 6829, 6836 ]
    - [ 7199, 6855, 6851, 6846, 6839, 6840, 6839, 6847, 6832, 6844 ]
    - [ 7198, 6852, 6839, 6840, 6827, 6840, 6840, 6835, 6842, 6839 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 7227, 6882, 6878, 6871, 6862, 6860, 6859, 6865, 6864, 6864 ]
    - [ 7210, 6880, 6876, 6876, 6865, 6866, 6869, 6865, 6863, 6867 ]
    - [ 7213, 6882, 6877, 6873, 6865, 6867, 6861, 6865, 6864, 6865 ]
    - [ 7215, 6883, 6874, 6872, 6867, 6868, 6861, 6863, 6866, 6868 ]
    - [ 7216, 6890, 6880, 6874, 6875, 6871, 6865, 6872, 6869, 6872 ]
    - [ 7216, 6883, 6883, 6877, 6866, 6866, 6861, 6867, 6867, 6866 ]
    - [ 7216, 6886, 6880, 6872, 6874, 6870, 6868, 6872, 6869, 6871 ]
    - [ 7220, 6885, 6878, 6874, 6869, 6870, 6870, 6868, 6866, 6868 ]
    - [ 7214, 6884, 6875, 6875, 6869, 6864, 6862, 6868, 6867, 6867 ]
    - [ 7209, 6879, 6875, 6872, 6865, 6868, 6867, 6861, 6856, 6867 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 12288, 12172, 12127, 12158, 12205 ]
    - [ 12348, 12184, 12147, 12160, 12276 ]
    - [ 12283, 12177, 12150, 12166, 12198 ]
    - [ 12396, 12104, 12153, 12129, 12135 ]
    - [ 12479, 12089, 12202, 12206, 12172 ]
    - [ 12405, 12183, 12111, 12035, 12083 ]
    - [ 12308, 12156, 12179, 12141, 12226 ]
    - [ 12352, 12059, 12053, 12095, 12189 ]
    - [ 12359, 12162, 12178, 12157, 12196 ]
    - [ 12396, 12136, 12150, 12201, 12180 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7460, 6906, 6907, 7032, 6909 ]
    - [ 7460, 6891, 6883, 6898, 7008 ]
    - [ 7470, 6891, 6885, 6898, 7008 ]
    - [ 7469, 6892, 6883, 6893, 6987 ]
    - [ 7462, 6906, 6900, 7023, 6911 ]
    - [ 7468, 6890, 6882, 6897, 7010 ]
    - [ 7459, 6905, 6898, 7025, 6911 ]
    - [ 7463, 6908, 6899, 7025, 6911 ]
    - [ 7476, 6906, 6901, 7024, 6912 ]
    - [ 7469, 6891, 6888, 6899, 7012 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 7169, 6892, 6889, 6888, 6893 ]
    - [ 7168, 6893, 6893, 6892, 6893 ]
    - [ 7164, 6895, 6890, 6888, 6894 ]
    - [ 7162, 6893, 6889, 6888, 6894 ]
    - [ 7169, 6891, 6889, 6893, 6892 ]
    - [ 7166, 6894, 6889, 6893, 6893 ]
    - [ 7168, 6894, 6889, 6888, 6895 ]
    - [ 7166, 6890, 6888, 6891, 6894 ]
    - [ 7165, 6892, 6890, 6890, 6892 ]
    - [ 7164, 6894, 6889, 6890, 6892 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 12355, 7180, 6886, 6880, 6883 ]
    - [ 12349, 7179, 6886, 6880, 6884 ]
    - [ 12355, 7184, 6883, 6880, 6886 ]
    - [ 12350, 7181, 6887, 6886, 6884 ]
    - [ 12353, 7182, 6883, 6881, 6886 ]
    - [ 12356, 7181, 6883, 6881, 6889 ]
    - [ 12360, 7179, 6886, 6879, 6887 ]
    - [ 12339, 7179, 6887, 6882, 6886 ]
    - [ 12344, 7180, 6884, 6881, 6887 ]
    - [ 12360, 7181, 6883, 6883, 6881 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 7291, 6873, 6860, 6845, 6847 ]
    - [ 7291, 6874, 6865, 6844, 6845 ]
    - [ 7283, 6868, 6860, 6843, 6844 ]
    - [ 7285, 6866, 6858, 6830, 6841 ]
    - [ 7292, 6875, 6864, 6844, 6847 ]
    - [ 7290, 6873, 6862, 6844, 6844 ]
    - [ 7289, 6867, 6860, 6846, 6846 ]
    - [ 7282, 6873, 6861, 6841, 6844 ]
    - [ 7291, 6872, 6862, 6845, 6845 ]
    - [ 7290, 6866, 6862, 6838, 6846 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 7264, 6853, 6846, 6843, 6840 ]
    - [ 7290, 6856, 6849, 6845, 6834 ]
    - [ 7283, 6856, 6849, 6844, 6843 ]
    - [ 7266, 6852, 6845, 6843, 6840 ]
    - [ 7295, 6856, 6848, 6843, 6840 ]
    - [ 7281, 6860, 6847, 6845, 6839 ]
    - [ 7284, 6855, 6848, 6844, 6833 ]
    - [ 7276, 6854, 6845, 6842, 6834 ]
    - [ 7291, 6854, 6848, 6845, 6845 ]
    - [ 7255, 6853, 6846, 6842, 6839 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 7189, 6841, 6835, 6832, 6834 ]
    - [ 7185, 6841, 6834, 6833, 6833 ]
    - [ 7183, 6843, 6836, 6833, 6834 ]
    - [ 7162, 6841, 6834, 6832, 6830 ]
    - [ 7182, 6842, 6836, 6832, 6831 ]
    - [ 7164, 6839, 6835, 6832, 6831 ]
    - [ 7167, 6838, 6834, 6831, 6822 ]
    - [ 7187, 6843, 6835, 6835, 6833 ]
    - [ 7193, 6841, 6836, 6832, 6832 ]
    - [ 7167, 6838, 6834, 6831, 6831 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 7171, 6843, 6837, 6836, 6833 ]
    - [ 7175, 6843, 6838, 6836, 6826 ]
    - [ 7193, 6845, 6838, 6837, 6834 ]
    - [ 7196, 6847, 6841, 6837, 6836 ]
    - [ 7196, 6845, 6839, 6835, 6834 ]
    - [ 7195, 6848, 6838, 6837, 6836 ]
    - [ 7192, 6847, 6841, 6830, 6838 ]
    - [ 7171, 6845, 6840, 6835, 6839 ]
    - [ 7196, 6844, 6830, 6838, 6838 ]
    - [ 7174, 6842, 6836, 6826, 6833 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 7105, 6814, 6809, 6807, 6808 ]
    - [ 7101, 6816, 6808, 6808, 6805 ]
    - [ 7097, 6813, 6808, 6805, 6806 ]
    - [ 7077, 6813, 6806, 6803, 6803 ]
    - [ 7103, 6815, 6810, 6806, 6806 ]
    - [ 7108, 6814, 6810, 6806, 6804 ]
    - [ 7106, 6814, 6808, 6806, 6802 ]
    - [ 7081, 6811, 6806, 6801, 6793 ]
    - [ 7104, 6813, 6809, 6800, 6806 ]
    - [ 7098, 6813, 6807, 6803, 6799 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 7360, 6909, 6904, 6900, 6891, 6903, 6909, 6903, 6891, 6899 ]
    - [ 7372, 6907, 6908, 6901, 6910, 6909, 6895, 6896, 6898, 6889 ]
    - [ 7351, 6915, 6906, 6899, 6894, 6894, 6891, 6894, 6894, 6888 ]
    - [ 7347, 6910, 6898, 6898, 6892, 6891, 6904, 6907, 6896, 6901 ]
    - [ 7342, 6917, 6927, 6895, 6902, 6897, 6891, 6892, 6890, 6888 ]
    - [ 7371, 6908, 6903, 6895, 6893, 6893, 6892, 6898, 6894, 6894 ]
    - [ 7365, 6910, 6904, 6896, 6894, 6893, 6895, 6893, 6895, 6899 ]
    - [ 7347, 6912, 6900, 6898, 6900, 6890, 6893, 6890, 6889, 6888 ]
    - [ 7329, 6910, 6899, 6896, 6890, 6893, 6893, 6891, 6890, 6892 ]
    - [ 7357, 6909, 6901, 6901, 6896, 6894, 6906, 6896, 6893, 6892 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 8149, 7155, 7092, 7027, 7040, 6997, 7029, 7059, 6949, 6953 ]
    - [ 8248, 7141, 7058, 7071, 7010, 6946, 6956, 6935, 6946, 7027 ]
    - [ 8181, 7102, 7099, 7038, 7053, 6997, 6951, 7021, 7059, 6951 ]
    - [ 8297, 7149, 7047, 7033, 6944, 6947, 7009, 6951, 7026, 7050 ]
    - [ 8240, 7137, 7090, 7022, 7021, 6930, 6965, 6949, 7016, 7035 ]
    - [ 8218, 7131, 7082, 7027, 6998, 6928, 6964, 6953, 6949, 7046 ]
    - [ 8280, 7160, 7076, 7081, 7044, 6988, 7029, 6960, 7017, 6957 ]
    - [ 8253, 7121, 7089, 7047, 6965, 6948, 6963, 7045, 7025, 6953 ]
    - [ 8240, 7146, 7097, 7035, 7031, 7004, 6943, 7023, 7042, 6954 ]
    - [ 8170, 7153, 7065, 7044, 7030, 6957, 6946, 6983, 7009, 7046 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 7607, 6964, 6956, 6950, 6947, 6944, 6936, 6941, 6929, 6940 ]
    - [ 7611, 6960, 6955, 6950, 6944, 6941, 6932, 6943, 6933, 6939 ]
    - [ 7626, 6979, 6958, 6952, 6950, 6945, 6948, 6940, 6941, 6931 ]
    - [ 7628, 6963, 6957, 6951, 6947, 6943, 6934, 6941, 6934, 6942 ]
    - [ 7625, 6964, 6959, 6951, 6944, 6946, 6948, 6932, 6937, 6939 ]
    - [ 7612, 6960, 6958, 6949, 6945, 6944, 6943, 6937, 6928, 6940 ]
    - [ 7626, 6973, 6954, 6950, 6947, 6945, 6944, 6942, 6938, 6938 ]
    - [ 7610, 6962, 6957, 6951, 6942, 6941, 6943, 6937, 6936, 6940 ]
    - [ 7607, 6974, 6956, 6952, 6943, 6942, 6940, 6943, 6938, 6939 ]
    - [ 7613, 6963, 6955, 6949, 6943, 6943, 6932, 6941, 6938, 6937 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 8573, 7238, 7147, 7177, 7044, 6974, 7029, 7094, 7081, 7068 ]
    - [ 8581, 7272, 7116, 7163, 7020, 7014, 7021, 7103, 7125, 7043 ]
    - [ 8708, 7217, 7189, 7149, 7076, 7027, 7027, 7042, 7090, 7166 ]
    - [ 8634, 7219, 7186, 7120, 7057, 6987, 7100, 7132, 7047, 6996 ]
    - [ 8650, 7269, 7160, 7119, 7055, 6994, 7026, 7113, 7016, 7097 ]
    - [ 8695, 7190, 7234, 7136, 7135, 6996, 7110, 7032, 7111, 7014 ]
    - [ 8606, 7182, 7208, 7110, 7116, 6994, 7144, 7105, 7018, 7037 ]
    - [ 8634, 7240, 7211, 7133, 7031, 7003, 7119, 7098, 7035, 7017 ]
    - [ 8702, 7204, 7228, 7100, 7132, 6996, 7012, 7100, 7077, 7134 ]
    - [ 8709, 7236, 7201, 7133, 7009, 6991, 7121, 7142, 7017, 7030 ]
