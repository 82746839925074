#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 57291, 48582, 47011, 46252, 45145, 44670, 44209, 46093, 45647, 46264 ]
    - [ 57257, 48626, 46528, 46302, 45511, 44324, 45308, 45607, 44781, 46362 ]
    - [ 50189, 47770, 46396, 45765, 45883, 45358, 45511, 45828, 44628, 44855 ]
    - [ 57692, 48104, 46299, 46408, 46181, 46302, 46234, 46346, 45947, 46675 ]
    - [ 53820, 47848, 46190, 45991, 44476, 45534, 45207, 46109, 45429, 45077 ]
    - [ 51724, 48122, 46618, 45810, 45438, 44301, 44152, 45825, 45896, 46416 ]
    - [ 54420, 48526, 46895, 45983, 44438, 44550, 45327, 44265, 45353, 46492 ]
    - [ 56574, 48152, 46163, 45481, 45605, 45393, 45521, 45687, 44686, 44654 ]
    - [ 53449, 47562, 46316, 45870, 45824, 45986, 45837, 46353, 44588, 45147 ]
    - [ 54088, 47881, 46148, 46022, 45914, 44442, 45469, 45386, 45274, 44958 ]
  2.0:
    - [ 3745, 3468, 3434, 3446, 3442, 3401, 3454, 3453, 3478, 3500 ]
    - [ 3794, 3460, 3440, 3377, 3414, 3413, 3417, 3525, 3511, 3530 ]
    - [ 3753, 3447, 3439, 3415, 3374, 3460, 3408, 3427, 3437, 3478 ]
    - [ 3855, 3496, 3431, 3432, 3444, 3461, 3499, 3416, 3538, 3536 ]
    - [ 3729, 3433, 3435, 3415, 3405, 3396, 3416, 3281, 3273, 3290 ]
    - [ 3762, 3379, 3381, 3359, 3381, 3390, 3434, 3437, 3384, 3468 ]
    - [ 3834, 3505, 3455, 3054, 3106, 2965, 2930, 2995, 3000, 3035 ]
    - [ 3877, 3501, 3432, 3471, 3467, 3498, 3457, 3496, 3456, 3494 ]
    - [ 3776, 3441, 3399, 3430, 3456, 3397, 3449, 3441, 3405, 3481 ]
    - [ 3801, 3515, 3488, 3483, 3456, 3484, 3475, 3535, 3487, 3567 ]
  3.0:
    - [ 1881, 1462, 1464, 1485, 1461, 1465, 1466, 1461, 1484, 1497 ]
    - [ 1882, 1504, 1474, 1456, 1457, 1465, 1463, 1476, 1483, 1508 ]
    - [ 1871, 1485, 1464, 1475, 1493, 1486, 1481, 1325, 1320, 1343 ]
    - [ 1880, 1448, 1441, 1505, 1476, 1478, 1493, 1311, 1328, 1353 ]
    - [ 1939, 1462, 1475, 1490, 1458, 1454, 1478, 1477, 1478, 1467 ]
    - [ 1898, 1497, 1488, 1527, 1494, 1483, 1499, 1499, 1485, 1560 ]
    - [ 1841, 1477, 1445, 1497, 1507, 1493, 1526, 1507, 1306, 1381 ]
    - [ 1859, 1452, 1473, 1473, 1450, 1475, 1462, 1312, 1297, 1329 ]
    - [ 1894, 1504, 1471, 1505, 1508, 1510, 1504, 1516, 1509, 1515 ]
    - [ 1931, 1499, 1471, 1489, 1478, 1490, 1485, 1490, 1526, 1531 ]
  4.0:
    - [ 1612, 1258, 1199, 1220, 1197, 1169, 1184, 1197, 1163, 1226 ]
    - [ 1597, 1148, 1205, 1192, 1120, 1164, 1154, 1163, 1138, 1225 ]
    - [ 1568, 1195, 1186, 1175, 1192, 1179, 1117, 1137, 1150, 1183 ]
    - [ 1597, 1175, 1174, 1184, 1189, 1220, 1184, 1193, 1181, 1187 ]
    - [ 1557, 1197, 1180, 1239, 1164, 1168, 1211, 1163, 1131, 1165 ]
    - [ 1603, 1201, 1188, 1154, 1152, 1152, 1162, 1169, 1170, 1185 ]
    - [ 1593, 1202, 1155, 1151, 1171, 1140, 1134, 1156, 1147, 1197 ]
    - [ 1576, 1163, 1175, 1166, 1175, 1125, 1150, 1141, 1192, 1142 ]
    - [ 1539, 1139, 1164, 1165, 1163, 1166, 1160, 1129, 1135, 1145 ]
    - [ 1566, 1182, 1173, 1186, 1168, 1125, 1186, 1142, 1193, 1175 ]
  5.0:
    - [ 1365, 895, 895, 892, 889, 886, 885, 883, 881, 888 ]
    - [ 1351, 897, 904, 898, 889, 888, 904, 884, 886, 911 ]
    - [ 1290, 905, 902, 886, 881, 880, 887, 893, 874, 893 ]
    - [ 1306, 916, 918, 904, 913, 901, 897, 906, 903, 914 ]
    - [ 1392, 919, 918, 899, 895, 905, 898, 899, 902, 914 ]
    - [ 1300, 910, 908, 895, 901, 893, 898, 904, 896, 912 ]
    - [ 1358, 890, 881, 880, 881, 890, 879, 893, 872, 884 ]
    - [ 1357, 904, 916, 896, 908, 898, 889, 887, 887, 902 ]
    - [ 1344, 928, 911, 918, 916, 927, 916, 918, 905, 938 ]
    - [ 1317, 904, 898, 904, 902, 900, 896, 903, 897, 910 ]
  6.0:
    - [ 1160, 798, 787, 791, 783, 783, 780, 789, 784, 799 ]
    - [ 1165, 777, 786, 788, 783, 783, 789, 773, 785, 792 ]
    - [ 1121, 782, 782, 781, 779, 776, 785, 782, 774, 794 ]
    - [ 1225, 784, 778, 784, 766, 776, 786, 775, 785, 779 ]
    - [ 1222, 787, 785, 791, 787, 785, 788, 789, 786, 797 ]
    - [ 1160, 788, 788, 778, 778, 781, 769, 783, 777, 784 ]
    - [ 1204, 794, 790, 787, 788, 775, 790, 788, 782, 793 ]
    - [ 1180, 762, 761, 766, 775, 766, 771, 767, 764, 787 ]
    - [ 1207, 780, 776, 774, 775, 776, 771, 777, 778, 791 ]
    - [ 1209, 780, 786, 780, 780, 773, 782, 788, 782, 782 ]
  7.0:
    - [ 1077, 643, 663, 655, 653, 650, 659, 656, 646, 661 ]
    - [ 1017, 663, 667, 666, 654, 659, 656, 659, 660, 665 ]
    - [ 1041, 661, 659, 658, 663, 654, 666, 656, 663, 665 ]
    - [ 1083, 658, 658, 660, 662, 662, 660, 661, 659, 662 ]
    - [ 1042, 671, 666, 657, 657, 656, 655, 663, 663, 656 ]
    - [ 1060, 653, 653, 657, 653, 658, 658, 654, 657, 663 ]
    - [ 1099, 667, 665, 657, 665, 663, 671, 666, 663, 668 ]
    - [ 1028, 649, 661, 665, 660, 646, 653, 659, 649, 666 ]
    - [ 1030, 675, 684, 668, 667, 666, 674, 686, 666, 670 ]
    - [ 1047, 664, 672, 665, 667, 669, 670, 666, 664, 682 ]
  8.0:
    - [ 996, 613, 606, 602, 606, 596, 598, 601, 598, 600 ]
    - [ 1002, 597, 588, 590, 588, 588, 592, 587, 591, 592 ]
    - [ 1054, 600, 592, 588, 594, 588, 589, 589, 588, 587 ]
    - [ 991, 621, 610, 614, 622, 610, 610, 612, 610, 619 ]
    - [ 1013, 625, 608, 609, 615, 614, 605, 608, 615, 605 ]
    - [ 1067, 590, 589, 591, 591, 603, 591, 591, 588, 596 ]
    - [ 990, 614, 605, 608, 608, 608, 618, 605, 608, 608 ]
    - [ 976, 584, 588, 589, 584, 585, 584, 584, 585, 589 ]
    - [ 1014, 600, 596, 598, 594, 591, 597, 595, 599, 594 ]
    - [ 1010, 601, 600, 599, 604, 600, 604, 599, 598, 609 ]
  9.0:
    - [ 1019, 604, 599, 599, 604, 601, 602, 600, 601, 603 ]
    - [ 1022, 596, 595, 595, 594, 593, 592, 595, 596, 597 ]
    - [ 1010, 602, 602, 601, 601, 602, 600, 597, 601, 599 ]
    - [ 945, 584, 588, 590, 586, 586, 584, 586, 587, 586 ]
    - [ 1034, 613, 613, 615, 613, 613, 611, 614, 613, 613 ]
    - [ 968, 586, 588, 588, 583, 585, 581, 583, 585, 589 ]
    - [ 984, 589, 586, 587, 586, 587, 585, 588, 588, 586 ]
    - [ 1116, 601, 597, 598, 597, 598, 595, 603, 596, 597 ]
    - [ 985, 592, 588, 587, 590, 588, 586, 585, 587, 587 ]
    - [ 1025, 598, 593, 595, 593, 593, 594, 592, 594, 596 ]
  10.0:
    - [ 984, 581, 581, 583, 583, 583, 583, 580, 582, 584 ]
    - [ 974, 571, 578, 576, 575, 573, 575, 574, 575, 576 ]
    - [ 1013, 585, 588, 587, 591, 587, 586, 586, 587, 586 ]
    - [ 960, 593, 593, 597, 595, 595, 595, 595, 595, 596 ]
    - [ 982, 595, 597, 595, 593, 596, 592, 596, 594, 597 ]
    - [ 994, 579, 578, 579, 578, 577, 578, 578, 577, 578 ]
    - [ 996, 579, 582, 579, 582, 579, 581, 578, 578, 580 ]
    - [ 966, 584, 591, 587, 587, 587, 587, 584, 584, 590 ]
    - [ 1006, 587, 587, 587, 590, 585, 585, 588, 586, 587 ]
    - [ 953, 572, 578, 574, 572, 574, 573, 575, 572, 574 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 10885, 6617, 5947, 5937, 5966, 5923, 5888, 5905, 5887, 5911 ]
    - [ 10623, 6656, 5935, 5947, 5919, 5850, 5914, 5907, 5892, 5879 ]
    - [ 10721, 6689, 5987, 5939, 5956, 5965, 5948, 5887, 5880, 6024 ]
    - [ 11043, 6708, 6016, 6025, 5936, 5975, 5933, 5943, 5896, 5935 ]
    - [ 10874, 6665, 6025, 5973, 5996, 5945, 5960, 5925, 5935, 5932 ]
    - [ 11141, 6853, 6100, 6115, 6096, 6088, 6071, 6063, 6058, 6077 ]
    - [ 10651, 6684, 6022, 6022, 5953, 5946, 5976, 5953, 5943, 5945 ]
    - [ 10710, 6654, 5952, 5913, 5908, 5914, 5878, 5902, 5889, 5933 ]
    - [ 10687, 6621, 5920, 5873, 5825, 5892, 5874, 5864, 5880, 5878 ]
    - [ 10825, 6455, 6026, 5887, 5855, 5916, 5901, 5858, 5899, 5909 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2859, 2486, 2476, 2511, 2151, 1899, 1876, 1903, 1884, 1947 ]
    - [ 2867, 2527, 2534, 2521, 2096, 2025, 1924, 1895, 1916, 1956 ]
    - [ 2868, 2514, 2493, 2462, 2146, 2046, 1931, 1930, 1884, 1927 ]
    - [ 2857, 2492, 2498, 2509, 2141, 1876, 1854, 1847, 1883, 1915 ]
    - [ 2840, 2530, 2504, 2497, 2109, 1930, 1923, 1875, 1881, 1926 ]
    - [ 2885, 2534, 2514, 2538, 2544, 1921, 1912, 1875, 1887, 1917 ]
    - [ 2907, 2534, 2520, 2513, 2157, 1957, 1920, 1895, 1904, 1942 ]
    - [ 2880, 2515, 2501, 2499, 2162, 1936, 1913, 1866, 1854, 1916 ]
    - [ 2865, 2524, 2517, 2521, 2094, 2032, 1933, 1906, 1914, 1952 ]
    - [ 2835, 2488, 2471, 2495, 2225, 1951, 1925, 1899, 1884, 1933 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4699, 4322, 4244, 4282, 4256, 3559, 3626, 3609, 3628, 3639 ]
    - [ 4646, 4397, 4305, 4269, 4315, 4255, 4259, 3645, 3606, 3703 ]
    - [ 4695, 4291, 4222, 4265, 4247, 4229, 4206, 3560, 3624, 3622 ]
    - [ 4635, 4345, 4235, 4278, 4268, 3732, 3709, 3687, 3658, 3640 ]
    - [ 4601, 4347, 4314, 4297, 4282, 3655, 3671, 3606, 3605, 3674 ]
    - [ 4619, 4265, 4289, 4308, 4311, 4298, 4245, 3622, 3653, 3642 ]
    - [ 4625, 4242, 4254, 4243, 4277, 3610, 3593, 3622, 3619, 3608 ]
    - [ 4660, 4334, 4269, 4250, 4270, 3615, 3630, 3652, 3640, 3692 ]
    - [ 4805, 4362, 4290, 4201, 4209, 4233, 4229, 3639, 3656, 3592 ]
    - [ 4612, 4289, 4268, 4206, 4268, 4227, 4257, 3619, 3583, 3642 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 58647, 60191, 59084, 60274, 62779, 61690, 58702, 59275, 60813, 61439 ]
    - [ 59764, 60694, 61812, 60494, 60373, 58914, 63693, 66002, 59867, 61007 ]
    - [ 58998, 62025, 62363, 59506, 67199, 61194, 61072, 60298, 66460, 63228 ]
    - [ 58765, 63095, 58894, 59075, 62079, 62172, 62186, 62408, 62822, 60911 ]
    - [ 63520, 64094, 60306, 60181, 61976, 58982, 61946, 60901, 63577, 60999 ]
    - [ 58341, 63022, 65289, 61472, 58652, 61376, 59979, 62397, 63352, 62054 ]
    - [ 62945, 62051, 63102, 60061, 62115, 59205, 62274, 63482, 56698, 62323 ]
    - [ 58426, 62271, 64126, 61215, 58502, 60858, 60574, 56976, 61704, 59441 ]
    - [ 57328, 57612, 58668, 57611, 59668, 60717, 61766, 60828, 63592, 59802 ]
    - [ 61171, 64085, 59770, 62522, 60889, 59376, 60760, 64176, 61796, 60863 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 8817, 8763, 8778, 8909, 8777 ]
    - [ 8823, 8741, 8757, 8760, 8824 ]
    - [ 8862, 8778, 8800, 8793, 8884 ]
    - [ 8823, 8781, 8756, 8776, 8812 ]
    - [ 8834, 8781, 8715, 8748, 8788 ]
    - [ 8849, 8805, 8723, 8776, 8808 ]
    - [ 8836, 8726, 8732, 8757, 8829 ]
    - [ 8807, 8726, 8782, 8779, 8811 ]
    - [ 8829, 8748, 8725, 8736, 8828 ]
    - [ 8792, 8739, 8767, 8721, 8822 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3305, 3208, 3214, 3186, 3268 ]
    - [ 3349, 3286, 3276, 3268, 3311 ]
    - [ 3282, 3212, 3202, 3215, 3277 ]
    - [ 3425, 3274, 3277, 3233, 3282 ]
    - [ 3408, 3259, 3332, 3276, 3353 ]
    - [ 3336, 3284, 3314, 3296, 3353 ]
    - [ 3370, 3284, 3246, 3264, 3248 ]
    - [ 3335, 3280, 3242, 3273, 3280 ]
    - [ 3356, 3224, 3231, 3261, 3299 ]
    - [ 3332, 3268, 3283, 3293, 3321 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3384, 3363, 3258, 3209, 3239 ]
    - [ 3323, 3293, 3280, 3128, 3184 ]
    - [ 3317, 3293, 3189, 3111, 3187 ]
    - [ 3440, 3303, 3326, 3141, 3194 ]
    - [ 3326, 3248, 3177, 3139, 3173 ]
    - [ 3424, 3325, 3217, 3207, 3199 ]
    - [ 3382, 3340, 3213, 3140, 3190 ]
    - [ 3410, 3374, 3366, 3194, 3272 ]
    - [ 3360, 3308, 3344, 3168, 3241 ]
    - [ 3415, 3319, 3247, 3124, 3191 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 3763, 3566, 3408, 3433, 3286 ]
    - [ 3822, 3534, 3480, 3482, 3394 ]
    - [ 3867, 3588, 3399, 3452, 3392 ]
    - [ 3875, 3487, 3500, 3414, 3429 ]
    - [ 3802, 3578, 3478, 3431, 3448 ]
    - [ 3824, 3507, 3498, 3408, 3452 ]
    - [ 3892, 3524, 3454, 3426, 3342 ]
    - [ 3809, 3521, 3431, 3424, 3424 ]
    - [ 3807, 3510, 3490, 3485, 3467 ]
    - [ 3809, 3515, 3460, 3416, 3308 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3079, 2960, 2950, 2995, 3023 ]
    - [ 3121, 2967, 3016, 2915, 2939 ]
    - [ 3080, 2939, 2966, 2979, 3027 ]
    - [ 3098, 3007, 2976, 3004, 2893 ]
    - [ 3134, 2951, 2940, 2986, 3000 ]
    - [ 3071, 2945, 3002, 3019, 3029 ]
    - [ 3079, 2964, 2948, 2935, 2916 ]
    - [ 3101, 2964, 2986, 3011, 3049 ]
    - [ 3062, 2911, 2936, 2907, 3000 ]
    - [ 3099, 3008, 2973, 2926, 2916 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4527, 4070, 4139, 3835, 3751 ]
    - [ 4436, 4066, 4132, 4153, 4107 ]
    - [ 4533, 4213, 4267, 4301, 4191 ]
    - [ 4490, 4101, 4129, 4124, 4157 ]
    - [ 4475, 4118, 4097, 4136, 4087 ]
    - [ 4484, 4069, 4080, 4265, 4144 ]
    - [ 4469, 4126, 4161, 4246, 4185 ]
    - [ 4571, 4171, 4221, 4181, 4077 ]
    - [ 4504, 4194, 4171, 4258, 4156 ]
    - [ 4492, 4155, 4247, 4257, 4155 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3772, 3509, 3424, 3486, 3504 ]
    - [ 3698, 3457, 3441, 3415, 3407 ]
    - [ 3806, 3391, 3412, 3402, 3518 ]
    - [ 3815, 3520, 3461, 3484, 3499 ]
    - [ 3816, 3450, 3427, 3013, 3047 ]
    - [ 3782, 3485, 3427, 3442, 3496 ]
    - [ 3839, 3513, 3459, 3407, 3512 ]
    - [ 3794, 3422, 3413, 3428, 3404 ]
    - [ 3763, 3507, 3440, 3405, 3442 ]
    - [ 3854, 3451, 3426, 3400, 3585 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4094, 3667, 3706, 3658, 3671 ]
    - [ 4100, 3709, 3619, 3339, 3297 ]
    - [ 4087, 3623, 3629, 3634, 3700 ]
    - [ 4153, 3781, 3692, 3691, 3749 ]
    - [ 4115, 3707, 3682, 3621, 3694 ]
    - [ 4015, 3694, 3655, 3659, 3622 ]
    - [ 4003, 3639, 3657, 3668, 3606 ]
    - [ 4127, 3706, 3683, 3676, 3730 ]
    - [ 3939, 3656, 3613, 3694, 3641 ]
    - [ 4107, 3821, 3651, 3247, 3234 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3391, 3068, 3045, 3055, 3075 ]
    - [ 3318, 3038, 2981, 3062, 2996 ]
    - [ 3439, 3085, 3012, 3012, 3025 ]
    - [ 3450, 3040, 3032, 3003, 3083 ]
    - [ 3371, 3030, 2995, 2977, 3083 ]
    - [ 3394, 3059, 2999, 3020, 3090 ]
    - [ 3407, 2994, 3033, 3007, 3048 ]
    - [ 3403, 3041, 2967, 3011, 3043 ]
    - [ 3355, 3021, 2972, 2992, 3028 ]
    - [ 3310, 3066, 3067, 3041, 3077 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 4023, 3703, 3688, 3730, 3776, 3816, 3727, 3658, 3809, 3802 ]
    - [ 3976, 3580, 3680, 3726, 3689, 3162, 3213, 3188, 3197, 3239 ]
    - [ 4006, 3758, 3688, 3708, 3843, 3843, 3770, 3725, 3779, 3709 ]
    - [ 3956, 3731, 3663, 3768, 3766, 3747, 3768, 3751, 3737, 3723 ]
    - [ 3909, 3657, 3806, 3739, 3741, 3756, 3813, 3718, 3721, 3693 ]
    - [ 3966, 3663, 3731, 3688, 3766, 3638, 3653, 3726, 3705, 3671 ]
    - [ 3981, 3642, 3727, 3703, 3687, 3722, 3687, 3778, 3693, 3663 ]
    - [ 3967, 3676, 3746, 3690, 3717, 3749, 3798, 3707, 3700, 3703 ]
    - [ 3937, 3666, 3671, 3689, 3747, 3700, 3755, 3776, 3696, 3669 ]
    - [ 3928, 3768, 3647, 3729, 3789, 3774, 3746, 3722, 3775, 3674 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 13805, 7741, 6731, 6737, 6707, 6776, 6763, 6687, 6699, 6708 ]
    - [ 13620, 7748, 6843, 6781, 6758, 6763, 6790, 6734, 6732, 6726 ]
    - [ 13419, 7558, 6766, 6765, 6723, 6746, 6761, 6739, 6694, 6704 ]
    - [ 13322, 7865, 6824, 6735, 6742, 6779, 6822, 6759, 6747, 6719 ]
    - [ 13804, 7687, 6823, 6808, 6691, 6732, 6732, 6757, 6709, 6744 ]
    - [ 13303, 7768, 6721, 6745, 6658, 6710, 6619, 6656, 6636, 6658 ]
    - [ 13795, 7899, 6769, 6822, 6748, 6811, 6742, 6706, 6758, 6734 ]
    - [ 13816, 7988, 6865, 6820, 6824, 6794, 6861, 6819, 6754, 6761 ]
    - [ 13059, 7647, 6964, 6828, 6842, 6831, 6769, 6767, 6774, 6781 ]
    - [ 14079, 7734, 6874, 6835, 6811, 6848, 6814, 6806, 6762, 6784 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 6718, 6151, 5759, 5749, 5779, 5819, 5766, 5798, 5862, 6107 ]
    - [ 6608, 5960, 5789, 5644, 5742, 5674, 5725, 5788, 5779, 5982 ]
    - [ 6777, 6115, 5875, 5874, 5897, 5929, 5893, 5949, 5964, 6202 ]
    - [ 6679, 6286, 5874, 5710, 5802, 5759, 5761, 5827, 5764, 5942 ]
    - [ 6742, 6314, 5808, 5744, 5792, 5768, 5727, 5752, 5832, 6066 ]
    - [ 6802, 6391, 6057, 5773, 5777, 5737, 5760, 5781, 5809, 5984 ]
    - [ 6620, 6184, 5869, 5721, 5702, 5687, 5662, 5684, 5705, 5979 ]
    - [ 6720, 6225, 5867, 5661, 5706, 5754, 5755, 5729, 5825, 5979 ]
    - [ 6614, 6090, 5784, 5777, 5738, 5860, 5798, 5804, 5767, 5940 ]
    - [ 6520, 5836, 5780, 5767, 5711, 5737, 5700, 5756, 5778, 6046 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 20688, 13616, 12066, 12025, 11969, 12034, 11998, 11895, 11964, 11886 ]
    - [ 20897, 13327, 12052, 11979, 11912, 11953, 11918, 11994, 11943, 11938 ]
    - [ 20318, 13292, 12302, 12087, 12059, 12042, 12065, 11970, 12000, 12016 ]
    - [ 20848, 13418, 12244, 12098, 12104, 12149, 12110, 12046, 12040, 12082 ]
    - [ 21774, 13253, 12252, 12043, 12118, 12161, 12100, 12087, 12111, 12058 ]
    - [ 20578, 13654, 12231, 12116, 12070, 12134, 12106, 11932, 11984, 12032 ]
    - [ 21403, 13596, 12098, 12185, 12056, 12150, 11982, 11966, 11978, 11926 ]
    - [ 20582, 13050, 12025, 11896, 11921, 11829, 11954, 11956, 11892, 11754 ]
    - [ 21026, 13010, 11983, 11911, 11849, 11957, 11935, 11874, 11889, 11922 ]
    - [ 22223, 13350, 12121, 12070, 11989, 12052, 12051, 12048, 11963, 11986 ]
