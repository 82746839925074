import * as bms from "../static/bms/*/stats-nominal.yml";
import * as React from "react";
import {useState} from "react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table';
import {NominalStat, NominalTuple} from "../types";
import {Link} from "react-router";


type Row = {
    [key in typeof NominalStat[number]]?: NominalTuple;
} & {
    benchmark: string;
};

const columnHelper = createColumnHelper<Row>();

// @ts-ignore
function roundToOne(num) {
    // @ts-ignore
    return +(Math.round(num + "e+1") + "e-1");
}

function makeColumn(key: keyof Row, title: String, unit: String, scale: number) {
    return columnHelper.accessor(row => {
        return row[key];
    }, {
        id: key.toString(),
        header: () => <span>{title}</span>,
        cell: info => {
            const val: NominalTuple | String | undefined = info.getValue();
            if (val) {
                let num = roundToOne(Number(val[1]) * scale).toString();
                return (<span title={`${val[2]} ${val[6]}`}>{num + (unit ? "\u2009" : "") + unit}</span>);
            } else {
                return (<span></span>);
            }
        },
        sortingFn: (rowA, rowB, columnId) => {
            const valA: NominalTuple | undefined = rowA.getValue(columnId);
            const valB: NominalTuple | undefined = rowB.getValue(columnId);
            const numA = valA ? valA[1] : 0;
            const numB = valB ? valB[1] : 0;
            return numA < numB ? -1 : numA > numB ? 1 : 0;
        },
        sortUndefined: -1
    })
}

const columns = [
    columnHelper.accessor('benchmark', {
        header: () => <span>Benchmark</span>,
        cell: info => <Link to={`/benchmark/${info.getValue()}`} className="underline">{info.getValue()}</Link>,
    }),
    columnHelper.group({
        id: "allocation",
        header: () => <span>Allocation</span>,
        columns: [
            makeColumn("AOS", "10p", "B", 1),
            makeColumn("AOA", "Avg", "B", 1),
            makeColumn("AOM", "Med", "B", 1),
            makeColumn("AOL", "90p", "B", 1),
            makeColumn("ARA", "Rate", "B/μs", 1)
        ]
    }),
    columnHelper.group({
        id: "bytecode",
        header: () => <span>Bytecode</span>,
        columns: [
            makeColumn("BAL", "aaload", "/μs", 1),
            makeColumn("BAS", "aastore", "/μs", 1),
            makeColumn("BGF", "getfield", "/μs", 1),
            makeColumn("BPF", "putfield", "/μs", 1),
            makeColumn("BEF", "Hot", "", 1),
            makeColumn("BUB", "Unique", "K", 1),
            makeColumn("BUF", "Funcs", "K", 1),
        ]
    }),
    columnHelper.group({
        id: "post-gc",
        header: () => <span>Post GC</span>,
        columns: [
            makeColumn("GCA", "Avg", "%", 1),
            makeColumn("GCM", "Med", "%", 1),

        ]
    }),
    columnHelper.group({
        id: "gc",
        header: () => <span>GC</span>,
        columns: [
            makeColumn("GCC", "Count@2x", "", 1),
            makeColumn("GCP", "STW@2x", "%", 1),
            makeColumn("GLK", "Leak", "%", 1),
            makeColumn("GSS", "Sensitivity", "%", 1),
            makeColumn("GTO", "Turnover", "", 1)
        ]
    }),
    columnHelper.group({
        id: "minheap",
        header: () => <span>Minheap</span>,
        columns: [
            makeColumn("GMD", "Default", "MB", 1),
            makeColumn("GML", "Large", "MB", 1),
            makeColumn("GMS", "Small", "MB", 1),
            makeColumn("GMV", "Vlarge", "MB", 1),
            makeColumn("GMU", "Uncomp", "MB", 1)
        ]
    }),
    columnHelper.group({
        id: "time",
        header: () => <span>Time</span>,
        columns: [
            makeColumn("PET", "Total", "s", 1),
            makeColumn("PPE", "//", "%", 1),
            makeColumn("PKP", "Kernel", "%", 1),
            makeColumn("PSD", "Noise", "%", 1),
            makeColumn("PWU", "Warmup", "", 1),
        ]
    }),
    columnHelper.group({
        id: "compiler",
        header: () => <span>Compiler</span>,
        columns: [
            makeColumn("PCC", "C2", "%", 1),
            makeColumn("PCS", "Worst", "%", 1),
            makeColumn("PIN", "Int", "%", 1),
        ]
    }),
    columnHelper.group({
        id: "arch",
        header: () => <span>Arch</span>,
        columns: [
            makeColumn("PLS", "LLC", "%", 1),
            makeColumn("PMS", "Mem", "%", 1),
            makeColumn("PFS", "Freq", "%", 1),
            makeColumn("UDC", "L1D$K", "", 1),
            makeColumn("UDT", "DTLBM", "", 1),
            makeColumn("ULL", "LLCM", "", 1),
            makeColumn("UIP", "IPC", "", 0.01),
            makeColumn("UAI", "Intel", "%", 1),
            makeColumn("UAA", "ARM", "%", 1)
        ]
    }),
    columnHelper.group({
        id: "tme",
        header: () => <span>Topdown</span>,
        columns: [
            makeColumn("USF", "FE", "%", 1),
            makeColumn("USB", "BE", "%", 1),
            makeColumn("UBM", "BE/Mem", "%", 1),
            makeColumn("USC", "SMT", "%", 0.1),
            makeColumn("UBS", "SPEC", "%", 0.1),
            makeColumn("UBP", "SPEC/MIS", "%", 0.1),
            makeColumn("UBR", "SPEC/RESYNC", "/M", 1),
        ]
    })
]

const rows: Row[] = Object.keys(bms).map(benchmark => {
    return {
        ...bms[benchmark],
        benchmark: benchmark
    };
});

export function Nominal() {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = useState({})

    const table = useReactTable({
        data: rows,
        columns,
        state: {
            sorting,
            columnVisibility
        },
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="flex mt-1 items-start">
            <div className="grid grid-rows-49 grid-flow-col border-r border-slate-500">
                {table.getHeaderGroups().map((headerGroup, i) => (
                    headerGroup.headers.map(header =>
                        <div
                            className={(i === 1 ? "w-28 " : "") + "h-8 whitespace-nowrap select-none row-span-" + header.colSpan.toString()}
                            key={header.id}
                        >
                            {header.isPlaceholder
                                ? null
                                : <div className={header.column.getCanSort()
                                    ? 'cursor-pointer'
                                    : ''}
                                       onClick={header.column.getToggleSortingHandler()}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    {{
                                        asc: ' 🔼',
                                        desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ?? null}

                                </div>

                            }
                        </div>
                    )))}

            </div>
            <div className="grid grid-rows-49 grid-flow-col overflow-x-scroll ml-2">
                {table.getRowModel().rows.map(row =>
                    row.getVisibleCells().map(cell =>
                        <div className="text-right h-8 pl-2 whitespace-nowrap tabular-nums" key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                    ))}

            </div>
        </div>
    );
}
