#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 7567, 6509, 6238, 6219, 6456, 6282, 6585, 6336, 6362, 6317 ]
    - [ 7275, 6279, 6184, 6137, 7359, 6540, 6792, 6593, 6530, 6503 ]
    - [ 7292, 6281, 6118, 6179, 6487, 6166, 6247, 7037, 6282, 6532 ]
    - [ 7420, 6274, 6128, 6133, 6251, 6544, 6187, 6433, 6533, 6417 ]
    - [ 7239, 6207, 6096, 6264, 6249, 6488, 6627, 6254, 6244, 6418 ]
    - [ 7398, 6415, 6264, 6178, 6405, 6378, 6484, 6294, 6320, 6828 ]
  2.0:
    - [ 6800, 5803, 5797, 5663, 5684, 5791, 5674, 5699, 5728, 5820 ]
    - [ 6805, 5862, 5777, 5709, 5676, 5744, 5799, 5780, 5667, 5670 ]
    - [ 6697, 5906, 5717, 5687, 5700, 5707, 5649, 5660, 5693, 5756 ]
    - [ 6800, 5914, 5776, 5668, 5617, 5672, 5673, 5656, 5630, 5634 ]
    - [ 6871, 5842, 5765, 5698, 5743, 5714, 5782, 5777, 5756, 5742 ]
    - [ 6743, 5872, 5683, 5697, 5647, 5660, 5659, 5695, 5627, 5653 ]
    - [ 6833, 5888, 5854, 5756, 5714, 5697, 5722, 5638, 5621, 5654 ]
    - [ 6718, 5901, 5763, 5768, 5670, 5779, 5681, 5642, 5684, 5691 ]
    - [ 6692, 5886, 5760, 5682, 5656, 5710, 5629, 5677, 5624, 5633 ]
    - [ 6814, 5874, 5784, 5703, 5671, 5775, 5762, 5712, 5663, 5708 ]
  3.0:
    - [ 6724, 5836, 5439, 5480, 5474, 5417, 5541, 5457, 5498, 5578 ]
    - [ 6682, 5717, 5417, 5518, 5585, 5439, 5542, 5483, 5454, 5527 ]
    - [ 6555, 5779, 5462, 5528, 5460, 5478, 5542, 5600, 5562, 5795 ]
    - [ 6505, 5784, 5495, 5506, 5628, 5557, 5608, 5620, 5573, 5725 ]
    - [ 6507, 5865, 5424, 5481, 5494, 5469, 5572, 5591, 5516, 5692 ]
    - [ 6627, 5772, 5439, 5491, 5610, 5440, 5518, 5517, 5543, 5668 ]
    - [ 6445, 5767, 5448, 5524, 5553, 5494, 5579, 5545, 5554, 5724 ]
    - [ 6604, 5838, 5450, 5501, 5473, 5453, 5472, 5410, 5420, 5502 ]
    - [ 6543, 5843, 5454, 5515, 5460, 5428, 5596, 5611, 5567, 5768 ]
    - [ 6555, 5741, 5436, 5493, 5448, 5404, 5526, 5565, 5527, 5698 ]
  4.0:
    - [ 6535, 5594, 5580, 5408, 5545, 5429, 5431, 5403, 5450, 5611 ]
    - [ 6689, 5568, 5593, 5434, 5480, 5390, 5411, 5451, 5444, 5521 ]
    - [ 6499, 5551, 5646, 5420, 5524, 5425, 5386, 5467, 5429, 5512 ]
    - [ 6509, 5551, 5609, 5398, 5500, 5424, 5437, 5422, 5402, 5472 ]
    - [ 6611, 5562, 5604, 5444, 5582, 5418, 5448, 5438, 5430, 5458 ]
    - [ 6512, 5543, 5628, 5408, 5538, 5422, 5388, 5432, 5402, 5587 ]
    - [ 6578, 5550, 5594, 5403, 5571, 5420, 5400, 5428, 5406, 5475 ]
    - [ 6551, 5588, 5665, 5418, 5562, 5423, 5540, 5409, 5417, 5474 ]
    - [ 6640, 5517, 5657, 5424, 5494, 5412, 5418, 5406, 5594, 5442 ]
    - [ 6477, 5554, 5645, 5421, 5429, 5426, 5406, 5433, 5436, 5479 ]
  5.0:
    - [ 6568, 5519, 5406, 5463, 5388, 5467, 5486, 5402, 5380, 5508 ]
    - [ 6567, 5537, 5405, 5661, 5427, 5400, 5463, 5439, 5494, 5495 ]
    - [ 6534, 5601, 5428, 5528, 5397, 5432, 5486, 5404, 5415, 5514 ]
    - [ 6540, 5674, 5406, 5492, 5477, 5385, 5547, 5385, 5467, 5465 ]
    - [ 6483, 5560, 5411, 5519, 5383, 5399, 5485, 5371, 5421, 5488 ]
    - [ 6820, 5505, 5442, 5490, 5420, 5422, 5465, 5440, 5445, 5514 ]
    - [ 6557, 5594, 5405, 5646, 5456, 5405, 5459, 5439, 5454, 5500 ]
    - [ 6824, 5532, 5444, 5502, 5385, 5397, 5466, 5391, 5386, 5496 ]
    - [ 6528, 5717, 5421, 5496, 5398, 5412, 5473, 5406, 5391, 5463 ]
    - [ 6557, 5578, 5418, 5551, 5467, 5392, 5484, 5425, 5421, 5443 ]
  6.0:
    - [ 6606, 5554, 5365, 5512, 5427, 5425, 5437, 5478, 5406, 5393 ]
    - [ 6678, 5576, 5377, 5530, 5422, 5411, 5427, 5465, 5408, 5395 ]
    - [ 6675, 5556, 5388, 5487, 5455, 5393, 5400, 5477, 5431, 5421 ]
    - [ 6757, 5534, 5385, 5506, 5565, 5388, 5408, 5488, 5375, 5394 ]
    - [ 6505, 5522, 5397, 5510, 5565, 5418, 5374, 5460, 5378, 5390 ]
    - [ 6538, 5532, 5422, 5478, 5400, 5409, 5421, 5470, 5357, 5397 ]
    - [ 6525, 5614, 5427, 5516, 5391, 5421, 5374, 5487, 5385, 5506 ]
    - [ 6582, 5695, 5446, 5579, 5381, 5373, 5383, 5481, 5384, 5420 ]
    - [ 6526, 5523, 5425, 5527, 5404, 5398, 5399, 5494, 5447, 5402 ]
    - [ 6488, 5533, 5396, 5497, 5426, 5490, 5403, 5489, 5424, 5368 ]
  7.0:
    - [ 6629, 5540, 5412, 5382, 5617, 5391, 5375, 5365, 5392, 5484 ]
    - [ 6454, 5598, 5418, 5421, 5576, 5411, 5415, 5439, 5385, 5483 ]
    - [ 6635, 5651, 5396, 5427, 5563, 5406, 5376, 5374, 5414, 5507 ]
    - [ 6677, 5500, 5420, 5419, 5572, 5407, 5378, 5377, 5370, 5597 ]
    - [ 6534, 5640, 5388, 5356, 5603, 5412, 5465, 5448, 5398, 5484 ]
    - [ 6571, 5549, 5402, 5383, 5599, 5387, 5359, 5377, 5381, 5464 ]
    - [ 6530, 5547, 5395, 5402, 5627, 5402, 5380, 5427, 5385, 5532 ]
    - [ 6572, 5523, 5437, 5371, 5653, 5419, 5377, 5406, 5402, 5499 ]
    - [ 6497, 5524, 5385, 5418, 5629, 5423, 5375, 5613, 5390, 5466 ]
    - [ 6677, 5533, 5383, 5380, 5600, 5395, 5368, 5370, 5397, 5443 ]
  8.0:
    - [ 6641, 5546, 5399, 5391, 5409, 5540, 5385, 5398, 5408, 5377 ]
    - [ 6584, 5554, 5404, 5454, 5422, 5712, 5399, 5395, 5411, 5397 ]
    - [ 6642, 5585, 5414, 5399, 5399, 5605, 5377, 5356, 5420, 5377 ]
    - [ 6579, 5526, 5397, 5387, 5380, 5625, 5394, 5380, 5380, 5402 ]
    - [ 6511, 5520, 5381, 5430, 5399, 5543, 5385, 5374, 5381, 5388 ]
    - [ 6515, 5545, 5378, 5385, 5367, 5556, 5385, 5367, 5376, 5385 ]
    - [ 6478, 5552, 5404, 5406, 5413, 5550, 5379, 5413, 5390, 5382 ]
    - [ 6746, 5525, 5396, 5430, 5405, 5633, 5400, 5375, 5374, 5373 ]
    - [ 6631, 5532, 5409, 5380, 5375, 5601, 5422, 5413, 5513, 5404 ]
    - [ 6564, 5588, 5396, 5414, 5394, 5597, 5387, 5382, 5363, 5401 ]
  9.0:
    - [ 6665, 5496, 5388, 5383, 5377, 5360, 5492, 5380, 5394, 5382 ]
    - [ 6464, 5507, 5380, 5399, 5385, 5391, 5517, 5367, 5395, 5437 ]
    - [ 6547, 5597, 5386, 5391, 5458, 5434, 5712, 5365, 5373, 5400 ]
    - [ 6561, 5566, 5397, 5371, 5389, 5391, 5486, 5432, 5433, 5414 ]
    - [ 6444, 5530, 5463, 5392, 5448, 5397, 5513, 5393, 5374, 5426 ]
    - [ 6802, 5536, 5396, 5432, 5377, 5410, 5524, 5357, 5478, 5378 ]
    - [ 6639, 5549, 5400, 5403, 5407, 5368, 5505, 5376, 5361, 5406 ]
    - [ 6477, 5570, 5412, 5547, 5449, 5385, 5493, 5388, 5389, 5385 ]
    - [ 6531, 5500, 5509, 5461, 5383, 5386, 5543, 5372, 5353, 5379 ]
    - [ 6693, 5614, 5470, 5399, 5390, 5366, 5491, 5401, 5396, 5414 ]
  10.0:
    - [ 6423, 5515, 5374, 5396, 5390, 5383, 5380, 5416, 5566, 5381 ]
    - [ 6568, 5548, 5563, 5443, 5504, 5378, 5390, 5408, 5419, 5378 ]
    - [ 6513, 5553, 5381, 5411, 5401, 5379, 5515, 5412, 5428, 5380 ]
    - [ 6792, 5536, 5447, 5422, 5381, 5405, 5401, 5394, 5415, 5454 ]
    - [ 6590, 5498, 5377, 5379, 5371, 5465, 5405, 5386, 5389, 5380 ]
    - [ 6637, 5546, 5381, 5375, 5435, 5369, 5417, 5391, 5398, 5358 ]
    - [ 6581, 5583, 5394, 5394, 5369, 5372, 5404, 5407, 5379, 5417 ]
    - [ 6602, 5581, 5425, 5395, 5395, 5374, 5429, 5385, 5406, 5392 ]
    - [ 6543, 5529, 5368, 5393, 5386, 5415, 5473, 5403, 5396, 5411 ]
    - [ 6517, 5611, 5451, 5413, 5433, 5497, 5395, 5402, 5415, 5388 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 43731, 27476, 25526, 25786, 24517, 25356, 25119, 25562, 25472, 24776 ]
    - [ 43907, 26865, 26023, 26087, 25235, 25545, 25272, 26230, 25652, 25323 ]
    - [ 45001, 27815, 25653, 26318, 25479, 25884, 25709, 26678, 26019, 25758 ]
    - [ 44406, 28570, 26124, 26327, 25373, 25612, 25448, 26337, 25652, 25614 ]
    - [ 43479, 26484, 25340, 25083, 24264, 24732, 24738, 25373, 24915, 24290 ]
    - [ 43466, 26668, 25323, 25049, 24440, 24795, 24457, 25481, 24798, 24554 ]
    - [ 44464, 27461, 25558, 25912, 25031, 25587, 25455, 26102, 25592, 25407 ]
    - [ 44621, 29706, 26009, 26082, 25046, 25741, 25698, 26290, 25762, 25434 ]
    - [ 44481, 27842, 26309, 26095, 24794, 25278, 25133, 25937, 25441, 25004 ]
    - [ 44343, 28065, 25904, 26448, 24946, 25710, 25243, 26366, 25594, 25718 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 6731, 5816, 5642, 5637, 5597, 5639, 5691, 5606, 5601, 5643 ]
    - [ 6712, 5824, 5617, 5648, 5664, 5596, 5587, 5672, 5578, 5661 ]
    - [ 6896, 5846, 5668, 5566, 5577, 5560, 5637, 5609, 5589, 5655 ]
    - [ 6650, 5821, 5570, 5598, 5637, 5626, 5596, 5590, 5660, 5610 ]
    - [ 6764, 5792, 5631, 5589, 5557, 5638, 5571, 5706, 5609, 5639 ]
    - [ 6754, 5741, 5617, 5559, 5636, 5635, 5589, 5599, 5628, 5635 ]
    - [ 6727, 5819, 5573, 5596, 5595, 5611, 5560, 5634, 5594, 5592 ]
    - [ 6798, 5830, 5610, 5630, 5626, 5571, 5595, 5607, 5631, 5641 ]
    - [ 6783, 5774, 5618, 5631, 5648, 5539, 5591, 5616, 5667, 5608 ]
    - [ 6667, 5866, 5678, 5629, 5687, 5612, 5620, 5651, 5617, 5626 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 7469, 6305, 6190, 6238, 6315, 6380, 6362, 6191, 6195, 6324 ]
    - [ 7170, 6352, 6317, 6352, 6402, 6272, 6367, 6274, 6280, 6229 ]
    - [ 7122, 6420, 6140, 6205, 6275, 6280, 6261, 6202, 6308, 6296 ]
    - [ 7275, 6483, 6427, 6316, 6390, 6373, 6318, 6311, 6204, 6311 ]
    - [ 7178, 6416, 6280, 6236, 6334, 6288, 6296, 6205, 6278, 6248 ]
    - [ 7252, 6432, 6182, 6254, 6392, 6334, 6409, 6196, 6219, 6332 ]
    - [ 7270, 6372, 6286, 6344, 6315, 6206, 6272, 6225, 6215, 6303 ]
    - [ 7240, 6359, 6362, 6204, 6381, 6366, 6334, 6353, 6294, 6326 ]
    - [ 7183, 6420, 6242, 6276, 6286, 6294, 6223, 6191, 6428, 6333 ]
    - [ 7233, 6359, 6222, 6311, 6367, 6347, 6267, 6266, 6316, 6301 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 7711, 6358, 6146, 6176, 6136, 6265, 6270, 6253, 6211, 6338 ]
    - [ 7520, 6408, 6162, 6159, 6091, 6222, 6287, 6316, 6167, 6190 ]
    - [ 7495, 6377, 6104, 6110, 6208, 6323, 6363, 6331, 6248, 6229 ]
    - [ 7482, 6403, 6136, 6108, 6092, 6161, 6116, 6159, 6142, 6193 ]
    - [ 7550, 6342, 6154, 6193, 6309, 6230, 6236, 6341, 6249, 6260 ]
    - [ 7670, 6372, 6133, 6139, 6144, 6212, 6154, 6198, 6166, 6220 ]
    - [ 7537, 6411, 6185, 6138, 6208, 6205, 6329, 6294, 6193, 6264 ]
    - [ 7500, 6349, 6149, 6135, 6106, 6165, 6159, 6244, 6164, 6281 ]
    - [ 7405, 6300, 6147, 6139, 6131, 6184, 6157, 6290, 6238, 6289 ]
    - [ 7635, 6373, 6164, 6159, 6134, 6164, 6179, 6282, 6161, 6278 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 8365, 7179, 7753, 7539, 7746, 7611, 7628, 7651, 8291, 7969 ]
    - [ 8368, 7509, 7864, 7595, 8040, 7770, 7833, 8080, 8430, 7899 ]
    - [ 8465, 7419, 7400, 7530, 7882, 7539, 8344, 7619, 8094, 8131 ]
    - [ 8216, 7518, 7742, 7245, 7762, 7591, 7346, 7874, 7492, 7786 ]
    - [ 8584, 7325, 7829, 7624, 7835, 7259, 7559, 7805, 7745, 8387 ]
    - [ 8550, 7216, 8049, 7609, 7897, 7716, 7759, 7691, 8013, 7670 ]
    - [ 8341, 7398, 7705, 7461, 7521, 7342, 7752, 7734, 7908, 7754 ]
    - [ 8283, 7832, 7905, 7619, 8164, 7621, 7819, 7747, 7665, 7780 ]
    - [ 8271, 7247, 7610, 7300, 7916, 7460, 7745, 7815, 7981, 7471 ]
    - [ 8284, 7641, 7492, 7331, 8073, 8312, 8131, 8548, 8028, 7829 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 7302, 6781, 6910, 6747, 7045, 6681, 6622, 6867, 6674, 6902 ]
    - [ 7260, 6766, 6914, 6746, 6677, 6504, 7092, 6701, 6588, 6830 ]
    - [ 7230, 6635, 6762, 6687, 7120, 6541, 6700, 6700, 6989, 6966 ]
    - [ 7348, 6783, 7012, 6635, 6871, 6553, 6911, 6752, 6865, 7022 ]
    - [ 7142, 6725, 6749, 6691, 6984, 6539, 6717, 6617, 6744, 6764 ]
    - [ 7313, 6680, 6709, 6535, 6769, 6652, 6696, 6921, 6865, 6870 ]
    - [ 7220, 6664, 6691, 6502, 6792, 6468, 6566, 6620, 6512, 6978 ]
    - [ 7233, 6817, 6798, 6531, 6859, 6523, 6982, 6778, 6714, 6727 ]
    - [ 7393, 6644, 6770, 6585, 6904, 6687, 7087, 6750, 6803, 6975 ]
    - [ 7171, 6666, 6733, 6566, 6781, 6534, 6701, 7084, 6799, 6750 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 26720, 30714, 30950, 29663, 28602 ]
    - [ 27185, 30601, 31067, 30118, 28192 ]
    - [ 27095, 30314, 30953, 29861, 28341 ]
    - [ 26979, 30135, 31489, 29669, 28279 ]
    - [ 28150, 30592, 31438, 29887, 27975 ]
    - [ 28369, 30079, 31072, 29921, 27913 ]
    - [ 26847, 30898, 31055, 30138, 27805 ]
    - [ 27259, 30615, 31192, 29838, 27806 ]
    - [ 29312, 30400, 31265, 29940, 28231 ]
    - [ 29352, 29964, 31376, 29743, 28003 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7232, 7492, 7582, 7473, 7481 ]
    - [ 7206, 7169, 7576, 7516, 7441 ]
    - [ 7212, 7408, 7544, 7449, 7471 ]
    - [ 7326, 7457, 7762, 7459, 7499 ]
    - [ 7298, 7538, 7521, 7536, 7453 ]
    - [ 7212, 7068, 7509, 7563, 7475 ]
    - [ 7257, 7181, 7581, 7590, 7476 ]
    - [ 7159, 7501, 7507, 7444, 7559 ]
    - [ 7204, 7118, 7505, 7800, 7485 ]
    - [ 7208, 7110, 7534, 7535, 7573 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 6985, 6920, 6944, 6893, 6899 ]
    - [ 6993, 6941, 6965, 6844, 6842 ]
    - [ 6956, 6960, 6912, 6852, 6908 ]
    - [ 7424, 6905, 6920, 6852, 6841 ]
    - [ 7000, 6906, 6957, 6846, 6902 ]
    - [ 6955, 6913, 6919, 6843, 6776 ]
    - [ 6955, 6972, 6957, 6881, 6896 ]
    - [ 6956, 6896, 6959, 6886, 6978 ]
    - [ 6983, 6923, 6906, 6893, 6927 ]
    - [ 7110, 6873, 6973, 6876, 6844 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 10920, 11525, 6588, 6529, 6453 ]
    - [ 10900, 10649, 10573, 10532, 8362 ]
    - [ 10568, 11112, 10319, 10381, 8265 ]
    - [ 10935, 10682, 10483, 7288, 6565 ]
    - [ 10812, 10658, 6570, 6487, 6382 ]
    - [ 10966, 10412, 10009, 6497, 6539 ]
    - [ 10959, 10825, 6508, 6563, 6427 ]
    - [ 10989, 11315, 8165, 7312, 6428 ]
    - [ 10947, 10748, 10055, 10041, 8225 ]
    - [ 10897, 10637, 6535, 6495, 6407 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 6778, 5905, 5781, 5702, 5693 ]
    - [ 6881, 5934, 5714, 5792, 5729 ]
    - [ 6681, 5854, 5781, 5675, 5683 ]
    - [ 6803, 5914, 5848, 5770, 5700 ]
    - [ 6750, 5898, 5775, 5690, 5727 ]
    - [ 6764, 5975, 5773, 5663, 5771 ]
    - [ 6768, 5928, 5760, 5672, 5682 ]
    - [ 6834, 5928, 5796, 5688, 5662 ]
    - [ 6764, 5858, 5760, 5690, 5817 ]
    - [ 6762, 5864, 5813, 5759, 5688 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 10061, 6956, 5879, 5949, 5807 ]
    - [ 9794, 6935, 5982, 5976, 5867 ]
    - [ 9754, 7024, 6197, 6000, 5869 ]
    - [ 9923, 6926, 5951, 5904, 5795 ]
    - [ 9772, 7021, 6227, 5968, 6039 ]
    - [ 10097, 6885, 5925, 5933, 5903 ]
    - [ 9737, 6923, 6001, 5911, 5888 ]
    - [ 10004, 7171, 5925, 5950, 5965 ]
    - [ 9780, 7219, 6092, 6036, 5867 ]
    - [ 9702, 6888, 6264, 6013, 5873 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 7949, 6170, 5870, 5664, 5728 ]
    - [ 7456, 6074, 5767, 5742, 5660 ]
    - [ 7559, 5838, 5831, 5675, 5633 ]
    - [ 7294, 6024, 5762, 5687, 5638 ]
    - [ 7477, 6013, 5887, 5708, 5681 ]
    - [ 7301, 6037, 5720, 5706, 5758 ]
    - [ 7521, 5932, 5822, 5732, 5692 ]
    - [ 7318, 5996, 5701, 5698, 5670 ]
    - [ 7219, 5955, 5757, 5675, 5710 ]
    - [ 7319, 6192, 5825, 5724, 5692 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 7585, 6014, 6026, 5975, 5773 ]
    - [ 7679, 6144, 5891, 5986, 5784 ]
    - [ 7382, 6139, 5966, 5873, 5755 ]
    - [ 7436, 6161, 6002, 5948, 5820 ]
    - [ 7281, 6075, 5958, 5826, 5726 ]
    - [ 7694, 6151, 5939, 5820, 5776 ]
    - [ 7276, 6099, 5961, 5895, 5946 ]
    - [ 7329, 6064, 5945, 5883, 5820 ]
    - [ 7384, 6139, 5886, 5787, 5845 ]
    - [ 7209, 6114, 5913, 5799, 5827 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 6518, 5776, 5593, 5557, 5581 ]
    - [ 6868, 5814, 5582, 5609, 5533 ]
    - [ 6684, 5709, 5591, 5515, 5554 ]
    - [ 6550, 5708, 5673, 5574, 5599 ]
    - [ 6579, 5780, 5611, 5564, 5486 ]
    - [ 6583, 5796, 5738, 5612, 5594 ]
    - [ 6516, 5679, 5592, 5678, 5697 ]
    - [ 6578, 5798, 5685, 5611, 5631 ]
    - [ 6656, 5726, 5624, 5585, 5541 ]
    - [ 6567, 5696, 5688, 5578, 5523 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 7929, 6299, 6074, 5960, 5891, 5928, 5800, 5904, 5895, 5837 ]
    - [ 7776, 6212, 5869, 5874, 5900, 5885, 5854, 5845, 5839, 5818 ]
    - [ 7860, 6222, 6096, 5984, 5885, 5901, 5879, 5889, 5854, 5873 ]
    - [ 7849, 6334, 6094, 5961, 5862, 5895, 5964, 5886, 5821, 5857 ]
    - [ 7815, 6302, 6121, 5915, 5906, 5898, 5853, 5885, 5812, 5862 ]
    - [ 7811, 6297, 5934, 6019, 5924, 5954, 5858, 5880, 5861, 5863 ]
    - [ 7979, 6285, 5927, 5974, 6071, 6058, 5952, 5966, 5852, 5862 ]
    - [ 7882, 6428, 5943, 6020, 5835, 5902, 5803, 5900, 5852, 5853 ]
    - [ 7853, 6175, 5921, 5875, 5855, 5890, 5853, 5861, 5845, 5893 ]
    - [ 7905, 6341, 5927, 5921, 6115, 5946, 5836, 5888, 5828, 5848 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 51053, 27655, 22794, 23104, 22276, 23556, 22766, 23391, 22936, 22721 ]
    - [ 51645, 26115, 23034, 23283, 22582, 23387, 22788, 23365, 22962, 22713 ]
    - [ 51813, 26315, 23487, 23203, 22446, 23407, 22940, 23309, 22686, 22452 ]
    - [ 54943, 27560, 22826, 23171, 22626, 23916, 22952, 23758, 23023, 22727 ]
    - [ 51821, 25075, 23243, 23779, 22822, 23651, 23073, 23458, 23078, 22662 ]
    - [ 54302, 26884, 23917, 23484, 23137, 23850, 23197, 23740, 23129, 22833 ]
    - [ 51721, 26786, 23495, 23296, 22633, 23379, 22824, 23484, 22890, 22514 ]
    - [ 52313, 27432, 23722, 23799, 23048, 24067, 22927, 23540, 23313, 23126 ]
    - [ 53152, 27098, 23838, 23821, 22852, 23562, 22959, 23641, 23246, 22576 ]
    - [ 51920, 27851, 22956, 23232, 22629, 23433, 22834, 23446, 22872, 22567 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 8304, 7223, 7274, 7082, 7046, 7087, 7063, 7061, 7037, 7064 ]
    - [ 8351, 7192, 7260, 7114, 7083, 7059, 7072, 7078, 7044, 7068 ]
    - [ 8381, 7173, 7231, 7049, 6996, 7022, 7014, 7037, 7045, 7029 ]
    - [ 8346, 7291, 7233, 7070, 7070, 7072, 7058, 7156, 7162, 7195 ]
    - [ 8338, 7372, 7203, 7226, 7173, 7087, 7030, 7047, 7072, 7011 ]
    - [ 8440, 7214, 7225, 7107, 7014, 7107, 7021, 7031, 7042, 7048 ]
    - [ 8354, 7249, 7316, 7084, 7005, 7033, 7025, 7104, 7004, 7084 ]
    - [ 8476, 7354, 7213, 7055, 7010, 7092, 7090, 7020, 7064, 7033 ]
    - [ 8369, 7315, 7230, 7055, 7032, 7058, 7049, 7034, 6998, 7070 ]
    - [ 8304, 7225, 7238, 7082, 7042, 7107, 7008, 7060, 7037, 7051 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 101538, 70612, 66397, 68703, 66446, 68985, 67483, 67289, 67194, 66673 ]
    - [ 102778, 71710, 67793, 68924, 66987, 69738, 69126, 68507, 68205, 68418 ]
    - [ 102000, 72700, 68836, 69988, 66256, 68580, 68088, 67229, 66654, 67289 ]
    - [ 103651, 71657, 67967, 69652, 68332, 70357, 69360, 69779, 68790, 68773 ]
    - [ 99997, 78722, 67406, 69027, 66437, 69341, 68435, 67668, 67344, 66764 ]
    - [ 102124, 72695, 69419, 70338, 67171, 68760, 68303, 67488, 67515, 67077 ]
    - [ 104238, 72791, 68614, 71363, 68530, 70087, 70366, 69568, 69320, 68687 ]
    - [ 100690, 72020, 68070, 68010, 66002, 68995, 68659, 68356, 66969, 66742 ]
    - [ 101986, 71898, 69797, 70209, 67550, 69960, 69346, 68778, 68419, 68005 ]
    - [ 100210, 71423, 67299, 69721, 66833, 69213, 68506, 69242, 68338, 68145 ]
