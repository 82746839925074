#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [4, 54, 12/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [2, 803, 17/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (4088829384/5088900.0)]
BAL: [2, 1, 17/20, 0, 34, 2204, nominal aaload per usec]
BAS: [4, 0, 13/20, 0, 1, 126, nominal aastore per usec]
BEF: [1, 1, 19/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [2, 183, 16/20, 0, 527, 32087, nominal getfield per usec]
BPF: [3, 55, 14/20, 0, 83, 3863, nominal putfield per usec]
BUB: [9, 159, 3/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [9, 28, 2/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [2, 86, 18/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (173/200)']
GCC: [2, 221, 19/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [4, 86, 14/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (172/200)']
GCP: [1, 0, 21/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (349/53921)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (200/202)]
GMD: [10, 201, 2/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [6, 345, 8/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [10, 157, 1/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [9, 208, 4/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [1, 0, 21/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (5111.8/5088.9)']
GTO: [2, 19, 17/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [7, 255, 8/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [4, 34, 15/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [9, 6, 3/22, 1, 3, 8, nominal execution time (sec)]
PFS: [1, 1, 20/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [4, 34, 15/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [10, 25, 2/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [2, 0, 19/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 19/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [2, 3, 19/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 3, 11/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [1, 19, 20/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [3, 13, 17/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [6, 26, 9/22, 5, 23, 41, nominal backend bound (memory) ( 7813070743.4 / 8745255544.6) * 30 )]
UBP: [3, 30, 16/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.03121474271594057 * 97836755.2 ) / ( 97836755.2 + 1747948.8 )']
UBR: [1, 547, 20/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.03121474271594057 * 1747948.8 ) / ( 97836755.2 + 1747948.8 ) )']
UBS: [3, 31, 17/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 19276011538.9 - 16565486265.3 ) / ( 6 * 14472463104.3 ) )]
UDC: [10, 27, 1/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 512151204.3/ 18419874317.2 )]
UDT: [6, 230, 10/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 4249896.8 / 18419874317.2 )]
UIP: [4, 127, 14/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 18419874317.2/14472463104.3)]
ULL: [10, 6819, 2/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 61952788.9) / 18419874317.2 ) )]
USB: [6, 30, 10/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 26440772953.4 / ( 6 * 14351749056.3 ) )]
USC: [3, 20, 17/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 1690009113.6 / ( 6 * 13750226397.0 ) )]
USF: [9, 43, 3/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 37376643476.2/( 6 * 14472463104.3) )]
