#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 2017, 1755, 1488, 1569, 1566, 1578, 1560, 1946, 1612, 1516 ]
    - [ 2195, 1483, 1529, 1518, 1502, 1742, 1556, 1582, 1635, 2089 ]
    - [ 2136, 1555, 1542, 1584, 1712, 1730, 1570, 1553, 1597, 1583 ]
    - [ 1969, 1631, 1673, 1506, 1541, 1667, 1602, 1655, 1519, 1603 ]
    - [ 1969, 1609, 1738, 1581, 1677, 1650, 1489, 1536, 1520, 2058 ]
    - [ 1959, 1673, 1643, 1576, 1607, 2045, 1632, 1665, 1516, 1615 ]
    - [ 2055, 1588, 1522, 1489, 1564, 1582, 2068, 1554, 1546, 1484 ]
    - [ 2149, 1668, 1572, 1502, 1466, 1551, 1495, 1481, 1840, 1537 ]
    - [ 1994, 2076, 1570, 1547, 1574, 1568, 1516, 2101, 2143, 1704 ]
    - [ 2009, 1548, 1636, 1598, 1547, 2020, 1592, 1550, 1580, 1526 ]
  2.0:
    - [ 1669, 1218, 1154, 1148, 1151, 1144, 1140, 1140, 1141, 1140 ]
    - [ 1635, 1207, 1150, 1145, 1147, 1134, 1134, 1133, 1139, 1130 ]
    - [ 1655, 1210, 1153, 1143, 1150, 1142, 1146, 1148, 1137, 1133 ]
    - [ 1763, 1216, 1158, 1152, 1155, 1143, 1146, 1143, 1138, 1134 ]
    - [ 1689, 1258, 1149, 1142, 1144, 1133, 1141, 1135, 1137, 1131 ]
    - [ 1679, 1243, 1152, 1151, 1154, 1145, 1144, 1139, 1134, 1133 ]
    - [ 1654, 1226, 1158, 1150, 1147, 1141, 1144, 1143, 1138, 1140 ]
    - [ 1636, 1204, 1143, 1137, 1134, 1133, 1134, 1131, 1130, 1125 ]
    - [ 1646, 1237, 1144, 1137, 1140, 1132, 1129, 1132, 1133, 1127 ]
    - [ 1665, 1262, 1167, 1150, 1155, 1150, 1144, 1146, 1156, 1143 ]
  3.0:
    - [ 1657, 1225, 1151, 1134, 1128, 1127, 1129, 1126, 1127, 1125 ]
    - [ 1629, 1219, 1132, 1120, 1129, 1116, 1118, 1114, 1129, 1113 ]
    - [ 1654, 1216, 1134, 1125, 1127, 1116, 1117, 1117, 1118, 1114 ]
    - [ 1652, 1220, 1133, 1130, 1131, 1121, 1123, 1120, 1130, 1119 ]
    - [ 1634, 1221, 1135, 1132, 1124, 1120, 1119, 1117, 1121, 1116 ]
    - [ 1728, 1232, 1126, 1121, 1129, 1116, 1116, 1115, 1116, 1113 ]
    - [ 1643, 1222, 1135, 1129, 1124, 1122, 1123, 1121, 1122, 1119 ]
    - [ 1671, 1218, 1133, 1122, 1125, 1117, 1119, 1119, 1117, 1114 ]
    - [ 1657, 1255, 1136, 1130, 1130, 1122, 1124, 1118, 1123, 1121 ]
    - [ 1644, 1225, 1134, 1128, 1130, 1122, 1122, 1118, 1123, 1119 ]
  4.0:
    - [ 1643, 1226, 1163, 1115, 1122, 1111, 1114, 1111, 1114, 1109 ]
    - [ 1719, 1228, 1142, 1117, 1114, 1114, 1112, 1112, 1109, 1107 ]
    - [ 1730, 1226, 1146, 1117, 1120, 1114, 1114, 1113, 1112, 1110 ]
    - [ 1633, 1229, 1157, 1111, 1108, 1110, 1111, 1109, 1109, 1106 ]
    - [ 1622, 1213, 1134, 1124, 1115, 1105, 1106, 1107, 1109, 1102 ]
    - [ 1636, 1232, 1133, 1113, 1118, 1106, 1109, 1108, 1108, 1104 ]
    - [ 1632, 1228, 1138, 1120, 1108, 1110, 1107, 1109, 1103, 1106 ]
    - [ 1641, 1237, 1134, 1111, 1111, 1108, 1107, 1108, 1106, 1101 ]
    - [ 1628, 1223, 1136, 1110, 1105, 1104, 1103, 1103, 1102, 1097 ]
    - [ 1647, 1257, 1147, 1113, 1108, 1104, 1108, 1105, 1107, 1106 ]
  5.0:
    - [ 1638, 1223, 1161, 1129, 1123, 1114, 1113, 1115, 1119, 1109 ]
    - [ 1625, 1241, 1167, 1121, 1118, 1109, 1107, 1108, 1108, 1104 ]
    - [ 1646, 1273, 1157, 1123, 1111, 1108, 1108, 1111, 1105, 1107 ]
    - [ 1747, 1222, 1158, 1122, 1124, 1111, 1109, 1110, 1117, 1105 ]
    - [ 1627, 1224, 1158, 1131, 1117, 1110, 1110, 1110, 1111, 1109 ]
    - [ 1666, 1223, 1125, 1168, 1129, 1113, 1115, 1116, 1114, 1109 ]
    - [ 1626, 1256, 1157, 1128, 1114, 1109, 1111, 1111, 1109, 1106 ]
    - [ 1629, 1216, 1123, 1155, 1117, 1103, 1102, 1104, 1100, 1100 ]
    - [ 1636, 1219, 1160, 1128, 1112, 1106, 1111, 1109, 1107, 1106 ]
    - [ 1644, 1209, 1167, 1128, 1111, 1105, 1105, 1108, 1102, 1103 ]
  6.0:
    - [ 1648, 1300, 1112, 1111, 1114, 1106, 1103, 1107, 1115, 1102 ]
    - [ 1637, 1261, 1113, 1113, 1113, 1109, 1110, 1113, 1114, 1108 ]
    - [ 1647, 1253, 1113, 1111, 1114, 1105, 1105, 1105, 1117, 1105 ]
    - [ 1636, 1256, 1110, 1113, 1119, 1105, 1105, 1106, 1114, 1105 ]
    - [ 1649, 1291, 1107, 1107, 1105, 1098, 1103, 1102, 1102, 1098 ]
    - [ 1732, 1285, 1108, 1109, 1111, 1100, 1096, 1101, 1110, 1101 ]
    - [ 1650, 1249, 1114, 1111, 1114, 1106, 1104, 1106, 1107, 1100 ]
    - [ 1649, 1259, 1111, 1103, 1103, 1100, 1101, 1104, 1101, 1098 ]
    - [ 1649, 1253, 1119, 1113, 1113, 1105, 1108, 1108, 1113, 1103 ]
    - [ 1643, 1256, 1114, 1111, 1106, 1103, 1103, 1106, 1107, 1100 ]
  7.0:
    - [ 1668, 1218, 1141, 1149, 1119, 1100, 1106, 1110, 1110, 1104 ]
    - [ 1646, 1218, 1137, 1158, 1105, 1104, 1104, 1101, 1103, 1100 ]
    - [ 1627, 1210, 1138, 1137, 1121, 1088, 1098, 1102, 1105, 1099 ]
    - [ 1629, 1248, 1128, 1148, 1106, 1099, 1102, 1102, 1098, 1099 ]
    - [ 1647, 1248, 1136, 1161, 1101, 1110, 1107, 1104, 1104, 1105 ]
    - [ 1647, 1258, 1143, 1148, 1118, 1099, 1106, 1112, 1105, 1103 ]
    - [ 1650, 1253, 1141, 1142, 1121, 1091, 1098, 1100, 1109, 1101 ]
    - [ 1613, 1208, 1127, 1142, 1118, 1088, 1097, 1096, 1097, 1097 ]
    - [ 1645, 1216, 1144, 1152, 1098, 1098, 1100, 1098, 1095, 1098 ]
    - [ 1626, 1209, 1124, 1136, 1090, 1096, 1094, 1096, 1095, 1095 ]
  8.0:
    - [ 1633, 1207, 1136, 1149, 1140, 1102, 1104, 1102, 1109, 1103 ]
    - [ 1760, 1213, 1136, 1148, 1144, 1113, 1106, 1107, 1115, 1103 ]
    - [ 1647, 1212, 1134, 1150, 1130, 1101, 1103, 1103, 1102, 1102 ]
    - [ 1638, 1198, 1137, 1137, 1133, 1097, 1100, 1101, 1102, 1095 ]
    - [ 1640, 1215, 1130, 1142, 1143, 1104, 1104, 1106, 1104, 1100 ]
    - [ 1657, 1213, 1136, 1149, 1143, 1105, 1107, 1108, 1104, 1102 ]
    - [ 1678, 1203, 1136, 1142, 1132, 1104, 1103, 1104, 1107, 1098 ]
    - [ 1644, 1245, 1131, 1144, 1146, 1103, 1108, 1104, 1107, 1104 ]
    - [ 1630, 1208, 1132, 1149, 1131, 1107, 1107, 1109, 1109, 1103 ]
    - [ 1696, 1208, 1143, 1147, 1137, 1099, 1104, 1108, 1110, 1101 ]
  9.0:
    - [ 1644, 1233, 1124, 1144, 1121, 1100, 1104, 1104, 1103, 1098 ]
    - [ 1649, 1208, 1131, 1144, 1126, 1106, 1108, 1109, 1109, 1106 ]
    - [ 1639, 1201, 1128, 1137, 1126, 1105, 1106, 1103, 1101, 1095 ]
    - [ 1671, 1243, 1130, 1145, 1130, 1108, 1108, 1109, 1111, 1100 ]
    - [ 1636, 1203, 1128, 1143, 1129, 1097, 1101, 1099, 1101, 1094 ]
    - [ 1648, 1218, 1132, 1136, 1115, 1092, 1092, 1094, 1100, 1091 ]
    - [ 1666, 1205, 1138, 1149, 1138, 1107, 1104, 1105, 1109, 1104 ]
    - [ 1652, 1210, 1139, 1148, 1129, 1104, 1104, 1103, 1103, 1101 ]
    - [ 1641, 1244, 1134, 1147, 1133, 1101, 1107, 1104, 1107, 1100 ]
    - [ 1685, 1210, 1134, 1148, 1132, 1110, 1107, 1110, 1109, 1108 ]
  10.0:
    - [ 1629, 1194, 1151, 1118, 1142, 1102, 1101, 1106, 1110, 1101 ]
    - [ 1649, 1202, 1162, 1128, 1152, 1110, 1116, 1113, 1120, 1106 ]
    - [ 1649, 1193, 1154, 1121, 1141, 1106, 1110, 1106, 1105, 1101 ]
    - [ 1806, 1199, 1159, 1123, 1144, 1105, 1103, 1106, 1115, 1103 ]
    - [ 1735, 1208, 1175, 1114, 1124, 1093, 1096, 1095, 1092, 1092 ]
    - [ 1632, 1191, 1170, 1120, 1135, 1106, 1109, 1105, 1103, 1101 ]
    - [ 1647, 1191, 1142, 1117, 1139, 1103, 1100, 1101, 1101, 1096 ]
    - [ 1657, 1216, 1166, 1114, 1126, 1101, 1102, 1101, 1103, 1095 ]
    - [ 1648, 1235, 1169, 1118, 1119, 1106, 1103, 1103, 1098, 1100 ]
    - [ 1652, 1192, 1154, 1122, 1144, 1105, 1104, 1104, 1106, 1101 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 4099, 2630, 2152, 1945, 1774, 1856, 1630, 1419, 1586, 1523 ]
    - [ 4140, 2732, 2281, 1976, 1819, 1786, 1613, 1409, 1444, 1464 ]
    - [ 4090, 2747, 2259, 2024, 1791, 1849, 1432, 1382, 1471, 1362 ]
    - [ 4017, 2825, 2247, 1951, 1984, 1623, 1621, 1375, 1430, 1368 ]
    - [ 3875, 2557, 2263, 1979, 1764, 1710, 1502, 1435, 1445, 1446 ]
    - [ 4011, 2644, 2326, 2024, 1905, 1590, 1644, 1399, 1451, 1565 ]
    - [ 3956, 2676, 2349, 1947, 1767, 1652, 1612, 1383, 1440, 1431 ]
    - [ 4065, 2712, 2267, 2154, 1850, 1668, 1617, 1395, 1434, 1601 ]
    - [ 3988, 2672, 2275, 2001, 1797, 1758, 1617, 1526, 1512, 1526 ]
    - [ 4052, 2644, 2188, 2034, 1775, 1563, 1522, 1519, 1672, 1340 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1711, 1395, 1361, 1316, 1339, 1338, 1336, 1333, 1334, 1334 ]
    - [ 1714, 1361, 1286, 1276, 1308, 1272, 1299, 1278, 1302, 1300 ]
    - [ 1637, 1365, 1282, 1316, 1286, 1314, 1323, 1313, 1309, 1302 ]
    - [ 1675, 1347, 1324, 1322, 1316, 1279, 1287, 1300, 1282, 1279 ]
    - [ 1650, 1364, 1317, 1289, 1304, 1311, 1287, 1310, 1313, 1289 ]
    - [ 1694, 1363, 1348, 1320, 1333, 1315, 1281, 1304, 1310, 1283 ]
    - [ 1690, 1326, 1324, 1298, 1333, 1290, 1301, 1310, 1324, 1308 ]
    - [ 1699, 1323, 1325, 1300, 1294, 1285, 1309, 1311, 1284, 1306 ]
    - [ 1687, 1319, 1289, 1297, 1304, 1276, 1303, 1308, 1309, 1283 ]
    - [ 1695, 1328, 1300, 1281, 1294, 1307, 1317, 1332, 1308, 1281 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1898, 1734, 1675, 1477, 1669, 1652, 1838, 1645, 1839, 1836 ]
    - [ 1896, 1723, 1670, 1667, 1474, 1643, 1826, 1828, 1642, 1643 ]
    - [ 1903, 1720, 1664, 1658, 1468, 1635, 1823, 1626, 1824, 1822 ]
    - [ 1889, 1711, 1458, 1640, 1639, 1610, 1795, 1608, 1611, 1632 ]
    - [ 1904, 1761, 1672, 1668, 1672, 1662, 1647, 1638, 1639, 1668 ]
    - [ 1893, 1728, 1673, 1662, 1477, 1840, 1644, 1844, 1837, 1638 ]
    - [ 1891, 1702, 1449, 1631, 1447, 1439, 1623, 1608, 1603, 1598 ]
    - [ 1881, 1725, 1678, 1475, 1668, 1646, 1839, 1642, 1648, 1668 ]
    - [ 1896, 1734, 1474, 1654, 1468, 1472, 1653, 1630, 1494, 1821 ]
    - [ 1905, 1761, 1669, 1661, 1472, 1656, 1635, 1638, 1850, 1849 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1802, 1249, 1179, 1177, 1177, 1165, 1167, 1164, 1164, 1163 ]
    - [ 1737, 1258, 1176, 1173, 1163, 1155, 1156, 1156, 1158, 1151 ]
    - [ 1758, 1283, 1178, 1164, 1161, 1156, 1158, 1167, 1153, 1153 ]
    - [ 1756, 1241, 1181, 1179, 1172, 1169, 1169, 1172, 1173, 1175 ]
    - [ 1784, 1290, 1212, 1210, 1197, 1187, 1187, 1194, 1189, 1191 ]
    - [ 1758, 1291, 1160, 1163, 1157, 1157, 1152, 1153, 1154, 1149 ]
    - [ 1811, 1242, 1179, 1177, 1183, 1165, 1170, 1168, 1177, 1163 ]
    - [ 1760, 1235, 1177, 1181, 1179, 1169, 1173, 1171, 1178, 1170 ]
    - [ 1758, 1274, 1173, 1168, 1156, 1151, 1151, 1149, 1158, 1151 ]
    - [ 1760, 1257, 1178, 1177, 1170, 1166, 1173, 1164, 1167, 1163 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 1735, 1428, 1373, 1186, 1303, 1191, 1192, 1266, 1202, 1177 ]
    - [ 1726, 1359, 1189, 1231, 1184, 1194, 1288, 1221, 1207, 1315 ]
    - [ 1747, 1350, 1182, 1298, 1190, 1291, 1181, 1291, 1197, 1189 ]
    - [ 1743, 1350, 1179, 1189, 1188, 1303, 1183, 1339, 1188, 1190 ]
    - [ 1737, 1403, 1302, 1235, 1184, 1203, 1188, 1240, 1179, 1188 ]
    - [ 1806, 1398, 1300, 1188, 1186, 1237, 1193, 1192, 1190, 1289 ]
    - [ 1711, 1387, 1285, 1168, 1192, 1192, 1348, 1233, 1173, 1182 ]
    - [ 1755, 1409, 1284, 1179, 1184, 1192, 1204, 1184, 1294, 1202 ]
    - [ 1743, 1369, 1227, 1190, 1353, 1174, 1296, 1233, 1182, 1405 ]
    - [ 1845, 1401, 1195, 1195, 1186, 1188, 1175, 1331, 1332, 1173 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 1797, 1494, 1273, 1472, 1265, 1303, 1325, 1432, 1455, 1609 ]
    - [ 1809, 1725, 1215, 1428, 1277, 1362, 1673, 1199, 1339, 1461 ]
    - [ 1737, 1621, 1211, 1427, 1461, 1263, 1437, 1263, 1348, 1407 ]
    - [ 1770, 1550, 1697, 1259, 1445, 1241, 1474, 1252, 1392, 1403 ]
    - [ 1774, 1614, 1277, 1387, 1603, 1453, 1548, 1280, 1303, 1406 ]
    - [ 1771, 1737, 1275, 1406, 1524, 1205, 1648, 1257, 1310, 1417 ]
    - [ 1771, 1707, 1275, 1374, 1444, 1286, 1423, 1245, 1457, 1259 ]
    - [ 1855, 1510, 1401, 1457, 1445, 1255, 1503, 1450, 1347, 1475 ]
    - [ 1767, 1373, 1584, 1204, 1452, 1254, 1387, 1419, 1393, 1228 ]
    - [ 1780, 1416, 1416, 1463, 1510, 1363, 1365, 1451, 1676, 1334 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 15490, 15553, 15468, 15523, 15547 ]
    - [ 15545, 15591, 15620, 15659, 15628 ]
    - [ 15475, 15491, 15609, 15637, 15687 ]
    - [ 15610, 15660, 15700, 15669, 15639 ]
    - [ 15588, 15284, 15399, 15519, 15249 ]
    - [ 15613, 15505, 15542, 15582, 15639 ]
    - [ 15514, 15490, 15491, 15539, 15540 ]
    - [ 15549, 15580, 15701, 15624, 15689 ]
    - [ 15573, 15465, 15571, 15730, 15716 ]
    - [ 15584, 15547, 15645, 15694, 15670 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 2239, 1477, 1458, 1462, 1468 ]
    - [ 2241, 1479, 1453, 1448, 1455 ]
    - [ 2222, 1477, 1456, 1446, 1451 ]
    - [ 2224, 1478, 1460, 1456, 1455 ]
    - [ 2219, 1468, 1453, 1445, 1441 ]
    - [ 2237, 1483, 1459, 1455, 1455 ]
    - [ 2234, 1473, 1456, 1452, 1450 ]
    - [ 2238, 1483, 1466, 1457, 1460 ]
    - [ 2238, 1478, 1459, 1454, 1449 ]
    - [ 2234, 1485, 1458, 1453, 1451 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1683, 1430, 1409, 1415, 1407 ]
    - [ 1676, 1439, 1404, 1404, 1403 ]
    - [ 1675, 1418, 1399, 1396, 1405 ]
    - [ 1683, 1434, 1407, 1408, 1411 ]
    - [ 1675, 1420, 1396, 1395, 1397 ]
    - [ 1682, 1440, 1408, 1413, 1407 ]
    - [ 1678, 1444, 1412, 1412, 1411 ]
    - [ 1674, 1427, 1410, 1404, 1397 ]
    - [ 1682, 1443, 1414, 1410, 1408 ]
    - [ 1672, 1426, 1408, 1407, 1407 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6796, 1749, 1335, 1329, 1318 ]
    - [ 6770, 1744, 1333, 1325, 1310 ]
    - [ 6768, 1740, 1318, 1335, 1308 ]
    - [ 6779, 1752, 1328, 1324, 1317 ]
    - [ 6777, 1752, 1334, 1325, 1318 ]
    - [ 6774, 1758, 1335, 1327, 1319 ]
    - [ 6772, 1753, 1334, 1329, 1316 ]
    - [ 6777, 1749, 1324, 1320, 1308 ]
    - [ 6778, 1740, 1333, 1324, 1313 ]
    - [ 6778, 1750, 1340, 1327, 1323 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2551, 1358, 1193, 1171, 1166 ]
    - [ 2528, 1353, 1205, 1179, 1170 ]
    - [ 2573, 1360, 1189, 1174, 1166 ]
    - [ 2553, 1351, 1200, 1181, 1169 ]
    - [ 2665, 1352, 1200, 1180, 1171 ]
    - [ 2591, 1354, 1195, 1180, 1170 ]
    - [ 2564, 1342, 1196, 1176, 1170 ]
    - [ 2546, 1353, 1181, 1167, 1162 ]
    - [ 2599, 1352, 1185, 1169, 1160 ]
    - [ 2575, 1343, 1188, 1176, 1164 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1899, 1426, 1195, 1156, 1147 ]
    - [ 1875, 1415, 1182, 1166, 1143 ]
    - [ 1841, 1344, 1176, 1151, 1150 ]
    - [ 1825, 1352, 1161, 1141, 1135 ]
    - [ 1862, 1321, 1177, 1158, 1146 ]
    - [ 1911, 1445, 1192, 1153, 1149 ]
    - [ 1835, 1359, 1194, 1150, 1145 ]
    - [ 1879, 1354, 1175, 1149, 1146 ]
    - [ 1845, 1395, 1174, 1155, 1148 ]
    - [ 1867, 1407, 1176, 1169, 1148 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1834, 1370, 1168, 1142, 1141 ]
    - [ 1706, 1304, 1157, 1158, 1153 ]
    - [ 1696, 1356, 1159, 1149, 1145 ]
    - [ 1689, 1346, 1151, 1133, 1146 ]
    - [ 1732, 1256, 1159, 1156, 1140 ]
    - [ 1674, 1253, 1152, 1141, 1138 ]
    - [ 1695, 1327, 1155, 1143, 1142 ]
    - [ 1704, 1318, 1152, 1142, 1140 ]
    - [ 1713, 1294, 1148, 1142, 1133 ]
    - [ 1694, 1293, 1151, 1143, 1139 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1756, 1263, 1176, 1183, 1177 ]
    - [ 1712, 1255, 1178, 1175, 1166 ]
    - [ 1702, 1263, 1179, 1173, 1171 ]
    - [ 1728, 1243, 1173, 1167, 1157 ]
    - [ 1729, 1253, 1179, 1173, 1175 ]
    - [ 1716, 1267, 1181, 1180, 1175 ]
    - [ 1804, 1255, 1177, 1177, 1175 ]
    - [ 1713, 1268, 1188, 1174, 1180 ]
    - [ 1692, 1251, 1179, 1173, 1163 ]
    - [ 1716, 1303, 1181, 1172, 1169 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1458, 1012, 961, 960, 965 ]
    - [ 1386, 1007, 942, 940, 940 ]
    - [ 1400, 1034, 959, 954, 951 ]
    - [ 1418, 997, 959, 944, 949 ]
    - [ 1423, 1044, 958, 952, 947 ]
    - [ 1390, 1014, 946, 943, 939 ]
    - [ 1397, 1032, 952, 940, 942 ]
    - [ 1413, 1053, 973, 956, 956 ]
    - [ 1388, 1004, 959, 955, 953 ]
    - [ 1404, 1008, 964, 962, 956 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 2186, 1593, 1509, 1494, 1491, 1489, 1492, 1484, 1485, 1485 ]
    - [ 2163, 1650, 1514, 1502, 1499, 1494, 1494, 1491, 1497, 1485 ]
    - [ 2186, 1607, 1505, 1503, 1504, 1490, 1487, 1490, 1488, 1474 ]
    - [ 2123, 1603, 1512, 1489, 1494, 1481, 1480, 1481, 1492, 1479 ]
    - [ 2181, 1677, 1532, 1497, 1501, 1496, 1491, 1500, 1490, 1482 ]
    - [ 2172, 1581, 1504, 1501, 1489, 1483, 1482, 1480, 1480, 1481 ]
    - [ 2194, 1631, 1511, 1510, 1486, 1487, 1487, 1490, 1486, 1484 ]
    - [ 2145, 1588, 1528, 1500, 1509, 1489, 1488, 1496, 1484, 1484 ]
    - [ 2165, 1602, 1502, 1496, 1499, 1490, 1480, 1490, 1483, 1483 ]
    - [ 2149, 1630, 1515, 1496, 1499, 1481, 1493, 1483, 1481, 1486 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 5071, 3520, 2879, 2730, 2391, 2162, 2120, 1855, 1891, 1823 ]
    - [ 5261, 3500, 2806, 2824, 2433, 2222, 2114, 1815, 1877, 1911 ]
    - [ 5146, 3342, 2980, 2595, 2349, 2142, 1986, 1843, 1883, 1823 ]
    - [ 5140, 3332, 2878, 2720, 2330, 2258, 1951, 1910, 1935, 1804 ]
    - [ 5271, 3368, 2931, 2673, 2361, 2098, 2087, 1846, 1891, 1824 ]
    - [ 5235, 3495, 3053, 2795, 2460, 2343, 2000, 1839, 1969, 1825 ]
    - [ 5215, 3563, 3080, 2638, 2453, 2206, 1862, 1839, 1885, 1895 ]
    - [ 5092, 3434, 2902, 2755, 2351, 2187, 2180, 1834, 1900, 1818 ]
    - [ 5095, 3276, 3053, 2616, 2371, 2158, 2124, 1829, 1885, 1812 ]
    - [ 5332, 3566, 3053, 2921, 2412, 2208, 2031, 1807, 1912, 1810 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 3157, 2220, 2040, 2012, 2003, 1997, 1994, 1992, 1992, 1988 ]
    - [ 3217, 2202, 2037, 2027, 2023, 2001, 2002, 1998, 1994, 1990 ]
    - [ 3167, 2229, 2026, 2018, 2000, 1990, 1996, 1988, 1989, 1984 ]
    - [ 3296, 2204, 2020, 2018, 2006, 1988, 1989, 1984, 1980, 1977 ]
    - [ 3206, 2209, 2030, 2015, 2009, 1991, 1995, 1990, 1990, 1983 ]
    - [ 3188, 2204, 2026, 2015, 2004, 1997, 1992, 1998, 1991, 1985 ]
    - [ 3160, 2195, 2013, 2007, 2006, 1984, 1983, 1986, 1989, 1977 ]
    - [ 3283, 2192, 2023, 2018, 2015, 1995, 1994, 1991, 1988, 1986 ]
    - [ 3410, 2233, 2033, 2018, 2009, 1982, 1984, 1981, 1982, 1980 ]
    - [ 3153, 2182, 2017, 2014, 2011, 1987, 1989, 1986, 1989, 1975 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 7772, 5391, 4140, 3993, 3203, 3258, 2578, 2463, 2505, 2528 ]
    - [ 7748, 5169, 4480, 3967, 3201, 3162, 2644, 2825, 2871, 2743 ]
    - [ 7895, 5066, 4094, 3570, 3582, 2946, 2681, 2811, 2819, 2773 ]
    - [ 7717, 5288, 4150, 3530, 3605, 3213, 3191, 2732, 2711, 2726 ]
    - [ 7869, 5514, 4325, 3533, 3221, 2851, 2907, 2472, 2576, 2507 ]
    - [ 7932, 5412, 4083, 3531, 3127, 3027, 2948, 2766, 2803, 2666 ]
    - [ 8050, 5195, 4146, 3509, 3134, 3039, 2818, 2442, 2624, 2502 ]
    - [ 7832, 5364, 4190, 3523, 3224, 3326, 2612, 2500, 2622, 2567 ]
    - [ 8139, 5497, 4135, 3531, 3172, 3343, 2665, 2833, 3017, 2690 ]
    - [ 7781, 5530, 4152, 3471, 3385, 3202, 2772, 2813, 2674, 2721 ]
