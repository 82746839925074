#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 350895, 348120, 352279, 356630, 354189, 356287, 379516, 347873, 383510, 350255 ]
    - [ 354397, 353009, 355339, 349313, 350278, 351272, 351973, 369508, 374969, 375166 ]
    - [ 368308, 381259, 382769, 372163, 359355, 367717, 355778, 366942, 349048, 355258 ]
    - [ 351846, 351286, 349363, 354554, 349411, 355979, 371694, 364113, 348049, 348963 ]
    - [ 353833, 363933, 366203, 366323, 379106, 358797, 351635, 358918, 354668, 364777 ]
    - [ 357031, 266333, 359927, 368164, 361873, 347165, 346635, 377021, 363864, 351961 ]
    - [ 353630, 371938, 372878, 372144, 362546, 348877, 347962, 348438, 352784, 349715 ]
    - [ 357796, 363659, 371469, 359447, 361891, 355970, 353834, 353013, 379992, 363311 ]
    - [ 354145, 360114, 350623, 350694, 350793, 371580, 350635, 257412, 356464, 361604 ]
    - [ 358146, 364412, 379112, 348853, 355670, 362998, 351797, 371613, 362635, 354348 ]
  2.0:
    - [ 5524, 5038, 5031, 5004, 5002, 5006, 5004, 5007, 5008, 5012 ]
    - [ 5370, 5017, 5002, 5009, 5024, 5017, 5026, 5024, 5031, 5027 ]
    - [ 5480, 4978, 4984, 4985, 4983, 4981, 4984, 4988, 4981, 5241 ]
    - [ 5324, 4964, 4999, 4996, 4998, 5002, 4995, 4997, 4995, 5002 ]
    - [ 5773, 5003, 5011, 5014, 5015, 5017, 5014, 5011, 5007, 5014 ]
    - [ 5399, 5066, 5069, 5039, 5035, 5037, 5038, 5036, 5038, 5039 ]
    - [ 5394, 5062, 5046, 5045, 5054, 5049, 5046, 5048, 5050, 5050 ]
    - [ 5362, 4996, 5051, 5052, 5050, 5053, 5052, 5046, 5046, 5052 ]
    - [ 5356, 4997, 5005, 5008, 5012, 5019, 5043, 5012, 5015, 5016 ]
    - [ 5380, 5008, 5013, 5009, 5010, 5006, 5009, 5010, 5008, 5020 ]
  3.0:
    - [ 5247, 4852, 4857, 4858, 4858, 4865, 4861, 4860, 4861, 4862 ]
    - [ 5325, 4930, 4928, 4934, 4933, 4935, 4929, 4931, 4931, 4931 ]
    - [ 5302, 4906, 4911, 4914, 4912, 4917, 4910, 4910, 4906, 4915 ]
    - [ 5304, 4915, 4920, 4921, 4922, 4923, 4924, 4924, 4923, 4924 ]
    - [ 5335, 4902, 4893, 4895, 4898, 4900, 4902, 4904, 4914, 4904 ]
    - [ 5315, 4901, 4904, 4910, 4913, 4910, 4903, 4903, 4903, 4905 ]
    - [ 5291, 4884, 4884, 4888, 4891, 4889, 4886, 4887, 4887, 4890 ]
    - [ 5222, 4817, 4819, 4827, 4826, 4824, 4825, 4822, 4820, 4818 ]
    - [ 5299, 4901, 4907, 4915, 4913, 4911, 4916, 4911, 4914, 4915 ]
    - [ 5303, 4895, 4899, 4903, 4898, 4900, 4901, 4902, 4904, 4901 ]
  4.0:
    - [ 5254, 4836, 4837, 4840, 4841, 4842, 4843, 4842, 4837, 4841 ]
    - [ 5319, 4907, 4909, 4914, 4906, 4912, 4908, 4906, 4907, 4909 ]
    - [ 5301, 4898, 4906, 4910, 4911, 4913, 4916, 4913, 4908, 4911 ]
    - [ 5273, 4872, 4872, 4871, 4879, 4886, 4886, 4882, 4882, 4884 ]
    - [ 5287, 4879, 4881, 4883, 4884, 4887, 4885, 4885, 4884, 4883 ]
    - [ 5310, 4917, 4922, 4925, 4923, 4922, 4924, 4925, 4926, 4929 ]
    - [ 5273, 4843, 4845, 4846, 4846, 4851, 4839, 4843, 4846, 4847 ]
    - [ 5315, 4912, 4911, 4910, 4914, 4913, 4913, 4916, 4912, 4917 ]
    - [ 5384, 4888, 4887, 4893, 4889, 4891, 4891, 4893, 4890, 4890 ]
    - [ 5225, 4815, 4816, 4819, 4823, 4821, 4815, 4814, 4815, 4823 ]
  5.0:
    - [ 5293, 4876, 4888, 4896, 4903, 4899, 4897, 4901, 4902, 4895 ]
    - [ 5320, 4905, 4906, 4911, 4907, 4903, 4899, 4902, 4895, 4899 ]
    - [ 5304, 4889, 4890, 4893, 4893, 4892, 4894, 4893, 4896, 4893 ]
    - [ 5320, 4896, 4948, 4953, 4951, 4952, 4949, 4949, 4949, 4952 ]
    - [ 5341, 4907, 4909, 4916, 4914, 4911, 4914, 4916, 4913, 4912 ]
    - [ 5305, 4897, 4896, 4909, 4908, 4914, 4909, 4912, 4907, 4910 ]
    - [ 5293, 4871, 4876, 4881, 4885, 4885, 4889, 4888, 4888, 4891 ]
    - [ 5261, 4847, 4848, 4852, 4848, 4850, 4856, 4857, 4862, 4873 ]
    - [ 5315, 4873, 4877, 4884, 4881, 4879, 4875, 4879, 4883, 4880 ]
    - [ 5317, 4892, 4896, 4896, 4898, 4903, 4902, 4906, 4908, 4907 ]
  6.0:
    - [ 5352, 4924, 4929, 4933, 4933, 4935, 4926, 4927, 4938, 4933 ]
    - [ 5337, 4895, 4898, 4901, 4905, 4905, 4905, 4907, 4900, 4902 ]
    - [ 5336, 4885, 4926, 4930, 4928, 4948, 4960, 4965, 4964, 4959 ]
    - [ 5312, 4875, 4876, 4882, 4876, 4878, 4880, 4878, 4881, 4879 ]
    - [ 5326, 4884, 4885, 4893, 4888, 4890, 4889, 4888, 4886, 4893 ]
    - [ 5319, 4887, 4886, 4884, 4889, 4889, 4891, 4894, 4899, 4900 ]
    - [ 5322, 4892, 4892, 4897, 4897, 4900, 4900, 4899, 4898, 4899 ]
    - [ 5347, 4908, 4912, 4912, 4915, 4914, 4918, 4915, 4922, 4916 ]
    - [ 5316, 4907, 4911, 4913, 4917, 4914, 4913, 4912, 4916, 4914 ]
    - [ 5442, 4902, 4952, 4954, 4954, 4955, 4951, 4952, 4948, 4952 ]
  7.0:
    - [ 5292, 4837, 4876, 4876, 4875, 4880, 4877, 4877, 4879, 4877 ]
    - [ 5345, 4894, 4889, 4888, 4889, 4891, 4890, 4890, 4888, 4889 ]
    - [ 5348, 4884, 4886, 4888, 4890, 4891, 4889, 4889, 4889, 4891 ]
    - [ 5352, 4889, 4893, 4897, 4900, 4897, 4898, 4898, 4901, 4895 ]
    - [ 5358, 4895, 4904, 4907, 4910, 4908, 4911, 4908, 4913, 4910 ]
    - [ 5343, 4875, 4879, 4885, 4884, 4885, 4882, 4884, 4882, 4879 ]
    - [ 5317, 4874, 4875, 4878, 4881, 4882, 4885, 4885, 4886, 4883 ]
    - [ 5297, 4846, 4847, 4846, 4850, 4845, 4846, 4847, 4849, 4849 ]
    - [ 5364, 4905, 4906, 4910, 4909, 4911, 4911, 4904, 4908, 4908 ]
    - [ 5351, 4901, 4915, 4916, 4915, 4917, 4916, 4920, 4914, 4918 ]
  8.0:
    - [ 5352, 4903, 4910, 4915, 4919, 4915, 4918, 4920, 4917, 4918 ]
    - [ 5347, 4887, 4896, 4897, 4894, 4898, 4899, 4899, 4899, 4898 ]
    - [ 5350, 4900, 4901, 4911, 4913, 4911, 4908, 4910, 4909, 4910 ]
    - [ 5353, 4889, 4894, 4904, 4902, 4901, 4895, 4899, 4899, 4902 ]
    - [ 5345, 4906, 4957, 4955, 4956, 4957, 4958, 4953, 4955, 4962 ]
    - [ 5359, 4909, 4914, 4915, 4913, 4916, 4916, 4920, 4921, 4914 ]
    - [ 5424, 4897, 4901, 4902, 4905, 4900, 4898, 4898, 4901, 4896 ]
    - [ 5391, 4899, 4906, 4910, 4907, 4908, 4909, 4912, 4909, 4909 ]
    - [ 5325, 4881, 4889, 4898, 4894, 4893, 4894, 4895, 4896, 4895 ]
    - [ 5359, 4927, 4932, 4938, 4937, 4936, 4938, 4940, 4940, 4940 ]
  9.0:
    - [ 5362, 4887, 4900, 4902, 4898, 4901, 4904, 4900, 4902, 4902 ]
    - [ 5382, 4907, 4910, 4913, 4915, 4916, 4915, 4914, 4915, 4913 ]
    - [ 5395, 4905, 4906, 4910, 4909, 4912, 4905, 4909, 4912, 4912 ]
    - [ 5399, 4890, 4893, 4899, 4901, 4897, 4898, 4900, 4899, 4903 ]
    - [ 5357, 4889, 4894, 4897, 4896, 4897, 4898, 4898, 4900, 4898 ]
    - [ 5374, 4894, 4890, 4891, 4895, 4897, 4897, 4902, 4897, 4900 ]
    - [ 5391, 4900, 4903, 4909, 4906, 4910, 4909, 4909, 4909, 4907 ]
    - [ 5366, 4891, 4894, 4896, 4898, 4894, 4898, 4897, 4900, 4902 ]
    - [ 5396, 4904, 4904, 4912, 4909, 4913, 4911, 4911, 4913, 4911 ]
    - [ 5343, 4857, 4859, 4861, 4860, 4865, 4861, 4862, 4861, 4861 ]
  10.0:
    - [ 5365, 4887, 4888, 4890, 4896, 4891, 4892, 4891, 4890, 4891 ]
    - [ 5378, 4893, 4894, 4897, 4897, 4898, 4895, 4899, 4899, 4898 ]
    - [ 5375, 4869, 4876, 4884, 4880, 4884, 4881, 4879, 4878, 4883 ]
    - [ 5355, 4841, 4838, 4847, 4847, 4850, 4846, 4846, 4847, 4845 ]
    - [ 5425, 4891, 4896, 4898, 4898, 4897, 4896, 4909, 4904, 4927 ]
    - [ 5374, 4880, 4883, 4892, 4889, 4895, 4897, 4883, 4888, 4892 ]
    - [ 5394, 4894, 4897, 4900, 4898, 4897, 4897, 4897, 4898, 4902 ]
    - [ 5366, 4892, 4894, 4895, 4896, 4900, 4897, 4899, 4898, 4895 ]
    - [ 5373, 4884, 4894, 4895, 4896, 4894, 4894, 4893, 4892, 4900 ]
    - [ 5395, 4893, 4895, 4899, 4904, 4902, 4902, 4901, 4902, 4900 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 9707, 8456, 7581, 7514, 7493, 7466, 7407, 7498, 7487, 8249 ]
    - [ 9424, 8409, 8427, 8255, 8265, 8237, 8277, 8255, 8242, 8132 ]
    - [ 9884, 8268, 7522, 7543, 7506, 7503, 7576, 7570, 7443, 8340 ]
    - [ 9722, 8377, 7544, 7343, 8129, 8137, 8154, 8129, 8118, 8167 ]
    - [ 9835, 8407, 7603, 8144, 8282, 8244, 8258, 8269, 8289, 8159 ]
    - [ 9181, 8429, 7667, 7621, 7608, 7568, 7502, 7555, 7551, 8340 ]
    - [ 8933, 8377, 7509, 8141, 8232, 8299, 8244, 8277, 8241, 8165 ]
    - [ 9919, 8529, 7790, 7668, 7689, 7596, 7661, 7690, 7615, 8337 ]
    - [ 9895, 8498, 7651, 7558, 7625, 7563, 7611, 7543, 7517, 8324 ]
    - [ 9681, 8391, 7608, 8272, 8202, 8268, 8232, 8212, 8257, 8125 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5097, 4708, 4740, 4763, 4754, 4762, 4761, 4759, 4759, 4756 ]
    - [ 5126, 4736, 4785, 4802, 4808, 4811, 4805, 4811, 4808, 4808 ]
    - [ 5181, 4732, 4825, 4886, 4887, 4880, 4878, 4882, 4883, 4889 ]
    - [ 5140, 4735, 4879, 4900, 4901, 4901, 4857, 4903, 4906, 4902 ]
    - [ 5059, 4704, 4710, 4752, 4744, 4741, 4746, 4741, 4745, 4740 ]
    - [ 5125, 4704, 4758, 4784, 4780, 4795, 4784, 4787, 4781, 4788 ]
    - [ 5053, 4664, 4725, 4748, 4744, 4749, 4756, 4748, 4759, 4765 ]
    - [ 5108, 4699, 4749, 4771, 4779, 4781, 4769, 4766, 4783, 4778 ]
    - [ 5097, 4703, 4760, 4781, 4786, 4784, 4786, 4784, 4783, 4781 ]
    - [ 5090, 4671, 4715, 4736, 4733, 4740, 4739, 4735, 4747, 4736 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5109, 4749, 4809, 4824, 4813, 4827, 4822, 4825, 4825, 4843 ]
    - [ 5127, 4739, 4777, 4837, 4821, 4814, 4817, 4800, 4802, 4819 ]
    - [ 5158, 4781, 4827, 4850, 4849, 4846, 4853, 4848, 4848, 4853 ]
    - [ 5131, 4762, 4801, 4836, 4832, 4834, 4832, 4831, 4830, 4828 ]
    - [ 5130, 4772, 4827, 4855, 4859, 4856, 4856, 4860, 4858, 4857 ]
    - [ 5100, 4730, 4767, 4786, 4787, 4794, 4788, 4786, 4788, 4790 ]
    - [ 5064, 4694, 4718, 4744, 4743, 4746, 4746, 4744, 4746, 4745 ]
    - [ 5121, 4737, 4781, 4998, 5005, 4998, 5000, 4998, 4967, 5000 ]
    - [ 5098, 4728, 4770, 4788, 4783, 4784, 4789, 4787, 4785, 4787 ]
    - [ 5148, 4774, 4828, 4860, 4870, 4861, 4853, 4864, 4857, 4864 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 8791, 8399, 8388, 8388, 8367, 8348, 8347, 8340, 8334, 8343 ]
    - [ 8829, 8362, 8354, 8351, 8346, 8349, 8345, 8375, 8341, 8350 ]
    - [ 8888, 8523, 8607, 8456, 8555, 8401, 8478, 8394, 8373, 8374 ]
    - [ 8830, 8348, 8343, 8345, 8338, 8335, 8331, 8341, 8340, 8344 ]
    - [ 8984, 8566, 8525, 8482, 8394, 8406, 8430, 8400, 8395, 8380 ]
    - [ 8771, 8413, 8389, 8386, 8383, 8393, 8363, 8365, 8342, 8380 ]
    - [ 8739, 8352, 8338, 8348, 8331, 8334, 8364, 8352, 8344, 8335 ]
    - [ 8828, 8352, 8356, 8336, 8344, 8354, 8352, 8359, 8348, 8365 ]
    - [ 9113, 8667, 8617, 8695, 8631, 8679, 8722, 8766, 8762, 8764 ]
    - [ 9002, 8399, 8357, 8349, 8359, 8365, 8359, 8371, 8360, 8354 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 291081, 290173, 288788, 288947, 287656, 289708, 288441, 567795, 289656, 568914 ]
    - [ 290154, 288143, 566828, 287355, 565176, 289284, 566632, 287819, 566426, 288327 ]
    - [ 290357, 289956, 289410, 566268, 567772, 290885, 288073, 288622, 289352, 288405 ]
    - [ 290737, 572606, 288643, 288877, 567819, 288822, 289015, 288495, 288502, 288406 ]
    - [ 296566, 299597, 572201, 569279, 290754, 296300, 292552, 288927, 569404, 294929 ]
    - [ 569405, 301912, 298229, 288714, 288112, 288246, 290839, 288491, 288518, 287758 ]
    - [ 290117, 289228, 568156, 568828, 289601, 290301, 289117, 289720, 289933, 288525 ]
    - [ 289333, 288030, 288787, 288423, 289295, 288768, 288251, 288836, 289092, 288779 ]
    - [ 294657, 288408, 289131, 288457, 288875, 288405, 288744, 287741, 288331, 288326 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 289313, 290773, 289673, 292563, 290558 ]
    - [ 299024, 293834, 296242, 297239, 293667 ]
    - [ 290103, 298677, 294591, 298597, 298644 ]
    - [ 287097, 292030, 287653, 289235, 290863 ]
    - [ 292126, 280863, 283657, 288966, 286016 ]
    - [ 303480, 301118, 302306, 301315, 298859 ]
    - [ 290425, 296224, 294898, 293321, 290251 ]
    - [ 296899, 301002, 293869, 295584, 294778 ]
    - [ 292677, 292974, 294275, 293482, 292598 ]
    - [ 292358, 293162, 289907, 287677, 289275 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 12230, 11244, 11161, 10905, 10909 ]
    - [ 12124, 11229, 11216, 11073, 10965 ]
    - [ 11809, 10980, 10961, 10956, 10958 ]
    - [ 11855, 10922, 10928, 10956, 10867 ]
    - [ 11826, 10911, 10884, 10885, 10882 ]
    - [ 11777, 10983, 11049, 10982, 10998 ]
    - [ 11899, 10982, 10924, 10909, 10979 ]
    - [ 11855, 10917, 10910, 10899, 10891 ]
    - [ 11757, 10948, 10938, 10971, 10901 ]
    - [ 11751, 10916, 10908, 10860, 10885 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 11388, 11007, 11094, 11076, 11057 ]
    - [ 11332, 11117, 10969, 11043, 11042 ]
    - [ 11270, 11041, 11008, 11020, 11053 ]
    - [ 11281, 11011, 11076, 11007, 11058 ]
    - [ 11335, 11025, 11012, 11053, 11026 ]
    - [ 11250, 10943, 10930, 10907, 10888 ]
    - [ 11249, 10907, 10943, 10977, 10991 ]
    - [ 11253, 11036, 11051, 11024, 11006 ]
    - [ 11212, 10972, 11171, 10968, 10927 ]
    - [ 11294, 11095, 10987, 11016, 11030 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 17640, 10298, 10195, 10283, 10191 ]
    - [ 17655, 10212, 10134, 10110, 10165 ]
    - [ 17644, 10184, 10104, 10166, 10175 ]
    - [ 17685, 10211, 10224, 10277, 10090 ]
    - [ 17656, 10214, 10296, 10148, 10137 ]
    - [ 17665, 10089, 10114, 10064, 10121 ]
    - [ 17607, 10117, 10153, 10204, 10147 ]
    - [ 17619, 10220, 10252, 10030, 10066 ]
    - [ 17528, 10462, 10336, 10213, 10061 ]
    - [ 17654, 10278, 10117, 10152, 10151 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5808, 5359, 5323, 5307, 5310 ]
    - [ 6103, 5652, 5428, 5287, 5301 ]
    - [ 5829, 5338, 5313, 5301, 5296 ]
    - [ 5883, 5366, 5343, 5321, 5319 ]
    - [ 5861, 5374, 5344, 5339, 5342 ]
    - [ 5844, 5369, 5344, 5331, 5334 ]
    - [ 5932, 5391, 5394, 5379, 5384 ]
    - [ 5853, 5395, 5366, 5348, 5349 ]
    - [ 5818, 5345, 5320, 5299, 5403 ]
    - [ 6074, 5392, 5370, 5358, 5350 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5543, 5178, 5164, 5086, 5085 ]
    - [ 5552, 5047, 5086, 5087, 5088 ]
    - [ 5547, 5074, 5106, 5109, 5103 ]
    - [ 5560, 5075, 5145, 5136, 5098 ]
    - [ 5599, 5116, 5080, 5083, 5079 ]
    - [ 5546, 5044, 5068, 5060, 5068 ]
    - [ 5534, 5073, 5116, 5105, 5106 ]
    - [ 5575, 5076, 5065, 5066, 5078 ]
    - [ 5555, 5061, 5123, 5096, 5094 ]
    - [ 5548, 5065, 5097, 5094, 5095 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5332, 4973, 4973, 4982, 4971 ]
    - [ 5350, 5038, 5043, 5048, 5049 ]
    - [ 5358, 5010, 4997, 5001, 5006 ]
    - [ 5310, 4984, 4987, 4976, 4936 ]
    - [ 5359, 5009, 5010, 5036, 5045 ]
    - [ 5382, 5009, 5015, 5018, 5020 ]
    - [ 5617, 4982, 4985, 4989, 4988 ]
    - [ 5342, 4979, 5011, 5014, 5018 ]
    - [ 5349, 4985, 5033, 5028, 5033 ]
    - [ 5400, 5005, 5007, 5016, 5011 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5373, 4981, 4953, 4964, 4962 ]
    - [ 5389, 5000, 5009, 5009, 5017 ]
    - [ 5363, 4987, 4989, 4988, 4986 ]
    - [ 5371, 5015, 5001, 5007, 5008 ]
    - [ 5357, 4990, 5034, 5028, 5045 ]
    - [ 5420, 5003, 5050, 5056, 5065 ]
    - [ 5379, 5005, 5002, 5006, 5007 ]
    - [ 5392, 5003, 4992, 5007, 5289 ]
    - [ 5378, 5031, 5064, 5059, 5068 ]
    - [ 5400, 5000, 5047, 5053, 5054 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 4575, 4221, 4220, 4219, 4222 ]
    - [ 4445, 4380, 4130, 4130, 4130 ]
    - [ 4494, 4123, 4116, 4121, 4121 ]
    - [ 4522, 4212, 4209, 4215, 4214 ]
    - [ 4523, 4217, 4272, 4307, 4276 ]
    - [ 4461, 4146, 4136, 4129, 4138 ]
    - [ 4512, 4208, 4213, 4207, 4215 ]
    - [ 4532, 4233, 4265, 4271, 4267 ]
    - [ 4495, 4185, 4219, 4217, 4223 ]
    - [ 4552, 4227, 4225, 4229, 4232 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 6508, 6068, 6028, 6025, 6001, 6038, 6004, 5972, 5963, 6137 ]
    - [ 6522, 6055, 6059, 6046, 6031, 6032, 5975, 5971, 6009, 6046 ]
    - [ 6529, 6089, 6059, 6045, 6076, 6037, 6025, 6030, 6035, 6006 ]
    - [ 6472, 6027, 5996, 5998, 5997, 6051, 6007, 6003, 6004, 5992 ]
    - [ 6582, 6093, 6056, 6081, 6108, 6078, 6091, 6055, 6044, 6031 ]
    - [ 6523, 6074, 6057, 6036, 6026, 6020, 5961, 5958, 6002, 5972 ]
    - [ 6531, 6063, 6023, 6056, 6030, 6022, 6025, 6023, 6029, 6026 ]
    - [ 6519, 6069, 6043, 6024, 6029, 6041, 6041, 6048, 6088, 6059 ]
    - [ 6547, 6074, 6062, 6074, 6073, 6069, 6060, 6051, 6055, 6050 ]
    - [ 6553, 6139, 6188, 6253, 6141, 6014, 5999, 6010, 5996, 6018 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 11817, 9970, 9294, 9770, 9670, 9628, 9573, 9707, 9703, 9550 ]
    - [ 11437, 9944, 9134, 9488, 9478, 9488, 9476, 9457, 9484, 9558 ]
    - [ 11860, 9705, 9279, 9529, 9613, 9502, 9542, 9557, 9643, 9522 ]
    - [ 11821, 9758, 9143, 9464, 9651, 9542, 9598, 9579, 9597, 9511 ]
    - [ 11747, 9721, 9111, 8874, 9003, 8969, 8955, 8954, 8942, 9514 ]
    - [ 11717, 9973, 9675, 9498, 9484, 9485, 9464, 9528, 9496, 9447 ]
    - [ 11599, 9639, 9057, 9433, 9618, 9551, 9541, 9586, 9606, 9063 ]
    - [ 11534, 9594, 9540, 9035, 8965, 9543, 8957, 9493, 8953, 9640 ]
    - [ 11846, 9953, 9218, 9519, 9568, 9539, 9591, 9644, 9604, 9066 ]
    - [ 11748, 9896, 9217, 9446, 9456, 9444, 9471, 9462, 9476, 9489 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 12240, 11720, 11780, 11819, 11813, 11725, 11723, 11723, 11716, 11724 ]
    - [ 12207, 11705, 11732, 11785, 11777, 11780, 11680, 11683, 11687, 11683 ]
    - [ 12187, 11593, 11654, 11684, 11694, 11690, 11691, 11779, 11754, 11672 ]
    - [ 12223, 11623, 11687, 11713, 11716, 11720, 11707, 11804, 11685, 11717 ]
    - [ 12292, 11756, 11805, 11823, 11821, 11820, 11821, 11808, 11820, 11741 ]
    - [ 12212, 11667, 11691, 11727, 11724, 11726, 11723, 11715, 11719, 11718 ]
    - [ 12250, 11726, 11811, 11748, 11750, 11748, 11751, 11739, 11750, 11750 ]
    - [ 12271, 11656, 11705, 11736, 11739, 11731, 11733, 11820, 11708, 11715 ]
    - [ 12195, 11615, 11667, 11701, 11699, 11693, 11698, 11737, 11690, 11700 ]
    - [ 12223, 11692, 11757, 11802, 11793, 11816, 11804, 11800, 11799, 11793 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 20735, 18624, 18314, 18166, 18187, 17987, 18165, 18110, 18144, 18136 ]
    - [ 20663, 18604, 18503, 18136, 18191, 18160, 18188, 18158, 18154, 18169 ]
    - [ 20852, 18698, 18448, 18265, 18319, 18228, 18194, 18229, 18203, 18225 ]
    - [ 20862, 18181, 17962, 17824, 18111, 17946, 18068, 17995, 17996, 18016 ]
    - [ 20676, 18667, 18758, 18338, 18298, 18263, 18296, 18311, 18277, 18301 ]
    - [ 20836, 18643, 18434, 18308, 18533, 18534, 18341, 18355, 18324, 18371 ]
    - [ 20766, 18303, 18276, 18116, 18226, 18011, 18173, 18228, 18219, 18259 ]
    - [ 20594, 18581, 18563, 18429, 18591, 18526, 18419, 18409, 18399, 18803 ]
    - [ 20970, 18407, 18294, 18154, 18176, 17997, 18007, 18218, 18158, 18186 ]
    - [ 20914, 18786, 18831, 18322, 18297, 18307, 18304, 18311, 18297, 18288 ]
