#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 1370, 585, 578, 577, 584, 583, 573, 554, 554, 561 ]
    - [ 1166, 577, 583, 575, 600, 583, 561, 555, 555, 566 ]
    - [ 1305, 579, 569, 579, 583, 575, 566, 550, 556, 559 ]
    - [ 1378, 577, 565, 581, 587, 589, 573, 568, 557, 561 ]
    - [ 1155, 576, 572, 592, 613, 561, 566, 550, 551, 554 ]
    - [ 1309, 575, 574, 585, 593, 592, 570, 560, 569, 564 ]
  2.0:
    - [ 1237, 565, 553, 541, 556, 562, 555, 524, 532, 530 ]
    - [ 1230, 559, 556, 536, 579, 586, 539, 528, 533, 533 ]
    - [ 1147, 560, 554, 543, 543, 546, 601, 532, 527, 533 ]
    - [ 1376, 556, 558, 548, 558, 550, 541, 538, 537, 535 ]
    - [ 1112, 560, 565, 545, 545, 547, 539, 527, 527, 529 ]
    - [ 1202, 571, 561, 568, 572, 559, 540, 541, 537, 541 ]
    - [ 1361, 571, 550, 555, 558, 544, 540, 532, 532, 527 ]
    - [ 1106, 564, 545, 553, 556, 569, 543, 531, 529, 532 ]
    - [ 1271, 563, 550, 553, 557, 556, 544, 528, 533, 529 ]
    - [ 1205, 573, 549, 561, 559, 540, 542, 536, 537, 542 ]
  3.0:
    - [ 1285, 553, 541, 537, 534, 536, 541, 525, 516, 523 ]
    - [ 1392, 560, 548, 550, 553, 550, 531, 524, 535, 532 ]
    - [ 1391, 560, 551, 533, 543, 548, 535, 524, 525, 520 ]
    - [ 1252, 566, 553, 563, 554, 554, 548, 533, 528, 530 ]
    - [ 1279, 555, 550, 550, 550, 541, 528, 525, 525, 528 ]
    - [ 1198, 563, 548, 548, 561, 572, 531, 529, 536, 551 ]
    - [ 1349, 565, 551, 543, 547, 552, 536, 525, 527, 529 ]
    - [ 1380, 559, 545, 552, 585, 557, 524, 534, 524, 522 ]
    - [ 1334, 564, 544, 577, 551, 551, 531, 524, 531, 528 ]
    - [ 1106, 567, 564, 553, 559, 565, 533, 533, 536, 537 ]
  4.0:
    - [ 1372, 563, 555, 543, 556, 570, 543, 535, 531, 531 ]
    - [ 1306, 559, 545, 552, 560, 540, 537, 527, 526, 523 ]
    - [ 1317, 562, 542, 549, 566, 549, 534, 529, 525, 534 ]
    - [ 1290, 561, 549, 542, 547, 548, 542, 527, 524, 521 ]
    - [ 1299, 560, 551, 549, 563, 554, 524, 527, 523, 529 ]
    - [ 1253, 560, 548, 541, 547, 561, 555, 523, 521, 526 ]
    - [ 1193, 563, 552, 540, 552, 540, 522, 517, 522, 517 ]
    - [ 1184, 561, 546, 544, 550, 541, 541, 529, 529, 529 ]
    - [ 1244, 562, 548, 544, 556, 554, 535, 525, 518, 521 ]
    - [ 1308, 563, 569, 547, 558, 559, 536, 526, 527, 531 ]
  5.0:
    - [ 1214, 567, 559, 559, 559, 555, 538, 538, 536, 535 ]
    - [ 1280, 558, 550, 542, 543, 547, 539, 520, 522, 519 ]
    - [ 1328, 592, 542, 550, 555, 539, 531, 527, 525, 534 ]
    - [ 1149, 570, 565, 539, 547, 566, 534, 519, 527, 527 ]
    - [ 1153, 560, 543, 542, 556, 547, 524, 524, 527, 521 ]
    - [ 1335, 564, 555, 546, 559, 546, 551, 536, 528, 537 ]
    - [ 1292, 561, 568, 538, 562, 544, 531, 531, 524, 529 ]
    - [ 1455, 557, 541, 545, 547, 545, 540, 528, 528, 524 ]
    - [ 1290, 565, 542, 547, 553, 545, 531, 519, 527, 528 ]
    - [ 1352, 562, 547, 541, 561, 558, 552, 527, 523, 530 ]
  6.0:
    - [ 1409, 570, 550, 557, 547, 556, 549, 537, 534, 531 ]
    - [ 1098, 564, 556, 553, 564, 546, 535, 537, 529, 532 ]
    - [ 1402, 563, 557, 543, 553, 612, 543, 530, 526, 533 ]
    - [ 1324, 567, 549, 555, 562, 573, 549, 534, 528, 529 ]
    - [ 1331, 560, 548, 540, 561, 571, 538, 524, 530, 548 ]
    - [ 1344, 563, 548, 548, 554, 548, 542, 527, 532, 527 ]
    - [ 1158, 555, 541, 541, 540, 555, 529, 517, 524, 523 ]
    - [ 1358, 561, 543, 546, 555, 556, 551, 523, 525, 529 ]
    - [ 1361, 562, 540, 541, 551, 564, 536, 530, 521, 524 ]
    - [ 1405, 560, 540, 546, 576, 546, 530, 526, 518, 523 ]
  7.0:
    - [ 1209, 566, 542, 541, 552, 549, 542, 526, 524, 533 ]
    - [ 1311, 563, 552, 547, 581, 564, 527, 532, 531, 523 ]
    - [ 1426, 573, 544, 582, 552, 538, 527, 518, 528, 557 ]
    - [ 1273, 566, 547, 549, 564, 543, 534, 529, 527, 526 ]
    - [ 1279, 572, 549, 550, 561, 552, 540, 562, 528, 531 ]
    - [ 1400, 553, 548, 541, 537, 555, 550, 526, 526, 527 ]
    - [ 1241, 582, 555, 556, 553, 546, 541, 535, 535, 526 ]
    - [ 1239, 561, 544, 540, 584, 547, 539, 532, 526, 528 ]
    - [ 1165, 565, 549, 545, 573, 543, 568, 527, 522, 533 ]
    - [ 1238, 565, 552, 555, 579, 561, 534, 528, 535, 544 ]
  8.0:
    - [ 1329, 564, 554, 551, 575, 557, 542, 531, 534, 533 ]
    - [ 1229, 578, 542, 535, 544, 559, 548, 525, 521, 529 ]
    - [ 1303, 566, 549, 545, 571, 569, 532, 525, 527, 532 ]
    - [ 1341, 564, 552, 550, 555, 558, 550, 530, 535, 575 ]
    - [ 1382, 569, 547, 545, 561, 548, 552, 532, 528, 532 ]
    - [ 1139, 565, 545, 548, 548, 554, 551, 524, 532, 532 ]
    - [ 1133, 557, 547, 547, 565, 562, 531, 528, 524, 525 ]
    - [ 1164, 569, 546, 561, 571, 543, 525, 545, 529, 558 ]
    - [ 1155, 564, 548, 536, 558, 550, 523, 529, 528, 528 ]
    - [ 1304, 559, 553, 545, 563, 554, 535, 532, 531, 523 ]
  9.0:
    - [ 1399, 577, 557, 569, 556, 561, 542, 539, 543, 536 ]
    - [ 1319, 586, 559, 561, 555, 560, 565, 536, 541, 546 ]
    - [ 1241, 579, 561, 569, 561, 564, 552, 534, 535, 567 ]
    - [ 1377, 571, 557, 568, 608, 559, 552, 543, 544, 553 ]
    - [ 1358, 577, 566, 580, 569, 558, 539, 534, 537, 540 ]
    - [ 1327, 592, 575, 556, 571, 574, 545, 546, 536, 545 ]
    - [ 1165, 582, 564, 558, 576, 573, 565, 548, 546, 575 ]
    - [ 1190, 585, 556, 560, 562, 576, 570, 541, 539, 542 ]
    - [ 1173, 576, 557, 548, 555, 559, 545, 531, 537, 561 ]
    - [ 1304, 579, 579, 564, 561, 557, 560, 541, 543, 545 ]
  10.0:
    - [ 1307, 577, 554, 547, 565, 578, 549, 536, 529, 539 ]
    - [ 1382, 581, 553, 553, 547, 555, 575, 537, 541, 540 ]
    - [ 1215, 578, 566, 556, 556, 566, 547, 543, 538, 539 ]
    - [ 1324, 586, 566, 565, 566, 558, 577, 541, 543, 540 ]
    - [ 1324, 593, 556, 556, 576, 560, 551, 536, 535, 538 ]
    - [ 1371, 595, 560, 557, 567, 570, 573, 546, 544, 547 ]
    - [ 1325, 589, 577, 568, 570, 563, 567, 552, 548, 550 ]
    - [ 1338, 580, 569, 564, 560, 569, 559, 550, 541, 546 ]
    - [ 1258, 576, 555, 563, 615, 572, 540, 541, 535, 540 ]
    - [ 1311, 575, 550, 554, 573, 578, 537, 535, 532, 534 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 15550, 11713, 11171, 11068, 11482, 11036, 11005, 10755, 10673, 10749 ]
    - [ 15668, 11712, 11315, 11055, 11809, 11090, 10931, 10877, 10781, 10858 ]
    - [ 15136, 11548, 11131, 10876, 11497, 10925, 10726, 10645, 10631, 10704 ]
    - [ 15236, 11683, 11211, 10958, 11424, 11056, 10764, 10768, 10687, 10725 ]
    - [ 15260, 11604, 11192, 10917, 11421, 11037, 10803, 10785, 10669, 10742 ]
    - [ 15269, 11680, 11260, 11009, 11628, 11062, 10769, 10738, 10775, 10727 ]
    - [ 15163, 11658, 11172, 10948, 11411, 11066, 10845, 10798, 10767, 10806 ]
    - [ 15162, 11575, 11052, 10948, 11616, 11002, 10764, 10727, 10631, 10722 ]
    - [ 15544, 11576, 11114, 10911, 11481, 11096, 10814, 10677, 10678, 10718 ]
    - [ 15432, 11679, 11230, 10988, 11382, 11165, 10863, 10775, 10750, 10755 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1411, 618, 626, 615, 619, 628, 593, 595, 589, 593 ]
    - [ 1384, 600, 612, 610, 661, 614, 608, 598, 585, 590 ]
    - [ 1363, 616, 607, 603, 620, 615, 607, 595, 588, 608 ]
    - [ 1330, 620, 610, 604, 598, 599, 595, 588, 585, 591 ]
    - [ 1163, 630, 605, 620, 628, 619, 599, 608, 598, 610 ]
    - [ 1128, 622, 605, 600, 629, 620, 587, 579, 593, 584 ]
    - [ 1357, 614, 621, 610, 635, 615, 601, 598, 595, 601 ]
    - [ 1101, 621, 609, 595, 643, 602, 609, 585, 586, 584 ]
    - [ 1320, 612, 618, 628, 636, 604, 598, 595, 597, 595 ]
    - [ 1262, 614, 619, 608, 625, 616, 595, 595, 586, 600 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1096, 582, 576, 571, 563, 597, 556, 546, 556, 562 ]
    - [ 1555, 602, 567, 559, 579, 571, 560, 561, 554, 566 ]
    - [ 1350, 571, 576, 564, 605, 573, 555, 551, 555, 561 ]
    - [ 1196, 576, 574, 554, 573, 582, 548, 553, 550, 548 ]
    - [ 1191, 580, 562, 553, 604, 567, 544, 544, 552, 549 ]
    - [ 1349, 582, 568, 567, 621, 580, 559, 548, 545, 567 ]
    - [ 1397, 563, 568, 559, 580, 572, 556, 550, 539, 548 ]
    - [ 1258, 575, 572, 568, 571, 576, 558, 552, 559, 555 ]
    - [ 1184, 574, 575, 571, 568, 561, 550, 547, 539, 550 ]
    - [ 1256, 586, 579, 572, 582, 570, 561, 550, 554, 566 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1324, 624, 614, 616, 670, 617, 589, 594, 596, 592 ]
    - [ 1297, 633, 644, 624, 660, 629, 612, 605, 599, 609 ]
    - [ 1401, 647, 643, 630, 699, 639, 605, 619, 627, 621 ]
    - [ 1302, 641, 626, 617, 632, 655, 617, 608, 599, 602 ]
    - [ 1271, 644, 632, 617, 635, 673, 633, 611, 602, 602 ]
    - [ 1317, 655, 632, 632, 641, 641, 606, 614, 600, 619 ]
    - [ 1336, 656, 633, 626, 626, 669, 611, 603, 604, 602 ]
    - [ 1467, 634, 629, 629, 632, 627, 607, 596, 605, 603 ]
    - [ 1227, 644, 636, 625, 632, 629, 593, 595, 616, 594 ]
    - [ 1318, 644, 629, 623, 668, 637, 629, 603, 606, 603 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 1283, 593, 593, 571, 589, 588, 585, 571, 563, 568 ]
    - [ 1245, 580, 581, 585, 603, 583, 566, 563, 558, 557 ]
    - [ 1324, 591, 591, 580, 589, 582, 573, 579, 569, 560 ]
    - [ 1144, 585, 573, 587, 618, 584, 569, 560, 568, 564 ]
    - [ 1073, 592, 574, 577, 608, 592, 570, 566, 567, 564 ]
    - [ 1067, 599, 586, 591, 592, 578, 571, 562, 578, 568 ]
    - [ 1176, 581, 581, 583, 573, 579, 565, 562, 563, 571 ]
    - [ 1141, 592, 583, 578, 591, 600, 585, 568, 559, 574 ]
    - [ 1286, 591, 577, 587, 588, 587, 570, 561, 573, 570 ]
    - [ 1221, 593, 578, 583, 606, 599, 574, 569, 565, 574 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 1315, 598, 598, 583, 601, 577, 579, 574, 562, 571 ]
    - [ 1315, 611, 591, 591, 597, 600, 587, 585, 570, 574 ]
    - [ 1396, 593, 597, 580, 592, 575, 570, 572, 572, 570 ]
    - [ 1289, 595, 584, 576, 595, 601, 564, 565, 560, 570 ]
    - [ 1131, 592, 595, 593, 618, 587, 569, 572, 568, 578 ]
    - [ 1405, 595, 599, 591, 598, 591, 573, 570, 577, 574 ]
    - [ 1384, 602, 595, 587, 601, 585, 578, 567, 567, 573 ]
    - [ 1153, 597, 596, 580, 597, 596, 580, 570, 570, 573 ]
    - [ 1283, 608, 587, 586, 608, 593, 571, 571, 570, 578 ]
    - [ 1104, 592, 584, 583, 603, 588, 572, 562, 571, 569 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 13645, 13593, 13619, 13503, 13514 ]
    - [ 13571, 13513, 13628, 13739, 13670 ]
    - [ 13524, 13538, 13606, 13556, 13564 ]
    - [ 13631, 13602, 13596, 13637, 13773 ]
    - [ 13585, 13621, 13478, 13608, 13504 ]
    - [ 13695, 13647, 13618, 13581, 13534 ]
    - [ 13487, 13567, 13558, 13548, 13498 ]
    - [ 13513, 13377, 13607, 13415, 13573 ]
    - [ 13630, 13439, 13465, 13456, 13545 ]
    - [ 13618, 13509, 13497, 13520, 13545 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 1323, 1020, 964, 958, 963 ]
    - [ 1310, 1000, 961, 980, 969 ]
    - [ 1313, 1018, 975, 1017, 957 ]
    - [ 1302, 973, 978, 973, 958 ]
    - [ 1323, 1022, 972, 971, 979 ]
    - [ 1312, 995, 960, 956, 971 ]
    - [ 1295, 1006, 959, 974, 958 ]
    - [ 1316, 1006, 971, 980, 961 ]
    - [ 1326, 993, 954, 969, 959 ]
    - [ 1307, 964, 955, 984, 980 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1100, 973, 976, 974, 973 ]
    - [ 1113, 975, 958, 960, 960 ]
    - [ 1080, 979, 963, 973, 973 ]
    - [ 1086, 971, 981, 956, 979 ]
    - [ 1104, 977, 970, 960, 974 ]
    - [ 1081, 978, 966, 970, 966 ]
    - [ 1118, 958, 970, 982, 959 ]
    - [ 1086, 968, 968, 985, 979 ]
    - [ 1116, 957, 972, 961, 959 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 4682, 1463, 863, 758, 765 ]
    - [ 4637, 1163, 838, 765, 755 ]
    - [ 4565, 908, 843, 751, 762 ]
    - [ 4563, 908, 836, 759, 766 ]
    - [ 4657, 904, 846, 754, 765 ]
    - [ 4704, 920, 846, 766, 769 ]
    - [ 4573, 910, 836, 749, 755 ]
    - [ 4577, 906, 832, 767, 751 ]
    - [ 4587, 902, 851, 760, 767 ]
    - [ 4671, 925, 841, 761, 757 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 1171, 600, 588, 588, 587 ]
    - [ 1134, 617, 588, 585, 595 ]
    - [ 1163, 607, 592, 594, 587 ]
    - [ 1185, 596, 585, 579, 595 ]
    - [ 1230, 590, 585, 581, 581 ]
    - [ 1198, 601, 584, 589, 581 ]
    - [ 1254, 608, 594, 588, 593 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1577, 587, 587, 581, 591 ]
    - [ 1549, 606, 575, 576, 581 ]
    - [ 1531, 596, 584, 582, 603 ]
    - [ 1433, 601, 576, 577, 617 ]
    - [ 1232, 598, 583, 570, 622 ]
    - [ 1527, 606, 569, 580, 629 ]
    - [ 1454, 598, 588, 571, 605 ]
    - [ 1580, 595, 594, 580, 598 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1399, 592, 582, 572, 587 ]
    - [ 1361, 576, 565, 564, 568 ]
    - [ 1171, 584, 562, 568, 564 ]
    - [ 1340, 578, 567, 569, 592 ]
    - [ 1306, 586, 571, 575, 596 ]
    - [ 1136, 581, 563, 555, 587 ]
    - [ 1323, 573, 558, 562, 579 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1757, 625, 588, 588, 597 ]
    - [ 1680, 614, 593, 578, 605 ]
    - [ 1682, 600, 577, 579, 604 ]
    - [ 1633, 620, 594, 575, 602 ]
    - [ 1900, 626, 589, 574, 590 ]
    - [ 1622, 607, 584, 586, 576 ]
    - [ 1744, 598, 577, 590, 611 ]
    - [ 1764, 605, 590, 582, 592 ]
    - [ 1782, 607, 588, 581, 595 ]
    - [ 1521, 608, 597, 582, 585 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1074, 523, 499, 503, 506 ]
    - [ 1174, 506, 511, 515, 510 ]
    - [ 1183, 518, 505, 499, 525 ]
    - [ 1050, 510, 506, 500, 513 ]
    - [ 1216, 505, 516, 509, 510 ]
    - [ 1020, 526, 520, 518, 513 ]
    - [ 1059, 509, 497, 500, 517 ]
    - [ 1104, 510, 494, 498, 558 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 2526, 1027, 1001, 991, 1047, 1011, 988, 960, 966, 972 ]
    - [ 2152, 1046, 1003, 988, 998, 1034, 1003, 968, 969, 966 ]
    - [ 2369, 1038, 998, 996, 1022, 1034, 1000, 969, 965, 964 ]
    - [ 2485, 1051, 1002, 987, 1025, 1022, 1006, 981, 973, 963 ]
    - [ 2118, 1086, 1020, 1030, 1035, 1038, 1007, 994, 980, 992 ]
    - [ 2422, 1051, 1005, 978, 1020, 1021, 987, 959, 965, 959 ]
    - [ 2383, 1053, 982, 1013, 1029, 1029, 1009, 975, 972, 964 ]
    - [ 2670, 1040, 1010, 985, 985, 1061, 998, 966, 969, 968 ]
    - [ 2340, 1049, 997, 1000, 1028, 1028, 1015, 973, 959, 976 ]
    - [ 2285, 1058, 1017, 992, 1020, 1009, 1032, 970, 964, 962 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 22246, 16602, 16070, 15574, 16133, 15795, 15596, 15305, 15294, 15324 ]
    - [ 22165, 16320, 15954, 15873, 16617, 15880, 15403, 15380, 15412, 15419 ]
    - [ 22213, 16208, 15740, 15638, 15942, 15754, 15362, 15159, 15138, 15185 ]
    - [ 22596, 16470, 15929, 15830, 16123, 15975, 15630, 15413, 15400, 15391 ]
    - [ 22469, 16563, 15608, 15716, 16216, 15961, 15264, 15272, 15217, 15267 ]
    - [ 22050, 16159, 15958, 15451, 16205, 15641, 15227, 15202, 15131, 15194 ]
    - [ 22564, 16362, 15861, 15842, 16298, 15942, 15330, 15389, 15364, 15368 ]
    - [ 22103, 16499, 15930, 15622, 16221, 15950, 15285, 15303, 15275, 15308 ]
    - [ 22044, 16852, 15737, 15897, 16110, 15848, 15715, 15448, 15425, 15443 ]
    - [ 22273, 16470, 15840, 15755, 16243, 15879, 15362, 15343, 15289, 15333 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 1525, 913, 907, 909, 895, 903, 884, 889, 884, 897 ]
    - [ 1481, 929, 913, 918, 908, 900, 899, 902, 914, 900 ]
    - [ 1625, 945, 924, 932, 925, 920, 933, 916, 916, 919 ]
    - [ 1629, 921, 908, 890, 908, 904, 891, 894, 894, 896 ]
    - [ 1726, 939, 915, 895, 919, 926, 901, 900, 900, 902 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 26321, 20667, 19883, 19540, 20125, 19937, 19442, 19498, 19070, 19158 ]
    - [ 26425, 20299, 19557, 19289, 19856, 19588, 19183, 19192, 18903, 18946 ]
    - [ 26064, 20351, 19775, 19393, 20103, 19686, 19296, 19370, 19020, 19094 ]
    - [ 26125, 20467, 19911, 19366, 20403, 19710, 19238, 19290, 19093, 19252 ]
    - [ 26727, 20447, 19722, 19246, 20205, 19622, 19138, 19169, 18912, 18980 ]
    - [ 26423, 20584, 19779, 19374, 20058, 20193, 19405, 19268, 19061, 19090 ]
    - [ 26537, 20029, 19797, 19380, 19987, 19533, 19815, 19235, 18977, 18992 ]
    - [ 26069, 20455, 19800, 19334, 20051, 19538, 19444, 19218, 19007, 19049 ]
    - [ 26301, 20541, 19651, 19476, 20071, 19626, 19614, 19383, 19050, 19079 ]
    - [ 26201, 20505, 19701, 19260, 19965, 19538, 19665, 19347, 18938, 18998 ]
