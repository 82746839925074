#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [7, 75, 7/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [8, 88, 4/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [10, 23556, 1/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (24129344080/1024300.0)]
BAL: [8, 252, 4/20, 0, 34, 2204, nominal aaload per usec]
BAS: [10, 126, 1/20, 0, 1, 126, nominal aastore per usec]
BEF: [6, 5, 9/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [9, 12289, 2/20, 0, 527, 32087, nominal getfield per usec]
BPF: [10, 3863, 1/20, 0, 83, 3863, nominal putfield per usec]
BUB: [3, 26, 14/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 3, 14/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [3, 89, 16/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (17/19)']
GCC: [10, 22408, 1/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [4, 84, 15/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (16/19)']
GCP: [10, 32, 2/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (8124/24657)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (19/19)]
GMD: [2, 19, 19/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [4, 109, 13/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [2, 21, 19/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [9, 2159, 4/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (23140.9/1024.3)']
GTO: [10, 1211, 1/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [4, 172, 14/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [9, 202, 4/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [5, 2, 13/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 11, 13/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [9, 202, 4/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [7, 7, 7/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [7, 19, 8/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [7, 9, 8/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [9, 34, 4/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 3, 3/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [10, 8, 2/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [4, 87, 14/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [10, 56, 1/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [3, 20, 17/22, 5, 23, 41, nominal backend bound (memory) ( 68678843417.7 / 95943748786.8) * 29 )]
UBP: [5, 40, 11/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.041198568779484364 * 938470499.4 ) / ( 938470499.4 + 13788469.8 )']
UBR: [2, 596, 18/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.041198568779484364 * 13788469.8 ) / ( 938470499.4 + 13788469.8 ) )']
UBS: [5, 41, 11/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 301287368654.1 - 257099952110.3 ) / ( 6 * 178757894804.2 ) )]
UDC: [5, 12, 12/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 3285242938.4/ 266608785085.0 )]
UDT: [5, 154, 13/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 41157924.1 / 266608785085.0 )]
UIP: [5, 149, 12/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 266608785085.0/178757894804.2)]
ULL: [5, 2830, 11/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 379574472.5) / 266608785085.0 ) )]
USB: [5, 29, 11/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 319520873758.2 / ( 6 * 182969336961.7 ) )]
USC: [9, 198, 3/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 217717121148.1 / ( 6 * 183224429910.5 ) )]
USF: [5, 23, 12/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 253998305902.5/( 6 * 178757894804.2) )]
