#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [9, 142, 2/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [9, 152, 3/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [7, 5740, 7/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (13201047840/2299500.0)]
BAL: [7, 231, 6/20, 0, 34, 2204, nominal aaload per usec]
BAS: [9, 31, 3/20, 0, 1, 126, nominal aastore per usec]
BEF: [6, 6, 8/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [7, 3002, 7/20, 0, 527, 32087, nominal getfield per usec]
BPF: [6, 142, 8/20, 0, 83, 3863, nominal putfield per usec]
BUB: [6, 87, 8/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [6, 11, 8/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [8, 112, 6/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (89/79)']
GCC: [8, 5118, 5/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [8, 111, 5/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (88/79)']
GCP: [8, 12, 6/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (4385/35763)]
GLK: [9, 17, 4/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (79/67)]
GMD: [5, 72, 12/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [9, 2543, 3/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [7, 29, 8/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [5, 73, 12/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [5, 249, 12/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (8038.6/2299.5)']
GTO: [7, 187, 6/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [5, 207, 11/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [5, 57, 13/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [7, 3, 8/22, 1, 3, 8, nominal execution time (sec)]
PFS: [4, 9, 15/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [5, 57, 13/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [5, 4, 11/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [5, 11, 11/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [9, 21, 3/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [3, 4, 16/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 2, 4/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [6, 4, 9/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [7, 102, 8/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [6, 32, 9/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [10, 41, 1/22, 5, 23, 41, nominal backend bound (memory) ( 79853093100.6 / 101816171635.1) * 53 )]
UBP: [3, 29, 17/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.030311857025011445 * 386181977.0 ) / ( 386181977.0 + 14902387.4 )']
UBR: [6, 1126, 10/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.030311857025011445 * 14902387.4 ) / ( 386181977.0 + 14902387.4 ) )']
UBS: [2, 30, 18/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 135101790719.6 - 111855896632.3 ) / ( 6 * 127815187239.1 ) )]
UDC: [9, 23, 3/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 2725567643.1/ 114387540829.4 )]
UDT: [9, 499, 3/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 57134478.0 / 114387540829.4 )]
UIP: [0, 89, 22/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 114387540829.4/127815187239.1)]
ULL: [10, 8506, 1/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 479996239.0) / 114387540829.4 ) )]
USB: [10, 53, 1/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 436387296139.3 / ( 6 * 134970328214.9 ) )]
USC: [7, 102, 7/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 81225484773.4 / ( 6 * 132503228011.0 ) )]
USF: [4, 18, 15/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 144092840011.3/( 6 * 127815187239.1) )]
