import * as React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router";
import * as pages from "./pages";
import "./app.css";

const container = document.getElementById('app') as HTMLInputElement;
const root = createRoot(container);
const basename = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/";
root.render(
    <React.StrictMode>
        <BrowserRouter basename={basename}>
            <Routes>
                <Route path="/" element={<pages.App/>}>
                    <Route index element={<pages.Nominal/>}/>
                    <Route path="2xheap" element={<pages.TwoXHeap/>}/>
                    <Route path="g1" element={<pages.G1/>}/>
                    <Route path="benchmark">
                        <Route index element={<pages.BenchmarkList/>}/>
                        <Route path=":bm">
                            <Route index element={<pages.BenchmarkNominal/>}/>
                            <Route path={"perf"} element={<pages.BenchmarkPerf/>}/>
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="*"
                    element={
                        <p>Invalid path</p>
                    }
                />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
