#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
GCA: [3, 87, 17/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (118/135)']
GCC: [5, 948, 12/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [4, 84, 15/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (114/135)']
GCP: [5, 2, 12/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1292/44516)]
GLK: [9, 26, 3/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (135/107)]
GMD: [7, 135, 8/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [7, 605, 7/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [8, 73, 5/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [6, 141, 9/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GMV: [4, 1123, 2/3, 371, 1123, 20641, nominal minimum heap size (MB) for vlarge size configuration (with compressed pointers)]
GSS: [5, 67, 13/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (860.1/512.3)']
PCC: [3, 104, 16/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [8, 150, 5/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [7, 17, 7/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [8, 150, 5/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [5, 2, 12/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [8, 25, 6/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [6, 7, 9/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [3, 4, 16/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 2, 4/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [8, 6, 6/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [9, 144, 3/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [9, 42, 3/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [4, 21, 15/22, 5, 23, 41, nominal backend bound (memory) ( 5072791215.2 / 5405307969.8) * 23 )]
UBP: [5, 38, 13/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.039239716665158415 * 35727622.3 ) / ( 35727622.3 + 1115245.0 )']
UBR: [6, 1187, 9/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.039239716665158415 * 1115245.0 ) / ( 35727622.3 + 1115245.0 ) )']
UBS: [5, 39, 12/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 18342980257.2 - 16249750185.1 ) / ( 6 * 8890779759.2 ) )]
UDC: [3, 8, 16/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 143671918.5/ 17702929024.9 )]
UDT: [5, 177, 11/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 3141056.6 / 17702929024.9 )]
UIP: [7, 199, 8/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 17702929024.9/8890779759.2)]
ULL: [5, 2352, 13/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 24054473.2) / 17702929024.9 ) )]
USB: [2, 23, 19/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 13211740513.8 / ( 6 * 9201926049.2 ) )]
USC: [5, 42, 13/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 2311999241.8 / ( 6 * 9117284345.3 ) )]
USF: [8, 38, 5/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 20577273717.5/( 6 * 8890779759.2) )]
