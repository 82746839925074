import {Outlet} from "react-router";
import * as React from "react";
import {NavBar} from "../components/NavBar";

export function App() {
    const commit = "0db32562cf169730c163d88df2eeb28217ca7d03";
    const commit_date = "Thu Dec 19 20:05:56 2024 +1100";
    return (
        <div className={"min-h-screen bg-white dark:bg-slate-800 dark:text-white flex flex-col"}>
            <NavBar/>
            <div className={"container mx-auto"}>
                <Outlet/>
                <div className="text-gray-500">
                    <a href={`https://github.com/dacapobench/dacapobench/commit/${commit}`}>{commit_date} {commit}</a>
                </div>
            </div>
        </div>
    );
}