#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [3, 40, 15/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [3, 890, 15/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (4791478392/5383100.0)]
BAL: [3, 9, 15/20, 0, 34, 2204, nominal aaload per usec]
BAS: [6, 1, 9/20, 0, 1, 126, nominal aastore per usec]
BEF: [3, 3, 15/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [4, 314, 13/20, 0, 527, 32087, nominal getfield per usec]
BPF: [4, 57, 13/20, 0, 83, 3863, nominal putfield per usec]
BUB: [7, 114, 6/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [8, 18, 5/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [6, 103, 10/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (200/194)']
GCC: [3, 659, 16/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [7, 101, 8/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (197/194)']
GCP: [4, 1, 15/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1401/90248)]
GLK: [10, 46, 2/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (194/132)]
GMD: [7, 174, 7/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [5, 174, 10/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [9, 77, 3/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [7, 142, 8/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [2, 14, 19/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (6171.333333333333/5383.1)']
GTO: [4, 34, 13/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [1, 60, 21/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [3, 31, 16/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [9, 6, 3/22, 1, 3, 8, nominal execution time (sec)]
PFS: [2, 2, 18/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [3, 31, 16/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [8, 11, 5/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [4, 3, 15/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [4, 2, 15/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [7, 13, 7/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [3, 0, 16/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [10, 168, 1/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [1, -9, 21/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [6, 26, 9/22, 5, 23, 41, nominal backend bound (memory) ( 38895898536.7 / 42593662812.6) * 29 )]
UBP: [4, 37, 15/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.038194348545106796 * 398183180.2 ) / ( 398183180.2 + 6566968.3 )']
UBR: [3, 619, 17/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.038194348545106796 * 6566968.3 ) / ( 398183180.2 + 6566968.3 ) )']
UBS: [4, 38, 15/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 86504826424.7 - 71722212075.4 ) / ( 6 * 64506115487.4 ) )]
UDC: [10, 24, 2/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 1683958724.3/ 70089779283.2 )]
UDT: [10, 576, 1/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 40388608.0 / 70089779283.2 )]
UIP: [2, 108, 19/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 70089779283.2/64506115487.4)]
ULL: [9, 5719, 3/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 206874136.1) / 70089779283.2 ) )]
USB: [5, 29, 11/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 116932888543.8 / ( 6 * 65511671908.2 ) )]
USC: [6, 92, 10/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 37334885389.3 / ( 6 * 67431662450.0 ) )]
USF: [9, 40, 4/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 155393215771.3/( 6 * 64506115487.4) )]
