#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5674, 6363, 6088, 6252, 5355, 6623, 10525, 8765, 8580, 9895 ]
  2.0:
    - [ 3149, 1557, 1350, 1259, 1268, 1250, 1235, 1257, 1240, 1243 ]
    - [ 3207, 1589, 1403, 1275, 1260, 1267, 1226, 1232, 1234, 1259 ]
    - [ 3243, 1498, 1357, 1249, 1255, 1253, 1231, 1218, 1235, 1221 ]
    - [ 2926, 1533, 1334, 1268, 1251, 1244, 1236, 1228, 1219, 1215 ]
    - [ 3038, 1506, 1372, 1243, 1253, 1247, 1224, 1217, 1226, 1224 ]
    - [ 2998, 1522, 1370, 1250, 1244, 1229, 1234, 1212, 1242, 1249 ]
    - [ 2990, 1508, 1346, 1232, 1232, 1230, 1226, 1201, 1209, 1226 ]
    - [ 3226, 1478, 1310, 1239, 1249, 1259, 1243, 1227, 1203, 1220 ]
    - [ 2958, 1498, 1367, 1260, 1245, 1230, 1211, 1233, 1205, 1214 ]
    - [ 2992, 1565, 1328, 1256, 1250, 1272, 1243, 1201, 1204, 1225 ]
  3.0:
    - [ 2779, 1366, 1199, 1129, 1098, 1077, 1080, 1075, 1068, 1094 ]
    - [ 2933, 1360, 1230, 1139, 1100, 1069, 1076, 1067, 1068, 1078 ]
    - [ 2737, 1382, 1220, 1121, 1118, 1098, 1087, 1093, 1080, 1069 ]
    - [ 2802, 1372, 1249, 1212, 1097, 1110, 1106, 1097, 1087, 1089 ]
    - [ 2893, 1404, 1273, 1143, 1101, 1118, 1097, 1099, 1098, 1088 ]
    - [ 2829, 1474, 1227, 1125, 1113, 1102, 1091, 1122, 1079, 1089 ]
    - [ 2901, 1359, 1202, 1124, 1102, 1102, 1101, 1091, 1059, 1088 ]
    - [ 2777, 1358, 1224, 1122, 1112, 1108, 1082, 1068, 1067, 1083 ]
    - [ 2881, 1331, 1198, 1126, 1098, 1086, 1080, 1069, 1061, 1060 ]
    - [ 2855, 1358, 1209, 1172, 1099, 1092, 1096, 1083, 1067, 1084 ]
  4.0:
    - [ 2754, 1310, 1141, 1090, 1067, 1060, 1034, 1022, 1030, 1028 ]
    - [ 2727, 1325, 1188, 1094, 1066, 1065, 1060, 1060, 1026, 1041 ]
    - [ 2837, 1271, 1155, 1078, 1059, 1050, 1046, 1034, 1033, 1037 ]
    - [ 2850, 1284, 1172, 1091, 1054, 1054, 1038, 1024, 1039, 1046 ]
    - [ 2706, 1301, 1170, 1054, 1035, 1026, 1011, 1020, 1002, 1007 ]
    - [ 2846, 1302, 1161, 1083, 1040, 1042, 1030, 1025, 1024, 1044 ]
    - [ 2724, 1276, 1204, 1083, 1052, 1066, 1036, 1041, 1042, 1032 ]
    - [ 2873, 1298, 1154, 1089, 1073, 1045, 1033, 1035, 1050, 1036 ]
    - [ 2723, 1253, 1183, 1091, 1040, 1049, 1032, 1020, 1012, 1025 ]
    - [ 2688, 1272, 1180, 1082, 1059, 1052, 1030, 1043, 1036, 1029 ]
  5.0:
    - [ 2808, 1248, 1099, 1036, 1003, 993, 999, 974, 974, 986 ]
    - [ 2732, 1256, 1146, 1050, 1015, 1011, 999, 975, 974, 1005 ]
    - [ 2709, 1233, 1119, 1019, 995, 993, 984, 970, 974, 972 ]
    - [ 2751, 1231, 1116, 1044, 1006, 993, 987, 976, 977, 978 ]
    - [ 2678, 1258, 1128, 1049, 1015, 1018, 1003, 995, 974, 987 ]
    - [ 2683, 1302, 1110, 1041, 993, 990, 984, 969, 981, 987 ]
    - [ 2689, 1250, 1116, 1027, 1005, 1000, 987, 976, 985, 986 ]
    - [ 2670, 1277, 1147, 1026, 1010, 995, 1010, 987, 974, 978 ]
    - [ 2791, 1219, 1069, 989, 947, 956, 939, 911, 935, 917 ]
    - [ 2768, 1256, 1151, 1039, 1023, 1014, 1007, 989, 982, 995 ]
  6.0:
    - [ 2867, 1255, 1122, 1051, 1008, 1016, 1003, 986, 985, 993 ]
    - [ 2744, 1238, 1101, 1029, 1012, 990, 969, 965, 981, 977 ]
    - [ 2653, 1204, 1106, 1022, 997, 984, 969, 958, 954, 968 ]
    - [ 2608, 1268, 1091, 1038, 972, 988, 968, 976, 958, 963 ]
    - [ 2743, 1208, 1094, 1025, 990, 998, 980, 991, 981, 976 ]
    - [ 2649, 1279, 1132, 1053, 1012, 1018, 995, 980, 979, 1007 ]
    - [ 2649, 1266, 1104, 1020, 991, 985, 982, 982, 975, 980 ]
    - [ 2722, 1261, 1142, 1023, 1002, 987, 1008, 980, 965, 978 ]
    - [ 2780, 1206, 1032, 987, 943, 953, 923, 913, 916, 919 ]
    - [ 2706, 1212, 1132, 1037, 985, 1001, 1009, 974, 966, 974 ]
  7.0:
    - [ 2781, 1258, 1092, 1012, 989, 982, 985, 960, 961, 998 ]
    - [ 2623, 1281, 1113, 1033, 999, 1046, 982, 981, 966, 1000 ]
    - [ 2699, 1206, 1092, 1021, 976, 979, 981, 953, 965, 955 ]
    - [ 2704, 1263, 1106, 1016, 987, 991, 986, 966, 951, 962 ]
    - [ 2658, 1208, 1116, 1026, 972, 966, 957, 964, 949, 962 ]
    - [ 2747, 1388, 1114, 1010, 985, 976, 971, 965, 955, 973 ]
    - [ 2689, 1216, 1105, 1035, 983, 993, 972, 964, 973, 965 ]
    - [ 2728, 1236, 1109, 1014, 986, 983, 963, 957, 956, 959 ]
    - [ 2650, 1222, 1080, 1014, 969, 968, 968, 962, 956, 955 ]
    - [ 2693, 1226, 1103, 1010, 985, 974, 966, 962, 949, 964 ]
  8.0:
    - [ 2707, 1216, 1100, 1025, 974, 987, 955, 980, 953, 959 ]
    - [ 2769, 1217, 1118, 1011, 986, 992, 972, 951, 971, 956 ]
    - [ 2649, 1218, 1105, 1029, 991, 992, 989, 984, 945, 969 ]
    - [ 2645, 1243, 1101, 1030, 995, 994, 982, 974, 989, 992 ]
    - [ 2604, 1264, 1102, 1014, 994, 975, 966, 962, 972, 963 ]
    - [ 2628, 1201, 1102, 1014, 971, 993, 976, 965, 970, 972 ]
    - [ 2613, 1237, 1094, 1008, 971, 970, 954, 973, 950, 962 ]
    - [ 2645, 1299, 1133, 1032, 1001, 988, 992, 974, 973, 969 ]
    - [ 2706, 1227, 1080, 1010, 986, 972, 960, 947, 946, 958 ]
    - [ 2850, 1244, 1112, 1020, 996, 975, 973, 954, 950, 954 ]
  9.0:
    - [ 2654, 1271, 1117, 1030, 990, 996, 980, 968, 963, 979 ]
    - [ 2605, 1274, 1100, 1022, 986, 1000, 1009, 978, 970, 1010 ]
    - [ 2736, 1289, 1127, 1019, 997, 986, 984, 977, 965, 967 ]
    - [ 2745, 1264, 1115, 1029, 1004, 1013, 982, 974, 958, 1030 ]
    - [ 2677, 1219, 1104, 1008, 982, 982, 960, 978, 959, 967 ]
    - [ 2741, 1260, 1118, 1026, 999, 994, 983, 978, 976, 978 ]
    - [ 2681, 1154, 1077, 991, 931, 946, 932, 913, 900, 910 ]
    - [ 2726, 1249, 1110, 1023, 984, 974, 971, 949, 946, 972 ]
    - [ 2778, 1248, 1118, 1027, 1007, 1000, 973, 981, 982, 969 ]
    - [ 2713, 1294, 1075, 1014, 995, 976, 966, 959, 967, 953 ]
  10.0:
    - [ 2660, 1279, 1104, 1026, 983, 976, 965, 955, 957, 958 ]
    - [ 2585, 1279, 1115, 1032, 966, 967, 974, 959, 951, 953 ]
    - [ 2834, 1241, 1099, 1027, 962, 975, 965, 946, 931, 966 ]
    - [ 2768, 1306, 1109, 1031, 984, 976, 984, 945, 952, 961 ]
    - [ 2944, 1215, 1092, 1011, 981, 979, 973, 957, 951, 958 ]
    - [ 2755, 1164, 1058, 985, 916, 919, 914, 918, 904, 917 ]
    - [ 2628, 1215, 1091, 999, 984, 963, 952, 946, 946, 959 ]
    - [ 2725, 1218, 1098, 999, 995, 1022, 958, 961, 942, 954 ]
    - [ 2747, 1211, 1070, 1020, 965, 966, 966, 940, 951, 964 ]
    - [ 2722, 1217, 1101, 991, 986, 987, 973, 956, 944, 963 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 14547, 8942, 7384, 5105, 4504, 4549, 4284, 4179, 4395, 4291 ]
    - [ 14303, 8645, 7630, 5023, 4448, 4593, 4268, 4048, 4052, 4190 ]
    - [ 14373, 8770, 7130, 4897, 4433, 4555, 4300, 4136, 4306, 4262 ]
    - [ 14356, 8755, 7371, 5160, 4430, 4484, 4240, 4251, 4474, 4198 ]
    - [ 14334, 8487, 7316, 5272, 4300, 4394, 4080, 4055, 4400, 4222 ]
    - [ 14237, 8764, 6989, 4697, 4298, 4441, 4113, 3983, 4096, 4055 ]
    - [ 14357, 8441, 6896, 4677, 4106, 4273, 4000, 3967, 3979, 4176 ]
    - [ 14411, 8676, 7153, 4756, 4334, 4366, 4138, 3953, 4198, 4103 ]
    - [ 14329, 8502, 7288, 5035, 4327, 4223, 4435, 3953, 4212, 4096 ]
    - [ 14413, 8631, 7526, 5011, 4301, 4344, 4081, 3919, 4235, 4252 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2878, 1456, 1297, 1234, 1236, 1246, 1242, 1222, 1206, 1232 ]
    - [ 2783, 1446, 1289, 1242, 1244, 1230, 1215, 1203, 1209, 1231 ]
    - [ 2853, 1415, 1266, 1230, 1252, 1213, 1228, 1228, 1227, 1235 ]
    - [ 2772, 1460, 1321, 1242, 1260, 1252, 1244, 1218, 1210, 1219 ]
    - [ 2907, 1361, 1257, 1184, 1156, 1147, 1179, 1152, 1154, 1161 ]
    - [ 2828, 1451, 1292, 1227, 1241, 1246, 1229, 1221, 1204, 1224 ]
    - [ 2886, 1433, 1276, 1244, 1219, 1219, 1229, 1212, 1206, 1213 ]
    - [ 2891, 1431, 1286, 1244, 1220, 1223, 1207, 1216, 1208, 1241 ]
    - [ 2743, 1499, 1296, 1230, 1236, 1227, 1226, 1203, 1184, 1232 ]
    - [ 2736, 1446, 1306, 1240, 1244, 1237, 1236, 1228, 1212, 1240 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4680, 2991, 2891, 2879, 2834, 2840, 2848, 2842, 2825, 2862 ]
    - [ 4643, 3015, 2887, 2825, 2811, 2824, 2811, 2832, 2824, 2802 ]
    - [ 4711, 3095, 2935, 2885, 2901, 2873, 2862, 2869, 2852, 2852 ]
    - [ 4734, 2922, 2812, 2763, 2762, 2744, 2776, 2774, 2727, 2818 ]
    - [ 4699, 3306, 2877, 2871, 2853, 2854, 2890, 2849, 2856, 2861 ]
    - [ 4666, 2978, 2889, 2869, 2850, 2855, 2834, 2816, 2830, 2837 ]
    - [ 4604, 3023, 2909, 2844, 2825, 2824, 2786, 2825, 2837, 2808 ]
    - [ 4666, 2975, 2867, 2829, 2820, 2829, 2893, 2839, 2798, 2814 ]
    - [ 4664, 3029, 3221, 2891, 3187, 2890, 2890, 2876, 2877, 2894 ]
    - [ 4374, 2963, 2864, 2829, 2821, 2784, 2812, 2876, 2794, 2818 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 3838, 2738, 2710, 2739, 2769, 2702, 2659, 2696, 2554, 2626 ]
    - [ 3938, 2823, 2656, 2662, 2696, 2697, 2712, 2657, 2629, 2673 ]
    - [ 3843, 2732, 2720, 2668, 2705, 2680, 2710, 2661, 2762, 2624 ]
    - [ 4021, 2798, 2602, 2638, 2658, 2751, 2624, 2634, 2639, 2819 ]
    - [ 3859, 2695, 2713, 2782, 2660, 2719, 2659, 2579, 2662, 2652 ]
    - [ 3869, 2857, 2598, 2640, 2600, 2642, 2633, 2562, 2685, 2649 ]
    - [ 3886, 2848, 2621, 2677, 2701, 2651, 2788, 2671, 2610, 2699 ]
    - [ 3856, 2782, 2738, 2676, 2750, 2627, 2685, 2584, 2565, 2700 ]
    - [ 3967, 2760, 2677, 2719, 2702, 2655, 2663, 2634, 2696, 2575 ]
    - [ 4001, 2854, 2700, 2632, 2786, 2658, 2675, 2719, 2588, 2734 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 7060, 7743, 7904, 8144, 7515, 8443, 7882, 7696, 8102, 8159 ]
    - [ 7208, 8535, 7976, 7755, 8421, 8395, 7166, 7561, 7534, 9546 ]
    - [ 7127, 7566, 6943, 7543, 6870, 7654, 7724, 7963, 8792, 8367 ]
    - [ 7472, 8882, 7794, 8475, 8117, 7534, 8624, 8068, 8527, 8102 ]
    - [ 6948, 8087, 6851, 7739, 8633, 7911, 8930, 7988, 7694, 6579 ]
    - [ 7353, 7551, 7272, 9489, 7173, 8464, 8200, 8222, 9382, 9738 ]
    - [ 7144, 8235, 8615, 7305, 7171, 7106, 8867, 7952, 7741, 8700 ]
    - [ 7015, 7559, 7264, 8931, 7602, 8434, 8711, 7642, 9082, 8947 ]
    - [ 7441, 8020, 7101, 7608, 7070, 8380, 8286, 8004, 7122, 7147 ]
    - [ 7276, 7263, 7270, 6592, 7280, 7870, 8154, 6902, 7782, 9674 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 6155, 5369, 5016, 4981, 5181, 4889, 4856, 4550, 4705, 4717 ]
    - [ 5897, 5185, 4610, 5233, 5027, 4951, 4891, 4708, 4838, 5012 ]
    - [ 6010, 5149, 4994, 4876, 5003, 5105, 4673, 4932, 4865, 4789 ]
    - [ 5809, 5105, 5014, 4881, 5027, 4973, 5414, 4862, 4786, 4743 ]
    - [ 6089, 4926, 5085, 4771, 5027, 4966, 4956, 5172, 4876, 4893 ]
    - [ 5876, 5180, 4954, 5080, 4949, 5083, 4825, 4754, 5283, 5157 ]
    - [ 5969, 5298, 5111, 5136, 4861, 5067, 5037, 5257, 4909, 4596 ]
    - [ 6213, 4777, 5033, 4610, 5050, 4922, 5220, 4907, 4704, 4657 ]
    - [ 5895, 4928, 5058, 4800, 4721, 4808, 4920, 4734, 4949, 5241 ]
    - [ 5754, 5263, 4844, 4777, 4806, 4658, 4583, 5019, 4963, 5037 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 21679, 20937, 20882, 20890, 20949 ]
    - [ 21433, 21056, 21108, 21193, 20855 ]
    - [ 21831, 20675, 21279, 20951, 21018 ]
    - [ 21788, 20390, 20784, 20870, 20896 ]
    - [ 21616, 21226, 21029, 20998, 21038 ]
    - [ 22242, 21152, 21044, 21207, 21133 ]
    - [ 21287, 21095, 21452, 21462, 21263 ]
    - [ 21337, 21186, 21123, 20987, 20859 ]
    - [ 21625, 21277, 21030, 21193, 20935 ]
    - [ 22159, 20951, 20982, 21346, 20903 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3315, 2565, 2355, 2288, 2325 ]
    - [ 3328, 2633, 2414, 2416, 2426 ]
    - [ 3349, 2671, 2396, 2378, 2445 ]
    - [ 3298, 2565, 2342, 2325, 2378 ]
    - [ 3300, 2597, 2347, 2305, 2310 ]
    - [ 3236, 2633, 2321, 2326, 2295 ]
    - [ 3308, 2599, 2373, 2366, 2352 ]
    - [ 3300, 2547, 2344, 2309, 2363 ]
    - [ 3277, 2637, 2354, 2278, 2295 ]
    - [ 3252, 2583, 2341, 2363, 2307 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 2698, 2222, 2196, 2143, 2128 ]
    - [ 2680, 2248, 2165, 2189, 2234 ]
    - [ 2671, 2232, 2193, 2239, 2183 ]
    - [ 2627, 2206, 2187, 2158, 2167 ]
    - [ 2674, 2260, 2187, 2149, 2178 ]
    - [ 2655, 2256, 2179, 2172, 2154 ]
    - [ 2635, 2250, 2186, 2189, 2178 ]
    - [ 2623, 2220, 2164, 2126, 2186 ]
    - [ 2665, 2253, 2181, 2163, 2157 ]
    - [ 2621, 2251, 2143, 2209, 2188 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 8532, 4049, 1875, 1799, 1800 ]
    - [ 8759, 3846, 1782, 1789, 1794 ]
    - [ 8838, 3798, 1837, 1769, 1750 ]
    - [ 8507, 3758, 1849, 1774, 1787 ]
    - [ 8806, 3776, 1805, 1809, 1767 ]
    - [ 8541, 3819, 1806, 1796, 1758 ]
    - [ 8792, 3853, 1953, 1798, 1758 ]
    - [ 8582, 3797, 2058, 1840, 1766 ]
    - [ 8154, 3735, 1852, 1799, 1768 ]
    - [ 8497, 3664, 1870, 2092, 1883 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2951, 1613, 1395, 1347, 1353 ]
    - [ 2941, 1615, 1388, 1365, 1359 ]
    - [ 2895, 1455, 1448, 1378, 1341 ]
    - [ 2909, 1613, 1365, 1329, 1320 ]
    - [ 2899, 1621, 1399, 1329, 1342 ]
    - [ 2972, 1635, 1380, 1379, 1354 ]
    - [ 2887, 1614, 1392, 1383, 1331 ]
    - [ 2947, 1663, 1384, 1345, 1368 ]
    - [ 2851, 1468, 1353, 1324, 1347 ]
    - [ 2911, 1637, 1393, 1325, 1343 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4321, 2031, 1739, 1699, 1600 ]
    - [ 4027, 2031, 1871, 1742, 1676 ]
    - [ 4018, 2048, 1797, 1691, 1651 ]
    - [ 4026, 2009, 1858, 1707, 1661 ]
    - [ 4007, 2092, 1939, 1719, 1661 ]
    - [ 4074, 2002, 1842, 1720, 1625 ]
    - [ 4146, 2046, 1828, 1700, 1629 ]
    - [ 4118, 2002, 1817, 1735, 1685 ]
    - [ 3987, 1998, 1757, 1748, 1735 ]
    - [ 4002, 1962, 1787, 1714, 1658 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3081, 1492, 1325, 1296, 1262 ]
    - [ 2961, 1463, 1368, 1304, 1246 ]
    - [ 3043, 1458, 1339, 1292, 1253 ]
    - [ 2944, 1525, 1369, 1268, 1247 ]
    - [ 3169, 1749, 1387, 1322, 1274 ]
    - [ 2937, 1505, 1359, 1288, 1260 ]
    - [ 2889, 1503, 1388, 1307, 1262 ]
    - [ 3180, 1558, 1381, 1327, 1264 ]
    - [ 2937, 1460, 1357, 1314, 1254 ]
    - [ 3106, 1551, 1387, 1290, 1243 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3629, 1860, 1637, 1492, 1509 ]
    - [ 3685, 1913, 1596, 1520, 1499 ]
    - [ 3760, 1902, 1599, 1514, 1465 ]
    - [ 3538, 1878, 1651, 1491, 1491 ]
    - [ 3573, 1833, 1660, 1493, 1493 ]
    - [ 3552, 1870, 1702, 1531, 1520 ]
    - [ 3590, 1882, 1674, 1511, 1517 ]
    - [ 3574, 1872, 1597, 1501, 1527 ]
    - [ 3747, 2002, 1655, 1541, 1545 ]
    - [ 3655, 1869, 1672, 1513, 1510 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2745, 1399, 1223, 1165, 1121 ]
    - [ 2781, 1418, 1190, 1137, 1120 ]
    - [ 2710, 1392, 1236, 1121, 1120 ]
    - [ 2754, 1362, 1242, 1135, 1138 ]
    - [ 2780, 1383, 1221, 1131, 1102 ]
    - [ 2760, 1400, 1221, 1115, 1139 ]
    - [ 2746, 1369, 1217, 1122, 1096 ]
    - [ 2744, 1354, 1233, 1149, 1139 ]
    - [ 2717, 1383, 1210, 1132, 1131 ]
    - [ 2749, 1407, 1232, 1123, 1132 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 3832, 2133, 1907, 1717, 1643, 1668, 1607, 1603, 1626, 1620 ]
    - [ 3945, 2115, 1944, 1700, 1670, 1651, 1631, 1639, 1592, 1644 ]
    - [ 3878, 2115, 1898, 1716, 1627, 1652, 1660, 1595, 1603, 1650 ]
    - [ 3841, 2135, 1948, 1754, 1653, 1664, 1642, 1597, 1591, 1618 ]
    - [ 3856, 2116, 1931, 1785, 1666, 1679, 1636, 1599, 1596, 1640 ]
    - [ 3899, 2100, 1941, 1716, 1654, 1672, 1677, 1622, 1615, 1638 ]
    - [ 3884, 2090, 1888, 1676, 1669, 1657, 1632, 1616, 1597, 1609 ]
    - [ 3854, 2169, 1879, 1717, 1648, 1637, 1650, 1640, 1613, 1636 ]
    - [ 3816, 2110, 1899, 1696, 1637, 1650, 1662, 1602, 1612, 1603 ]
    - [ 3879, 2154, 1930, 1743, 1661, 1690, 1644, 1600, 1591, 1606 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 20103, 12562, 10273, 6885, 6136, 6738, 6327, 5865, 6168, 6229 ]
    - [ 19612, 12211, 9439, 6596, 6027, 6454, 6176, 5729, 6038, 6258 ]
    - [ 19844, 12186, 10165, 6666, 6116, 6269, 6066, 5901, 6016, 5974 ]
    - [ 20011, 12479, 9906, 6944, 6019, 6418, 6185, 5897, 6048, 6078 ]
    - [ 20067, 12502, 10694, 7181, 6514, 6715, 6304, 5805, 6165, 6212 ]
    - [ 20078, 12623, 10334, 7201, 6471, 6811, 6100, 5954, 6115, 6367 ]
    - [ 19867, 12246, 9294, 6680, 6000, 6573, 6214, 5897, 6142, 5994 ]
    - [ 20113, 12340, 10410, 6966, 6004, 6435, 5970, 5740, 5801, 6063 ]
    - [ 19906, 12497, 9691, 7052, 6166, 7183, 6205, 5852, 6301, 6305 ]
    - [ 19707, 12177, 9785, 6789, 5796, 6595, 5991, 5613, 5801, 6105 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 4509, 2391, 2247, 2209, 2251, 2172, 2136, 2140, 2173, 2104 ]
    - [ 4439, 2321, 2168, 2158, 2220, 2197, 2184, 2106, 2066, 2114 ]
    - [ 4525, 2282, 2182, 2188, 2136, 2146, 2209, 2199, 2158, 2162 ]
    - [ 4474, 2346, 2216, 2150, 2152, 2159, 2113, 2088, 2132, 2067 ]
    - [ 4532, 2326, 2247, 2263, 2254, 2213, 2156, 2169, 2161, 2195 ]
    - [ 4512, 2352, 2199, 2187, 2180, 2171, 2189, 2185, 2157, 2254 ]
    - [ 4596, 2281, 2211, 2175, 2238, 2153, 2160, 2151, 2139, 2143 ]
    - [ 4504, 2375, 2284, 2221, 2202, 2181, 2148, 2182, 2219, 2121 ]
    - [ 4550, 2355, 2194, 2191, 2249, 2194, 2162, 2204, 2091, 2161 ]
    - [ 4509, 2304, 2227, 2171, 2148, 2186, 2164, 2117, 2150, 2192 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 30659, 17587, 11708, 9537, 8784, 9490, 8707, 8534, 8956, 9005 ]
    - [ 30311, 16402, 10948, 9106, 8781, 9280, 8482, 8347, 8664, 8999 ]
    - [ 29990, 16364, 11156, 10066, 8949, 9496, 8947, 8540, 8907, 9116 ]
    - [ 30366, 17923, 12729, 9324, 8649, 9484, 8695, 8396, 8671, 8673 ]
    - [ 29980, 17722, 11296, 9790, 8961, 9461, 8761, 8493, 8990, 9051 ]
    - [ 30585, 17803, 12243, 9815, 9163, 9220, 8815, 9331, 8805, 8920 ]
    - [ 29857, 16990, 11170, 9478, 8618, 9768, 9105, 8241, 8774, 8759 ]
    - [ 30589, 17508, 11832, 10115, 9280, 9337, 9227, 8688, 8858, 9206 ]
    - [ 29977, 17098, 11124, 9468, 8884, 9240, 8740, 8461, 8834, 8629 ]
    - [ 30229, 17799, 12188, 9838, 8833, 9688, 8651, 8641, 8873, 8500 ]
