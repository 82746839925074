#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 1199, 924, 925, 890, 956, 962, 951, 918, 924, 748 ]
    - [ 1137, 902, 880, 904, 892, 723, 893, 895, 897, 888 ]
    - [ 1180, 949, 957, 946, 927, 920, 881, 902, 915, 852 ]
    - [ 1202, 965, 938, 910, 925, 939, 916, 906, 937, 887 ]
    - [ 938, 951, 949, 859, 949, 933, 888, 915, 947, 786 ]
    - [ 1193, 956, 907, 950, 908, 938, 910, 915, 876, 766 ]
    - [ 1198, 945, 919, 948, 935, 937, 921, 951, 924, 941 ]
    - [ 1138, 1001, 984, 944, 959, 944, 939, 912, 867, 931 ]
    - [ 1150, 961, 947, 924, 925, 893, 934, 923, 794, 938 ]
    - [ 1181, 961, 912, 936, 938, 909, 885, 915, 894, 864 ]
  2.0:
    - [ 721, 642, 558, 602, 609, 587, 607, 602, 605, 600 ]
    - [ 741, 631, 602, 600, 592, 588, 565, 608, 582, 574 ]
    - [ 843, 629, 578, 567, 560, 583, 627, 526, 590, 572 ]
    - [ 783, 627, 587, 598, 589, 599, 567, 597, 614, 602 ]
    - [ 793, 609, 628, 583, 585, 593, 615, 604, 590, 601 ]
    - [ 794, 623, 556, 583, 621, 628, 598, 599, 626, 601 ]
    - [ 723, 607, 605, 586, 574, 583, 572, 588, 599, 580 ]
    - [ 782, 626, 559, 607, 613, 618, 603, 596, 595, 601 ]
    - [ 763, 648, 608, 607, 580, 624, 601, 601, 603, 612 ]
    - [ 780, 621, 624, 616, 620, 628, 530, 620, 631, 602 ]
  3.0:
    - [ 769, 604, 595, 532, 563, 584, 594, 593, 573, 595 ]
    - [ 752, 582, 581, 540, 556, 575, 546, 554, 555, 534 ]
    - [ 744, 597, 539, 487, 542, 528, 549, 546, 529, 534 ]
    - [ 793, 579, 570, 514, 575, 567, 562, 550, 561, 550 ]
    - [ 781, 611, 570, 554, 572, 568, 563, 572, 532, 545 ]
    - [ 756, 591, 557, 548, 529, 554, 544, 539, 492, 491 ]
    - [ 763, 596, 574, 582, 551, 560, 541, 564, 513, 540 ]
    - [ 777, 652, 595, 588, 581, 578, 553, 563, 531, 584 ]
    - [ 747, 553, 533, 497, 543, 533, 527, 541, 530, 497 ]
    - [ 762, 579, 535, 511, 540, 567, 550, 591, 589, 578 ]
  4.0:
    - [ 763, 567, 557, 502, 514, 523, 537, 553, 538, 541 ]
    - [ 669, 558, 537, 539, 541, 532, 502, 565, 538, 501 ]
    - [ 702, 559, 549, 533, 534, 527, 533, 532, 546, 532 ]
    - [ 714, 558, 556, 547, 532, 539, 522, 532, 539, 497 ]
    - [ 757, 530, 492, 540, 530, 566, 536, 552, 538, 546 ]
    - [ 730, 543, 546, 546, 532, 549, 497, 534, 536, 551 ]
    - [ 736, 583, 571, 529, 543, 557, 537, 565, 523, 503 ]
    - [ 715, 565, 554, 546, 561, 534, 521, 548, 536, 527 ]
    - [ 717, 564, 508, 484, 535, 529, 517, 513, 518, 522 ]
    - [ 749, 600, 523, 550, 572, 567, 524, 542, 547, 546 ]
  5.0:
    - [ 698, 550, 535, 527, 538, 536, 528, 538, 526, 525 ]
    - [ 721, 559, 518, 528, 527, 500, 514, 534, 520, 533 ]
    - [ 736, 542, 530, 530, 530, 545, 527, 534, 536, 484 ]
    - [ 688, 556, 543, 523, 531, 520, 538, 547, 547, 547 ]
    - [ 716, 552, 552, 524, 536, 527, 508, 548, 533, 534 ]
    - [ 679, 579, 545, 529, 545, 547, 555, 552, 550, 535 ]
    - [ 719, 558, 525, 548, 546, 547, 534, 528, 517, 516 ]
    - [ 744, 541, 504, 518, 536, 542, 490, 544, 552, 508 ]
    - [ 715, 538, 525, 531, 478, 527, 524, 526, 533, 536 ]
    - [ 694, 575, 537, 554, 529, 542, 544, 533, 544, 544 ]
  6.0:
    - [ 741, 568, 543, 539, 534, 520, 525, 541, 511, 520 ]
    - [ 756, 561, 530, 516, 523, 529, 493, 528, 520, 543 ]
    - [ 727, 549, 568, 542, 527, 545, 524, 547, 537, 504 ]
    - [ 729, 565, 518, 532, 520, 530, 522, 516, 510, 513 ]
    - [ 672, 545, 548, 509, 529, 529, 491, 526, 541, 515 ]
    - [ 599, 505, 536, 543, 535, 532, 509, 522, 507, 522 ]
    - [ 735, 565, 485, 517, 527, 524, 518, 538, 525, 482 ]
    - [ 725, 540, 516, 513, 524, 521, 509, 526, 525, 522 ]
    - [ 724, 580, 547, 509, 539, 550, 534, 536, 529, 519 ]
    - [ 689, 552, 500, 504, 535, 539, 498, 544, 511, 532 ]
  7.0:
    - [ 726, 572, 507, 530, 546, 534, 527, 530, 534, 544 ]
    - [ 752, 549, 547, 530, 525, 527, 532, 541, 535, 530 ]
    - [ 754, 532, 521, 512, 522, 510, 521, 520, 491, 488 ]
    - [ 690, 566, 557, 541, 520, 551, 537, 555, 551, 547 ]
    - [ 688, 556, 513, 477, 520, 533, 473, 515, 515, 506 ]
    - [ 731, 539, 533, 538, 550, 526, 538, 526, 532, 545 ]
    - [ 746, 579, 513, 576, 551, 542, 512, 498, 541, 527 ]
    - [ 734, 521, 517, 519, 512, 522, 530, 523, 539, 532 ]
    - [ 763, 564, 533, 540, 535, 539, 495, 547, 545, 543 ]
    - [ 725, 531, 537, 528, 529, 531, 539, 535, 532, 521 ]
  8.0:
    - [ 733, 552, 514, 507, 503, 517, 478, 527, 511, 516 ]
    - [ 747, 550, 497, 498, 543, 538, 517, 544, 532, 531 ]
    - [ 705, 526, 472, 512, 501, 532, 516, 533, 480, 527 ]
    - [ 701, 537, 480, 517, 511, 525, 511, 471, 539, 516 ]
    - [ 717, 577, 567, 534, 526, 537, 526, 551, 529, 513 ]
    - [ 712, 598, 580, 567, 529, 564, 495, 508, 527, 500 ]
    - [ 700, 533, 521, 519, 515, 527, 482, 519, 529, 527 ]
    - [ 741, 546, 503, 555, 547, 552, 545, 528, 541, 536 ]
    - [ 690, 551, 549, 513, 541, 536, 545, 554, 532, 536 ]
    - [ 703, 554, 567, 536, 549, 537, 542, 540, 540, 535 ]
  9.0:
    - [ 720, 574, 524, 511, 523, 530, 536, 535, 524, 512 ]
    - [ 772, 566, 517, 546, 545, 543, 516, 535, 510, 516 ]
    - [ 744, 535, 520, 470, 509, 520, 515, 514, 516, 514 ]
    - [ 725, 553, 547, 544, 555, 543, 490, 552, 500, 489 ]
    - [ 767, 582, 545, 511, 545, 545, 504, 529, 494, 538 ]
    - [ 753, 564, 533, 529, 527, 537, 512, 526, 511, 510 ]
    - [ 732, 533, 526, 520, 520, 517, 535, 536, 510, 532 ]
    - [ 724, 548, 553, 540, 563, 567, 523, 563, 540, 540 ]
    - [ 752, 561, 554, 548, 552, 552, 544, 546, 548, 563 ]
    - [ 713, 527, 525, 479, 528, 548, 537, 548, 537, 531 ]
  10.0:
    - [ 734, 546, 509, 484, 551, 529, 514, 531, 531, 514 ]
    - [ 744, 564, 550, 526, 537, 530, 499, 516, 551, 532 ]
    - [ 714, 552, 499, 531, 549, 539, 523, 493, 527, 549 ]
    - [ 732, 576, 521, 528, 519, 519, 506, 527, 518, 516 ]
    - [ 723, 567, 541, 538, 544, 550, 522, 538, 519, 499 ]
    - [ 716, 547, 521, 509, 539, 494, 537, 531, 519, 529 ]
    - [ 754, 572, 573, 532, 547, 538, 541, 538, 547, 548 ]
    - [ 756, 564, 590, 544, 553, 538, 547, 543, 562, 505 ]
    - [ 688, 542, 517, 501, 533, 516, 520, 526, 512, 519 ]
    - [ 727, 539, 544, 534, 557, 564, 532, 521, 528, 527 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 1751, 924, 949, 1043, 857, 854, 870, 863, 856, 812 ]
    - [ 1803, 886, 966, 885, 911, 874, 823, 859, 866, 865 ]
    - [ 1907, 918, 967, 956, 902, 879, 865, 848, 863, 862 ]
    - [ 1803, 851, 931, 895, 866, 871, 868, 899, 850, 855 ]
    - [ 1753, 1089, 840, 915, 924, 877, 884, 910, 864, 874 ]
    - [ 1714, 905, 960, 881, 905, 868, 875, 867, 871, 875 ]
    - [ 1740, 964, 967, 890, 904, 858, 854, 876, 865, 875 ]
    - [ 1728, 880, 939, 1023, 928, 886, 892, 880, 838, 847 ]
    - [ 1768, 1058, 990, 876, 906, 845, 839, 860, 865, 868 ]
    - [ 1779, 918, 968, 882, 931, 884, 882, 805, 820, 871 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 668, 540, 513, 525, 532, 523, 522, 529, 440, 522 ]
    - [ 697, 563, 529, 521, 536, 523, 525, 541, 532, 492 ]
    - [ 718, 551, 524, 486, 510, 528, 507, 541, 526, 527 ]
    - [ 702, 543, 525, 524, 545, 531, 461, 549, 560, 544 ]
    - [ 675, 583, 478, 499, 540, 529, 477, 555, 527, 545 ]
    - [ 692, 564, 527, 515, 536, 532, 530, 525, 526, 534 ]
    - [ 697, 549, 537, 500, 544, 537, 531, 530, 507, 536 ]
    - [ 690, 538, 548, 513, 524, 516, 525, 517, 518, 499 ]
    - [ 697, 565, 532, 508, 538, 539, 533, 460, 533, 531 ]
    - [ 687, 527, 529, 522, 530, 550, 454, 524, 535, 522 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 754, 582, 569, 598, 584, 598, 523, 606, 588, 502 ]
    - [ 741, 633, 593, 524, 609, 601, 562, 600, 558, 566 ]
    - [ 693, 582, 565, 516, 603, 588, 568, 596, 599, 508 ]
    - [ 746, 524, 625, 589, 595, 594, 598, 555, 598, 567 ]
    - [ 742, 569, 583, 527, 587, 570, 581, 598, 607, 567 ]
    - [ 739, 607, 593, 592, 585, 578, 598, 600, 606, 620 ]
    - [ 744, 609, 626, 606, 587, 610, 613, 602, 574, 585 ]
    - [ 734, 594, 601, 628, 606, 586, 599, 586, 521, 579 ]
    - [ 776, 576, 547, 604, 590, 589, 602, 611, 514, 582 ]
    - [ 749, 619, 589, 601, 603, 611, 566, 590, 606, 507 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1088, 958, 985, 960, 935, 881, 865, 929, 903, 926 ]
    - [ 1086, 953, 950, 974, 987, 980, 988, 979, 983, 975 ]
    - [ 1047, 939, 940, 969, 968, 956, 929, 916, 927, 925 ]
    - [ 1125, 964, 1016, 990, 980, 960, 953, 968, 905, 947 ]
    - [ 1139, 1001, 969, 937, 933, 870, 871, 932, 920, 945 ]
    - [ 1131, 955, 997, 886, 948, 968, 962, 972, 954, 976 ]
    - [ 1101, 989, 986, 971, 920, 923, 931, 936, 945, 959 ]
    - [ 1113, 930, 1006, 956, 969, 895, 967, 946, 906, 940 ]
    - [ 1141, 958, 921, 965, 953, 914, 973, 944, 957, 961 ]
    - [ 1096, 956, 992, 966, 972, 982, 919, 954, 944, 863 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 2054, 2136, 2056, 2062, 1934, 2020, 2052, 1953, 1959, 1846 ]
    - [ 2083, 2095, 2044, 2124, 2074, 2124, 2059, 2059, 2120, 2069 ]
    - [ 2124, 2040, 2045, 2048, 2022, 1949, 1957, 2018, 2009, 1940 ]
    - [ 2234, 2257, 2244, 2227, 2228, 2228, 2142, 2255, 2236, 2250 ]
    - [ 2204, 2159, 2095, 2320, 2249, 2150, 2145, 2108, 2233, 2229 ]
    - [ 2062, 2049, 2050, 2087, 2066, 2147, 2127, 2141, 1949, 2089 ]
    - [ 2228, 2228, 2106, 2247, 2039, 2133, 2046, 2129, 2136, 2132 ]
    - [ 2134, 2004, 2109, 2074, 2133, 2133, 2144, 2120, 2129, 2056 ]
    - [ 2131, 2066, 2078, 2113, 2125, 2133, 2135, 2024, 2131, 2104 ]
    - [ 2233, 2263, 2245, 2261, 2126, 2159, 2217, 2153, 2245, 2228 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 1228, 1168, 1041, 1007, 1026, 966, 1019, 920, 923, 1012 ]
    - [ 1326, 1118, 1001, 1009, 1041, 1022, 1007, 996, 1005, 1047 ]
    - [ 1413, 1120, 932, 1017, 1033, 1023, 1014, 1032, 1022, 1030 ]
    - [ 1389, 1023, 1071, 997, 1020, 1031, 1030, 951, 973, 973 ]
    - [ 1383, 1267, 1001, 1038, 979, 1012, 1006, 982, 1094, 972 ]
    - [ 1465, 1106, 1033, 1038, 1017, 1030, 1106, 1027, 1003, 1011 ]
    - [ 1320, 1249, 1043, 1000, 972, 1059, 949, 971, 1000, 1014 ]
    - [ 1254, 1082, 1005, 1061, 1055, 1036, 1028, 1046, 1022, 1069 ]
    - [ 1167, 1151, 994, 966, 1004, 963, 985, 933, 972, 1008 ]
    - [ 1342, 1302, 1078, 1048, 955, 1138, 1110, 987, 941, 981 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 15178, 15169, 15155, 15197, 15161 ]
    - [ 15129, 15140, 15078, 15094, 15152 ]
    - [ 15249, 15287, 15231, 15219, 15250 ]
    - [ 15229, 15223, 15235, 15229, 15218 ]
    - [ 15201, 15145, 15118, 15147, 15185 ]
    - [ 15194, 15184, 15149, 15189, 15174 ]
    - [ 15232, 15168, 15140, 15142, 15205 ]
    - [ 15179, 15148, 15126, 15161, 15167 ]
    - [ 15151, 15145, 15143, 15081, 15164 ]
    - [ 15154, 15172, 15144, 15154, 15140 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 1438, 1557, 1533, 1402, 1399 ]
    - [ 1447, 1553, 1400, 1373, 1409 ]
    - [ 1439, 1582, 1563, 1528, 1527 ]
    - [ 1434, 1541, 1508, 1488, 1533 ]
    - [ 1477, 1586, 1557, 1551, 1568 ]
    - [ 1462, 1600, 1541, 1541, 1538 ]
    - [ 1419, 1607, 1500, 1511, 1525 ]
    - [ 1425, 1594, 1512, 1510, 1530 ]
    - [ 1442, 1638, 1521, 1520, 1526 ]
    - [ 1420, 1555, 1499, 1490, 1509 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1419, 1413, 1388, 1379, 1409 ]
    - [ 1416, 1435, 1393, 1427, 1433 ]
    - [ 1409, 1407, 1407, 1402, 1414 ]
    - [ 1411, 1386, 1377, 1377, 1388 ]
    - [ 1434, 1411, 1400, 1387, 1384 ]
    - [ 1408, 1389, 1372, 1379, 1390 ]
    - [ 1415, 1390, 1385, 1376, 1366 ]
    - [ 1416, 1394, 1392, 1382, 1393 ]
    - [ 1418, 1393, 1387, 1387, 1390 ]
    - [ 1415, 1395, 1415, 1398, 1390 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 1594, 2546, 1122, 1127, 1126 ]
    - [ 1535, 2527, 1067, 1064, 1067 ]
    - [ 1578, 2516, 1101, 1097, 1096 ]
    - [ 1592, 2571, 1116, 1113, 1125 ]
    - [ 1590, 2530, 1079, 1067, 1085 ]
    - [ 1575, 2568, 1075, 1048, 1060 ]
    - [ 1567, 2508, 1069, 1058, 1040 ]
    - [ 1612, 2509, 1086, 1071, 1089 ]
    - [ 1598, 2496, 1080, 1073, 1069 ]
    - [ 1537, 2485, 1058, 1097, 1084 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 771, 637, 614, 622, 593 ]
    - [ 842, 634, 630, 615, 608 ]
    - [ 788, 613, 583, 620, 612 ]
    - [ 827, 660, 626, 591, 622 ]
    - [ 773, 670, 533, 563, 609 ]
    - [ 770, 584, 566, 570, 597 ]
    - [ 821, 631, 607, 577, 573 ]
    - [ 834, 672, 620, 639, 591 ]
    - [ 789, 602, 583, 573, 584 ]
    - [ 793, 679, 634, 609, 622 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1037, 823, 771, 763, 744 ]
    - [ 1198, 795, 764, 761, 766 ]
    - [ 1191, 792, 764, 755, 739 ]
    - [ 1090, 798, 757, 763, 764 ]
    - [ 1194, 888, 798, 777, 800 ]
    - [ 1037, 832, 742, 787, 776 ]
    - [ 1107, 828, 791, 775, 761 ]
    - [ 1124, 812, 796, 759, 771 ]
    - [ 1079, 816, 792, 767, 751 ]
    - [ 1156, 847, 783, 786, 737 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 805, 634, 616, 597, 606 ]
    - [ 792, 645, 609, 622, 607 ]
    - [ 829, 634, 629, 622, 616 ]
    - [ 779, 636, 551, 594, 617 ]
    - [ 728, 590, 579, 606, 577 ]
    - [ 802, 625, 615, 594, 600 ]
    - [ 761, 607, 600, 567, 589 ]
    - [ 814, 652, 607, 614, 612 ]
    - [ 737, 581, 565, 590, 600 ]
    - [ 803, 641, 563, 612, 645 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 892, 703, 638, 639, 610 ]
    - [ 966, 725, 703, 674, 683 ]
    - [ 935, 717, 641, 667, 671 ]
    - [ 889, 714, 663, 652, 675 ]
    - [ 870, 632, 636, 646, 681 ]
    - [ 906, 641, 636, 674, 628 ]
    - [ 914, 764, 643, 701, 687 ]
    - [ 892, 704, 657, 646, 629 ]
    - [ 919, 644, 629, 620, 587 ]
    - [ 898, 689, 654, 644, 666 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 657, 528, 521, 491, 509 ]
    - [ 676, 531, 499, 483, 497 ]
    - [ 662, 550, 537, 545, 543 ]
    - [ 695, 545, 512, 492, 506 ]
    - [ 682, 544, 444, 502, 509 ]
    - [ 692, 507, 534, 498, 503 ]
    - [ 674, 565, 525, 534, 516 ]
    - [ 733, 613, 544, 569, 539 ]
    - [ 709, 559, 537, 456, 532 ]
    - [ 654, 495, 445, 442, 497 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 1232, 1036, 996, 1008, 1013, 992, 992, 1020, 1007, 999 ]
    - [ 1166, 1042, 1019, 977, 995, 963, 974, 1001, 990, 981 ]
    - [ 1211, 1049, 1017, 1026, 1015, 1013, 1016, 1017, 1009, 997 ]
    - [ 1198, 1032, 1053, 1022, 1022, 1010, 1000, 988, 1021, 1004 ]
    - [ 1153, 1027, 991, 978, 984, 1006, 987, 984, 979, 984 ]
    - [ 1228, 1039, 1008, 1028, 1023, 1001, 1008, 1027, 993, 996 ]
    - [ 1230, 1051, 1024, 1027, 1024, 1002, 1000, 1030, 1018, 1016 ]
    - [ 1183, 1038, 1020, 1016, 1003, 1002, 993, 988, 997, 1016 ]
    - [ 1208, 1047, 1019, 1047, 1025, 1028, 1005, 1016, 1005, 1011 ]
    - [ 1189, 1029, 1033, 1024, 1010, 1016, 999, 998, 1016, 991 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 2789, 1407, 1422, 1280, 1330, 1276, 1268, 1256, 1182, 1178 ]
    - [ 2541, 1548, 1356, 1243, 1287, 1250, 1237, 1232, 1228, 1226 ]
    - [ 2513, 1326, 1419, 1280, 1313, 1275, 1252, 1244, 1240, 1249 ]
    - [ 2610, 1322, 1414, 1283, 1303, 1253, 1247, 1236, 1238, 1258 ]
    - [ 2576, 1353, 1681, 1267, 1321, 1206, 1147, 1214, 1161, 1133 ]
    - [ 2559, 1352, 1413, 1468, 1327, 1346, 1245, 1227, 1234, 1267 ]
    - [ 2651, 1500, 1429, 1238, 1288, 1262, 1249, 1235, 1241, 1241 ]
    - [ 2653, 1285, 1421, 1304, 1296, 1246, 1244, 1234, 1234, 1235 ]
    - [ 2667, 1318, 1383, 1234, 1282, 1253, 1226, 1227, 1236, 1232 ]
    - [ 2431, 1630, 1398, 1336, 1332, 1279, 1293, 1260, 1225, 1238 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 2792, 2437, 2438, 2454, 2462, 2424, 2423, 2456, 2429, 2491 ]
    - [ 2692, 2488, 2473, 2487, 2467, 2495, 2433, 2415, 2408, 2390 ]
    - [ 2653, 2424, 2470, 2498, 2483, 2480, 2438, 2394, 2396, 2455 ]
    - [ 2713, 2387, 2445, 2472, 2493, 2474, 2461, 2463, 2490, 2491 ]
    - [ 2688, 2454, 2459, 2402, 2423, 2431, 2389, 2404, 2400, 2467 ]
    - [ 2629, 2407, 2441, 2471, 2486, 2508, 2499, 2502, 2498, 2484 ]
    - [ 2778, 2454, 2448, 2442, 2469, 2489, 2432, 2436, 2447, 2489 ]
    - [ 2700, 2487, 2484, 2458, 2454, 2435, 2423, 2445, 2445, 2534 ]
    - [ 2729, 2473, 2435, 2436, 2458, 2474, 2499, 2461, 2460, 2436 ]
    - [ 2730, 2476, 2409, 2431, 2409, 2436, 2429, 2465, 2466, 2511 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 3988, 2306, 2181, 2190, 2177, 2151, 2141, 2099, 2076, 2079 ]
    - [ 4003, 2334, 2575, 2238, 2242, 2228, 2266, 2180, 2108, 2152 ]
    - [ 3880, 2332, 2195, 2231, 2082, 2150, 2118, 2103, 2110, 2124 ]
    - [ 4099, 2244, 2284, 2158, 2216, 2197, 2207, 2131, 2115, 2139 ]
    - [ 4267, 2281, 2300, 2176, 2145, 2183, 2160, 2110, 2142, 2114 ]
    - [ 3931, 2280, 2291, 2182, 2235, 2131, 2102, 2131, 2096, 2107 ]
    - [ 3944, 2228, 2290, 2198, 2185, 2146, 2139, 2073, 2133, 2094 ]
    - [ 3951, 2286, 2170, 2119, 2228, 2154, 2131, 2120, 2075, 2096 ]
    - [ 3905, 2229, 2396, 2191, 2170, 2171, 2106, 2106, 2097, 2102 ]
    - [ 3905, 2239, 2196, 2138, 2157, 2108, 2085, 2036, 2094, 2061 ]
