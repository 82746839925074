import * as React from "react";
import {NavLink} from "react-router";

type BMNavProps = {
    bm: string
}

export function BMNav(props: BMNavProps) {
    return (<div className={"flex gap-1"}>
        <NavLink to={`/benchmark/${props.bm}`}
                 className={({isActive}) => isActive ? "font-bold underline" : "underline"}
                 end
        >Nominal</NavLink>
        <NavLink to={`/benchmark/${props.bm}/perf`}
                 className={({isActive}) => isActive ? "font-bold underline" : "underline"}
                 end
        >Perf</NavLink>
    </div>);
}