#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 2192, 2247, 1843, 2249, 1896, 2555, 1640, 2562, 2195, 2965 ]
    - [ 2002, 1445, 1727, 1750, 1628, 1662, 1439, 1915, 1443, 1511 ]
    - [ 2022, 1774, 1790, 1843, 2336, 1599, 1881, 1959, 1828, 2062 ]
    - [ 1580, 1681, 1762, 1722, 1912, 1555, 1372, 1686, 1391, 1802 ]
    - [ 2008, 1756, 2006, 1753, 2371, 2003, 1843, 1862, 2724, 1546 ]
    - [ 1684, 1387, 1637, 1687, 1437, 1702, 1952, 1736, 1593, 1851 ]
    - [ 1987, 2340, 2372, 2051, 2341, 2002, 1684, 2253, 2739, 1698 ]
    - [ 1929, 1916, 1780, 1809, 1736, 1605, 1629, 1795, 1445, 1513 ]
    - [ 1638, 1620, 1585, 1537, 1516, 1382, 1520, 1818, 1661, 1502 ]
    - [ 2174, 1830, 2028, 1913, 1790, 2031, 2004, 1948, 2161, 1821 ]
  2.0:
    - [ 751, 519, 466, 498, 495, 492, 503, 496, 500, 504 ]
    - [ 677, 491, 486, 482, 480, 480, 478, 476, 477, 482 ]
    - [ 667, 487, 480, 483, 482, 478, 477, 478, 487, 498 ]
    - [ 725, 511, 507, 510, 507, 500, 500, 495, 493, 500 ]
    - [ 700, 484, 455, 499, 496, 499, 493, 491, 480, 490 ]
    - [ 665, 508, 505, 497, 500, 497, 497, 492, 498, 498 ]
    - [ 688, 500, 496, 486, 493, 492, 495, 493, 494, 497 ]
    - [ 680, 517, 501, 505, 504, 499, 499, 498, 496, 503 ]
    - [ 710, 503, 508, 503, 503, 497, 499, 497, 499, 504 ]
    - [ 716, 504, 497, 494, 493, 496, 501, 495, 501, 505 ]
  3.0:
    - [ 676, 499, 483, 476, 476, 467, 483, 473, 470, 482 ]
    - [ 646, 498, 471, 462, 457, 449, 456, 456, 453, 455 ]
    - [ 660, 483, 480, 478, 466, 461, 462, 461, 436, 465 ]
    - [ 681, 486, 462, 465, 463, 461, 458, 463, 455, 446 ]
    - [ 676, 490, 489, 486, 479, 478, 472, 480, 479, 477 ]
    - [ 697, 479, 477, 469, 481, 475, 472, 466, 463, 475 ]
    - [ 686, 504, 500, 499, 491, 489, 485, 490, 483, 487 ]
    - [ 644, 493, 469, 470, 470, 472, 471, 464, 466, 465 ]
    - [ 672, 495, 479, 479, 478, 476, 472, 470, 476, 479 ]
    - [ 698, 498, 478, 481, 477, 479, 471, 481, 467, 447 ]
  4.0:
    - [ 658, 474, 465, 468, 463, 466, 431, 465, 460, 469 ]
    - [ 675, 467, 462, 457, 452, 449, 455, 449, 447, 451 ]
    - [ 675, 450, 420, 455, 458, 457, 455, 453, 460, 462 ]
    - [ 620, 468, 460, 464, 467, 468, 461, 463, 466, 456 ]
    - [ 681, 468, 464, 456, 469, 460, 461, 467, 457, 418 ]
    - [ 688, 466, 461, 449, 459, 452, 452, 457, 451, 449 ]
    - [ 658, 457, 419, 464, 462, 466, 468, 469, 460, 469 ]
    - [ 652, 477, 471, 472, 463, 460, 464, 468, 462, 465 ]
    - [ 645, 468, 447, 446, 452, 445, 442, 439, 404, 447 ]
    - [ 678, 467, 454, 452, 459, 463, 454, 456, 450, 459 ]
  5.0:
    - [ 645, 463, 456, 456, 455, 451, 449, 455, 452, 455 ]
    - [ 642, 464, 425, 461, 457, 461, 454, 462, 458, 454 ]
    - [ 660, 475, 465, 469, 468, 472, 464, 435, 461, 466 ]
    - [ 595, 471, 465, 462, 460, 461, 456, 452, 461, 458 ]
    - [ 630, 452, 438, 434, 435, 436, 440, 433, 430, 440 ]
    - [ 651, 422, 458, 452, 450, 454, 449, 418, 448, 439 ]
    - [ 636, 450, 440, 446, 443, 437, 442, 402, 437, 438 ]
    - [ 619, 451, 409, 445, 436, 436, 439, 437, 442, 434 ]
    - [ 659, 461, 470, 464, 451, 449, 453, 462, 457, 460 ]
    - [ 634, 470, 468, 462, 463, 468, 464, 464, 463, 465 ]
  6.0:
    - [ 648, 451, 415, 450, 448, 446, 439, 446, 441, 445 ]
    - [ 669, 451, 462, 442, 440, 441, 440, 403, 440, 446 ]
    - [ 661, 467, 423, 458, 458, 456, 460, 454, 452, 457 ]
    - [ 688, 464, 436, 459, 463, 448, 422, 461, 418, 462 ]
    - [ 661, 471, 473, 467, 476, 469, 465, 462, 463, 472 ]
    - [ 673, 470, 462, 458, 463, 454, 456, 456, 455, 464 ]
    - [ 646, 454, 453, 443, 450, 443, 450, 438, 441, 445 ]
    - [ 680, 476, 468, 472, 465, 464, 430, 459, 452, 466 ]
    - [ 660, 473, 469, 471, 465, 466, 465, 470, 466, 462 ]
    - [ 655, 465, 463, 461, 461, 460, 425, 465, 457, 459 ]
  7.0:
    - [ 674, 477, 459, 470, 464, 462, 469, 465, 458, 468 ]
    - [ 691, 446, 468, 474, 476, 468, 477, 472, 463, 475 ]
    - [ 658, 464, 452, 417, 455, 449, 454, 441, 447, 417 ]
    - [ 624, 452, 406, 451, 442, 432, 444, 404, 435, 432 ]
    - [ 666, 420, 452, 456, 446, 447, 446, 449, 451, 455 ]
    - [ 634, 451, 471, 465, 473, 463, 459, 463, 458, 462 ]
    - [ 626, 466, 449, 451, 454, 455, 452, 446, 447, 449 ]
    - [ 625, 460, 428, 462, 461, 456, 452, 451, 458, 456 ]
    - [ 638, 445, 441, 440, 444, 433, 434, 439, 430, 435 ]
    - [ 652, 450, 408, 441, 441, 434, 431, 433, 434, 441 ]
  8.0:
    - [ 620, 458, 446, 450, 448, 446, 415, 439, 441, 443 ]
    - [ 662, 447, 452, 463, 458, 457, 426, 459, 453, 460 ]
    - [ 652, 464, 472, 467, 460, 459, 456, 422, 453, 463 ]
    - [ 676, 473, 472, 467, 459, 470, 468, 460, 457, 471 ]
    - [ 626, 445, 435, 439, 448, 440, 445, 442, 437, 440 ]
    - [ 622, 407, 439, 439, 429, 435, 427, 432, 423, 429 ]
    - [ 621, 482, 490, 475, 474, 468, 477, 471, 471, 472 ]
    - [ 647, 453, 449, 441, 440, 439, 414, 437, 432, 438 ]
    - [ 636, 463, 454, 459, 461, 461, 459, 469, 460, 461 ]
    - [ 661, 465, 452, 457, 455, 445, 449, 453, 453, 449 ]
  9.0:
    - [ 649, 458, 462, 446, 446, 433, 439, 438, 431, 436 ]
    - [ 530, 469, 460, 462, 456, 454, 454, 454, 423, 454 ]
    - [ 660, 472, 459, 456, 462, 463, 461, 458, 461, 458 ]
    - [ 621, 447, 443, 449, 443, 443, 445, 438, 442, 441 ]
    - [ 625, 460, 459, 454, 451, 443, 449, 411, 435, 447 ]
    - [ 670, 467, 472, 468, 461, 452, 454, 452, 452, 459 ]
    - [ 630, 459, 444, 443, 444, 455, 454, 450, 440, 453 ]
    - [ 632, 456, 447, 450, 444, 436, 441, 442, 442, 436 ]
    - [ 674, 453, 429, 460, 457, 452, 453, 462, 456, 451 ]
    - [ 647, 445, 433, 436, 444, 440, 408, 426, 435, 403 ]
  10.0:
    - [ 667, 463, 452, 452, 466, 441, 454, 450, 455, 457 ]
    - [ 656, 465, 457, 447, 461, 457, 425, 462, 453, 454 ]
    - [ 680, 465, 426, 462, 465, 457, 458, 459, 452, 458 ]
    - [ 653, 444, 407, 442, 443, 438, 441, 443, 439, 449 ]
    - [ 660, 474, 451, 457, 458, 447, 456, 448, 452, 455 ]
    - [ 671, 452, 462, 448, 455, 440, 437, 409, 442, 445 ]
    - [ 666, 462, 463, 464, 456, 446, 411, 422, 439, 451 ]
    - [ 669, 452, 451, 443, 450, 441, 442, 447, 446, 451 ]
    - [ 684, 474, 466, 455, 447, 447, 454, 454, 452, 449 ]
    - [ 682, 459, 453, 456, 454, 459, 453, 449, 445, 450 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 3292, 1720, 1363, 1511, 1318, 1400, 1342, 1281, 1377, 1299 ]
    - [ 3432, 1853, 1344, 1701, 1295, 1231, 1315, 1301, 1294, 1173 ]
    - [ 3593, 1639, 1326, 1299, 1203, 1346, 1284, 1286, 1394, 1294 ]
    - [ 3259, 1769, 1325, 1497, 1211, 1323, 1303, 1281, 1345, 1303 ]
    - [ 3313, 1638, 1332, 1338, 1269, 1365, 1296, 1269, 1365, 1262 ]
    - [ 3419, 1602, 1335, 1265, 1234, 1342, 1320, 1338, 1372, 1285 ]
    - [ 3270, 1545, 1316, 1471, 1346, 1354, 1280, 1286, 1239, 1262 ]
    - [ 3444, 1636, 1293, 1328, 1321, 1245, 1281, 1278, 1390, 1298 ]
    - [ 3372, 1712, 1330, 1349, 1304, 1362, 1287, 1320, 1395, 1276 ]
    - [ 3424, 1531, 1323, 1347, 1296, 1364, 1298, 1179, 1373, 1328 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 625, 391, 443, 443, 433, 438, 436, 439, 432, 434 ]
    - [ 619, 438, 445, 450, 427, 439, 440, 438, 439, 391 ]
    - [ 656, 458, 413, 448, 452, 449, 449, 439, 447, 446 ]
    - [ 589, 424, 392, 426, 433, 438, 425, 428, 418, 427 ]
    - [ 619, 447, 441, 433, 431, 435, 428, 383, 434, 434 ]
    - [ 633, 448, 444, 439, 429, 445, 428, 400, 440, 437 ]
    - [ 634, 444, 396, 416, 440, 444, 437, 448, 442, 443 ]
    - [ 599, 450, 449, 448, 447, 439, 445, 451, 449, 445 ]
    - [ 655, 450, 437, 446, 447, 439, 432, 440, 440, 437 ]
    - [ 619, 447, 454, 447, 441, 440, 435, 442, 442, 416 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 676, 522, 517, 513, 513, 511, 507, 505, 460, 514 ]
    - [ 689, 522, 507, 514, 493, 503, 504, 510, 499, 507 ]
    - [ 689, 520, 503, 505, 508, 503, 501, 504, 491, 502 ]
    - [ 653, 510, 513, 515, 512, 505, 513, 511, 512, 516 ]
    - [ 692, 511, 464, 517, 516, 513, 520, 510, 507, 513 ]
    - [ 661, 518, 516, 517, 515, 516, 518, 498, 498, 513 ]
    - [ 727, 529, 524, 522, 522, 516, 521, 508, 510, 517 ]
    - [ 660, 510, 515, 516, 508, 515, 499, 503, 503, 499 ]
    - [ 677, 509, 502, 498, 500, 501, 501, 491, 503, 504 ]
    - [ 679, 517, 510, 509, 511, 509, 497, 502, 502, 500 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1265, 923, 905, 882, 859, 849, 828, 846, 804, 862 ]
    - [ 1187, 944, 907, 836, 838, 839, 824, 815, 782, 822 ]
    - [ 1114, 928, 894, 883, 849, 834, 855, 846, 834, 852 ]
    - [ 1208, 938, 900, 877, 866, 863, 829, 846, 803, 850 ]
    - [ 1135, 959, 886, 855, 840, 818, 850, 834, 810, 853 ]
    - [ 1230, 951, 922, 911, 877, 869, 859, 827, 848, 836 ]
    - [ 1140, 931, 941, 858, 857, 861, 848, 851, 855, 855 ]
    - [ 1194, 932, 924, 867, 847, 844, 863, 844, 844, 850 ]
    - [ 1239, 960, 893, 839, 856, 853, 829, 853, 844, 832 ]
    - [ 1236, 951, 901, 851, 843, 861, 839, 855, 831, 828 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 4911, 3511, 3512, 3462, 3447, 3448, 3460, 3469, 3620, 3606 ]
    - [ 3748, 4264, 4136, 3316, 3300, 3301, 3190, 3306, 3628, 3617 ]
    - [ 4661, 4393, 4062, 3323, 3329, 3313, 3168, 3590, 3608, 3616 ]
    - [ 4044, 4217, 3557, 3466, 3352, 3467, 3351, 3498, 3618, 3630 ]
    - [ 4363, 4234, 4110, 3315, 3176, 3285, 3339, 3600, 3616, 3627 ]
    - [ 4140, 4151, 4513, 3331, 3326, 3314, 3334, 3483, 3586, 3593 ]
    - [ 4261, 3342, 3808, 3315, 3326, 3300, 3322, 3312, 3602, 3630 ]
    - [ 3635, 3623, 3899, 3188, 3314, 3323, 3196, 3179, 3606, 3599 ]
    - [ 4191, 4160, 3928, 3346, 3457, 3464, 3471, 3791, 3672, 3439 ]
    - [ 3876, 4292, 4401, 3437, 3301, 3310, 3458, 3603, 3720, 3616 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 2275, 1780, 1420, 1486, 1450, 1441, 1405, 1188, 1171, 1124 ]
    - [ 2293, 1842, 1524, 1552, 1495, 1485, 1536, 1187, 1169, 1132 ]
    - [ 2326, 1581, 1410, 1448, 1497, 1470, 1641, 1214, 1268, 1249 ]
    - [ 2139, 1769, 1535, 1542, 1411, 1442, 1435, 1356, 1242, 1193 ]
    - [ 2123, 1620, 1454, 1357, 1440, 1444, 1370, 1429, 1318, 1394 ]
    - [ 2270, 1836, 1558, 1566, 1532, 1501, 1449, 1238, 1203, 1280 ]
    - [ 2300, 1616, 1478, 1497, 1449, 1590, 1466, 1222, 1265, 1260 ]
    - [ 2338, 1773, 1526, 1596, 1604, 1521, 1444, 1251, 1241, 1298 ]
    - [ 1915, 1355, 1322, 1235, 1242, 1327, 1270, 1196, 1241, 1313 ]
    - [ 2138, 1582, 1405, 1352, 1336, 1323, 1266, 1332, 1195, 1199 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 9601, 9544, 9578, 9472, 9546 ]
    - [ 9706, 9605, 9554, 9547, 9536 ]
    - [ 9643, 9586, 9589, 9606, 9539 ]
    - [ 9599, 9552, 9564, 9526, 9586 ]
    - [ 9586, 9619, 9537, 9582, 9595 ]
    - [ 9578, 9584, 9757, 9644, 9540 ]
    - [ 9765, 9567, 9553, 9563, 9525 ]
    - [ 9569, 9578, 9522, 9673, 9586 ]
    - [ 9543, 9535, 9483, 9578, 9512 ]
    - [ 9616, 9652, 9579, 9600, 9576 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 1297, 1107, 1115, 1130, 1094 ]
    - [ 1287, 1108, 1113, 1080, 1115 ]
    - [ 1274, 1160, 1106, 1146, 1133 ]
    - [ 1266, 1104, 1110, 1087, 1087 ]
    - [ 1303, 1112, 1093, 1090, 1096 ]
    - [ 1257, 1096, 985, 956, 961 ]
    - [ 1268, 1112, 1081, 976, 968 ]
    - [ 1277, 1105, 1106, 1112, 1090 ]
    - [ 1278, 1126, 1102, 1120, 1096 ]
    - [ 1273, 1086, 1094, 1082, 1084 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 969, 937, 956, 950, 972 ]
    - [ 956, 925, 920, 935, 943 ]
    - [ 972, 952, 952, 959, 964 ]
    - [ 968, 941, 955, 943, 950 ]
    - [ 958, 936, 922, 919, 938 ]
    - [ 968, 949, 937, 942, 964 ]
    - [ 952, 932, 922, 928, 941 ]
    - [ 977, 946, 951, 952, 960 ]
    - [ 957, 943, 940, 928, 945 ]
    - [ 990, 955, 949, 951, 955 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 3437, 1217, 769, 784, 775 ]
    - [ 3537, 1034, 772, 781, 852 ]
    - [ 3440, 1112, 767, 778, 773 ]
    - [ 3558, 840, 783, 780, 795 ]
    - [ 3507, 798, 756, 761, 775 ]
    - [ 3431, 894, 768, 768, 776 ]
    - [ 3532, 810, 779, 771, 773 ]
    - [ 3542, 817, 770, 769, 749 ]
    - [ 3525, 1082, 839, 786, 782 ]
    - [ 3446, 798, 763, 756, 842 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 741, 522, 517, 520, 512 ]
    - [ 749, 520, 496, 500, 454 ]
    - [ 771, 523, 512, 488, 517 ]
    - [ 737, 531, 476, 521, 505 ]
    - [ 776, 520, 514, 503, 482 ]
    - [ 762, 526, 524, 515, 476 ]
    - [ 723, 526, 524, 469, 515 ]
    - [ 752, 531, 500, 500, 504 ]
    - [ 802, 538, 504, 470, 460 ]
    - [ 754, 521, 512, 508, 513 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1090, 734, 683, 688, 699 ]
    - [ 1126, 706, 678, 673, 685 ]
    - [ 1117, 717, 695, 700, 713 ]
    - [ 1111, 719, 687, 686, 689 ]
    - [ 1190, 726, 694, 694, 704 ]
    - [ 1089, 710, 698, 697, 701 ]
    - [ 1150, 711, 698, 714, 699 ]
    - [ 1121, 699, 684, 692, 697 ]
    - [ 1131, 703, 690, 687, 711 ]
    - [ 1171, 723, 706, 694, 712 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 746, 508, 496, 496, 453 ]
    - [ 752, 525, 503, 500, 507 ]
    - [ 651, 511, 489, 487, 491 ]
    - [ 683, 522, 471, 507, 510 ]
    - [ 720, 508, 495, 516, 500 ]
    - [ 691, 540, 510, 515, 512 ]
    - [ 673, 512, 500, 508, 503 ]
    - [ 723, 503, 504, 511, 519 ]
    - [ 726, 507, 507, 498, 502 ]
    - [ 740, 525, 507, 501, 506 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 794, 544, 523, 543, 534 ]
    - [ 822, 534, 520, 519, 520 ]
    - [ 813, 545, 517, 519, 470 ]
    - [ 813, 552, 548, 536, 539 ]
    - [ 847, 552, 548, 486, 553 ]
    - [ 769, 534, 545, 524, 543 ]
    - [ 798, 549, 539, 538, 542 ]
    - [ 814, 561, 560, 554, 572 ]
    - [ 805, 532, 520, 523, 523 ]
    - [ 796, 556, 536, 530, 510 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 612, 427, 424, 423, 430 ]
    - [ 612, 427, 428, 429, 441 ]
    - [ 646, 410, 433, 415, 430 ]
    - [ 637, 428, 422, 424, 387 ]
    - [ 575, 437, 436, 435, 432 ]
    - [ 633, 459, 440, 384, 440 ]
    - [ 636, 437, 436, 440, 433 ]
    - [ 577, 445, 426, 428, 436 ]
    - [ 641, 456, 453, 439, 443 ]
    - [ 672, 448, 397, 429, 436 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 983, 682, 673, 672, 686, 665, 661, 663, 668, 662 ]
    - [ 970, 685, 706, 672, 679, 673, 672, 669, 664, 670 ]
    - [ 1022, 678, 679, 676, 677, 662, 670, 661, 662, 665 ]
    - [ 922, 680, 670, 669, 664, 664, 666, 664, 661, 655 ]
    - [ 975, 686, 679, 679, 671, 674, 677, 673, 672, 676 ]
    - [ 986, 693, 675, 673, 676, 673, 670, 669, 673, 674 ]
    - [ 1062, 688, 674, 685, 684, 677, 666, 666, 665, 671 ]
    - [ 957, 694, 687, 682, 677, 673, 667, 659, 670, 661 ]
    - [ 996, 677, 666, 675, 667, 661, 672, 658, 654, 666 ]
    - [ 996, 698, 673, 672, 670, 671, 666, 663, 661, 664 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 4451, 2237, 1812, 1791, 1745, 1816, 1733, 1744, 1774, 1687 ]
    - [ 4716, 2096, 1917, 1833, 1800, 1813, 1752, 1787, 1818, 1715 ]
    - [ 4730, 2237, 2075, 1831, 1802, 1842, 1765, 1725, 1854, 1741 ]
    - [ 4923, 2363, 1840, 1832, 1777, 1788, 1740, 1763, 1827, 1698 ]
    - [ 4836, 2150, 1812, 1748, 1812, 1851, 1733, 1781, 1774, 1724 ]
    - [ 4672, 2268, 1827, 1831, 1779, 1874, 1784, 1576, 1834, 1713 ]
    - [ 4854, 2200, 1818, 1833, 1653, 1840, 1744, 1822, 1860, 1714 ]
    - [ 4740, 2287, 1778, 1872, 1783, 1833, 1748, 1800, 1848, 1719 ]
    - [ 4661, 2253, 1855, 1789, 1772, 1847, 1732, 1778, 1782, 1735 ]
    - [ 4885, 2333, 1837, 1872, 1648, 1664, 1758, 1858, 1811, 1712 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 1718, 1449, 1495, 1445, 1556, 1452, 1502, 1464, 1512, 1481 ]
    - [ 1663, 1492, 1477, 1489, 1520, 1491, 1527, 1463, 1503, 1469 ]
    - [ 1725, 1493, 1499, 1533, 1463, 1528, 1492, 1521, 1500, 1516 ]
    - [ 1738, 1486, 1497, 1473, 1495, 1487, 1514, 1477, 1509, 1500 ]
    - [ 1709, 1504, 1461, 1490, 1465, 1479, 1467, 1508, 1460, 1533 ]
    - [ 1720, 1471, 1511, 1447, 1506, 1436, 1470, 1482, 1460, 1508 ]
    - [ 1736, 1474, 1491, 1453, 1511, 1455, 1496, 1444, 1484, 1470 ]
    - [ 1701, 1496, 1503, 1482, 1488, 1498, 1505, 1483, 1533, 1513 ]
    - [ 1692, 1453, 1485, 1443, 1494, 1460, 1490, 1431, 1445, 1491 ]
    - [ 1717, 1479, 1461, 1470, 1462, 1497, 1470, 1492, 1461, 1464 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 8279, 3520, 3256, 3293, 3194, 3183, 3134, 3057, 3023, 3050 ]
    - [ 8214, 3758, 3447, 3299, 3252, 3256, 3346, 3157, 3236, 3214 ]
    - [ 8063, 3676, 3336, 3353, 3175, 3278, 3235, 3139, 3218, 3160 ]
    - [ 8645, 3618, 3206, 3289, 3045, 3307, 3150, 3095, 3133, 3263 ]
    - [ 8081, 3519, 3332, 3058, 3218, 3182, 3092, 3079, 3044, 3158 ]
    - [ 8165, 3797, 3322, 3278, 3146, 3282, 3179, 3127, 3205, 3137 ]
    - [ 8304, 3721, 3243, 3201, 3152, 3180, 3159, 3110, 3242, 3144 ]
    - [ 7956, 3544, 3210, 3235, 3171, 3203, 3072, 3037, 3205, 3061 ]
    - [ 7956, 3609, 3331, 3255, 3145, 3329, 3090, 3121, 3209, 3280 ]
    - [ 8618, 3920, 3281, 3261, 3102, 3157, 3156, 3077, 3136, 3100 ]
