#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 26055, 22794, 22915, 23279, 21911, 21226, 21310, 21519, 23102, 21398 ]
    - [ 29273, 23369, 22411, 22056, 21770, 22611, 21505, 21628, 22682, 21913 ]
    - [ 24826, 25135, 22166, 23707, 22812, 23232, 23731, 22638, 23452, 23050 ]
    - [ 28569, 28524, 25837, 25649, 26240, 24336, 27515, 25099, 23294, 26178 ]
    - [ 30075, 27964, 24383, 23087, 22724, 23817, 23085, 22731, 23005, 24970 ]
    - [ 29428, 28537, 24594, 21753, 22069, 22533, 22863, 21939, 21199, 23721 ]
    - [ 27813, 29062, 32617, 31169, 28194, 27619, 26757, 25372, 26939, 24882 ]
    - [ 34482, 26550, 25921, 26060, 24831, 25393, 25370, 25033, 23725, 23289 ]
    - [ 27553, 28605, 23103, 21605, 22675, 23471, 22506, 22750, 21914, 23215 ]
    - [ 28830, 25662, 23837, 23659, 23600, 24108, 23933, 22700, 23379, 24439 ]
  2.0:
    - [ 2712, 2234, 2253, 2207, 2257, 2187, 2203, 2175, 2184, 2265 ]
    - [ 2820, 2336, 2329, 2293, 2324, 2199, 2218, 2241, 2242, 2283 ]
    - [ 2854, 2305, 2225, 2168, 2196, 2211, 2217, 2200, 2181, 2256 ]
    - [ 2748, 2219, 2219, 2180, 2199, 2177, 2145, 2131, 2173, 2258 ]
    - [ 2803, 2278, 2171, 2173, 2156, 2138, 2113, 2097, 2121, 2211 ]
    - [ 2713, 2254, 2260, 2270, 2241, 2233, 2232, 2246, 2216, 2295 ]
    - [ 2729, 2235, 2243, 2217, 2280, 2238, 2231, 2238, 2243, 2317 ]
    - [ 2849, 2266, 2245, 2233, 2229, 2197, 2154, 2152, 2161, 2253 ]
    - [ 2744, 2213, 2226, 2234, 2208, 2208, 2168, 2190, 2188, 2284 ]
    - [ 2773, 2231, 2276, 2239, 2251, 2219, 2203, 2209, 2209, 2274 ]
  3.0:
    - [ 1890, 1345, 1331, 1307, 1341, 1366, 1339, 1337, 1343, 1373 ]
    - [ 1890, 1319, 1299, 1306, 1300, 1278, 1273, 1284, 1333, 1387 ]
    - [ 1898, 1309, 1292, 1335, 1309, 1329, 1343, 1344, 1327, 1337 ]
    - [ 1920, 1351, 1321, 1858, 1346, 1345, 1395, 1343, 1362, 1359 ]
    - [ 1901, 1317, 1309, 1316, 1475, 1351, 1392, 1353, 1367, 1351 ]
    - [ 1936, 1452, 1343, 1334, 1329, 1458, 1343, 1341, 1341, 1374 ]
    - [ 1905, 1351, 1329, 1338, 1298, 1310, 1310, 1318, 1332, 1334 ]
    - [ 1883, 1352, 1301, 1343, 1361, 1353, 1354, 1354, 1375, 1391 ]
    - [ 1920, 1400, 1345, 1342, 1345, 1359, 1338, 2250, 1347, 1487 ]
    - [ 1918, 1350, 1312, 1344, 1356, 1335, 1354, 1359, 1363, 1367 ]
  4.0:
    - [ 1752, 1229, 1216, 2134, 1168, 1175, 1178, 1180, 1175, 1192 ]
    - [ 1759, 1235, 1207, 2129, 1173, 1201, 1180, 1187, 2135, 1191 ]
    - [ 1831, 1226, 1211, 1200, 2180, 1245, 1191, 1681, 1184, 1212 ]
    - [ 1778, 1241, 2150, 2151, 2128, 1178, 1197, 1196, 1192, 1212 ]
    - [ 1755, 1237, 1198, 1206, 1210, 1181, 1214, 1189, 1201, 1222 ]
    - [ 1817, 1215, 1195, 1195, 1187, 1189, 1292, 1190, 1184, 1201 ]
    - [ 1760, 1234, 1210, 1266, 1198, 1200, 1205, 2124, 1191, 2239 ]
    - [ 1768, 1238, 1201, 1220, 2129, 1193, 1194, 1184, 1196, 1216 ]
    - [ 1827, 1546, 1203, 1204, 1192, 1202, 1201, 1201, 1205, 2146 ]
    - [ 1753, 1243, 1198, 2175, 1191, 1215, 1191, 1192, 1194, 1222 ]
  5.0:
    - [ 1729, 1130, 1107, 1099, 1106, 1096, 1110, 1113, 1090, 1107 ]
    - [ 2535, 1118, 1116, 1105, 1101, 2055, 2027, 2011, 1114, 1124 ]
    - [ 1746, 1128, 1112, 1121, 1132, 1109, 1114, 1105, 1113, 1126 ]
    - [ 1715, 1143, 1114, 2032, 1121, 1109, 1180, 1109, 2016, 1128 ]
    - [ 1763, 1125, 1104, 1095, 1100, 1093, 1095, 1098, 1099, 1118 ]
    - [ 1717, 1135, 1118, 1116, 2016, 1109, 1108, 1113, 1114, 1122 ]
    - [ 1740, 1201, 1112, 1109, 1095, 1097, 1101, 1108, 1106, 1110 ]
    - [ 2523, 2056, 2042, 2063, 2025, 1100, 1178, 1104, 1161, 1114 ]
    - [ 1719, 1130, 1111, 1125, 1107, 1110, 1112, 1099, 1105, 1104 ]
    - [ 1731, 2048, 1136, 1123, 2030, 2032, 1104, 1111, 2021, 1154 ]
  6.0:
    - [ 1668, 1087, 1074, 1056, 1073, 1080, 1051, 1063, 1089, 1989 ]
    - [ 1694, 1081, 1068, 1964, 1051, 1993, 2025, 1065, 2023, 1071 ]
    - [ 1701, 1099, 1082, 1068, 1067, 1078, 1070, 1074, 1072, 1069 ]
    - [ 1695, 1074, 2019, 1066, 1057, 1059, 1060, 1061, 1053, 2012 ]
    - [ 1675, 1103, 1076, 1074, 1178, 1065, 1076, 1078, 1078, 1072 ]
    - [ 1722, 1113, 1077, 1081, 1077, 1067, 1071, 1071, 1076, 1078 ]
    - [ 1676, 1075, 2012, 1059, 1054, 1054, 1052, 1054, 1076, 2016 ]
    - [ 1671, 1106, 1071, 2000, 1074, 1071, 1074, 1074, 1079, 1161 ]
    - [ 1705, 1084, 1261, 1086, 1075, 1073, 1072, 1073, 1066, 1090 ]
    - [ 1683, 1093, 1071, 1078, 1080, 1070, 1077, 1072, 1066, 1061 ]
  7.0:
    - [ 1673, 1120, 1970, 1043, 1986, 1022, 1032, 1971, 1026, 1039 ]
    - [ 1670, 1062, 1055, 1055, 1059, 1047, 1050, 1049, 1037, 1956 ]
    - [ 2474, 1974, 1052, 1046, 1041, 1055, 1047, 1046, 1048, 1050 ]
    - [ 1666, 1053, 1031, 2002, 1031, 1977, 1973, 1025, 1032, 1059 ]
    - [ 1657, 1997, 1979, 1989, 1049, 1050, 1044, 1050, 1051, 1058 ]
    - [ 1651, 1060, 1028, 1036, 1024, 1019, 1967, 1026, 1033, 1036 ]
    - [ 1661, 1057, 1033, 1033, 1024, 1025, 1981, 1937, 1061, 2009 ]
    - [ 1640, 1141, 1988, 1040, 1035, 1990, 1034, 1038, 1041, 1999 ]
    - [ 1727, 1115, 1025, 1035, 1033, 1023, 1019, 1016, 1022, 1029 ]
    - [ 1679, 1046, 1036, 1037, 1980, 1956, 1026, 1984, 1044, 1029 ]
  8.0:
    - [ 1689, 1050, 1934, 1041, 1038, 1012, 1022, 1949, 1918, 1956 ]
    - [ 1630, 1039, 1945, 1014, 1012, 1002, 1011, 1009, 1963, 1024 ]
    - [ 1625, 1032, 1955, 1014, 1186, 1036, 1003, 1968, 1014, 1967 ]
    - [ 1640, 1057, 1022, 1968, 1015, 1934, 1010, 1014, 1005, 1031 ]
    - [ 2460, 1963, 1937, 1956, 1022, 996, 1952, 1959, 1954, 1961 ]
    - [ 1654, 1040, 1018, 1015, 1017, 1016, 1010, 1015, 1007, 1013 ]
    - [ 1662, 1045, 1019, 1017, 1958, 1002, 1946, 1949, 1006, 1014 ]
    - [ 1652, 1968, 1020, 1936, 1976, 1010, 1000, 1017, 1010, 1013 ]
    - [ 1655, 1023, 1017, 1020, 1013, 1003, 1020, 1028, 1935, 1033 ]
    - [ 1663, 1957, 1946, 1013, 1024, 1001, 1009, 1007, 1005, 1022 ]
  9.0:
    - [ 1654, 1021, 1008, 987, 997, 1015, 1990, 1971, 1930, 991 ]
    - [ 1683, 1073, 1054, 1049, 1015, 998, 989, 993, 1937, 1005 ]
    - [ 1612, 1956, 1927, 1005, 1917, 1927, 997, 999, 989, 1910 ]
    - [ 1617, 1023, 994, 1906, 1002, 990, 995, 1032, 1924, 1944 ]
    - [ 1630, 1955, 993, 999, 1022, 989, 999, 991, 1920, 1002 ]
    - [ 1615, 1020, 1006, 998, 1011, 989, 1013, 997, 992, 1003 ]
    - [ 1642, 1023, 1003, 1013, 992, 1944, 997, 996, 1960, 1001 ]
    - [ 1632, 1021, 1931, 1008, 1916, 1006, 988, 1929, 1006, 994 ]
    - [ 1653, 1043, 1011, 1931, 1052, 1022, 1044, 1025, 1929, 1031 ]
    - [ 1608, 1955, 1002, 1011, 1926, 996, 994, 997, 1002, 997 ]
  10.0:
    - [ 1672, 998, 976, 976, 984, 975, 976, 1002, 975, 979 ]
    - [ 1634, 1013, 1854, 1934, 1005, 978, 1006, 1916, 987, 1063 ]
    - [ 1688, 1014, 997, 997, 995, 992, 1920, 1918, 1072, 1065 ]
    - [ 1631, 1021, 1889, 1891, 1000, 1888, 989, 995, 990, 1904 ]
    - [ 1687, 1928, 996, 989, 999, 978, 988, 984, 1018, 988 ]
    - [ 1658, 1006, 983, 996, 1008, 1901, 982, 980, 993, 988 ]
    - [ 1640, 1026, 987, 1008, 1004, 1006, 1029, 1007, 1075, 1006 ]
    - [ 1621, 1003, 1894, 1910, 986, 989, 980, 997, 1069, 986 ]
    - [ 1628, 1015, 997, 992, 1935, 1010, 1889, 1048, 1053, 1063 ]
    - [ 2444, 1928, 1022, 1007, 1019, 1004, 1014, 993, 1011, 1885 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 22995, 19627, 19575, 23981, 23654, 23788, 23854, 23948, 23855, 23978 ]
    - [ 22690, 19628, 19633, 23998, 23811, 23769, 23786, 23800, 24031, 23946 ]
    - [ 22917, 19534, 19490, 23708, 23772, 23733, 24077, 23735, 23841, 23943 ]
    - [ 22649, 19806, 19574, 23950, 23847, 23834, 23956, 23874, 23927, 23919 ]
    - [ 22305, 19902, 19739, 23835, 23677, 23715, 23836, 23830, 23878, 23853 ]
    - [ 22494, 19648, 19908, 23926, 23920, 23943, 24071, 23943, 24008, 23995 ]
    - [ 22154, 19699, 19595, 23755, 23737, 23738, 23763, 23746, 23789, 23901 ]
    - [ 22513, 19653, 19673, 23921, 23981, 23879, 23826, 23994, 23885, 24012 ]
    - [ 22439, 19643, 19565, 23851, 23811, 24019, 23989, 25044, 24047, 23942 ]
    - [ 22543, 19559, 19657, 23772, 23760, 23799, 23802, 23865, 23817, 23935 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2384, 1910, 2049, 1907, 1885, 1875, 1874, 1876, 1878, 1936 ]
    - [ 2386, 1918, 1901, 1892, 1884, 1888, 1885, 1885, 1885, 2310 ]
    - [ 2357, 1968, 1886, 1942, 1872, 1867, 1876, 1968, 1881, 1931 ]
    - [ 2370, 1912, 1989, 1918, 2000, 1890, 1882, 1878, 1887, 2057 ]
    - [ 2369, 1895, 1888, 1889, 1874, 1874, 1876, 1878, 1885, 1932 ]
    - [ 2395, 1902, 1888, 1891, 1876, 1889, 1992, 1880, 1892, 1941 ]
    - [ 2368, 1905, 1899, 1892, 1885, 1887, 1882, 1884, 1892, 1941 ]
    - [ 2369, 1908, 1880, 1879, 1871, 1870, 1897, 1877, 1874, 1929 ]
    - [ 2389, 1899, 1889, 1882, 1880, 1880, 1880, 1888, 1883, 1936 ]
    - [ 2398, 2000, 1892, 1886, 1882, 1877, 1877, 1955, 1886, 1937 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 2783, 2342, 2322, 2322, 2307, 2313, 2307, 2306, 2317, 2367 ]
    - [ 2776, 2341, 2330, 2312, 2308, 2314, 2315, 2309, 2313, 2348 ]
    - [ 2790, 2370, 2347, 2323, 2329, 2328, 2331, 2331, 2322, 2376 ]
    - [ 2792, 2358, 2346, 2341, 2328, 2332, 2342, 2313, 2326, 2423 ]
    - [ 2774, 2326, 2325, 2316, 2303, 2294, 2299, 2295, 2285, 2336 ]
    - [ 2769, 2351, 2322, 2321, 2312, 2310, 2316, 2297, 2317, 2355 ]
    - [ 2778, 2345, 2321, 2319, 2303, 2302, 2319, 2312, 2318, 2359 ]
    - [ 2803, 2323, 2301, 2295, 2288, 2296, 2303, 2305, 2288, 2346 ]
    - [ 2782, 2336, 2321, 2315, 2300, 2295, 2294, 2314, 2305, 2350 ]
    - [ 2787, 2355, 2321, 2331, 2312, 2319, 2313, 2324, 2310, 2363 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 114035, 130073, 124274, 115731, 118480, 129279, 124856, 119272, 124682, 122060 ]
    - [ 123272, 118754, 122980, 129078, 122850, 130015, 131125, 122491, 123914, 122705 ]
    - [ 116955, 129606, 122716, 118833, 116203, 124541, 116207, 117910, 119413, 127104 ]
    - [ 123811, 120563, 130052, 121169, 121629, 130412, 117056, 126408, 125046, 115584 ]
    - [ 124295, 117683, 122806, 119088, 127439, 120435, 123541, 130891, 121363, 122030 ]
    - [ 120390, 109615, 123855, 118362, 120674, 129843, 131312, 126327, 123065, 134200 ]
    - [ 116458, 124269, 129557, 125076, 121298, 134831, 124456, 124797, 121981, 125338 ]
    - [ 129103, 116078, 124026, 131207, 125889, 123781, 126856, 131840, 122483, 126494 ]
    - [ 115887, 118290, 122645, 120173, 122032, 129090, 119167, 124500, 135184, 130108 ]
    - [ 115538, 124269, 127465, 119427, 126840, 123455, 122717, 140852, 118226, 125435 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 24246, 23846, 23392, 24026, 23598, 19398, 18311, 18537, 17527, 19545 ]
    - [ 23640, 25056, 24001, 23293, 23569, 18220, 17948, 19043, 18843, 18751 ]
    - [ 24456, 23390, 24194, 23992, 23424, 18006, 18221, 18170, 20390, 19326 ]
    - [ 23817, 23932, 24044, 24653, 23224, 18433, 19266, 18689, 17193, 19304 ]
    - [ 24091, 23310, 23789, 23523, 23982, 19129, 19103, 18874, 19287, 19187 ]
    - [ 24745, 23506, 23648, 24753, 23868, 17889, 18533, 19460, 17582, 18865 ]
    - [ 24717, 23843, 23361, 23317, 24346, 18862, 18132, 20106, 18026, 18952 ]
    - [ 23702, 23360, 23779, 24164, 23880, 19034, 19249, 19309, 17865, 18056 ]
    - [ 23917, 23069, 22903, 23090, 24098, 17507, 18371, 19256, 17762, 18651 ]
    - [ 24339, 24442, 24492, 23919, 24580, 18946, 18896, 19847, 18562, 21289 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 102149, 101944, 102704, 103350, 103359 ]
    - [ 101438, 101703, 104530, 101929, 103619 ]
    - [ 101765, 102081, 101705, 102616, 103608 ]
    - [ 103618, 102508, 105208, 105671, 114119 ]
    - [ 101410, 101687, 101875, 102162, 101937 ]
    - [ 101934, 105800, 106338, 106839, 106457 ]
    - [ 102888, 103067, 103124, 101729, 105649 ]
    - [ 102048, 101755, 102803, 103813, 103082 ]
    - [ 101430, 102657, 101809, 102075, 104509 ]
    - [ 111755, 101959, 106498, 102153, 105310 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5751, 7367, 7406, 7831, 8623 ]
    - [ 5710, 6275, 6459, 6717, 6755 ]
    - [ 5758, 6324, 6185, 6630, 7425 ]
    - [ 5719, 7331, 6485, 6287, 6997 ]
    - [ 5757, 6630, 6908, 7490, 7128 ]
    - [ 5823, 6608, 6738, 7033, 6314 ]
    - [ 5799, 7552, 6639, 6975, 6099 ]
    - [ 5781, 7038, 6671, 6951, 6106 ]
    - [ 5751, 7065, 6561, 6861, 6776 ]
    - [ 5855, 6444, 6670, 8866, 7507 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4841, 5728, 5200, 5141, 5323 ]
    - [ 5300, 5635, 4940, 5012, 5603 ]
    - [ 4897, 5447, 5548, 5252, 7536 ]
    - [ 6161, 5386, 5341, 5003, 5053 ]
    - [ 5121, 4913, 4857, 5368, 5226 ]
    - [ 4790, 4842, 5287, 5082, 5301 ]
    - [ 5386, 5026, 5257, 5318, 5230 ]
    - [ 4786, 4899, 4904, 5119, 5317 ]
    - [ 6854, 5215, 5111, 5265, 5339 ]
    - [ 4962, 5791, 5280, 5807, 5722 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 9273, 4754, 4750, 4676, 4712 ]
    - [ 8122, 5529, 6463, 4543, 4324 ]
    - [ 7518, 4859, 5068, 4695, 4637 ]
    - [ 7507, 4193, 5341, 5380, 4895 ]
    - [ 9045, 5515, 5306, 4691, 4458 ]
    - [ 7002, 5854, 6147, 6489, 4382 ]
    - [ 7262, 4394, 4866, 4736, 4445 ]
    - [ 7100, 5809, 5394, 4615, 5854 ]
    - [ 6439, 4565, 5206, 4746, 3689 ]
    - [ 6255, 4511, 4739, 3940, 3662 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2676, 2272, 2201, 2218, 2288 ]
    - [ 2652, 2249, 2260, 2241, 2296 ]
    - [ 2700, 2290, 2259, 2271, 2329 ]
    - [ 2667, 2248, 2281, 2256, 2331 ]
    - [ 2628, 2250, 2240, 2239, 2317 ]
    - [ 2720, 2278, 2282, 2465, 2375 ]
    - [ 2645, 2252, 2200, 2223, 2284 ]
    - [ 2679, 2291, 2211, 2223, 2276 ]
    - [ 2680, 2234, 2245, 2254, 2293 ]
    - [ 2655, 2246, 2248, 2263, 2285 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3569, 2696, 2682, 2681, 2746 ]
    - [ 3478, 2728, 2699, 2693, 2798 ]
    - [ 3485, 2721, 2637, 2625, 2700 ]
    - [ 3552, 2715, 2720, 2689, 2748 ]
    - [ 3507, 2723, 2711, 2703, 2765 ]
    - [ 3522, 2676, 2766, 2762, 2790 ]
    - [ 3595, 2682, 2664, 2724, 2778 ]
    - [ 3547, 2683, 2669, 2662, 2729 ]
    - [ 3523, 2697, 2689, 2656, 2772 ]
    - [ 3474, 2721, 2703, 2686, 2800 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 2770, 2218, 2208, 2197, 2283 ]
    - [ 2763, 2197, 2204, 2184, 2280 ]
    - [ 2764, 2228, 2201, 2212, 2291 ]
    - [ 2776, 2208, 2216, 2230, 2302 ]
    - [ 2784, 2319, 2303, 2296, 2345 ]
    - [ 2741, 2297, 2287, 2285, 2357 ]
    - [ 2788, 2308, 2240, 2268, 2334 ]
    - [ 2757, 2236, 2237, 2209, 2303 ]
    - [ 2793, 2250, 2179, 2223, 2272 ]
    - [ 2787, 2230, 2215, 2199, 2304 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3053, 2431, 2407, 2459, 2494 ]
    - [ 3068, 2385, 2364, 2377, 2452 ]
    - [ 3048, 2401, 2426, 2337, 2439 ]
    - [ 3290, 2535, 2571, 2525, 2641 ]
    - [ 3130, 2563, 2454, 2439, 2530 ]
    - [ 3040, 2793, 2417, 2413, 2508 ]
    - [ 3026, 2376, 2407, 2380, 2480 ]
    - [ 3109, 2554, 2468, 2446, 2578 ]
    - [ 3038, 2451, 2405, 2463, 2483 ]
    - [ 3156, 2481, 2452, 2449, 2561 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2502, 2015, 2010, 2027, 2071 ]
    - [ 2494, 2020, 1999, 1967, 2058 ]
    - [ 2479, 1990, 1969, 2000, 2039 ]
    - [ 2470, 2031, 2009, 2007, 2056 ]
    - [ 2535, 2023, 2021, 2021, 2095 ]
    - [ 2483, 2024, 2036, 2009, 2110 ]
    - [ 2496, 2057, 2032, 2000, 2085 ]
    - [ 2494, 2006, 1993, 1994, 2068 ]
    - [ 2494, 2002, 1988, 1994, 2054 ]
    - [ 2575, 2109, 2025, 2019, 2077 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 3909, 3064, 3121, 3065, 3015, 2991, 3019, 2995, 3000, 3005 ]
    - [ 3851, 3225, 3096, 3089, 3033, 3039, 3034, 3052, 2994, 3001 ]
    - [ 3922, 3056, 3051, 3016, 3012, 3019, 2976, 2998, 2942, 3005 ]
    - [ 3791, 3142, 3076, 3100, 3093, 3031, 3033, 2951, 2941, 3022 ]
    - [ 3763, 3054, 3063, 3023, 3030, 2999, 3014, 3017, 2954, 3000 ]
    - [ 3852, 3094, 3091, 3059, 3040, 3038, 3051, 3021, 2979, 3048 ]
    - [ 3796, 3041, 3076, 3026, 3009, 3001, 2996, 2953, 2925, 2985 ]
    - [ 3948, 3102, 3108, 3061, 3029, 3068, 3036, 2966, 2991, 3028 ]
    - [ 3731, 3167, 3046, 3044, 3008, 3023, 3033, 3018, 3003, 3016 ]
    - [ 3809, 3244, 3045, 3038, 3003, 3001, 3017, 3009, 2982, 3001 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 36322, 32161, 32111, 37477, 37294, 37288, 37311, 37311, 37448, 37469 ]
    - [ 35852, 32076, 32071, 37445, 37238, 37315, 37207, 37018, 37209, 37172 ]
    - [ 36157, 32054, 32211, 37499, 37490, 37356, 37579, 37654, 37293, 37417 ]
    - [ 36229, 32031, 32487, 37361, 37241, 37262, 37356, 37356, 37419, 37254 ]
    - [ 35951, 32290, 32231, 37239, 37128, 37286, 37122, 37346, 37182, 37375 ]
    - [ 35889, 32095, 32044, 37418, 37260, 37296, 37420, 37415, 37266, 37247 ]
    - [ 35989, 32077, 32007, 37762, 37185, 37383, 37235, 37303, 37337, 37406 ]
    - [ 36242, 32139, 32082, 37620, 37363, 37300, 37438, 37567, 37309, 37704 ]
    - [ 36118, 32048, 31998, 37411, 37513, 37368, 37253, 37327, 37277, 37283 ]
    - [ 36580, 32054, 32024, 37425, 37326, 37350, 37217, 37460, 37425, 37591 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 5098, 4554, 4395, 4303, 4316, 4234, 4211, 4216, 4215, 4432 ]
    - [ 5149, 4503, 4499, 4470, 4570, 4354, 4359, 4338, 4329, 4525 ]
    - [ 4983, 4672, 4654, 4558, 4567, 4387, 4363, 4379, 4379, 4563 ]
    - [ 4999, 4584, 4527, 4452, 4450, 4229, 4240, 4201, 4200, 4374 ]
    - [ 4950, 4513, 4497, 4488, 4538, 4364, 4358, 4384, 4332, 4496 ]
    - [ 5079, 4601, 4508, 4461, 4466, 4324, 4340, 4345, 4297, 4500 ]
    - [ 4996, 4618, 4543, 4476, 4514, 4340, 4360, 4328, 4408, 4401 ]
    - [ 5196, 4496, 4306, 4268, 4292, 4082, 4112, 4095, 4088, 4395 ]
    - [ 4901, 4588, 4469, 4399, 4415, 4235, 4247, 4126, 4139, 4244 ]
    - [ 5069, 4491, 4470, 4422, 4502, 4398, 4345, 4349, 4339, 4496 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 44092, 39183, 38840, 44847, 45039, 44829, 44914, 44813, 44919, 44793 ]
    - [ 44158, 38677, 38573, 44268, 44347, 44178, 44301, 44378, 44315, 44382 ]
    - [ 44189, 38995, 38881, 45045, 44866, 44921, 44670, 44855, 44901, 44839 ]
    - [ 44541, 38901, 39099, 44979, 44808, 44944, 44793, 44819, 44842, 44913 ]
    - [ 44109, 38998, 38956, 44679, 44789, 44885, 44944, 44957, 45039, 44923 ]
    - [ 44279, 39028, 39103, 44833, 44731, 44925, 44484, 44809, 44958, 44978 ]
    - [ 44150, 38971, 38930, 44653, 44719, 44668, 44596, 44601, 44694, 44566 ]
    - [ 44302, 39059, 38828, 44973, 44665, 44602, 44798, 44784, 44770, 44869 ]
    - [ 43966, 39032, 39059, 45006, 44834, 44699, 44825, 44824, 44735, 44913 ]
    - [ 44028, 39145, 39090, 44828, 44847, 44973, 44842, 44792, 44941, 44915 ]
