#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 58076, 54941, 54555, 53259, 53374, 53828, 54363, 54490, 54726, 53523 ]
    - [ 57803, 53238, 53377, 52568, 52351, 53581, 54164, 54334, 54442, 53230 ]
    - [ 59228, 54218, 53907, 52795, 53362, 53574, 54270, 54516, 54154, 55025 ]
    - [ 57851, 54337, 54566, 53449, 52838, 53574, 54058, 53482, 53977, 53608 ]
    - [ 55032, 52306, 53363, 52392, 52495, 53220, 54436, 54127, 54217, 53375 ]
    - [ 57887, 54338, 54192, 52833, 53201, 53515, 54430, 54105, 54131, 53574 ]
    - [ 58220, 54418, 54492, 53318, 53408, 53991, 54329, 54423, 54559, 54023 ]
    - [ 58114, 53599, 54258, 53135, 53201, 54299, 54729, 55065, 54777, 54082 ]
    - [ 55274, 54645, 54606, 53695, 53704, 54422, 54549, 54978, 54880, 54040 ]
    - [ 57655, 53819, 54074, 52614, 52624, 53658, 54320, 53313, 53996, 53384 ]
  2.0:
    - [ 6068, 4978, 4903, 4621, 4341, 3970, 3545, 3253, 3184, 2839 ]
    - [ 6031, 5021, 5025, 4595, 4416, 3999, 3593, 3319, 3020, 2869 ]
    - [ 6124, 5086, 4956, 4575, 4329, 3956, 3634, 3328, 3034, 2847 ]
    - [ 6110, 5026, 4969, 4715, 4477, 4026, 3582, 3304, 3051, 2842 ]
    - [ 6032, 5092, 5015, 4682, 4475, 4003, 3652, 3311, 3038, 2884 ]
    - [ 6064, 5037, 4933, 4622, 4380, 3964, 3576, 3280, 2986, 2884 ]
    - [ 6015, 4931, 4893, 4557, 4326, 3992, 3595, 3253, 3088, 2897 ]
    - [ 6130, 5086, 4981, 4604, 4371, 4078, 3644, 3288, 3052, 2859 ]
    - [ 6024, 5091, 4968, 4627, 4444, 4042, 3639, 3337, 3058, 2926 ]
    - [ 6121, 5172, 4999, 4603, 4442, 4075, 3630, 3338, 3012, 2932 ]
  3.0:
    - [ 5692, 4722, 4650, 4361, 4114, 3721, 3283, 2948, 2681, 2508 ]
    - [ 5674, 4735, 4601, 4376, 4163, 3746, 3304, 2922, 2643, 2496 ]
    - [ 5768, 4712, 4629, 4306, 4103, 3694, 3246, 2924, 2682, 2519 ]
    - [ 5682, 4726, 4528, 4285, 4098, 3745, 3294, 2959, 2674, 2497 ]
    - [ 5677, 4683, 4667, 4395, 4117, 3720, 3253, 2951, 2654, 2506 ]
    - [ 5613, 4633, 4628, 4259, 4123, 3725, 3305, 2916, 2674, 2510 ]
    - [ 5744, 4688, 4549, 4292, 4077, 4212, 3262, 2917, 2631, 2463 ]
    - [ 5722, 4726, 4664, 4365, 4142, 3728, 3261, 2974, 2677, 2537 ]
    - [ 5660, 4699, 4519, 4243, 4118, 3737, 3255, 2938, 2642, 2520 ]
    - [ 5720, 4728, 4646, 4391, 4116, 3719, 3329, 2957, 2662, 2532 ]
  4.0:
    - [ 5718, 4631, 4541, 4355, 4139, 3779, 3313, 2947, 2655, 2508 ]
    - [ 5773, 4720, 4659, 4316, 4125, 3740, 3303, 2988, 2686, 2517 ]
    - [ 5740, 4718, 4630, 4376, 4118, 3730, 3278, 2937, 2675, 2475 ]
    - [ 5868, 4794, 4657, 4355, 4172, 3747, 3289, 2965, 2661, 2526 ]
    - [ 5737, 4713, 4662, 4387, 4133, 3774, 3310, 2932, 2639, 2498 ]
    - [ 5690, 4672, 4618, 4314, 4102, 3789, 3300, 2976, 2641, 2475 ]
    - [ 5672, 4656, 4664, 4409, 4114, 3756, 3251, 2929, 2643, 2535 ]
    - [ 5644, 4653, 4556, 4374, 4105, 3693, 3270, 2972, 2661, 2517 ]
    - [ 5691, 4679, 4610, 4351, 4121, 3815, 3340, 2990, 2678, 2565 ]
    - [ 5872, 4677, 4620, 4384, 4129, 3735, 3304, 2940, 2687, 2584 ]
  5.0:
    - [ 5646, 4645, 4521, 4249, 4059, 3730, 3274, 2945, 2634, 2512 ]
    - [ 5653, 4717, 4591, 4291, 4079, 3685, 3236, 2938, 2633, 2522 ]
    - [ 5704, 4686, 4590, 4229, 4071, 3713, 3241, 2922, 2635, 2517 ]
    - [ 5654, 4675, 4587, 4298, 4114, 3696, 3238, 2937, 2637, 2522 ]
    - [ 5592, 4534, 4465, 4198, 3962, 3553, 3122, 2809, 2508, 2375 ]
    - [ 5661, 4730, 4513, 4307, 4056, 3670, 3243, 2934, 2643, 2507 ]
    - [ 5694, 4676, 4569, 4279, 4079, 3666, 3248, 2916, 2620, 2482 ]
    - [ 5774, 4707, 4568, 4237, 4065, 3684, 3258, 2925, 2642, 2476 ]
    - [ 5710, 4606, 4589, 4281, 4089, 3689, 3257, 2931, 2644, 2530 ]
    - [ 5690, 4745, 4638, 4311, 4106, 3702, 3244, 2929, 2649, 2504 ]
  6.0:
    - [ 5784, 4723, 4572, 4405, 4133, 3758, 3293, 2962, 2627, 2503 ]
    - [ 5700, 4703, 4600, 4312, 4073, 3702, 3276, 2939, 2641, 2523 ]
    - [ 5766, 4750, 4633, 4337, 4079, 3693, 3279, 2942, 2665, 2527 ]
    - [ 5867, 4708, 4663, 4354, 4124, 3712, 3273, 3202, 2665, 2508 ]
    - [ 5724, 4735, 4621, 4327, 4075, 3727, 3254, 2922, 2651, 2526 ]
    - [ 5669, 4730, 4569, 4297, 4066, 3742, 3455, 2960, 2658, 2536 ]
    - [ 5764, 4706, 4648, 4359, 4116, 3717, 3288, 2950, 2661, 2540 ]
    - [ 5666, 4737, 4615, 4338, 4083, 3699, 3256, 2954, 2673, 2524 ]
    - [ 5675, 4687, 4531, 4346, 4131, 3702, 3258, 2954, 2665, 2531 ]
    - [ 5704, 4707, 4629, 4355, 4085, 3751, 3298, 2965, 2664, 2515 ]
  7.0:
    - [ 5702, 4704, 4627, 4342, 4135, 3681, 3266, 2948, 2656, 2503 ]
    - [ 5726, 4662, 4565, 4332, 4152, 3719, 3293, 2971, 2666, 2550 ]
    - [ 5628, 4771, 4673, 4329, 4149, 3753, 3293, 2962, 2671, 2507 ]
    - [ 5646, 4644, 4589, 4338, 4098, 3692, 3268, 2933, 2647, 2555 ]
    - [ 5663, 4699, 4631, 4254, 4014, 3734, 3250, 2975, 2651, 2526 ]
    - [ 5644, 4697, 4572, 4345, 4117, 3731, 3267, 2939, 2657, 2508 ]
    - [ 5666, 4711, 4616, 4338, 4117, 3731, 3265, 2943, 2652, 2514 ]
    - [ 5675, 4721, 4572, 4314, 4099, 3709, 3259, 2942, 2652, 2515 ]
    - [ 5705, 4656, 4622, 4324, 4085, 3696, 3310, 2953, 2666, 2551 ]
    - [ 5684, 4725, 4632, 4290, 4089, 3714, 3290, 2953, 2653, 2524 ]
  8.0:
    - [ 5722, 4680, 4581, 4365, 4155, 3705, 3297, 2954, 2653, 2508 ]
    - [ 5686, 4691, 4528, 4298, 4064, 3740, 3238, 2895, 2621, 2497 ]
    - [ 5653, 4668, 4622, 4276, 4139, 3716, 3274, 2950, 2667, 2538 ]
    - [ 5770, 4679, 4561, 4289, 4107, 3671, 3244, 2929, 2638, 2493 ]
    - [ 5713, 4690, 4635, 4353, 4103, 3715, 3285, 2946, 2666, 2519 ]
    - [ 5684, 4682, 4631, 4361, 4100, 3681, 3274, 2950, 2656, 2510 ]
    - [ 5705, 4701, 4682, 4364, 4079, 3720, 3307, 2967, 2653, 2508 ]
    - [ 5707, 4697, 4584, 4339, 4091, 3682, 3278, 2956, 2652, 2525 ]
    - [ 5716, 4775, 4639, 4405, 4150, 3701, 3273, 2933, 2641, 2515 ]
    - [ 5701, 4708, 4606, 4336, 4107, 3704, 3251, 2931, 2634, 2529 ]
  9.0:
    - [ 5610, 4685, 4591, 4330, 4098, 3708, 3253, 2932, 2645, 2506 ]
    - [ 5930, 4713, 4594, 4364, 4096, 3643, 3260, 2921, 3099, 2469 ]
    - [ 5673, 4673, 4633, 4339, 4137, 3717, 3267, 2946, 2653, 2524 ]
    - [ 5597, 4624, 4514, 4254, 4042, 3638, 3240, 2895, 2628, 2464 ]
    - [ 5580, 4659, 4561, 4350, 4125, 3721, 3460, 2969, 2683, 2540 ]
    - [ 5726, 4686, 4657, 4380, 4076, 3733, 3309, 2964, 2675, 2533 ]
    - [ 5686, 4661, 4558, 4342, 4077, 3711, 3240, 2931, 2636, 2547 ]
    - [ 5608, 4621, 4546, 4315, 4049, 3686, 3259, 2919, 2629, 2504 ]
    - [ 5683, 4688, 4644, 4314, 4118, 3731, 3275, 2938, 2673, 2537 ]
    - [ 5680, 4673, 4640, 4404, 4166, 3780, 3315, 2983, 2677, 2540 ]
  10.0:
    - [ 5597, 4726, 4521, 4326, 4089, 3727, 3252, 2934, 2645, 2505 ]
    - [ 5638, 4687, 4618, 4326, 4107, 3748, 3265, 2954, 2657, 2527 ]
    - [ 5671, 4680, 4586, 4274, 4094, 3706, 3255, 2944, 2660, 2536 ]
    - [ 5672, 4706, 4610, 4384, 4105, 3675, 3265, 2935, 2626, 2507 ]
    - [ 5670, 4720, 4606, 4295, 4106, 3645, 3250, 2956, 2649, 2513 ]
    - [ 5650, 4669, 4594, 4277, 4075, 3714, 3238, 2948, 2652, 2507 ]
    - [ 5687, 4756, 4667, 4384, 4147, 3720, 3263, 2942, 2657, 2526 ]
    - [ 5637, 4713, 4634, 4243, 4120, 3684, 3267, 2924, 2652, 2541 ]
    - [ 5675, 4703, 4523, 4317, 4083, 3701, 3218, 2897, 2625, 2513 ]
    - [ 5658, 4667, 4608, 4311, 4114, 3698, 3280, 2936, 2662, 2502 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 12549, 8021, 7455, 6845, 7049, 7377, 6744, 6528, 6090, 5056 ]
    - [ 12651, 8227, 7571, 7191, 7169, 7362, 6756, 6641, 5846, 5278 ]
    - [ 12732, 8026, 7210, 6875, 7074, 7287, 6788, 6525, 6013, 5220 ]
    - [ 12720, 8095, 7359, 6896, 7047, 7476, 6839, 6616, 5939, 5390 ]
    - [ 12685, 8025, 7308, 6999, 7156, 7469, 6747, 6485, 5870, 5414 ]
    - [ 12799, 8011, 7537, 6822, 7055, 7272, 6711, 6639, 5805, 5382 ]
    - [ 12758, 8118, 7227, 6839, 6992, 7541, 6801, 6565, 5809, 5352 ]
    - [ 13015, 8272, 7209, 7028, 7033, 7518, 6711, 6474, 5771, 5428 ]
    - [ 12570, 8241, 7095, 6888, 7115, 7511, 6826, 6602, 6023, 5216 ]
    - [ 12777, 8104, 7320, 7033, 7105, 7392, 6958, 6491, 6098, 5244 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5563, 4461, 4424, 4172, 4035, 3626, 3183, 2816, 2594, 2445 ]
    - [ 5533, 4623, 4500, 4204, 3991, 3605, 3167, 2857, 2545, 2469 ]
    - [ 5514, 4560, 4457, 4190, 3968, 3602, 3170, 2835, 2561, 2427 ]
    - [ 5482, 4591, 4452, 4190, 3961, 3581, 3152, 2860, 2582, 2421 ]
    - [ 5584, 4574, 4548, 4258, 3973, 3617, 3177, 2856, 2557, 2440 ]
    - [ 5689, 4717, 4554, 4242, 3985, 3607, 3176, 2853, 2545, 2417 ]
    - [ 5475, 4659, 4466, 4168, 3937, 3570, 3167, 2855, 2545, 2392 ]
    - [ 5507, 4562, 4522, 4173, 3964, 3573, 3159, 2860, 2572, 2437 ]
    - [ 5651, 4587, 4512, 4212, 3961, 3618, 3152, 2819, 2543, 2406 ]
    - [ 5495, 4576, 4489, 4190, 3983, 3578, 3159, 2830, 2525, 2419 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5553, 4557, 4541, 4151, 4047, 3597, 3207, 2889, 2600, 2470 ]
    - [ 5579, 4655, 4454, 4198, 3969, 3586, 3224, 2838, 2541, 2421 ]
    - [ 5541, 4616, 4532, 4282, 4104, 3703, 3162, 2834, 2622, 2450 ]
    - [ 5557, 4602, 4496, 4288, 3977, 3673, 3208, 2895, 2603, 2403 ]
    - [ 5645, 4630, 4523, 4284, 4081, 3687, 3234, 2903, 2624, 2429 ]
    - [ 5667, 4533, 4571, 4301, 4090, 3613, 3225, 2913, 2537, 2404 ]
    - [ 5603, 4660, 4601, 4220, 4021, 3678, 3407, 2891, 2613, 2397 ]
    - [ 5578, 4608, 4416, 4215, 4033, 3671, 3213, 2894, 2599, 2394 ]
    - [ 5553, 4577, 4585, 4335, 4054, 3687, 3231, 2910, 2619, 2496 ]
    - [ 5560, 4627, 4556, 4223, 4027, 3710, 3278, 2905, 2603, 2418 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 11177, 9291, 9628, 9275, 9338, 9020, 9236, 9223, 8820, 8096 ]
    - [ 11709, 13518, 13752, 13497, 14190, 14762, 14120, 14599, 15005, 15395 ]
    - [ 11193, 9572, 9665, 9642, 9374, 9499, 9036, 9264, 9232, 8158 ]
    - [ 10367, 9130, 9217, 9222, 9100, 8522, 9210, 9894, 8587, 9614 ]
    - [ 10505, 9268, 9499, 9072, 10089, 9566, 9640, 9717, 9478, 8687 ]
    - [ 10873, 9502, 9521, 9497, 8769, 9054, 8982, 9128, 8426, 8302 ]
    - [ 10838, 9759, 9414, 9647, 9836, 9016, 9213, 9861, 8824, 8467 ]
    - [ 10662, 10190, 9186, 9055, 9118, 9226, 9153, 9468, 8935, 8359 ]
    - [ 10786, 9146, 9302, 9425, 9371, 9625, 9094, 9292, 8982, 8239 ]
    - [ 11484, 12113, 12622, 12996, 13870, 13424, 14712, 13972, 11968, 11278 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 17770, 16004, 15442, 15267, 15316, 15003, 14517, 15459, 15622, 15233 ]
    - [ 16167, 14782, 13620, 13250, 13118, 13203, 12735, 13056, 13520, 12259 ]
    - [ 15490, 14468, 13510, 13648, 13325, 13194, 12792, 13540, 13292, 12358 ]
    - [ 16379, 14679, 13820, 13425, 13488, 13529, 13053, 13656, 13936, 13286 ]
    - [ 17709, 15278, 15232, 15209, 15529, 14926, 14615, 15320, 15689, 15204 ]
    - [ 16370, 14840, 13955, 13497, 14055, 13581, 12657, 13690, 13760, 13621 ]
    - [ 17035, 15256, 13899, 13911, 13443, 13575, 13213, 13364, 13809, 12867 ]
    - [ 15763, 14221, 13418, 13479, 13366, 13637, 12726, 13231, 13202, 12740 ]
    - [ 16728, 16032, 14751, 14535, 14791, 14649, 14356, 14979, 14843, 14396 ]
    - [ 16617, 15135, 14480, 14264, 13492, 13978, 13178, 13463, 14345, 13130 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 13420, 12188, 12234, 10753, 10108, 9953, 9978, 9701, 10040, 9840 ]
    - [ 12136, 10410, 10170, 9478, 9084, 9427, 8939, 8925, 8827, 8920 ]
    - [ 13370, 10949, 10833, 9579, 9723, 9016, 8760, 8664, 9220, 8759 ]
    - [ 13592, 11135, 10686, 9756, 9845, 9097, 8855, 8920, 8802, 8423 ]
    - [ 13967, 12066, 11496, 10676, 10311, 10213, 9350, 9413, 10221, 9766 ]
    - [ 13060, 10950, 10560, 9623, 9105, 9202, 8429, 8672, 8933, 9158 ]
    - [ 12721, 10805, 9481, 9301, 9144, 9267, 8685, 8556, 8867, 8563 ]
    - [ 13737, 10396, 10635, 9416, 9351, 8926, 8497, 8604, 8599, 8895 ]
    - [ 12767, 10616, 10380, 9696, 9116, 8984, 8757, 8809, 8970, 9000 ]
    - [ 14316, 10897, 10750, 9755, 9416, 8849, 8683, 8901, 8620, 8367 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 92736, 89207, 90569, 85918, 90130 ]
    - [ 90123, 87212, 90008, 89201, 89579 ]
    - [ 94569, 90459, 89902, 89154, 90571 ]
    - [ 92437, 89726, 89802, 89103, 89147 ]
    - [ 88835, 87907, 88635, 87177, 91688 ]
    - [ 90732, 87917, 87803, 88449, 87687 ]
    - [ 92527, 90073, 88486, 87739, 88554 ]
    - [ 91704, 89859, 88784, 87776, 89994 ]
    - [ 90376, 89318, 88845, 87583, 90843 ]
    - [ 93024, 89998, 90042, 88296, 89791 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7678, 7293, 6808, 6741, 6295 ]
    - [ 8048, 7325, 6930, 6687, 6597 ]
    - [ 7636, 7308, 7270, 7306, 6882 ]
    - [ 7782, 7070, 6602, 6516, 6179 ]
    - [ 8134, 7180, 6776, 6560, 6362 ]
    - [ 7708, 7112, 6653, 6539, 6303 ]
    - [ 8114, 7361, 6863, 6716, 6514 ]
    - [ 8333, 7153, 6700, 6557, 6977 ]
    - [ 7751, 7168, 6762, 6617, 6339 ]
    - [ 8048, 7153, 6675, 6631, 6400 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 7524, 6785, 6769, 6528, 6320 ]
    - [ 7451, 6847, 6713, 6635, 6424 ]
    - [ 7555, 6900, 6799, 6515, 6382 ]
    - [ 7528, 6825, 6773, 6573, 6403 ]
    - [ 7484, 7142, 6909, 6563, 6322 ]
    - [ 7545, 6880, 6959, 6617, 6353 ]
    - [ 7452, 6899, 6835, 6511, 6324 ]
    - [ 7504, 6847, 6784, 6487, 6450 ]
    - [ 7443, 6864, 6695, 6510, 6442 ]
    - [ 7442, 6820, 6808, 6573, 6300 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 19157, 11824, 6089, 12867, 12975 ]
    - [ 18755, 11354, 5489, 12019, 11902 ]
    - [ 18984, 10984, 12089, 12139, 12254 ]
    - [ 18815, 11077, 12685, 11317, 3580 ]
    - [ 18703, 11263, 12633, 12126, 11672 ]
    - [ 19205, 11803, 6009, 12669, 12570 ]
    - [ 18560, 11234, 5460, 11448, 3583 ]
    - [ 19130, 11856, 6173, 12156, 4286 ]
    - [ 18661, 11321, 5589, 12185, 12460 ]
    - [ 19306, 11793, 6109, 12117, 4259 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 6651, 4661, 3487, 2561, 2301 ]
    - [ 6583, 4641, 3532, 2582, 2369 ]
    - [ 6657, 4711, 3595, 2612, 2340 ]
    - [ 6661, 4697, 3540, 2747, 2481 ]
    - [ 6650, 4585, 3456, 2649, 2393 ]
    - [ 6581, 4658, 3511, 2538, 2355 ]
    - [ 6578, 4625, 3491, 2627, 2408 ]
    - [ 6631, 4652, 3489, 2647, 2354 ]
    - [ 6542, 4601, 3452, 2561, 2344 ]
    - [ 6656, 4627, 3471, 2572, 2363 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 6508, 5177, 5099, 4845, 4522 ]
    - [ 6453, 5163, 5067, 4692, 4448 ]
    - [ 6457, 5171, 5079, 4787, 4474 ]
    - [ 6506, 5099, 5052, 4723, 4429 ]
    - [ 6423, 5193, 5105, 4764, 4474 ]
    - [ 6599, 5209, 5050, 4691, 4515 ]
    - [ 6497, 5175, 5260, 4916, 4621 ]
    - [ 6636, 5194, 5058, 4749, 4452 ]
    - [ 6574, 5135, 5080, 4726, 4471 ]
    - [ 6554, 5148, 5019, 4662, 4415 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 6116, 5074, 4934, 4682, 4557 ]
    - [ 6085, 5119, 4946, 4602, 4399 ]
    - [ 6100, 5028, 4982, 4619, 4385 ]
    - [ 6118, 5057, 4989, 4668, 4395 ]
    - [ 6334, 5205, 4867, 4580, 4366 ]
    - [ 6055, 4993, 4999, 4639, 4430 ]
    - [ 6107, 5068, 4959, 4607, 4401 ]
    - [ 6027, 5068, 5000, 4663, 4415 ]
    - [ 6093, 5041, 4873, 4606, 4388 ]
    - [ 6097, 4934, 5009, 4561, 4382 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 6267, 5078, 4915, 4535, 4358 ]
    - [ 6175, 5089, 5034, 4678, 4467 ]
    - [ 6192, 5063, 4957, 4700, 4424 ]
    - [ 6276, 5116, 5019, 4674, 4392 ]
    - [ 6256, 5089, 5031, 4661, 4416 ]
    - [ 6189, 5198, 5007, 4646, 4524 ]
    - [ 6392, 5106, 5038, 4677, 4544 ]
    - [ 6299, 5037, 5041, 4608, 4437 ]
    - [ 6164, 5126, 5019, 4754, 4422 ]
    - [ 6194, 5077, 4946, 4796, 4399 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 5072, 4278, 4084, 3881, 3612 ]
    - [ 5133, 4364, 4204, 3877, 3703 ]
    - [ 5082, 4202, 4162, 3914, 3687 ]
    - [ 5268, 4235, 4110, 3844, 3612 ]
    - [ 5225, 4234, 4141, 3791, 3621 ]
    - [ 5272, 4225, 4031, 3929, 3652 ]
    - [ 5195, 4303, 3995, 3775, 3615 ]
    - [ 5212, 4145, 4171, 3879, 3684 ]
    - [ 5137, 4264, 4190, 3852, 3633 ]
    - [ 5075, 4233, 4159, 3858, 3685 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 8455, 6964, 6851, 6508, 6113, 5671, 5045, 4625, 4280, 4104 ]
    - [ 8535, 6968, 6830, 6520, 6155, 5729, 5106, 4637, 4319, 4130 ]
    - [ 8407, 6994, 6849, 6467, 6170, 5708, 5089, 4634, 4294, 4138 ]
    - [ 8481, 7002, 6888, 6506, 6200, 5689, 5092, 4691, 4301, 4127 ]
    - [ 8395, 7025, 6910, 6554, 6159, 5727, 5083, 4677, 4263, 4124 ]
    - [ 8433, 7018, 6829, 6486, 6178, 5676, 5086, 4657, 4253, 4118 ]
    - [ 8487, 6988, 6858, 6504, 6186, 5709, 5087, 4666, 4290, 4098 ]
    - [ 8430, 7014, 6890, 6530, 6140, 5668, 5089, 4686, 4290, 4115 ]
    - [ 8401, 7009, 6911, 6529, 6179, 5753, 5139, 4689, 4321, 4144 ]
    - [ 8410, 6959, 6864, 6552, 6144, 5685, 5073, 4638, 4254, 4136 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 17137, 11225, 9721, 9249, 9483, 9966, 9086, 8884, 7992, 6848 ]
    - [ 17462, 10643, 10074, 9541, 9584, 10066, 9008, 8690, 8067, 6961 ]
    - [ 17501, 10973, 9893, 9187, 9688, 9836, 8868, 8678, 8071, 6947 ]
    - [ 17135, 10755, 10103, 9714, 9700, 9887, 9069, 8778, 7963, 6863 ]
    - [ 17222, 11091, 9790, 9362, 9429, 10145, 9200, 8736, 7898, 6928 ]
    - [ 17559, 10821, 9953, 9356, 9485, 9904, 9066, 8847, 7956, 7242 ]
    - [ 17295, 11077, 9797, 9339, 9492, 10118, 9115, 8789, 8044, 6900 ]
    - [ 17681, 10598, 9675, 9600, 9551, 9888, 9102, 8658, 7908, 6994 ]
    - [ 17337, 10870, 10117, 9438, 9376, 9894, 9114, 8916, 7622, 7182 ]
    - [ 17513, 10717, 9842, 9195, 9618, 9827, 9082, 8934, 7870, 7200 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 10292, 7955, 7868, 7661, 7654, 7450, 7543, 6965, 7039, 6336 ]
    - [ 10138, 7665, 7612, 7423, 7356, 7175, 7219, 6708, 6713, 6050 ]
    - [ 10121, 7579, 7564, 7385, 7297, 7104, 7165, 6580, 6631, 6044 ]
    - [ 10149, 7665, 7659, 7469, 7414, 7182, 7228, 6671, 6766, 6100 ]
    - [ 10173, 7885, 7817, 7614, 7556, 7344, 7386, 6749, 6935, 6262 ]
    - [ 10286, 7732, 7690, 7556, 7420, 7262, 7295, 6961, 6857, 6232 ]
    - [ 10281, 7720, 7680, 7544, 7426, 7238, 7268, 6707, 6787, 6175 ]
    - [ 10325, 7734, 7694, 7526, 7420, 7255, 7239, 6828, 6748, 6218 ]
    - [ 10140, 7617, 7535, 7345, 7302, 7101, 7167, 6483, 6627, 6040 ]
    - [ 10122, 7559, 7519, 7363, 7270, 7078, 7119, 6554, 6595, 5998 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 23591, 13286, 12245, 12101, 13510, 14456, 13586, 13411, 12981, 10808 ]
    - [ 24076, 12985, 11812, 12737, 13138, 14077, 14120, 13512, 12494, 10904 ]
    - [ 22179, 12910, 12119, 12151, 12741, 14113, 13625, 13403, 12348, 10899 ]
    - [ 23853, 12684, 12062, 12005, 13011, 14722, 13623, 13641, 12875, 10774 ]
    - [ 24079, 12931, 12125, 11901, 13091, 14303, 13716, 13313, 12843, 10852 ]
    - [ 23180, 12574, 12016, 12499, 12659, 14466, 13269, 13029, 12257, 10613 ]
    - [ 24362, 12812, 12461, 12342, 13305, 14681, 13802, 13715, 12641, 10916 ]
    - [ 22557, 12316, 12233, 12320, 12613, 14110, 13217, 12904, 12138, 10515 ]
    - [ 23096, 12361, 12128, 11607, 12406, 14087, 13592, 12846, 12452, 10565 ]
    - [ 22553, 12558, 11988, 11872, 12606, 14306, 13603, 12954, 12602, 10242 ]
