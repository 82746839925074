#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [5, 58, 10/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [6, 3340, 9/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (1025219152/306900.0)]
BAL: [5, 34, 11/20, 0, 34, 2204, nominal aaload per usec]
BAS: [7, 6, 6/20, 0, 1, 126, nominal aastore per usec]
BEF: [1, 1, 19/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [5, 527, 11/20, 0, 527, 32087, nominal getfield per usec]
BPF: [6, 95, 9/20, 0, 83, 3863, nominal putfield per usec]
BUB: [10, 177, 1/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [9, 26, 3/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [7, 107, 7/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (14/13)']
GCC: [5, 841, 13/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [7, 107, 7/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (14/13)']
GCP: [9, 23, 3/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1614/6877)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (13/13)]
GMD: [1, 13, 20/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GMS: [4, 9, 15/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [1, 17, 20/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [7, 755, 7/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (2624.7/306.9)']
GTO: [5, 75, 10/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [10, 1083, 1/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [2, 23, 18/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 13, 11/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [2, 23, 18/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [5, 2, 12/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [9, 37, 3/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [8, 12, 6/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [6, 9, 9/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [3, 0, 16/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [10, 8, 2/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [2, 76, 18/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [8, 35, 6/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [4, 21, 15/22, 5, 23, 41, nominal backend bound (memory) ( 4212670480.7 / 4870023626.8) * 25 )]
UBP: [10, 134, 1/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.13732048016906906 * 71774158.6 ) / ( 71774158.6 + 1414513.4 )']
UBR: [9, 2653, 3/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.13732048016906906 * 1414513.4 ) / ( 71774158.6 + 1414513.4 ) )']
UBS: [10, 137, 1/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 20196698907.3 - 15321827256.4 ) / ( 6 * 5916660118.6 ) )]
UDC: [6, 14, 10/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 150865220.7/ 10732087606.6 )]
UDT: [5, 174, 12/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 1870079.4 / 10732087606.6 )]
UIP: [6, 181, 9/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 10732087606.6/5916660118.6)]
ULL: [4, 2138, 15/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 9249167.8) / 10732087606.6 ) )]
USB: [2, 25, 18/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 13697525161.8 / ( 6 * 8885671600.1 ) )]
USC: [2, 19, 18/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 1051432511.1 / ( 6 * 8909702757.1 ) )]
USF: [7, 32, 8/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 11499738612.2/( 6 * 5916660118.6) )]
