#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [2, 37, 17/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [4, 1462, 13/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (3649074776/2494700.0)]
BAL: [5, 39, 10/20, 0, 34, 2204, nominal aaload per usec]
BAS: [8, 13, 5/20, 0, 1, 126, nominal aastore per usec]
BEF: [7, 8, 6/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [3, 256, 15/20, 0, 527, 32087, nominal getfield per usec]
BPF: [5, 83, 11/20, 0, 83, 3863, nominal putfield per usec]
BUB: [8, 149, 4/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [10, 29, 1/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [6, 104, 9/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (26/25)']
GCC: [8, 3457, 6/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [6, 100, 9/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (25/25)']
GCP: [6, 7, 10/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (3362/42790)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (25/25)]
GMD: [3, 25, 17/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [1, 25, 18/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [6, 25, 10/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [4, 31, 14/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [8, 2024, 5/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (53005.8/2494.7)']
GTO: [7, 139, 7/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [6, 211, 10/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [10, 277, 2/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [7, 3, 8/22, 1, 3, 8, nominal execution time (sec)]
PFS: [10, 20, 1/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [10, 277, 2/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [3, 1, 16/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [3, 1, 17/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 19/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [5, 5, 13/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [10, 9, 1/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [7, 102, 8/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [6, 32, 9/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [1, 17, 20/22, 5, 23, 41, nominal backend bound (memory) ( 14157266928.2 / 16048467582.2) * 20 )]
UBP: [8, 85, 5/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.08691638449182561 * 264893091.0 ) / ( 264893091.0 + 3411161.8 )']
UBR: [5, 1105, 11/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.08691638449182561 * 3411161.8 ) / ( 264893091.0 + 3411161.8 ) )']
UBS: [8, 86, 5/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 91210985855.3 - 77683044156.6 ) / ( 6 * 25940528508.7 ) )]
UDC: [4, 9, 15/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 650998034.7/ 69651605315.5 )]
UDT: [3, 78, 16/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 5444876.2 / 69651605315.5 )]
UIP: [10, 268, 2/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 69651605315.5/25940528508.7)]
ULL: [1, 1160, 20/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 46914622.7) / 69651605315.5 ) )]
USB: [1, 20, 20/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 39631740194.9 / ( 6 * 32572837513.7 ) )]
USC: [4, 35, 15/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 7061455357.7 / ( 6 * 33375737865.8 ) )]
USF: [5, 21, 13/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 33105525247.5/( 6 * 25940528508.7) )]
