#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 3292, 2898, 2991, 2822, 2538, 2622, 2692, 2706, 2567, 2483 ]
    - [ 3412, 3016, 2973, 3043, 2706, 2816, 2757, 2794, 2795, 2784 ]
    - [ 3393, 2840, 2855, 2653, 2591, 2538, 2470, 2569, 2551, 2644 ]
    - [ 3234, 2867, 2799, 2714, 2939, 2892, 2978, 2492, 2469, 2621 ]
    - [ 3454, 3017, 2871, 2911, 2690, 2767, 2645, 2678, 2593, 2656 ]
    - [ 3431, 2834, 2755, 2790, 2675, 2605, 2758, 2534, 2584, 2541 ]
    - [ 3398, 3151, 2899, 2848, 2763, 2913, 2731, 2720, 2759, 2741 ]
    - [ 3431, 2781, 2894, 3021, 3017, 3151, 2979, 2712, 2675, 2577 ]
    - [ 3306, 2878, 2851, 2916, 2891, 2853, 2780, 2582, 2559, 2781 ]
    - [ 3272, 2911, 2929, 2897, 2907, 3123, 3085, 2752, 2695, 2552 ]
  2.0:
    - [ 1561, 681, 571, 559, 526, 539, 516, 495, 492, 514 ]
    - [ 1708, 735, 634, 594, 555, 570, 560, 541, 505, 514 ]
    - [ 1591, 722, 622, 603, 574, 587, 545, 536, 530, 547 ]
    - [ 1563, 728, 619, 622, 614, 602, 572, 576, 542, 547 ]
    - [ 1621, 725, 625, 603, 584, 600, 562, 547, 543, 572 ]
    - [ 1528, 709, 631, 610, 570, 587, 560, 550, 558, 521 ]
    - [ 1515, 653, 613, 598, 585, 600, 598, 551, 569, 550 ]
    - [ 1615, 715, 624, 594, 568, 596, 588, 572, 553, 543 ]
    - [ 1545, 681, 605, 569, 577, 556, 570, 549, 558, 530 ]
    - [ 1520, 665, 589, 605, 572, 585, 558, 532, 528, 501 ]
  3.0:
    - [ 1436, 604, 500, 475, 464, 460, 447, 433, 429, 437 ]
    - [ 1405, 558, 510, 476, 463, 457, 440, 433, 425, 421 ]
    - [ 1400, 520, 478, 438, 436, 436, 417, 397, 406, 397 ]
    - [ 1403, 572, 485, 476, 451, 459, 449, 425, 430, 418 ]
    - [ 1399, 602, 506, 498, 476, 462, 448, 426, 430, 423 ]
    - [ 1437, 624, 518, 553, 516, 468, 455, 435, 424, 410 ]
    - [ 1387, 553, 482, 456, 437, 426, 426, 413, 393, 404 ]
    - [ 1599, 631, 508, 485, 455, 457, 438, 433, 427, 429 ]
    - [ 1446, 557, 476, 453, 448, 431, 425, 419, 441, 436 ]
    - [ 1406, 558, 508, 475, 463, 461, 437, 438, 426, 432 ]
  4.0:
    - [ 1390, 487, 393, 370, 350, 354, 350, 323, 336, 312 ]
    - [ 1380, 489, 430, 396, 380, 374, 359, 343, 339, 334 ]
    - [ 1376, 503, 429, 388, 381, 367, 358, 336, 336, 329 ]
    - [ 1369, 504, 406, 384, 368, 361, 352, 340, 331, 328 ]
    - [ 1408, 480, 402, 376, 365, 346, 333, 320, 321, 310 ]
    - [ 1374, 493, 397, 376, 364, 346, 332, 320, 324, 313 ]
    - [ 1355, 489, 412, 377, 365, 364, 343, 330, 331, 327 ]
    - [ 1368, 495, 394, 381, 353, 350, 349, 317, 317, 311 ]
    - [ 1365, 490, 398, 384, 359, 350, 338, 321, 319, 313 ]
    - [ 1446, 507, 425, 396, 389, 365, 354, 344, 340, 338 ]
  5.0:
    - [ 1388, 494, 398, 374, 354, 344, 331, 318, 315, 315 ]
    - [ 1380, 489, 406, 371, 361, 350, 331, 320, 317, 311 ]
    - [ 1406, 491, 400, 371, 352, 347, 326, 315, 310, 306 ]
    - [ 1371, 492, 408, 379, 360, 357, 343, 329, 326, 319 ]
    - [ 1433, 512, 403, 363, 354, 345, 329, 318, 312, 307 ]
    - [ 1425, 496, 408, 374, 355, 348, 333, 324, 319, 314 ]
    - [ 1448, 508, 414, 384, 363, 341, 333, 314, 311, 312 ]
    - [ 1378, 503, 400, 376, 359, 350, 337, 325, 329, 321 ]
    - [ 1436, 496, 405, 379, 359, 335, 336, 318, 314, 310 ]
    - [ 1385, 473, 397, 359, 344, 336, 328, 314, 308, 305 ]
  6.0:
    - [ 1415, 499, 418, 389, 374, 360, 348, 332, 324, 325 ]
    - [ 1395, 490, 403, 368, 360, 339, 335, 317, 314, 313 ]
    - [ 1384, 492, 400, 375, 355, 347, 333, 330, 321, 315 ]
    - [ 1443, 512, 406, 376, 357, 362, 343, 330, 325, 321 ]
    - [ 1367, 489, 403, 372, 363, 347, 339, 326, 319, 324 ]
    - [ 1384, 495, 402, 370, 353, 347, 333, 318, 312, 308 ]
    - [ 1398, 527, 411, 390, 377, 374, 348, 333, 330, 328 ]
    - [ 1370, 486, 404, 372, 353, 350, 338, 323, 323, 323 ]
    - [ 1350, 477, 390, 362, 347, 332, 325, 315, 311, 305 ]
    - [ 1372, 497, 403, 394, 376, 356, 356, 342, 337, 331 ]
  7.0:
    - [ 1393, 500, 405, 382, 353, 349, 332, 324, 320, 315 ]
    - [ 1394, 488, 403, 370, 354, 359, 346, 318, 318, 317 ]
    - [ 1391, 490, 392, 369, 348, 343, 330, 314, 310, 307 ]
    - [ 1371, 490, 400, 373, 357, 344, 328, 317, 312, 307 ]
    - [ 1445, 511, 405, 372, 356, 342, 326, 311, 313, 304 ]
    - [ 1385, 500, 395, 376, 356, 355, 344, 320, 312, 313 ]
    - [ 1383, 493, 401, 374, 353, 345, 333, 319, 318, 312 ]
    - [ 1416, 528, 425, 382, 366, 369, 340, 325, 325, 322 ]
    - [ 1385, 486, 392, 364, 353, 344, 329, 313, 312, 309 ]
    - [ 1405, 509, 396, 366, 353, 344, 333, 322, 313, 308 ]
  8.0:
    - [ 1395, 493, 388, 364, 355, 339, 333, 320, 311, 311 ]
    - [ 1380, 489, 392, 373, 356, 346, 335, 321, 319, 316 ]
    - [ 1385, 486, 392, 368, 363, 351, 336, 322, 319, 313 ]
    - [ 1518, 520, 390, 381, 349, 343, 332, 315, 314, 311 ]
    - [ 1474, 486, 400, 376, 360, 344, 337, 325, 319, 313 ]
    - [ 1444, 500, 418, 431, 370, 375, 364, 325, 337, 324 ]
    - [ 1394, 502, 401, 373, 359, 351, 335, 320, 314, 312 ]
    - [ 1383, 482, 409, 374, 368, 352, 346, 322, 317, 317 ]
    - [ 1393, 506, 416, 386, 373, 358, 349, 336, 333, 325 ]
    - [ 1391, 495, 411, 376, 367, 354, 332, 324, 321, 314 ]
  9.0:
    - [ 1462, 512, 410, 373, 354, 337, 335, 316, 310, 309 ]
    - [ 1382, 482, 392, 374, 348, 341, 332, 316, 311, 310 ]
    - [ 1413, 487, 397, 362, 356, 349, 333, 316, 305, 307 ]
    - [ 1382, 492, 409, 368, 355, 338, 327, 319, 310, 308 ]
    - [ 1395, 482, 400, 367, 358, 341, 330, 316, 311, 306 ]
    - [ 1430, 486, 398, 359, 342, 340, 330, 312, 311, 308 ]
    - [ 1411, 506, 411, 377, 358, 348, 339, 319, 313, 314 ]
    - [ 1395, 481, 393, 406, 386, 356, 349, 328, 350, 312 ]
    - [ 1404, 478, 386, 369, 352, 348, 326, 312, 313, 307 ]
    - [ 1411, 485, 393, 369, 351, 340, 328, 316, 312, 307 ]
  10.0:
    - [ 1374, 478, 394, 376, 357, 344, 334, 313, 310, 306 ]
    - [ 1362, 493, 404, 365, 355, 343, 335, 318, 314, 315 ]
    - [ 1386, 478, 398, 369, 352, 337, 331, 316, 312, 308 ]
    - [ 1362, 475, 401, 370, 354, 369, 325, 311, 307, 309 ]
    - [ 1384, 481, 385, 367, 349, 342, 327, 311, 306, 302 ]
    - [ 1414, 488, 393, 365, 353, 346, 329, 314, 309, 303 ]
    - [ 1362, 481, 388, 367, 349, 354, 329, 313, 310, 306 ]
    - [ 1396, 492, 390, 360, 355, 346, 336, 312, 312, 308 ]
    - [ 1464, 484, 400, 366, 359, 348, 336, 313, 306, 304 ]
    - [ 1395, 495, 391, 377, 360, 342, 334, 317, 314, 308 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 4302, 2167, 2001, 1845, 1731, 1661, 1608, 1583, 1511, 1539 ]
    - [ 4322, 2289, 1945, 1909, 1805, 1753, 1719, 1586, 1636, 1626 ]
    - [ 4389, 2149, 1993, 1897, 1777, 1685, 1708, 1643, 1499, 1517 ]
    - [ 4292, 2290, 2008, 1907, 1821, 1687, 1631, 1583, 1628, 1530 ]
    - [ 4371, 2301, 1935, 1917, 1750, 1702, 1691, 1666, 1597, 1616 ]
    - [ 4283, 2228, 1944, 1992, 1711, 1699, 1622, 1564, 1542, 1549 ]
    - [ 4364, 2315, 1916, 1968, 1855, 1705, 1744, 1649, 1536, 1560 ]
    - [ 4392, 2229, 2005, 1856, 1811, 1629, 1697, 1616, 1642, 1583 ]
    - [ 4309, 2335, 1913, 1846, 1841, 1670, 1627, 1601, 1527, 1568 ]
    - [ 4383, 2276, 1945, 1912, 1720, 1727, 1658, 1647, 1659, 1614 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1479, 557, 518, 493, 480, 497, 467, 453, 462, 483 ]
    - [ 1561, 635, 560, 546, 541, 538, 486, 496, 500, 488 ]
    - [ 1569, 669, 605, 566, 557, 557, 563, 528, 519, 556 ]
    - [ 1539, 685, 622, 586, 586, 555, 575, 532, 528, 523 ]
    - [ 1519, 596, 495, 531, 517, 499, 464, 473, 454, 453 ]
    - [ 1568, 679, 579, 546, 549, 566, 518, 523, 520, 521 ]
    - [ 1575, 655, 631, 593, 532, 553, 529, 522, 528, 525 ]
    - [ 1521, 659, 570, 562, 559, 556, 571, 555, 511, 513 ]
    - [ 1498, 603, 501, 530, 520, 514, 507, 477, 484, 481 ]
    - [ 1561, 675, 620, 576, 599, 589, 540, 552, 518, 525 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1519, 665, 605, 559, 568, 557, 540, 528, 521, 519 ]
    - [ 1568, 724, 629, 654, 686, 622, 613, 597, 593, 590 ]
    - [ 1566, 719, 579, 578, 615, 551, 537, 517, 515, 530 ]
    - [ 1579, 728, 637, 662, 689, 614, 604, 596, 590, 590 ]
    - [ 1516, 663, 560, 588, 588, 556, 535, 527, 520, 490 ]
    - [ 1616, 694, 567, 614, 620, 546, 531, 521, 512, 482 ]
    - [ 1590, 736, 657, 665, 668, 608, 525, 510, 504, 487 ]
    - [ 1543, 669, 577, 567, 606, 556, 545, 597, 590, 569 ]
    - [ 1583, 729, 641, 667, 658, 624, 610, 598, 592, 565 ]
    - [ 1576, 733, 641, 667, 690, 622, 610, 600, 595, 597 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 2309, 1603, 1634, 1888, 1684, 1595, 1794, 2452, 2268, 2275 ]
    - [ 2131, 1550, 2067, 1916, 1653, 2021, 1834, 2155, 2512, 2936 ]
    - [ 2074, 1424, 1817, 1759, 1465, 1815, 1832, 2185, 2754, 3214 ]
    - [ 2224, 1584, 1695, 1890, 1370, 1717, 1532, 1770, 1923, 2460 ]
    - [ 2168, 1478, 1978, 2079, 2007, 1478, 2020, 3255, 3244, 3467 ]
    - [ 2076, 1525, 1578, 1472, 1493, 1581, 1615, 2007, 1896, 1724 ]
    - [ 2278, 1527, 1808, 1322, 1653, 1757, 1541, 1821, 2468, 1915 ]
    - [ 2348, 1514, 2217, 1957, 1791, 1691, 1448, 1928, 1569, 1510 ]
    - [ 2319, 1410, 1688, 2087, 2209, 2097, 2453, 2249, 2261, 2148 ]
    - [ 2075, 1519, 1464, 1627, 1594, 1481, 1624, 2266, 2680, 2911 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 2605, 1837, 1746, 1782, 1884, 1851, 1851, 1788, 1806, 1748 ]
    - [ 2649, 1864, 1811, 1771, 1920, 1921, 1873, 1884, 1893, 1907 ]
    - [ 2685, 1893, 1868, 1808, 1907, 1808, 1887, 1842, 1937, 1762 ]
    - [ 2638, 1815, 1801, 1786, 1804, 1742, 1823, 1778, 1810, 1697 ]
    - [ 2570, 1834, 1824, 1741, 1780, 1749, 1719, 1746, 1666, 1639 ]
    - [ 2648, 1832, 1834, 1794, 1849, 1873, 1842, 1842, 1907, 1802 ]
    - [ 2761, 2009, 1852, 1760, 1824, 1815, 1775, 1771, 1680, 1673 ]
    - [ 2534, 1804, 1780, 1808, 1784, 1868, 1855, 1844, 1875, 1902 ]
    - [ 2661, 1914, 1815, 1847, 1874, 1828, 1844, 1879, 1893, 1859 ]
    - [ 2675, 1862, 1782, 1752, 1805, 1808, 1818, 1691, 1715, 1705 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 6252, 4583, 4870, 5878, 4672, 4501, 4790, 4549, 4274, 4390 ]
    - [ 6235, 5467, 4886, 4632, 4959, 8092, 4581, 4543, 5127, 9408 ]
    - [ 6507, 4941, 4541, 4704, 4604, 4883, 5106, 4191, 4028, 4116 ]
    - [ 7396, 7517, 9128, 5978, 4674, 4853, 6481, 4815, 4804, 6861 ]
    - [ 6096, 4640, 6426, 4535, 4684, 4373, 4403, 4287, 4159, 4238 ]
    - [ 6175, 6203, 4902, 4313, 4968, 5005, 4950, 9932, 4876, 5220 ]
    - [ 6146, 5400, 12148, 15299, 4718, 4188, 4510, 4102, 7180, 4185 ]
    - [ 6398, 4900, 4833, 8325, 4179, 4188, 4192, 4196, 4091, 4465 ]
    - [ 6371, 6034, 4712, 4630, 4690, 6484, 4803, 4266, 4478, 4847 ]
    - [ 6088, 4449, 4745, 4453, 4090, 4213, 6087, 3908, 4554, 4556 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 9818, 8958, 9002, 9007, 8985 ]
    - [ 9865, 9045, 8986, 9038, 9025 ]
    - [ 9825, 8984, 8923, 8956, 8970 ]
    - [ 9696, 8901, 8930, 8918, 8914 ]
    - [ 9665, 8888, 8901, 8861, 8832 ]
    - [ 9780, 8974, 8886, 8840, 8882 ]
    - [ 9785, 8946, 9004, 9031, 9017 ]
    - [ 9786, 8936, 8945, 8918, 8934 ]
    - [ 9737, 8821, 8898, 8930, 8936 ]
    - [ 9837, 8941, 8864, 8888, 8942 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3753, 935, 850, 847, 851 ]
    - [ 3710, 935, 842, 842, 835 ]
    - [ 3657, 867, 798, 790, 803 ]
    - [ 3705, 877, 809, 797, 811 ]
    - [ 3713, 862, 793, 784, 791 ]
    - [ 3695, 883, 825, 808, 794 ]
    - [ 3745, 909, 808, 786, 813 ]
    - [ 3706, 885, 783, 772, 769 ]
    - [ 3726, 957, 862, 852, 863 ]
    - [ 3737, 941, 819, 794, 802 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1361, 819, 800, 782, 780 ]
    - [ 1397, 795, 788, 775, 782 ]
    - [ 1430, 818, 813, 789, 769 ]
    - [ 1373, 763, 777, 736, 747 ]
    - [ 1414, 827, 806, 800, 783 ]
    - [ 1385, 786, 786, 807, 810 ]
    - [ 1395, 813, 830, 811, 794 ]
    - [ 1357, 778, 765, 754, 753 ]
    - [ 1380, 779, 764, 760, 778 ]
    - [ 1359, 774, 773, 757, 773 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 18985, 1344, 859, 771, 745 ]
    - [ 20047, 1314, 822, 725, 677 ]
    - [ 18347, 1289, 787, 686, 654 ]
    - [ 18400, 1272, 791, 696, 659 ]
    - [ 18261, 1251, 812, 697, 665 ]
    - [ 18372, 1363, 854, 750, 720 ]
    - [ 18606, 1354, 856, 770, 729 ]
    - [ 18676, 1291, 810, 705, 667 ]
    - [ 18482, 1379, 862, 762, 720 ]
    - [ 18351, 1295, 789, 680, 649 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2494, 1011, 749, 669, 611 ]
    - [ 2463, 989, 734, 680, 620 ]
    - [ 2462, 987, 722, 651, 616 ]
    - [ 2504, 1011, 775, 711, 688 ]
    - [ 2500, 1031, 752, 674, 650 ]
    - [ 2516, 1027, 796, 710, 693 ]
    - [ 2523, 1038, 776, 722, 695 ]
    - [ 2510, 1045, 781, 714, 676 ]
    - [ 2476, 999, 750, 675, 642 ]
    - [ 2484, 1029, 782, 705, 675 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 2182, 1002, 814, 804, 774 ]
    - [ 2230, 1014, 841, 795, 782 ]
    - [ 2383, 1091, 813, 790, 758 ]
    - [ 2154, 994, 841, 797, 760 ]
    - [ 2184, 980, 809, 786, 765 ]
    - [ 2276, 1016, 848, 791, 828 ]
    - [ 2271, 1009, 843, 771, 771 ]
    - [ 2235, 1014, 848, 801, 784 ]
    - [ 2173, 984, 831, 810, 797 ]
    - [ 2218, 1005, 882, 797, 795 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1565, 720, 646, 598, 599 ]
    - [ 1602, 678, 599, 579, 556 ]
    - [ 1569, 697, 628, 606, 594 ]
    - [ 1615, 716, 605, 599, 564 ]
    - [ 1500, 646, 569, 557, 527 ]
    - [ 1551, 693, 629, 587, 567 ]
    - [ 1525, 675, 570, 546, 526 ]
    - [ 1558, 717, 621, 596, 590 ]
    - [ 1530, 718, 578, 562, 540 ]
    - [ 1540, 705, 618, 574, 600 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1775, 780, 690, 651, 628 ]
    - [ 1658, 765, 665, 636, 633 ]
    - [ 1735, 777, 659, 675, 634 ]
    - [ 1706, 792, 663, 637, 629 ]
    - [ 1676, 759, 665, 651, 635 ]
    - [ 1695, 733, 646, 642, 634 ]
    - [ 1653, 763, 679, 693, 655 ]
    - [ 1834, 818, 655, 608, 647 ]
    - [ 1699, 758, 661, 619, 631 ]
    - [ 1696, 773, 683, 644, 633 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1379, 574, 515, 494, 493 ]
    - [ 1355, 618, 523, 515, 488 ]
    - [ 1355, 605, 528, 508, 492 ]
    - [ 1359, 621, 532, 503, 493 ]
    - [ 1336, 614, 559, 544, 518 ]
    - [ 1349, 646, 557, 526, 502 ]
    - [ 1368, 616, 551, 548, 502 ]
    - [ 1348, 591, 509, 478, 476 ]
    - [ 1380, 641, 549, 507, 523 ]
    - [ 1377, 620, 551, 520, 511 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 2174, 961, 834, 826, 816, 805, 780, 767, 739, 773 ]
    - [ 2131, 944, 826, 806, 783, 771, 793, 744, 779, 719 ]
    - [ 2164, 980, 852, 853, 804, 810, 784, 751, 764, 737 ]
    - [ 2176, 984, 834, 821, 788, 818, 766, 782, 737, 709 ]
    - [ 2168, 956, 827, 825, 792, 825, 773, 728, 728, 747 ]
    - [ 2142, 977, 823, 815, 793, 802, 731, 755, 740, 693 ]
    - [ 2159, 968, 823, 818, 803, 795, 744, 740, 718, 753 ]
    - [ 2145, 957, 831, 819, 828, 791, 783, 731, 741, 700 ]
    - [ 2188, 1011, 834, 843, 807, 780, 775, 724, 744, 747 ]
    - [ 2110, 965, 836, 825, 835, 793, 776, 755, 784, 753 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 5959, 3110, 2641, 2536, 2383, 2291, 2260, 2234, 2002, 2149 ]
    - [ 5972, 3065, 2534, 2575, 2387, 2345, 2224, 2284, 2069, 2127 ]
    - [ 5987, 3027, 2573, 2537, 2423, 2279, 2267, 2088, 2185, 2067 ]
    - [ 6044, 3074, 2675, 2490, 2336, 2379, 2217, 2194, 2143, 2107 ]
    - [ 5953, 3167, 2774, 2519, 2359, 2224, 2165, 2204, 2139, 2072 ]
    - [ 5950, 2966, 2656, 2670, 2396, 2203, 2272, 2246, 2081, 2147 ]
    - [ 5958, 2975, 2644, 2580, 2395, 2279, 2314, 2152, 2030, 2179 ]
    - [ 6079, 3091, 2697, 2717, 2409, 2211, 2219, 2303, 2100, 2147 ]
    - [ 6042, 3091, 2539, 2573, 2390, 2336, 2297, 2110, 2168, 2190 ]
    - [ 6097, 3023, 2634, 2513, 2431, 2302, 2321, 2271, 2172, 2047 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 3575, 1525, 1293, 1230, 1193, 1144, 1124, 1108, 1088, 1068 ]
    - [ 3598, 1503, 1222, 1232, 1157, 1151, 1123, 1074, 1048, 1048 ]
    - [ 3573, 1476, 1248, 1184, 1150, 1101, 1086, 1074, 1037, 1035 ]
    - [ 3593, 1499, 1299, 1249, 1206, 1167, 1121, 1082, 1089, 1083 ]
    - [ 3525, 1518, 1306, 1209, 1203, 1137, 1132, 1114, 1053, 1108 ]
    - [ 3528, 1485, 1251, 1168, 1179, 1126, 1089, 1093, 1060, 1039 ]
    - [ 3657, 1491, 1295, 1231, 1175, 1131, 1096, 1110, 1028, 1027 ]
    - [ 3497, 1448, 1261, 1196, 1159, 1123, 1104, 1069, 1042, 1057 ]
    - [ 3594, 1511, 1271, 1206, 1194, 1153, 1111, 1109, 1075, 1092 ]
    - [ 3676, 1522, 1294, 1233, 1176, 1154, 1086, 1099, 1026, 1041 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 8513, 4591, 3798, 3647, 3213, 3177, 3000, 2821, 2842, 2785 ]
    - [ 8473, 4614, 3837, 3684, 3314, 3123, 3111, 2952, 2650, 2782 ]
    - [ 8425, 4380, 3727, 3540, 3047, 3039, 2987, 2970, 2860, 2837 ]
    - [ 8341, 4486, 3865, 3677, 3225, 3061, 3092, 2837, 2923, 2788 ]
    - [ 8461, 4589, 3709, 3561, 3074, 2941, 3021, 2955, 2804, 2820 ]
    - [ 8546, 4449, 3813, 3648, 3200, 2947, 3002, 2915, 2753, 2748 ]
    - [ 8482, 4322, 3761, 3569, 3135, 3044, 3080, 2830, 2832, 2759 ]
    - [ 8390, 4446, 3772, 3382, 3104, 3078, 2912, 2925, 2824, 2631 ]
    - [ 8398, 4465, 3742, 3404, 3134, 3000, 3130, 2871, 2702, 2772 ]
    - [ 8511, 4365, 3919, 3500, 3089, 3022, 3023, 2984, 2876, 2722 ]
