#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [1, 32, 19/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [3, 24, 14/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [8, 6721, 5/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (6337924424/942900.0)]
BAL: [6, 82, 8/20, 0, 34, 2204, nominal aaload per usec]
BAS: [6, 1, 9/20, 0, 1, 126, nominal aastore per usec]
BEF: [5, 4, 11/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [6, 1719, 8/20, 0, 527, 32087, nominal getfield per usec]
BPF: [8, 583, 5/20, 0, 83, 3863, nominal putfield per usec]
BUB: [7, 95, 7/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [7, 15, 7/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [10, 133, 1/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (262/196)']
GCC: [4, 781, 14/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [10, 144, 1/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (283/196)']
GCP: [8, 16, 5/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (2587/15287)]
GLK: [7, 5, 7/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (196/186)]
GMD: [9, 191, 3/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [9, 3519, 2/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [3, 7, 16/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [10, 269, 2/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [7, 467, 8/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (5355/942.9)']
GTO: [3, 32, 15/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [5, 179, 13/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [6, 74, 10/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 11, 13/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [6, 74, 10/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [3, 1, 16/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [9, 31, 4/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [8, 19, 5/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [7, 10, 8/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [9, 7, 4/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [8, 112, 6/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [10, 47, 2/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [8, 35, 5/22, 5, 23, 41, nominal backend bound (memory) ( 49527924847.8 / 55592246789.7) * 40 )]
UBP: [5, 38, 13/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.03987660337652507 * 257948491.8 ) / ( 257948491.8 + 8663520.0 )']
UBR: [7, 1295, 7/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.03987660337652507 * 8663520.0 ) / ( 257948491.8 + 8663520.0 ) )']
UBS: [5, 39, 12/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 100917811973.1 - 82724922849.3 ) / ( 6 * 76038276346.4 ) )]
UDC: [7, 16, 8/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 1362591673.9/ 82889927040.1 )]
UDT: [6, 258, 9/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 21435101.4 / 82889927040.1 )]
UIP: [2, 109, 18/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 82889927040.1/76038276346.4)]
ULL: [8, 4478, 6/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 187355859.6) / 82889927040.1 ) )]
USB: [8, 40, 5/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 191197743339.4 / ( 6 * 79018233234.1 ) )]
USC: [8, 155, 5/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 73435084899.6 / ( 6 * 78746961946.9 ) )]
USF: [5, 21, 13/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 99820568779.0/( 6 * 76038276346.4) )]
