#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 136335, 131589, 132156, 132159, 132219, 131812, 132560, 132130, 132264, 133079 ]
    - [ 137345, 132030, 132365, 132183, 131968, 131917, 132469, 131999, 134637, 133408 ]
    - [ 136762, 132474, 133641, 132599, 133453, 132438, 132319, 132491, 133256, 133707 ]
    - [ 136827, 132123, 133627, 133343, 133573, 133682, 131854, 133804, 133989, 133697 ]
    - [ 136131, 131619, 132390, 132205, 131938, 131995, 131973, 132315, 132217, 132943 ]
    - [ 136651, 132147, 131965, 131823, 132199, 132673, 132463, 132296, 132456, 133395 ]
    - [ 136453, 132474, 132361, 132564, 132544, 132276, 132371, 132605, 137500, 133184 ]
    - [ 137097, 132944, 132895, 132506, 132514, 132755, 132207, 132665, 134691, 133423 ]
    - [ 136085, 132576, 132144, 132318, 131906, 131520, 131423, 132193, 131634, 132541 ]
    - [ 136523, 132863, 132139, 132684, 132739, 132554, 132628, 132971, 132952, 133969 ]
  2.0:
    - [ 3862, 3430, 3200, 2795, 2939, 3294, 3335, 3226, 3572, 3539 ]
    - [ 3207, 3256, 3463, 3039, 3061, 3071, 3005, 3251, 3265, 3332 ]
    - [ 3296, 3236, 3562, 3293, 3013, 3250, 3398, 3350, 3276, 3114 ]
    - [ 3391, 3139, 3240, 3385, 3322, 3371, 3262, 3318, 3467, 3077 ]
    - [ 3305, 3208, 3308, 3742, 3384, 3246, 3432, 3356, 3374, 3149 ]
    - [ 3379, 3394, 3271, 3462, 3481, 3341, 3292, 3139, 3216, 3444 ]
    - [ 3662, 3284, 3400, 3401, 3503, 3413, 3165, 3147, 3381, 3423 ]
    - [ 4038, 3532, 2863, 3479, 3312, 3468, 3432, 3282, 3345, 3010 ]
    - [ 3573, 3297, 3573, 3741, 3312, 2871, 3275, 3475, 3233, 2702 ]
    - [ 3642, 3517, 3488, 3769, 3335, 3519, 3257, 3364, 3088, 3484 ]
  3.0:
    - [ 3465, 3102, 2844, 3213, 2447, 2703, 2875, 2584, 2541, 2882 ]
    - [ 3371, 2613, 2624, 2328, 2756, 2842, 2806, 2577, 2776, 2951 ]
    - [ 3067, 2780, 2570, 2741, 2787, 3096, 2559, 2623, 2661, 2669 ]
    - [ 3351, 2443, 2860, 3054, 2607, 2612, 2846, 2868, 2699, 3147 ]
    - [ 2620, 2800, 3003, 2541, 3266, 2732, 3167, 2921, 3117, 2557 ]
    - [ 4010, 2703, 2682, 2532, 3297, 2499, 2412, 3065, 2767, 3185 ]
    - [ 3083, 2586, 2901, 2808, 2884, 2792, 2581, 3066, 2448, 2812 ]
    - [ 4194, 3065, 2961, 2883, 2912, 2762, 2355, 2700, 3410, 2746 ]
    - [ 3373, 2296, 2407, 2545, 2654, 2646, 2919, 2649, 2666, 2760 ]
    - [ 2745, 3216, 2757, 2410, 2357, 2590, 2275, 2986, 2703, 2949 ]
  4.0:
    - [ 2451, 3002, 2449, 2847, 3001, 2225, 2400, 2756, 2878, 2429 ]
    - [ 3640, 2360, 3096, 3175, 2724, 2234, 2351, 2346, 3002, 2456 ]
    - [ 2905, 2534, 2401, 2442, 2111, 2060, 2391, 2191, 2453, 2407 ]
    - [ 3242, 2984, 2966, 2332, 2251, 2956, 3006, 2490, 2857, 2175 ]
    - [ 2997, 2425, 2566, 2165, 2188, 2170, 2421, 2883, 2373, 2348 ]
    - [ 3302, 2889, 2835, 2136, 2391, 2814, 3091, 2737, 3122, 2584 ]
    - [ 2776, 2425, 2786, 2526, 2918, 2326, 2704, 2421, 2131, 2871 ]
    - [ 3713, 3057, 3080, 2673, 2145, 2414, 2474, 2267, 2444, 2378 ]
    - [ 3310, 3268, 3151, 2211, 2047, 2152, 2910, 3125, 2532, 2480 ]
    - [ 2319, 2947, 2795, 2095, 2235, 2649, 2940, 2168, 2826, 2348 ]
  5.0:
    - [ 3096, 2913, 2274, 2553, 2873, 2368, 2057, 1946, 2818, 2527 ]
    - [ 2764, 2680, 2548, 3092, 2853, 2211, 2313, 3060, 2698, 2657 ]
    - [ 3130, 3055, 2149, 2113, 2700, 2763, 2277, 2393, 2004, 2023 ]
    - [ 2716, 2266, 2415, 2760, 2323, 2142, 2974, 2083, 2234, 2813 ]
    - [ 3308, 2921, 2872, 2073, 2247, 2511, 3209, 2791, 2234, 2378 ]
    - [ 3037, 2185, 2086, 2961, 2002, 2018, 2369, 3045, 2215, 2345 ]
    - [ 2430, 2281, 2341, 2805, 1967, 2051, 2223, 2182, 2469, 2749 ]
    - [ 2860, 2042, 2193, 2193, 2516, 2657, 2187, 2547, 3055, 2427 ]
    - [ 2511, 2192, 2828, 2454, 2991, 1946, 2375, 2761, 2444, 2049 ]
    - [ 2659, 2970, 2166, 2767, 2032, 2503, 2899, 2323, 2658, 2114 ]
  6.0:
    - [ 3269, 2921, 2654, 2076, 2697, 3010, 2801, 2364, 2310, 2916 ]
    - [ 2484, 2766, 2191, 2238, 2151, 1989, 2217, 2826, 2671, 1947 ]
    - [ 2736, 2446, 2267, 1713, 1998, 2968, 1899, 2077, 2266, 2670 ]
    - [ 2518, 2931, 2154, 2069, 2625, 2089, 3066, 2763, 2087, 2258 ]
    - [ 2813, 2286, 2038, 2222, 2161, 2260, 2247, 2136, 2117, 2186 ]
    - [ 2490, 3038, 2207, 2010, 2084, 2064, 2824, 2813, 2041, 2862 ]
    - [ 2828, 2826, 2281, 2092, 3098, 1731, 3113, 2869, 3162, 2171 ]
    - [ 2752, 2373, 2091, 2783, 2935, 2056, 2153, 2846, 2692, 2923 ]
    - [ 2748, 2329, 3013, 2144, 2268, 2873, 1977, 2174, 1897, 2078 ]
    - [ 3080, 2244, 2805, 1956, 2981, 3016, 2689, 2948, 2938, 2852 ]
  7.0:
    - [ 2261, 1942, 2324, 2803, 3139, 1945, 2910, 2728, 1844, 2118 ]
    - [ 3190, 2940, 2616, 2010, 2341, 2693, 2018, 2822, 2725, 2757 ]
    - [ 2566, 1960, 2698, 2662, 2216, 1845, 2633, 2068, 2415, 2711 ]
    - [ 3597, 1989, 2585, 1941, 2765, 2149, 2871, 2622, 2219, 2623 ]
    - [ 2552, 2984, 2767, 2762, 2160, 2934, 1739, 2732, 2119, 1879 ]
    - [ 2773, 3353, 2883, 2369, 2570, 2474, 2776, 2202, 2184, 3264 ]
    - [ 2278, 2226, 2185, 2312, 1952, 1871, 2161, 2066, 2102, 2299 ]
    - [ 3272, 1830, 2093, 2662, 3039, 1702, 2110, 2885, 1984, 2872 ]
    - [ 2801, 2978, 2373, 2138, 2180, 2947, 2885, 2847, 2948, 1876 ]
    - [ 2641, 3096, 2064, 2064, 1878, 2456, 2924, 1936, 2922, 2061 ]
  8.0:
    - [ 2251, 2099, 2824, 2603, 2205, 1989, 1887, 2812, 2448, 2519 ]
    - [ 3154, 2626, 2887, 1947, 2232, 1800, 2902, 1807, 2670, 2156 ]
    - [ 3060, 1896, 2583, 1960, 2841, 2700, 2880, 2115, 2717, 2801 ]
    - [ 2682, 2890, 3056, 2499, 2917, 2804, 2072, 2901, 2824, 2203 ]
    - [ 3569, 2167, 2196, 2082, 1911, 2865, 2092, 2169, 2823, 2690 ]
    - [ 2598, 1953, 2596, 2042, 2525, 2040, 2531, 2563, 2728, 2114 ]
    - [ 3386, 2282, 2248, 1743, 2045, 2747, 1895, 1993, 1862, 2147 ]
    - [ 3009, 1951, 2880, 2701, 2190, 2274, 1674, 2068, 2987, 2064 ]
    - [ 3698, 2200, 2766, 1932, 2039, 2753, 2295, 3102, 2271, 2280 ]
    - [ 3241, 2135, 1695, 2347, 2822, 2632, 2031, 1885, 2539, 2376 ]
  9.0:
    - [ 3124, 2076, 2295, 3131, 2767, 2858, 1834, 2576, 1948, 2386 ]
    - [ 2334, 1902, 2715, 2689, 1803, 2298, 2853, 2179, 2729, 1826 ]
    - [ 2646, 2178, 2860, 2671, 1973, 1774, 2203, 1866, 2647, 3132 ]
    - [ 2528, 1785, 2850, 2209, 2989, 1916, 2033, 2136, 2575, 2712 ]
    - [ 2401, 2283, 1711, 2085, 2681, 2805, 2654, 2260, 1821, 2447 ]
    - [ 2317, 2816, 2867, 1898, 2229, 1760, 1897, 1840, 2464, 2705 ]
    - [ 2490, 2595, 1998, 2060, 1887, 2753, 2722, 2082, 2652, 2178 ]
    - [ 2220, 1946, 2209, 1885, 1904, 2584, 2880, 2838, 2174, 1828 ]
    - [ 3072, 2302, 2360, 2718, 2687, 2686, 2657, 1807, 2315, 2744 ]
    - [ 3397, 2173, 2781, 2004, 1753, 2778, 2774, 2639, 1958, 1819 ]
  10.0:
    - [ 3162, 2624, 2159, 2148, 2819, 1751, 2126, 2092, 2087, 2105 ]
    - [ 2305, 2062, 2670, 2110, 1801, 2168, 1819, 2674, 2048, 2857 ]
    - [ 3127, 2714, 1799, 2658, 2023, 2507, 1881, 2756, 1964, 1971 ]
    - [ 2962, 2121, 2643, 2060, 2031, 1924, 2718, 2909, 2439, 2730 ]
    - [ 3064, 2872, 1875, 1907, 2218, 2140, 1823, 2273, 2096, 2023 ]
    - [ 2476, 3457, 1759, 1830, 2825, 2314, 1996, 2859, 1796, 2925 ]
    - [ 2611, 2704, 2573, 2106, 2734, 2744, 1847, 2242, 2757, 2240 ]
    - [ 2440, 2164, 2759, 2689, 1688, 2963, 2178, 2882, 2478, 1803 ]
    - [ 2262, 1821, 2736, 2414, 1708, 2367, 2063, 2918, 1845, 1878 ]
    - [ 2123, 2826, 2532, 2452, 2003, 3071, 2115, 2604, 2786, 1918 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 27382, 27189, 26357, 26239, 26216, 27570, 26332, 26283, 27633, 26302 ]
    - [ 27173, 26433, 26321, 26225, 26179, 26192, 26136, 26302, 26221, 26283 ]
    - [ 27028, 26301, 26135, 26106, 26013, 26125, 26010, 26117, 26032, 26029 ]
    - [ 27291, 26333, 26227, 26228, 26166, 26117, 26189, 26255, 26239, 26205 ]
    - [ 27211, 26303, 26289, 26191, 26197, 26156, 26184, 26178, 26201, 26081 ]
    - [ 27273, 26464, 26481, 26467, 26359, 26366, 26345, 26471, 26317, 26351 ]
    - [ 27093, 26045, 25933, 25951, 25811, 25833, 25841, 25891, 25776, 25867 ]
    - [ 27065, 26240, 25763, 26180, 26098, 26061, 26127, 26212, 25974, 26165 ]
    - [ 27094, 26276, 26148, 26094, 26090, 26065, 26077, 26073, 26062, 25943 ]
    - [ 27047, 26224, 26173, 26077, 26105, 26062, 26154, 26167, 26077, 26058 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3547, 3820, 3018, 3827, 3736, 3370, 4012, 4538, 3342, 4444 ]
    - [ 4423, 4276, 4458, 4288, 3656, 3358, 4110, 4468, 4359, 3784 ]
    - [ 4149, 3463, 3876, 4932, 3203, 3957, 3452, 3587, 3905, 3206 ]
    - [ 4115, 3308, 3918, 3663, 4357, 3812, 3661, 3803, 4062, 3815 ]
    - [ 4081, 3976, 4276, 4224, 4178, 4733, 4104, 4199, 3759, 3582 ]
    - [ 3742, 4281, 3774, 3923, 5558, 4032, 3489, 4651, 3367, 3869 ]
    - [ 4222, 4240, 4139, 3444, 3380, 3845, 3310, 3773, 3386, 3877 ]
    - [ 4250, 4078, 3559, 4558, 3628, 3901, 4644, 3884, 4935, 3429 ]
    - [ 4104, 3959, 3493, 4781, 3977, 3681, 4518, 3702, 3199, 3689 ]
    - [ 4749, 4470, 4101, 4196, 3788, 3780, 3593, 3531, 3659, 3459 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 7583, 6163, 5532, 5999, 6164, 6463, 4941, 5974, 5568, 6527 ]
    - [ 6621, 6097, 6194, 5997, 5966, 5799, 5667, 6117, 6244, 5902 ]
    - [ 6604, 6614, 6265, 6033, 5767, 5451, 5509, 5240, 6695, 6794 ]
    - [ 7705, 6127, 6160, 5970, 6712, 6271, 6265, 6068, 6231, 6411 ]
    - [ 6544, 5827, 6283, 6148, 6215, 6102, 5524, 6452, 6105, 6365 ]
    - [ 7166, 6170, 5935, 6018, 6031, 6735, 5487, 5349, 6035, 6226 ]
    - [ 6554, 5965, 5850, 5599, 5900, 5824, 5412, 5894, 6276, 6460 ]
    - [ 7469, 5845, 5550, 5396, 6157, 6274, 5689, 5214, 6195, 6240 ]
    - [ 6524, 5744, 5969, 5248, 5705, 6457, 5907, 5486, 6244, 6003 ]
    - [ 6779, 6060, 5698, 5749, 6168, 5419, 5185, 5567, 6710, 6363 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 38942, 41125, 40525, 40240, 42416, 42028, 41735, 41602, 40654, 40582 ]
    - [ 40545, 40884, 40542, 39054, 41166, 41900, 41933, 42662, 41432, 40393 ]
    - [ 41651, 41306, 41181, 43015, 40868, 42516, 41868, 39489, 39886, 42626 ]
    - [ 43139, 39450, 40108, 40467, 39754, 40758, 42115, 42308, 40751, 42613 ]
    - [ 39830, 41315, 40692, 40413, 42601, 41576, 41586, 39553, 41441, 41383 ]
    - [ 39388, 42439, 40716, 40562, 41441, 40626, 42056, 40062, 40847, 41723 ]
    - [ 38697, 42609, 39958, 40678, 41896, 41412, 41359, 42544, 41350, 41798 ]
    - [ 40823, 40349, 39733, 41061, 39849, 42707, 40771, 40010, 42559, 39661 ]
    - [ 41728, 41608, 41552, 41216, 41570, 41661, 40913, 39923, 40920, 39599 ]
    - [ 40902, 40943, 40757, 40851, 40440, 41433, 41837, 42759, 41474, 41924 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 13744, 12852, 11663, 11311, 11203, 11179, 11486, 11257, 11166, 11270 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 16323, 15235, 14901, 15096, 15274, 15074, 15092, 15414, 15448, 15574 ]
    - [ 17276, 14730, 14790, 15433, 15447, 15365, 15097, 15497, 15536, 15610 ]
    - [ 16657, 15217, 15498, 15164, 15276, 15651, 15976, 15260, 15432, 14829 ]
    - [ 17819, 15720, 15267, 15034, 15443, 15228, 15087, 15749, 14604, 15260 ]
    - [ 16684, 15552, 15216, 15060, 15792, 14947, 14862, 15295, 14806, 15562 ]
    - [ 16045, 15688, 15388, 15626, 15533, 15613, 15530, 15409, 15607, 15553 ]
    - [ 16536, 15856, 15544, 15454, 15562, 16142, 15522, 15199, 15662, 15704 ]
    - [ 16190, 15721, 15142, 16002, 15339, 15354, 15853, 15344, 15590, 15882 ]
    - [ 17354, 14983, 14899, 15299, 15758, 15156, 15378, 15414, 15426, 15381 ]
    - [ 16396, 15692, 15177, 15358, 15216, 15364, 15433, 15837, 15682, 15442 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 29287, 29406, 29460, 29570, 29503 ]
    - [ 29504, 29405, 29721, 29560, 29602 ]
    - [ 29472, 29345, 29594, 29433, 29375 ]
    - [ 29387, 29594, 29472, 29457, 29700 ]
    - [ 29641, 29604, 29479, 29610, 29646 ]
    - [ 29510, 29346, 29378, 29498, 29328 ]
    - [ 29316, 29323, 29437, 29479, 29468 ]
    - [ 29422, 29296, 29433, 29340, 29435 ]
    - [ 29636, 29758, 29721, 29696, 29730 ]
    - [ 29420, 29282, 29360, 29425, 29590 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3759, 4031, 3960, 3956, 4041 ]
    - [ 3800, 4534, 3820, 4033, 3734 ]
    - [ 3713, 3990, 4028, 4169, 4304 ]
    - [ 3767, 4025, 3772, 3267, 4051 ]
    - [ 3780, 3854, 3858, 3925, 4056 ]
    - [ 3701, 3920, 3898, 3788, 3909 ]
    - [ 3743, 4272, 3877, 3887, 3848 ]
    - [ 3860, 3875, 3994, 3988, 3784 ]
    - [ 3850, 4204, 3824, 3889, 3842 ]
    - [ 3778, 3929, 4003, 4058, 3759 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3235, 3908, 3820, 4115, 4181 ]
    - [ 3976, 3850, 3607, 3719, 4043 ]
    - [ 3843, 3968, 3973, 4084, 3998 ]
    - [ 4130, 4047, 4185, 3811, 3910 ]
    - [ 3563, 3644, 4180, 4031, 3897 ]
    - [ 3633, 3924, 3943, 3896, 4037 ]
    - [ 3974, 4012, 4293, 3932, 3918 ]
    - [ 3710, 4269, 3903, 4070, 3940 ]
    - [ 3250, 4109, 3543, 4060, 4093 ]
    - [ 3729, 4065, 3969, 3536, 3869 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6833, 4581, 4364, 3496, 3301 ]
    - [ 7005, 4330, 3658, 3904, 3630 ]
    - [ 6848, 4548, 4296, 3400, 3324 ]
    - [ 7029, 4610, 4084, 3684, 3945 ]
    - [ 6446, 4637, 4608, 3027, 3648 ]
    - [ 7194, 4529, 4484, 3696, 3816 ]
    - [ 6903, 4313, 4079, 3717, 3799 ]
    - [ 6258, 4441, 3660, 3125, 3791 ]
    - [ 6763, 4478, 4194, 3838, 3664 ]
    - [ 6682, 4330, 3751, 3557, 3509 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3504, 3513, 2991, 3572, 3463 ]
    - [ 3993, 3505, 3314, 3536, 3734 ]
    - [ 4103, 3487, 3535, 3423, 3584 ]
    - [ 3233, 3304, 3450, 3495, 3628 ]
    - [ 3752, 3545, 3219, 3392, 3370 ]
    - [ 2994, 3534, 3482, 3981, 3389 ]
    - [ 4015, 3308, 3676, 3456, 3401 ]
    - [ 3605, 3291, 3300, 2826, 3353 ]
    - [ 3423, 3526, 3375, 3843, 3527 ]
    - [ 3293, 3439, 3491, 3458, 3485 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3750, 3599, 3242, 3271, 3529 ]
    - [ 3778, 3377, 3164, 3428, 3078 ]
    - [ 3848, 3308, 3258, 3373, 3233 ]
    - [ 3740, 3465, 3353, 3078, 3824 ]
    - [ 3679, 3319, 3260, 3155, 3205 ]
    - [ 3489, 3458, 3512, 3162, 3264 ]
    - [ 3646, 3288, 3064, 3317, 3410 ]
    - [ 3409, 3027, 3311, 3264, 3274 ]
    - [ 3311, 3255, 3011, 3526, 3298 ]
    - [ 3245, 3404, 3381, 3287, 3071 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3658, 3516, 3206, 3522, 3378 ]
    - [ 3625, 3569, 3587, 3308, 3223 ]
    - [ 3220, 3407, 3500, 3358, 3366 ]
    - [ 3804, 3477, 3337, 3500, 3534 ]
    - [ 3781, 3468, 3338, 3456, 3513 ]
    - [ 3613, 3212, 3459, 2655, 3332 ]
    - [ 3754, 3588, 3293, 3315, 3368 ]
    - [ 3118, 3469, 3510, 3612, 3320 ]
    - [ 3367, 3526, 3378, 3313, 3470 ]
    - [ 3686, 3587, 3222, 3584, 3408 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3981, 3536, 3463, 3587, 3609 ]
    - [ 3738, 3577, 3404, 3515, 3869 ]
    - [ 3930, 3628, 3374, 3657, 3660 ]
    - [ 3425, 3505, 3572, 3207, 3398 ]
    - [ 3654, 3715, 3378, 3853, 3525 ]
    - [ 4069, 3408, 3531, 3848, 3693 ]
    - [ 3430, 3544, 3333, 3508, 3432 ]
    - [ 4052, 3499, 3150, 3747, 3465 ]
    - [ 3744, 3546, 3568, 3515, 3332 ]
    - [ 3390, 3790, 4361, 3476, 3133 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2740, 2906, 2805, 3043, 2888 ]
    - [ 3495, 2969, 3001, 3299, 2896 ]
    - [ 3247, 2858, 3028, 2914, 3042 ]
    - [ 3615, 3107, 2528, 2998, 2739 ]
    - [ 3334, 3053, 3061, 3060, 3018 ]
    - [ 3253, 2897, 3471, 2879, 2965 ]
    - [ 2920, 3338, 2930, 3018, 2988 ]
    - [ 2822, 3084, 2655, 3208, 3026 ]
    - [ 3603, 3151, 2985, 3080, 2464 ]
    - [ 3502, 2991, 2883, 2811, 3095 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 4726, 3994, 4313, 3577, 4099, 4167, 3884, 3845, 3819, 3797 ]
    - [ 4512, 4310, 3993, 3736, 4296, 4254, 3873, 4354, 4093, 4457 ]
    - [ 4605, 4120, 3948, 4593, 3981, 4066, 4062, 4110, 4160, 3970 ]
    - [ 4703, 4390, 4113, 4532, 3973, 3833, 4054, 4474, 4128, 3909 ]
    - [ 4160, 3891, 4211, 3931, 3922, 4280, 4220, 4062, 3801, 3790 ]
    - [ 4603, 4431, 4329, 4217, 4045, 3766, 4012, 4142, 3935, 4357 ]
    - [ 4674, 4358, 4236, 4173, 4121, 4170, 3977, 4523, 3940, 3735 ]
    - [ 4523, 3913, 3686, 4071, 4182, 4251, 4164, 3777, 4039, 3843 ]
    - [ 4716, 3688, 4211, 3823, 4322, 4083, 4217, 3673, 3890, 4362 ]
    - [ 4732, 4012, 3966, 3728, 4424, 3914, 3846, 4105, 3981, 3908 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 33377, 31638, 31730, 31573, 31439, 31373, 31299, 31334, 31293, 31321 ]
    - [ 32996, 31175, 31196, 31055, 31069, 31009, 30982, 31021, 31013, 31010 ]
    - [ 32920, 31228, 31062, 31049, 31027, 31060, 31020, 31068, 31121, 30995 ]
    - [ 33480, 31191, 31132, 31034, 31104, 31017, 31062, 31083, 31089, 31074 ]
    - [ 33112, 31037, 30964, 31057, 30870, 30736, 30839, 30843, 30799, 30827 ]
    - [ 33516, 31726, 31737, 31593, 31610, 31650, 31672, 31679, 31616, 31646 ]
    - [ 33105, 31319, 31200, 31115, 31164, 31085, 31046, 31022, 31150, 31038 ]
    - [ 34226, 32220, 32115, 32134, 32086, 32070, 31990, 32020, 32047, 32150 ]
    - [ 33164, 31303, 31282, 31277, 31123, 31143, 31179, 31253, 31259, 31174 ]
    - [ 33548, 31654, 31556, 31635, 31438, 31581, 31535, 31495, 31545, 31417 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 5967, 5273, 5472, 5275, 5617, 5348, 5655, 5692, 5899, 5538 ]
    - [ 6005, 5727, 5557, 5613, 5497, 5618, 5563, 5525, 5395, 5384 ]
    - [ 6163, 5506, 5738, 5198, 5416, 5466, 5537, 5501, 5527, 5520 ]
    - [ 4607, 5742, 5741, 5760, 5498, 5423, 5616, 5633, 5621, 5784 ]
    - [ 5929, 5468, 5543, 5300, 5444, 5301, 5262, 5569, 5551, 6291 ]
    - [ 5934, 5553, 5549, 5458, 5479, 5492, 5884, 5699, 5867, 5780 ]
    - [ 5942, 5537, 5509, 5576, 5578, 5561, 5549, 5694, 5455, 5723 ]
    - [ 5694, 5350, 5035, 5401, 5767, 5153, 5411, 5597, 5510, 5619 ]
    - [ 6187, 5568, 5160, 5513, 5847, 5660, 5759, 5300, 5444, 5822 ]
    - [ 6153, 5692, 5438, 5554, 5335, 5805, 5530, 5866, 5581, 5548 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 55480, 53128, 52644, 52393, 52372, 52515, 52525, 52587, 52589, 52512 ]
    - [ 54363, 51465, 51403, 51359, 51236, 51267, 51641, 51449, 51247, 51266 ]
    - [ 55022, 53134, 52522, 52417, 52481, 52228, 52594, 52277, 52124, 52179 ]
    - [ 53757, 51808, 51555, 51294, 51157, 51130, 51445, 51314, 51106, 51164 ]
    - [ 54940, 52467, 51984, 52040, 51880, 51907, 51926, 51984, 52042, 51953 ]
    - [ 54571, 53160, 52493, 52608, 52416, 52399, 52795, 52584, 52423, 52346 ]
    - [ 55101, 52846, 52150, 52126, 52034, 51872, 52496, 51971, 51887, 51870 ]
    - [ 54344, 51488, 51746, 51505, 51524, 51635, 52068, 51698, 51619, 51601 ]
    - [ 53914, 51574, 50996, 51044, 50906, 50926, 51220, 51077, 50983, 50912 ]
    - [ 55166, 53238, 52400, 52393, 52229, 52363, 52957, 52582, 52492, 52384 ]
