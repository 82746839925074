#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 3159, 2711, 2586, 2569, 2767, 2527, 2626, 2567, 2598, 2589 ]
    - [ 3296, 2881, 2816, 2879, 2789, 2649, 2561, 2779, 2870, 2661 ]
    - [ 3253, 2850, 2801, 2864, 2966, 2899, 2809, 2533, 2578, 2604 ]
    - [ 3174, 2692, 2560, 2493, 2822, 2972, 2603, 2541, 2528, 2581 ]
    - [ 3188, 2635, 2829, 2551, 2895, 2954, 2602, 2554, 2503, 2603 ]
    - [ 3248, 2671, 2579, 2538, 2856, 2844, 2570, 2529, 2918, 2565 ]
    - [ 3226, 2671, 2556, 2728, 2832, 2833, 2558, 2503, 2818, 2577 ]
    - [ 3284, 2661, 2576, 2884, 2516, 2895, 2516, 2489, 2518, 2556 ]
    - [ 3170, 2657, 2558, 2567, 2902, 2995, 2974, 2567, 2613, 2544 ]
    - [ 3215, 2900, 2600, 2942, 2872, 2923, 2531, 2532, 2612, 2564 ]
  2.0:
    - [ 2399, 1969, 2067, 2117, 2060, 2099, 2070, 2060, 2084, 2062 ]
    - [ 2410, 2131, 2081, 2095, 2116, 2087, 2084, 2092, 2096, 2146 ]
    - [ 2377, 1956, 2077, 2053, 2106, 2096, 2082, 2113, 2060, 2086 ]
    - [ 2390, 1970, 2044, 2055, 2075, 2074, 2030, 2046, 2052, 2101 ]
    - [ 2300, 1941, 2050, 2092, 2065, 2074, 2050, 2058, 2077, 2080 ]
    - [ 2501, 1977, 2101, 2091, 2062, 2127, 2099, 2073, 2072, 2092 ]
    - [ 2450, 1972, 2053, 2044, 2064, 2104, 2064, 2049, 2065, 2061 ]
    - [ 2396, 1973, 2051, 2059, 2053, 2080, 2056, 2040, 2076, 2054 ]
    - [ 2390, 1958, 2045, 2036, 2068, 2022, 2051, 2075, 2071, 2055 ]
    - [ 2403, 1993, 2102, 2116, 2103, 2124, 2143, 2155, 2110, 2137 ]
  3.0:
    - [ 2404, 1960, 1923, 1931, 1900, 1916, 1920, 1892, 1944, 1925 ]
    - [ 2371, 1948, 1908, 1914, 1913, 1904, 1868, 1909, 1905, 1885 ]
    - [ 2336, 1922, 1912, 1902, 1866, 1902, 1871, 1876, 1874, 1899 ]
    - [ 2374, 1955, 1878, 1878, 1898, 1906, 1867, 1872, 1913, 1836 ]
    - [ 2323, 1898, 1890, 1872, 1878, 1883, 1866, 1886, 1848, 1897 ]
    - [ 2368, 1883, 1835, 1844, 1850, 1851, 1869, 1863, 1837, 1853 ]
    - [ 2441, 1900, 1876, 1886, 1874, 1881, 1886, 1879, 1859, 1867 ]
    - [ 2372, 1904, 1920, 1927, 1863, 1849, 1864, 1857, 1852, 1904 ]
    - [ 2415, 1931, 1886, 1880, 1900, 1870, 1884, 1861, 1872, 1889 ]
    - [ 2340, 1894, 1873, 1858, 1858, 1856, 1845, 1839, 1851, 1842 ]
  4.0:
    - [ 2339, 1967, 1942, 1948, 1952, 1929, 1919, 1933, 1941, 1929 ]
    - [ 2369, 1937, 1874, 1872, 1882, 1863, 1892, 1890, 1877, 1934 ]
    - [ 2362, 1970, 1937, 1923, 1889, 1930, 1928, 1946, 1924, 1939 ]
    - [ 2419, 1994, 1967, 1953, 1938, 1928, 1945, 1942, 1956, 1989 ]
    - [ 2341, 1926, 1870, 1859, 1873, 1860, 1861, 1876, 1881, 1892 ]
    - [ 2406, 1960, 1925, 1916, 1947, 1914, 1917, 1905, 1896, 1914 ]
    - [ 2429, 1951, 1931, 1931, 1925, 1912, 1919, 1909, 1918, 1902 ]
    - [ 2441, 1965, 1907, 1915, 1909, 1886, 1922, 1892, 1907, 1939 ]
    - [ 2429, 1982, 1970, 1951, 1943, 1923, 1962, 1936, 1961, 1919 ]
    - [ 2409, 1932, 1909, 1887, 1906, 1921, 1943, 1961, 1888, 1887 ]
  5.0:
    - [ 2322, 1973, 1919, 1918, 1925, 1897, 1897, 1914, 1916, 1893 ]
    - [ 2362, 1958, 1916, 1902, 1911, 1903, 1864, 1874, 1882, 1917 ]
    - [ 2413, 1945, 1909, 1898, 1914, 1913, 1906, 1890, 1915, 1890 ]
    - [ 2308, 1938, 1924, 1888, 1921, 1915, 1930, 1897, 1913, 1937 ]
    - [ 2324, 1926, 1862, 1868, 1895, 1851, 1851, 1874, 1863, 1844 ]
    - [ 2314, 1982, 1938, 1914, 1916, 1886, 1934, 1939, 1926, 1917 ]
    - [ 2347, 1929, 1908, 1931, 1908, 1927, 1915, 1916, 1918, 1907 ]
    - [ 2419, 1984, 1934, 1939, 1925, 1933, 1910, 1958, 1970, 1959 ]
    - [ 2428, 1965, 1899, 1899, 1928, 1905, 1925, 1876, 1875, 1857 ]
    - [ 2351, 1971, 1892, 1895, 1921, 1903, 1892, 1915, 1898, 1874 ]
  6.0:
    - [ 2284, 1909, 1934, 1904, 1898, 1918, 1894, 1889, 1883, 1877 ]
    - [ 2393, 1904, 1889, 1879, 1897, 1866, 1872, 1853, 1912, 1884 ]
    - [ 2400, 1886, 1853, 1840, 1872, 1889, 1897, 1862, 1864, 1911 ]
    - [ 2430, 1990, 1942, 1892, 1910, 1944, 1937, 1900, 1909, 1909 ]
    - [ 2402, 1955, 1887, 1875, 1895, 1888, 1879, 1875, 1870, 1879 ]
    - [ 2467, 1998, 1951, 1913, 1933, 1932, 1929, 1920, 1908, 1934 ]
    - [ 2389, 1907, 1924, 1856, 1852, 1913, 1868, 1891, 1907, 1928 ]
    - [ 2375, 1967, 1870, 1890, 1883, 1866, 1854, 1847, 1846, 1869 ]
    - [ 2342, 1960, 1934, 1916, 1880, 1879, 1908, 1940, 1899, 1897 ]
    - [ 2416, 1920, 1904, 1918, 1925, 1921, 1906, 1909, 1911, 1889 ]
  7.0:
    - [ 2289, 1880, 1896, 1869, 1899, 1915, 1888, 1857, 1882, 1901 ]
    - [ 2430, 1962, 1880, 1928, 1899, 1877, 1896, 1897, 1921, 1901 ]
    - [ 2409, 1962, 1900, 1933, 1883, 1898, 1897, 1910, 1837, 1908 ]
    - [ 2316, 1925, 1873, 1866, 1871, 1880, 1862, 1855, 1874, 1879 ]
    - [ 2371, 1948, 1923, 1909, 1904, 1881, 1898, 1903, 1916, 1936 ]
    - [ 2319, 1885, 1891, 1838, 1907, 1854, 1865, 1877, 1861, 1888 ]
    - [ 2300, 1921, 1906, 1907, 1888, 1919, 1911, 1906, 1932, 1903 ]
    - [ 2425, 1988, 1980, 1923, 1912, 1927, 1951, 1928, 1914, 1912 ]
    - [ 2285, 1869, 1811, 1853, 1850, 1853, 1841, 1826, 1847, 1841 ]
    - [ 2336, 1872, 1834, 1833, 1827, 1780, 1840, 1821, 1819, 1838 ]
  8.0:
    - [ 2404, 1958, 1912, 1881, 1899, 1878, 1901, 1890, 1907, 1873 ]
    - [ 2337, 1945, 1910, 1898, 1908, 1910, 1895, 1921, 1896, 1899 ]
    - [ 2357, 1906, 1914, 1886, 1895, 1878, 1864, 1870, 1887, 1879 ]
    - [ 2320, 1936, 1890, 1842, 1871, 1864, 1864, 1838, 1827, 1849 ]
    - [ 2377, 1915, 1810, 1847, 1840, 1868, 1825, 1847, 1819, 1828 ]
    - [ 2364, 1870, 1809, 1818, 1831, 1847, 1837, 1824, 1826, 1850 ]
    - [ 2300, 1943, 1889, 1863, 1863, 1848, 1885, 1827, 1845, 1887 ]
    - [ 2438, 1908, 1866, 1860, 1846, 1850, 1864, 1857, 1854, 1850 ]
    - [ 2360, 1980, 1939, 1924, 1924, 1919, 1945, 1980, 1975, 1978 ]
    - [ 2435, 1945, 1888, 1905, 1890, 1880, 1900, 1868, 1930, 1914 ]
  9.0:
    - [ 2384, 1942, 1895, 1894, 1879, 1921, 1939, 1924, 1880, 1902 ]
    - [ 2244, 1846, 1829, 1781, 1789, 1822, 1815, 1829, 1792, 1783 ]
    - [ 2427, 1995, 1907, 1922, 1932, 1912, 1918, 1912, 1896, 1893 ]
    - [ 2329, 1918, 1906, 1887, 1930, 1902, 1872, 1937, 1884, 1888 ]
    - [ 2355, 1875, 1871, 1842, 1834, 1824, 1849, 1842, 1837, 1845 ]
    - [ 2310, 1879, 1822, 1827, 1815, 1805, 1828, 1810, 1807, 1816 ]
    - [ 2388, 1941, 1908, 1922, 1891, 1909, 1885, 1893, 1893, 1922 ]
    - [ 2405, 1975, 1900, 1921, 1925, 1894, 1880, 1853, 1885, 1909 ]
    - [ 2390, 1909, 1862, 1833, 1885, 1863, 1861, 1851, 1855, 1857 ]
    - [ 2297, 1829, 1790, 1832, 1804, 1803, 1831, 1783, 1795, 1814 ]
  10.0:
    - [ 2320, 1906, 1820, 1850, 1838, 1852, 1818, 1808, 1833, 1822 ]
    - [ 2308, 1931, 1881, 1860, 1888, 1886, 1845, 1848, 1864, 1890 ]
    - [ 2372, 1910, 1849, 1862, 1861, 1869, 1831, 1873, 1890, 1902 ]
    - [ 2400, 1868, 1833, 1841, 1828, 1857, 1814, 1838, 1820, 1820 ]
    - [ 2357, 1887, 1852, 1856, 1858, 1854, 1887, 1856, 1855, 1854 ]
    - [ 2337, 1932, 1870, 1832, 1845, 1827, 1881, 1886, 1858, 1878 ]
    - [ 2259, 1946, 1921, 1903, 1901, 1910, 1913, 1904, 1907, 1957 ]
    - [ 2299, 1921, 1849, 1848, 1838, 1855, 1848, 1843, 1834, 1826 ]
    - [ 2400, 1939, 1870, 1874, 1861, 1883, 1899, 1870, 1877, 1895 ]
    - [ 2332, 1886, 1808, 1814, 1804, 1802, 1806, 1809, 1821, 1778 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 20754, 17090, 16560, 21198, 21321, 21302, 20689, 20701, 20697, 20687 ]
    - [ 20539, 16770, 16473, 20951, 20952, 20886, 20963, 20886, 20443, 20923 ]
    - [ 20970, 16966, 16591, 21135, 21145, 21179, 21127, 20595, 20601, 20547 ]
    - [ 20557, 16885, 16587, 21144, 20524, 21138, 21135, 20574, 20533, 21187 ]
    - [ 20757, 16737, 16396, 20815, 20869, 20811, 20843, 20837, 20872, 20834 ]
    - [ 20814, 16961, 16593, 21250, 21330, 20776, 20715, 21242, 21226, 21276 ]
    - [ 20631, 16886, 16296, 20864, 20945, 20819, 20839, 20330, 20341, 20387 ]
    - [ 20219, 16633, 16336, 20750, 20751, 20741, 20808, 20828, 20822, 20741 ]
    - [ 20310, 16645, 16322, 20951, 21013, 20962, 20430, 20435, 20455, 20445 ]
    - [ 20839, 16410, 16386, 21013, 21133, 21043, 20964, 21035, 21120, 20529 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2286, 1918, 1867, 1855, 1863, 1927, 1889, 1887, 1818, 1836 ]
    - [ 2251, 1931, 1930, 1909, 1918, 1886, 1925, 1923, 1865, 1887 ]
    - [ 2256, 1917, 1899, 1928, 1907, 1957, 1927, 1875, 1875, 1909 ]
    - [ 2217, 1871, 1879, 1839, 1848, 1885, 1859, 1877, 1864, 1874 ]
    - [ 2273, 1930, 1865, 1881, 1879, 1852, 1867, 1851, 1843, 1879 ]
    - [ 2268, 1911, 1933, 1863, 1887, 1914, 1890, 1941, 1947, 1955 ]
    - [ 2260, 1919, 1880, 1881, 1858, 1889, 1842, 1855, 1916, 1852 ]
    - [ 2235, 1934, 1918, 1950, 1893, 1872, 1926, 1903, 1909, 1929 ]
    - [ 2251, 1962, 1874, 1896, 1911, 1912, 1918, 1912, 1881, 1904 ]
    - [ 2257, 1894, 1872, 1921, 1855, 1912, 1856, 1848, 1879, 1901 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3581, 3353, 3280, 3293, 3272, 3271, 3322, 3341, 3289, 3284 ]
    - [ 3610, 3293, 3300, 3248, 3247, 3263, 3257, 3261, 3250, 3258 ]
    - [ 3644, 3308, 3301, 3288, 3270, 3262, 3226, 3240, 3264, 3262 ]
    - [ 3562, 3270, 3278, 3282, 3279, 3262, 3278, 3278, 3233, 3261 ]
    - [ 3663, 3298, 3217, 3237, 3185, 3248, 3209, 3218, 3206, 3255 ]
    - [ 3616, 3253, 3360, 3356, 3293, 3249, 3260, 3250, 3252, 3266 ]
    - [ 3554, 3256, 3268, 3219, 3317, 3303, 3224, 3262, 3202, 3245 ]
    - [ 3556, 3362, 3221, 3242, 3209, 3242, 3276, 3319, 3283, 3330 ]
    - [ 3715, 3267, 3375, 3381, 3288, 3233, 3254, 3304, 3305, 3248 ]
    - [ 3557, 3392, 3305, 3349, 3322, 3339, 3346, 3327, 3298, 3331 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 7391, 6916, 6792, 6719, 6864, 6852, 6903, 6868, 6983, 6974 ]
    - [ 7427, 6905, 6844, 6808, 6850, 6872, 6783, 6859, 6782, 6842 ]
    - [ 7263, 6844, 6794, 6833, 6750, 6857, 6841, 6842, 6867, 6843 ]
    - [ 7270, 6780, 6829, 6890, 6789, 6957, 6802, 6898, 6844, 6847 ]
    - [ 7420, 6820, 6963, 6852, 6853, 6845, 6850, 6880, 6968, 6883 ]
    - [ 7316, 6911, 6817, 6770, 6818, 6871, 6865, 6910, 6722, 6896 ]
    - [ 7242, 6851, 6905, 6853, 6785, 6719, 6848, 6786, 6830, 6844 ]
    - [ 7374, 6805, 6831, 6853, 6835, 6878, 6925, 6840, 6929, 6944 ]
    - [ 7306, 6911, 6885, 6760, 6884, 6860, 6837, 6893, 6817, 6912 ]
    - [ 7433, 6939, 6786, 6930, 6967, 6812, 6907, 6952, 6951, 6808 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 12025, 11230, 10989, 11120, 11718, 11812, 11560, 11409, 11381, 11433 ]
    - [ 11683, 11142, 11512, 12088, 11323, 11449, 11413, 11585, 11538, 11488 ]
    - [ 11676, 11067, 11439, 11043, 11735, 11431, 11601, 11412, 12019, 11178 ]
    - [ 12229, 11459, 11607, 11722, 11317, 11768, 11581, 11045, 11559, 11762 ]
    - [ 11444, 11587, 11731, 11506, 11666, 12021, 11535, 11417, 11976, 11914 ]
    - [ 11219, 11588, 11243, 11082, 11185, 11022, 11125, 11347, 11495, 11161 ]
    - [ 11242, 11229, 11626, 11634, 11320, 11964, 11123, 11082, 11330, 11772 ]
    - [ 11891, 11492, 10979, 11476, 11740, 11058, 11334, 11277, 11444, 11233 ]
    - [ 11383, 11045, 11850, 11734, 11601, 11517, 11622, 11687, 11172, 11617 ]
    - [ 11526, 11354, 11475, 11607, 11015, 11569, 11472, 11494, 11257, 11452 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 10574, 9737, 9549, 9627, 9066, 9296, 9314, 9420, 8938, 9608 ]
    - [ 10600, 10061, 9304, 9750, 9397, 9642, 9883, 9632, 9657, 9591 ]
    - [ 10772, 9581, 9601, 9412, 9796, 9427, 9672, 9620, 9718, 10224 ]
    - [ 10381, 9905, 9328, 9291, 9267, 9341, 9732, 9594, 9395, 9522 ]
    - [ 10535, 9862, 10008, 9864, 9903, 9980, 9685, 9029, 9466, 9942 ]
    - [ 10380, 9792, 9472, 9361, 9532, 9801, 9897, 9686, 9675, 9667 ]
    - [ 10807, 9887, 9820, 9375, 9286, 9711, 10136, 9702, 9660, 10210 ]
    - [ 10723, 9510, 9759, 9666, 9767, 9382, 9506, 10004, 9989, 9039 ]
    - [ 10730, 9726, 9929, 9297, 9319, 9793, 9569, 9808, 9672, 9670 ]
    - [ 10381, 9398, 9449, 10623, 9673, 9528, 9611, 9270, 9855, 9640 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 32012, 31788, 31747, 31833, 31783 ]
    - [ 31722, 31921, 31746, 31767, 31739 ]
    - [ 31362, 31516, 31498, 32046, 32082 ]
    - [ 31660, 31589, 31526, 31791, 32119 ]
    - [ 31611, 31859, 32021, 31651, 31786 ]
    - [ 31719, 32040, 31833, 31975, 32150 ]
    - [ 31461, 31651, 31880, 31722, 31657 ]
    - [ 31395, 31858, 31808, 31688, 31880 ]
    - [ 31681, 31961, 31919, 32245, 31927 ]
    - [ 31910, 31720, 31721, 32216, 31859 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3215, 3260, 3307, 3278, 3315 ]
    - [ 3198, 3199, 3243, 3198, 3233 ]
    - [ 3275, 3254, 3286, 3252, 3236 ]
    - [ 3219, 3250, 3313, 3310, 3305 ]
    - [ 3149, 3188, 3211, 3220, 3230 ]
    - [ 3138, 3212, 3261, 3283, 3291 ]
    - [ 3186, 3241, 3261, 3261, 3230 ]
    - [ 3251, 3279, 3270, 3221, 3258 ]
    - [ 3211, 3235, 3265, 3258, 3233 ]
    - [ 3198, 3220, 3260, 3196, 3203 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3023, 3183, 3211, 3144, 3186 ]
    - [ 2984, 3129, 3135, 3124, 3171 ]
    - [ 3102, 3162, 3157, 3162, 3199 ]
    - [ 3085, 3250, 3213, 3222, 3188 ]
    - [ 3120, 3185, 3229, 3194, 3194 ]
    - [ 3018, 3167, 3192, 3179, 3149 ]
    - [ 3021, 3125, 3136, 3132, 3105 ]
    - [ 3106, 3106, 3129, 3224, 3193 ]
    - [ 3122, 3215, 3235, 3176, 3218 ]
    - [ 3019, 3117, 3124, 3122, 3131 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 4535, 2754, 2667, 2600, 2589 ]
    - [ 4567, 2715, 2615, 2629, 2688 ]
    - [ 4501, 2737, 2612, 2607, 2586 ]
    - [ 4459, 2655, 2640, 2656, 2633 ]
    - [ 4367, 2613, 2550, 2557, 2554 ]
    - [ 4566, 2711, 2700, 2643, 2683 ]
    - [ 4572, 2787, 2692, 2634, 2635 ]
    - [ 4386, 2612, 2579, 2553, 2563 ]
    - [ 4530, 2730, 2647, 2575, 2585 ]
    - [ 4645, 2785, 2718, 2662, 2669 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2260, 1954, 2031, 2033, 2049 ]
    - [ 2195, 1932, 2038, 2020, 2054 ]
    - [ 2181, 1936, 2034, 2034, 2029 ]
    - [ 2176, 1966, 2112, 2048, 2113 ]
    - [ 2237, 2011, 2090, 2075, 2086 ]
    - [ 2204, 2018, 2143, 2129, 2107 ]
    - [ 2237, 2103, 2128, 2098, 2125 ]
    - [ 2267, 2164, 2098, 2129, 2108 ]
    - [ 2222, 1938, 2127, 2086, 2099 ]
    - [ 2185, 1995, 2093, 2086, 2104 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5170, 2727, 2812, 2790, 2839 ]
    - [ 5154, 2702, 2791, 2701, 2731 ]
    - [ 4703, 2778, 2783, 2784, 2805 ]
    - [ 5030, 2713, 2796, 2774, 2795 ]
    - [ 4764, 2869, 2732, 2679, 2676 ]
    - [ 4713, 2741, 2791, 2769, 2793 ]
    - [ 5011, 2915, 2743, 2759, 2787 ]
    - [ 4843, 2760, 2780, 2768, 2792 ]
    - [ 5047, 2718, 2743, 2697, 2723 ]
    - [ 4894, 2710, 2752, 2735, 2774 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3123, 2213, 2113, 2106, 2145 ]
    - [ 3123, 2102, 2147, 2122, 2156 ]
    - [ 3157, 2048, 2117, 2086, 2091 ]
    - [ 3272, 1979, 2049, 2045, 2054 ]
    - [ 3237, 1996, 2073, 2097, 2069 ]
    - [ 3176, 2074, 2127, 2126, 2170 ]
    - [ 3245, 2028, 2099, 2108, 2079 ]
    - [ 3254, 2115, 2061, 2060, 2070 ]
    - [ 3107, 2110, 2109, 2109, 2087 ]
    - [ 3222, 2011, 2110, 2095, 2105 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3263, 2828, 2993, 3004, 3002 ]
    - [ 3324, 2739, 2941, 2903, 2911 ]
    - [ 3231, 3062, 2960, 3011, 2938 ]
    - [ 3359, 2727, 2879, 2845, 2904 ]
    - [ 3273, 2704, 2892, 2869, 2905 ]
    - [ 3221, 2933, 2865, 2900, 2885 ]
    - [ 3248, 2741, 2966, 2982, 3008 ]
    - [ 3324, 2788, 2953, 2969, 2985 ]
    - [ 3281, 2780, 2966, 2963, 2958 ]
    - [ 3347, 3024, 2964, 2971, 2970 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2218, 2042, 2023, 2001, 2029 ]
    - [ 2212, 1917, 1978, 1992, 1999 ]
    - [ 2277, 1901, 2005, 2002, 1991 ]
    - [ 2288, 1845, 1979, 1994, 1967 ]
    - [ 2301, 1919, 2023, 2046, 2005 ]
    - [ 2259, 1911, 1998, 1997, 2011 ]
    - [ 2231, 1887, 1991, 1935, 1966 ]
    - [ 2284, 2083, 2017, 2040, 2030 ]
    - [ 2300, 1895, 1978, 2000, 1989 ]
    - [ 2301, 1918, 2010, 2004, 1994 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 3174, 2568, 2715, 2747, 2724, 2727, 2720, 2708, 2701, 2737 ]
    - [ 3359, 2578, 2743, 2685, 2716, 2713, 2701, 2715, 2696, 2697 ]
    - [ 3210, 2722, 2659, 2682, 2697, 2690, 2707, 2667, 2671, 2716 ]
    - [ 3190, 2617, 2721, 2684, 2692, 2727, 2715, 2702, 2707, 2730 ]
    - [ 3201, 2604, 2753, 2727, 2703, 2700, 2697, 2723, 2748, 2725 ]
    - [ 3274, 2569, 2692, 2728, 2703, 2691, 2736, 2722, 2701, 2677 ]
    - [ 3280, 2594, 2755, 2711, 2736, 2698, 2761, 2713, 2712, 2720 ]
    - [ 3288, 2573, 2722, 2713, 2751, 2695, 2690, 2729, 2699, 2734 ]
    - [ 3163, 2534, 2690, 2698, 2707, 2734, 2722, 2694, 2678, 2665 ]
    - [ 3261, 2617, 2703, 2689, 2692, 2684, 2745, 2698, 2753, 2714 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 25551, 20822, 20293, 26229, 26227, 26205, 26315, 26272, 26191, 25587 ]
    - [ 25787, 20872, 20309, 26230, 26299, 26214, 25658, 26237, 26297, 25512 ]
    - [ 25509, 20810, 20315, 26146, 26139, 26119, 25533, 26196, 26251, 26129 ]
    - [ 25615, 20848, 20403, 26291, 26330, 26407, 26272, 26262, 25688, 26323 ]
    - [ 25516, 20751, 20288, 26146, 25463, 25459, 25472, 26157, 25449, 26166 ]
    - [ 25192, 20801, 20258, 26138, 26136, 25560, 25547, 25531, 25560, 25555 ]
    - [ 25934, 20795, 20340, 26233, 26231, 26354, 25618, 25625, 26261, 26411 ]
    - [ 25743, 20784, 20352, 26254, 25578, 26375, 25598, 26299, 25672, 25617 ]
    - [ 25321, 20865, 20433, 26253, 26253, 25535, 26356, 26318, 26301, 26283 ]
    - [ 25468, 20567, 20116, 25874, 25939, 25878, 25928, 25914, 25219, 25232 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 3196, 2909, 2832, 2852, 2909, 2822, 2830, 2807, 2842, 2873 ]
    - [ 3104, 2852, 2923, 2860, 2900, 2935, 2953, 2914, 2939, 2902 ]
    - [ 3098, 2771, 2843, 2831, 2855, 2819, 2887, 2857, 2827, 2842 ]
    - [ 3115, 2873, 2917, 2855, 2877, 2910, 2873, 2874, 2901, 2854 ]
    - [ 3137, 2889, 2868, 2886, 2889, 2887, 2831, 2879, 2890, 2906 ]
    - [ 3211, 2845, 2951, 2923, 2893, 2907, 2914, 2882, 2901, 2925 ]
    - [ 3186, 2808, 2844, 2875, 2909, 2911, 2894, 2879, 2903, 2862 ]
    - [ 3128, 2913, 2916, 2891, 2904, 2895, 2871, 2874, 2897, 2882 ]
    - [ 3280, 2991, 3004, 2956, 2901, 2931, 2899, 2930, 2910, 2967 ]
    - [ 3101, 2909, 2909, 2924, 2938, 2896, 2915, 2877, 2940, 2914 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 44275, 38300, 37455, 47929, 47929, 48105, 46874, 46802, 47906, 46786 ]
    - [ 44643, 38309, 48258, 48273, 48266, 48310, 47022, 47018, 48163, 48201 ]
    - [ 44513, 37968, 37285, 47868, 46601, 46622, 46624, 46644, 46622, 46633 ]
    - [ 45586, 38422, 48169, 48248, 48087, 48028, 48110, 48095, 48165, 46997 ]
    - [ 45193, 38793, 48847, 48823, 48974, 48863, 47714, 47622, 47685, 47610 ]
    - [ 44463, 38050, 48537, 48483, 48324, 48248, 47176, 47181, 47132, 47193 ]
    - [ 44514, 48298, 48001, 48037, 47966, 48066, 48068, 46732, 46769, 48003 ]
    - [ 44300, 38010, 37721, 48193, 47069, 47034, 47002, 46957, 47074, 47001 ]
    - [ 44576, 37751, 37237, 47777, 47731, 47750, 46554, 47691, 47829, 46464 ]
    - [ 45057, 38035, 47995, 47987, 47929, 47936, 48082, 48065, 46817, 46900 ]
