#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [9, 115, 3/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [7, 80, 7/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [5, 2097, 11/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (1100193376/524600.0)]
BAL: [7, 129, 7/20, 0, 34, 2204, nominal aaload per usec]
BAS: [4, 0, 13/20, 0, 1, 126, nominal aastore per usec]
BEF: [8, 11, 4/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [8, 6226, 5/20, 0, 527, 32087, nominal getfield per usec]
BPF: [2, 31, 16/20, 0, 83, 3863, nominal putfield per usec]
BUB: [6, 55, 9/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [5, 4, 10/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [0, 16, 22/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (52/322)']
GCC: [1, 58, 21/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [0, 14, 22/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (48/322)']
GCP: [4, 1, 15/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (101/6464)]
GLK: [10, 120, 1/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (322/146)]
GMD: [9, 183, 4/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [6, 127, 10/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [1, 6, 20/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (556.1666666666666/524.6)']
GTO: [1, 7, 19/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [8, 277, 6/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [5, 64, 11/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [3, 1, 17/22, 1, 3, 8, nominal execution time (sec)]
PFS: [0, -1, 22/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [5, 64, 11/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [6, 5, 10/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [6, 18, 10/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [10, 46, 1/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [10, 63, 2/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [3, 0, 16/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [9, 7, 4/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [3, 77, 17/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [9, 42, 3/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [0, 5, 22/22, 5, 23, 41, nominal backend bound (memory) ( 20376746266.7 / 28324241570.1) * 7 )]
UBP: [7, 52, 8/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.0529997832060344 * 596307468.3 ) / ( 596307468.3 + 4241249.8 )']
UBR: [1, 374, 21/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.0529997832060344 * 4241249.8 ) / ( 596307468.3 + 4241249.8 ) )']
UBS: [6, 52, 9/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 172579699527.1 - 149601932819.2 ) / ( 6 * 72257423577.8 ) )]
UDC: [1, 3, 20/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 492976889.3/ 152707806923.9 )]
UDT: [0, 14, 22/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 2149823.0 / 152707806923.9 )]
UIP: [8, 211, 6/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 152707806923.9/72257423577.8)]
ULL: [0, 335, 22/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 25853146.4) / 152707806923.9 ) )]
USB: [0, 7, 22/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 33469947499.2 / ( 6 * 75785897267.0 ) )]
USC: [10, 351, 1/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 159600844699.3 / ( 6 * 75749977027.5 ) )]
USF: [4, 18, 15/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 81273753716.4/( 6 * 72257423577.8) )]
