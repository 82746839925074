#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# key: [score/10, value, rank, min, median, max, description]
AOA: [3, 41, 14/20, 28, 58, 211, nominal average object size (bytes)]
AOL: [5, 64, 10/20, 24, 56, 200, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [9, 11858, 2/20, 54, 2097, 23556, nominal allocation rate (bytes / usec) (21963862800/1852200.0)]
BAL: [8, 234, 5/20, 0, 34, 2204, nominal aaload per usec]
BAS: [8, 28, 4/20, 0, 1, 126, nominal aastore per usec]
BEF: [7, 7, 7/20, 1, 4, 29, nominal execution focus / dominance of hot code]
BGF: [7, 3677, 6/20, 0, 527, 32087, nominal getfield per usec]
BPF: [8, 601, 4/20, 0, 83, 3863, nominal putfield per usec]
BUB: [1, 17, 18/20, 1, 34, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 2, 15/20, 0, 4, 29, nominal thousands of unique function calls]
GCA: [5, 98, 13/22, 16, 100, 133, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (671/681)']
GCC: [3, 552, 17/22, 31, 948, 22408, nominal GC count at 2X heap size (G1)]
GCM: [2, 82, 18/22, 14, 98, 144, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (565/681)']
GCP: [5, 4, 11/22, 0, 2, 78, nominal percentage of time spent in GC pauses at 2X heap size (G1) (3160/63341)]
GLK: [6, 0, 9/22, 0, 0, 120, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (681/680)]
GMD: [10, 681, 1/22, 5, 72, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [10, 10201, 1/20, 13, 149, 10201, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [8, 69, 6/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [10, 903, 1/22, 7, 73, 903, nominal minimum heap size (MB) for default size without compressed pointers]
GMV: [10, 20641, 1/3, 371, 1123, 20641, nominal minimum heap size (MB) for vlarge size configuration (with compressed pointers)]
GSS: [3, 38, 16/22, 0, 249, 7638, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (2559.4/1852.2)']
GTO: [2, 30, 16/20, 3, 52, 1211, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [2, 87, 18/22, 0, 201, 1083, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [4, 55, 14/22, 1, 61, 323, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [5, 2, 13/22, 1, 3, 8, nominal execution time (sec)]
PFS: [3, 5, 17/22, -1, 12, 20, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [4, 55, 14/22, 1, 61, 323, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [1, 0, 21/22, 0, 2, 56, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [9, 31, 4/22, -2, 8, 40, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [10, 40, 2/22, 0, 5, 46, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [8, 24, 5/22, 3, 6, 87, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [7, 1, 7/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [9, 127, 4/22, 2, 92, 168, nominal percentage change (slowdown) when running on ARM Neoverse N1 (Ampere Altra Q80-30) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UAI: [4, 24, 14/22, -19, 25, 56, nominal percentage change (slowdown) when running on Intel Golden Cove (i9-12900KF) v AMD Zen 4 (Ryzen 9 7950X) on a single core (taskset 0)]
UBM: [10, 40, 2/22, 5, 23, 41, nominal backend bound (memory) ( 119843341024.4 / 127249855204.9) * 43 )]
UBP: [3, 29, 17/22, 5, 39, 134, 'nominal 1000 x bad speculation: mispredicts ( 1000 * ( 0.030553549105264234 * 445266975.9 ) / ( 445266975.9 + 13829295.6 )']
UBR: [4, 920, 14/22, 164, 1087, 3487, 'nominal 1000000 x bad speculation: pipeline restarts ( 1000000 * ( 0.030553549105264234 * 13829295.6 ) / ( 445266975.9 + 13829295.6 ) )']
UBS: [2, 30, 18/22, 5, 39, 137, nominal 1000 x bad speculation ( 1000 * ( 264750455596.3 - 231876994672.7 ) / ( 6 * 179321562122.2 ) )]
UDC: [7, 16, 8/22, 2, 12, 27, nominal data cache misses per K instructions ( 1000 * 3917626317.9/ 243829317056.0 )]
UDT: [9, 476, 4/22, 14, 174, 576, nominal DTLB misses per M instructions ( 1000000 * 116278493.4 / 243829317056.0 )]
UIP: [5, 135, 13/22, 89, 149, 476, nominal 100 x instructions per cycle (IPC) ( 100 * 243829317056.0/179321562122.2)]
ULL: [7, 4315, 7/22, 335, 2645, 8506, nominal LLC misses per M instructions ( 1000000 * ( ( 528780026.1) / 243829317056.0 ) )]
USB: [9, 43, 4/22, 7, 29, 53, nominal 100 x back end bound ( 100 * 470605166319.2 / ( 6 * 178469383273.6 ) )]
USC: [8, 140, 6/22, 1, 52, 351, nominal 1000 x SMT contention ( 1000 * 151690072086.5 / ( 6 * 180445363130.5 ) )]
USF: [3, 17, 17/22, 4, 23, 51, nominal 100 x front end bound ( 100 * 187824098314.6/( 6 * 179321562122.2) )]
