import * as React from "react";
import {Benchmark} from "../types";
import {Link} from "react-router";

export function BenchmarkList() {
    return (
        <ul className={"list-disc"}>
            {Benchmark.map(key => <li key={key}>
                <Link to={`/benchmark/${key}`} className={"underline"}>{key}</Link>
                : <Link to={`/benchmark/${key}/perf`} className={"underline"}>perf</Link>
            </li>)}
        </ul>
    )
}