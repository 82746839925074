#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5612, 4565, 4594, 4281, 4240, 4280, 4288, 4387, 4414, 4414 ]
    - [ 5390, 4583, 4516, 4228, 4316, 4311, 4273, 4386, 4469, 4453 ]
    - [ 5397, 4639, 4459, 4451, 4446, 4334, 4321, 4445, 4378, 4502 ]
    - [ 5331, 4587, 4443, 4357, 4427, 4500, 4439, 4537, 4439, 4489 ]
    - [ 5366, 4504, 4404, 4335, 4451, 4440, 4421, 4223, 4255, 4350 ]
    - [ 5368, 4704, 4464, 4379, 4293, 4385, 4154, 4141, 4192, 4169 ]
    - [ 5263, 4485, 4162, 3954, 4030, 4154, 4102, 4160, 4277, 4403 ]
    - [ 5432, 4631, 4451, 4346, 4374, 4432, 4360, 4275, 4520, 4534 ]
    - [ 5430, 4607, 4556, 4382, 4461, 4403, 4408, 4509, 4380, 4435 ]
    - [ 5405, 4713, 4587, 4222, 4146, 4210, 4213, 4231, 4252, 4457 ]
  2.0:
    - [ 3527, 2893, 2839, 2850, 2849, 2858, 2834, 2825, 2807, 2797 ]
    - [ 3568, 2932, 2913, 2917, 2896, 2903, 2888, 2894, 2883, 2861 ]
    - [ 3537, 2886, 2862, 2871, 2843, 2928, 2857, 2861, 2853, 2846 ]
    - [ 3637, 2917, 2889, 2913, 2886, 2884, 2876, 2894, 2871, 2876 ]
    - [ 3562, 2905, 2879, 2857, 2877, 2892, 2878, 2879, 2865, 2884 ]
    - [ 3595, 2937, 2915, 2912, 2887, 2914, 2910, 2883, 2862, 2876 ]
    - [ 3555, 2893, 2871, 2876, 2911, 2883, 2857, 2858, 2844, 2854 ]
    - [ 3521, 2912, 2894, 2877, 2860, 2864, 2863, 2862, 2847, 2864 ]
    - [ 3528, 2895, 2895, 2872, 2884, 2887, 2894, 2900, 2877, 2845 ]
    - [ 3518, 2967, 2916, 2914, 2930, 2908, 2952, 2889, 2896, 2895 ]
  3.0:
    - [ 3536, 2860, 2868, 2900, 2870, 2862, 2891, 2861, 2860, 2894 ]
    - [ 3514, 2865, 2877, 2902, 2886, 2884, 2919, 2855, 2864, 2897 ]
    - [ 3459, 2893, 2890, 2867, 2861, 2866, 2875, 2883, 2857, 2868 ]
    - [ 3538, 2952, 2887, 2874, 2857, 2879, 2892, 2890, 2849, 2864 ]
    - [ 3703, 2881, 2871, 2886, 2880, 2864, 2872, 2885, 2819, 2847 ]
    - [ 3469, 2886, 2879, 2887, 2869, 2882, 2864, 2854, 2866, 2857 ]
    - [ 3484, 2819, 2825, 2844, 2821, 2851, 2821, 2817, 2849, 2854 ]
    - [ 3465, 2914, 2878, 2858, 2883, 2871, 2940, 2926, 2891, 2822 ]
    - [ 3510, 2880, 2877, 2910, 2898, 2890, 2879, 2865, 2864, 2899 ]
    - [ 3537, 2833, 2862, 2843, 2826, 2856, 2836, 2830, 2821, 2844 ]
  4.0:
    - [ 3481, 2845, 2812, 2815, 2818, 2796, 2823, 2838, 2801, 2794 ]
    - [ 3514, 2876, 2876, 2864, 2870, 2863, 2848, 2856, 2868, 2857 ]
    - [ 3554, 2858, 2849, 2843, 2846, 2864, 2853, 2842, 2832, 2841 ]
    - [ 3458, 2885, 2882, 2869, 2867, 2877, 2872, 2880, 2853, 2888 ]
    - [ 3524, 2867, 2844, 2840, 2811, 2833, 2846, 2828, 2823, 2824 ]
    - [ 3507, 2857, 2814, 2834, 2815, 2826, 2833, 2825, 2856, 2814 ]
    - [ 3442, 2861, 2809, 2839, 2896, 2916, 2895, 2842, 2873, 2877 ]
    - [ 3448, 2851, 2839, 2845, 2835, 2801, 2843, 2821, 2826, 2803 ]
    - [ 3494, 2879, 2848, 2855, 2830, 2815, 2852, 2842, 2834, 2823 ]
    - [ 3464, 2851, 2841, 2822, 2837, 2837, 2826, 2805, 2806, 2802 ]
  5.0:
    - [ 3490, 2821, 2809, 2795, 2796, 2810, 2801, 2798, 2818, 2835 ]
    - [ 3534, 2818, 2793, 2781, 2780, 2767, 2782, 2799, 2790, 2785 ]
    - [ 3485, 2845, 2805, 2807, 2808, 2799, 2789, 2783, 2795, 2788 ]
    - [ 3475, 2813, 2762, 2758, 2772, 2772, 2781, 2773, 2771, 2765 ]
    - [ 3539, 2849, 2833, 2844, 2834, 2832, 2821, 2816, 2821, 2820 ]
    - [ 3505, 2850, 2817, 2817, 2795, 2792, 2800, 2808, 2804, 2804 ]
    - [ 3468, 2874, 2843, 2853, 2866, 2825, 2843, 2850, 2841, 2865 ]
    - [ 3511, 2829, 2818, 2827, 2834, 2812, 2808, 2809, 2818, 2832 ]
    - [ 3486, 2848, 2833, 2830, 2802, 2757, 2774, 2790, 2766, 2775 ]
    - [ 3468, 2837, 2811, 2825, 2808, 2817, 2827, 2829, 2806, 2811 ]
  6.0:
    - [ 3488, 2825, 2836, 2801, 2815, 2799, 2791, 2796, 2792, 2795 ]
    - [ 3458, 2835, 2806, 2832, 2801, 2810, 2801, 2822, 2802, 2800 ]
    - [ 3507, 2866, 2813, 2796, 2783, 2788, 2792, 2790, 2792, 2783 ]
    - [ 3446, 2823, 2781, 2794, 2783, 2777, 2790, 2786, 2763, 2757 ]
    - [ 3466, 2822, 2808, 2798, 2795, 2807, 2794, 2783, 2788, 2816 ]
    - [ 3471, 2824, 2798, 2820, 2791, 2794, 2788, 2786, 2791, 2793 ]
    - [ 3458, 2800, 2795, 2791, 2795, 2813, 2800, 2803, 2808, 2800 ]
    - [ 3541, 2829, 2820, 2801, 2805, 2792, 2796, 2808, 2808, 2801 ]
    - [ 3442, 2810, 2790, 2804, 2774, 2783, 2788, 2778, 2788, 2779 ]
    - [ 3453, 2830, 2801, 2787, 3020, 2794, 2802, 2801, 2790, 2836 ]
  7.0:
    - [ 3524, 2821, 2803, 2800, 2789, 2790, 2800, 2794, 2791, 2796 ]
    - [ 3478, 2836, 2792, 2816, 2811, 2788, 2786, 2803, 2789, 2787 ]
    - [ 3509, 2805, 2791, 2784, 2783, 2780, 2790, 2786, 2785, 2812 ]
    - [ 3481, 2907, 2801, 2800, 2806, 2784, 2798, 2798, 2781, 2790 ]
    - [ 3577, 2827, 2796, 2803, 2795, 2791, 2802, 2795, 2791, 2781 ]
    - [ 3534, 2825, 2842, 2815, 2805, 2813, 2808, 2801, 2768, 2760 ]
    - [ 3440, 2855, 2777, 2773, 2766, 2775, 2766, 2776, 2770, 2767 ]
    - [ 3441, 2817, 2793, 2789, 2789, 2786, 2795, 2787, 2782, 2788 ]
    - [ 3486, 2822, 2798, 2784, 2792, 2756, 2771, 2767, 2795, 2769 ]
    - [ 3489, 2806, 2774, 2772, 2771, 2772, 2775, 2769, 2767, 2765 ]
  8.0:
    - [ 3525, 2852, 2784, 2788, 2786, 2778, 2775, 2786, 2764, 2769 ]
    - [ 3457, 2816, 2798, 2796, 2783, 2789, 2788, 2789, 2784, 2774 ]
    - [ 3455, 2801, 2786, 2781, 2787, 2787, 2781, 2778, 2779, 2779 ]
    - [ 3488, 2806, 2816, 2798, 2781, 2790, 2771, 2784, 2776, 2774 ]
    - [ 3485, 2845, 2806, 2806, 2794, 2806, 2794, 2800, 2800, 2794 ]
    - [ 3468, 2805, 2776, 2808, 2766, 2764, 2759, 2738, 2735, 2736 ]
    - [ 3473, 2794, 2768, 2775, 2776, 2772, 2748, 2766, 2776, 2763 ]
    - [ 3459, 2822, 2776, 2783, 2763, 2774, 2767, 2768, 2773, 2771 ]
    - [ 3515, 2850, 2785, 2788, 2789, 2782, 2782, 2786, 2794, 2792 ]
    - [ 3436, 2799, 2765, 2762, 2773, 2762, 2764, 2766, 2761, 2766 ]
  9.0:
    - [ 3476, 2836, 2801, 2874, 2782, 2787, 2784, 2793, 2785, 2793 ]
    - [ 3570, 2796, 2762, 2754, 2751, 2753, 2748, 2754, 2756, 2739 ]
    - [ 3523, 2798, 2764, 2779, 2771, 2756, 2785, 2768, 2765, 2758 ]
    - [ 3482, 2824, 2830, 2781, 2804, 2805, 2766, 2765, 2732, 2730 ]
    - [ 3550, 2811, 2860, 2787, 2779, 2776, 2804, 2786, 2738, 2740 ]
    - [ 3473, 2764, 2741, 2747, 2754, 2744, 2743, 2754, 2741, 2739 ]
    - [ 3461, 2846, 2772, 2776, 2771, 2777, 2764, 2766, 2765, 2763 ]
    - [ 3435, 2785, 2776, 2775, 2767, 2760, 2762, 2762, 2729, 2732 ]
    - [ 3462, 2778, 2749, 2743, 2739, 2742, 2743, 2749, 2749, 2742 ]
    - [ 3425, 2800, 2762, 2763, 2755, 2767, 2751, 2749, 2749, 2750 ]
  10.0:
    - [ 3469, 2843, 2753, 2768, 2759, 2756, 2766, 2754, 2768, 2768 ]
    - [ 3493, 2839, 2760, 2770, 2755, 2751, 2757, 2751, 2721, 2725 ]
    - [ 3429, 2801, 2765, 2771, 2764, 2775, 2757, 2771, 2769, 2787 ]
    - [ 3466, 2776, 2762, 2758, 2743, 2762, 2749, 2770, 2745, 2735 ]
    - [ 3489, 2797, 2754, 2752, 2749, 2745, 2751, 2743, 2750, 2734 ]
    - [ 3509, 2805, 2789, 2791, 2777, 2773, 2778, 2770, 2770, 2777 ]
    - [ 3455, 2911, 2756, 2766, 2745, 2763, 2754, 2756, 2752, 2754 ]
    - [ 3507, 2790, 2771, 2777, 2769, 2769, 2771, 2779, 2770, 2765 ]
    - [ 3438, 2790, 2764, 2758, 2758, 2761, 2759, 2767, 2754, 2770 ]
    - [ 3459, 2781, 2761, 2748, 2737, 2733, 2751, 2741, 2746, 2753 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 7705, 4444, 3640, 3570, 3501, 3409, 3426, 3450, 3490, 3446 ]
    - [ 8017, 4885, 3556, 3791, 3382, 3541, 3456, 3430, 3470, 3410 ]
    - [ 7257, 4299, 3529, 3633, 3443, 3529, 3401, 3462, 3389, 3415 ]
    - [ 7935, 5074, 3733, 3716, 3479, 3580, 3559, 3491, 3528, 3542 ]
    - [ 7306, 4668, 3535, 3474, 3479, 3369, 3410, 3447, 3450, 3378 ]
    - [ 7670, 4472, 3639, 3638, 3554, 3483, 3516, 3528, 3552, 3460 ]
    - [ 7583, 4646, 3599, 3586, 3562, 3420, 3452, 3466, 3485, 3404 ]
    - [ 7897, 4661, 3600, 3564, 3485, 3394, 3411, 3429, 3520, 3392 ]
    - [ 7618, 4718, 3617, 3655, 3525, 3449, 3462, 3491, 3531, 3503 ]
    - [ 7712, 4783, 3567, 3752, 3572, 3434, 3463, 3484, 3557, 3476 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3399, 2745, 2733, 2735, 2690, 2718, 2699, 2688, 2708, 2722 ]
    - [ 3351, 2740, 2735, 2711, 2752, 2703, 2725, 2731, 2741, 2729 ]
    - [ 3419, 2787, 2720, 2742, 2749, 2706, 2713, 2743, 2707, 2758 ]
    - [ 3406, 2749, 2690, 2694, 3241, 2685, 2691, 2708, 2685, 2694 ]
    - [ 3360, 2629, 2615, 2637, 2615, 2631, 2629, 2622, 2612, 2612 ]
    - [ 3366, 2752, 2723, 2714, 2730, 2748, 2704, 2710, 2726, 2736 ]
    - [ 3429, 2688, 2654, 2628, 2662, 2656, 2649, 2636, 2668, 2657 ]
    - [ 3279, 2715, 2696, 2690, 2697, 2658, 2700, 2690, 2723, 2711 ]
    - [ 3371, 2731, 2713, 2729, 2730, 2731, 2721, 2693, 2587, 2573 ]
    - [ 3307, 2617, 2636, 2570, 2610, 2600, 2576, 2562, 2564, 2573 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3390, 2624, 2592, 2595, 2598, 2579, 2582, 2585, 2584, 2594 ]
    - [ 3489, 2700, 2687, 2689, 2662, 2591, 2595, 2598, 2585, 2595 ]
    - [ 3287, 2656, 2588, 2634, 2582, 2578, 2588, 2606, 2582, 2593 ]
    - [ 3423, 2706, 2680, 2725, 2682, 2683, 2689, 2681, 2671, 2676 ]
    - [ 3394, 2710, 2684, 2686, 2664, 2665, 2669, 2669, 2578, 2583 ]
    - [ 3431, 2710, 2683, 2690, 2668, 2595, 2596, 2599, 2605, 2621 ]
    - [ 3401, 2714, 2688, 2957, 2684, 2679, 2679, 2681, 2680, 2677 ]
    - [ 3343, 2623, 2606, 2973, 2599, 2595, 2574, 2581, 2601, 2586 ]
    - [ 3367, 2718, 2695, 2867, 2679, 2692, 2679, 2688, 2678, 2673 ]
    - [ 3402, 2708, 2676, 2682, 2679, 2675, 2675, 2669, 2670, 2663 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 4922, 4372, 5317, 4273, 4278, 4778, 5152, 3974, 4863, 4431 ]
    - [ 4688, 5239, 4341, 4715, 4490, 5139, 4525, 4308, 4356, 4855 ]
    - [ 4614, 4356, 5301, 5898, 5238, 5439, 5193, 5160, 5592, 4575 ]
    - [ 4819, 4950, 5723, 4115, 5191, 5867, 4245, 5012, 4444, 5177 ]
    - [ 4909, 4807, 5474, 5192, 4076, 4748, 5563, 4565, 4833, 4711 ]
    - [ 4351, 5010, 4877, 4476, 4652, 5869, 4307, 4967, 5033, 4567 ]
    - [ 4514, 4620, 5392, 3946, 4146, 4800, 5392, 5151, 4511, 4416 ]
    - [ 4552, 6368, 5784, 3988, 4688, 5613, 4901, 5484, 5023, 4956 ]
    - [ 4924, 4794, 4948, 4194, 4966, 4611, 4487, 4746, 5345, 4699 ]
    - [ 4264, 4793, 4794, 4737, 4003, 5102, 4787, 4648, 4588, 4913 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 3768, 3165, 3168, 3176, 3185, 3180, 3190, 3154, 3122, 3140 ]
    - [ 3792, 3279, 3286, 3262, 3235, 3263, 3261, 3273, 3188, 3195 ]
    - [ 3801, 3242, 3213, 3231, 3226, 3204, 3233, 3162, 3169, 3203 ]
    - [ 3768, 3215, 3213, 3216, 3212, 3244, 3255, 3262, 3172, 3188 ]
    - [ 3804, 3257, 3211, 3221, 3222, 3206, 3222, 3207, 3178, 3138 ]
    - [ 3820, 3233, 3221, 3239, 3221, 3258, 3214, 3212, 3155, 3160 ]
    - [ 3757, 3195, 3189, 3176, 3194, 3188, 3147, 3138, 3170, 3143 ]
    - [ 3768, 3241, 3229, 3283, 3220, 3227, 3234, 3228, 3197, 3173 ]
    - [ 3844, 3233, 3260, 3210, 3181, 3184, 3197, 3158, 3173, 3182 ]
    - [ 3760, 3190, 3188, 3227, 3193, 3188, 3193, 3207, 3157, 3163 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 3778, 3194, 3163, 3152, 3173, 3141, 3116, 3125, 3108, 3110 ]
    - [ 3677, 3190, 3179, 3161, 3158, 3198, 3170, 3159, 3148, 3167 ]
    - [ 3814, 3161, 3123, 3119, 3120, 3130, 3138, 3134, 3144, 3134 ]
    - [ 3771, 3152, 3139, 3111, 3148, 3133, 3218, 3159, 3161, 3168 ]
    - [ 3791, 3215, 3169, 3166, 3165, 3187, 3153, 3198, 3104, 3083 ]
    - [ 3748, 3166, 3150, 3184, 3209, 3173, 3125, 3070, 3059, 3109 ]
    - [ 3722, 3131, 3156, 3166, 3129, 3120, 3166, 3148, 3107, 3112 ]
    - [ 3741, 3184, 3179, 3205, 3137, 3172, 3202, 3196, 3126, 3119 ]
    - [ 3758, 3125, 3119, 3142, 3135, 3127, 3097, 3107, 3066, 3114 ]
    - [ 3757, 3196, 3105, 3157, 3164, 3170, 3135, 3129, 3090, 3083 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 122769, 123160, 124013, 124251, 122885 ]
    - [ 119962, 121771, 121433, 121360, 121787 ]
    - [ 121234, 122578, 122229, 122279, 122364 ]
    - [ 122739, 123538, 123504, 123184, 123928 ]
    - [ 122873, 123907, 123180, 122583, 122355 ]
    - [ 122367, 121974, 123205, 123723, 122909 ]
    - [ 122444, 123251, 124196, 124238, 121507 ]
    - [ 121955, 121882, 123677, 123604, 123741 ]
    - [ 122529, 122995, 122806, 123239, 123252 ]
    - [ 121171, 121572, 121440, 121249, 119818 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5725, 4829, 4746, 4740, 4733 ]
    - [ 5754, 4780, 4717, 4731, 4733 ]
    - [ 5717, 4758, 4696, 4694, 4714 ]
    - [ 5657, 4709, 4706, 4713, 4761 ]
    - [ 5687, 4758, 4711, 4744, 4709 ]
    - [ 5794, 4783, 4711, 4710, 4709 ]
    - [ 5666, 4755, 4712, 4717, 4711 ]
    - [ 5752, 4779, 4709, 4712, 4713 ]
    - [ 5718, 4747, 4717, 4718, 4728 ]
    - [ 5721, 4785, 4776, 4766, 4746 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4737, 4539, 4545, 4547, 4551 ]
    - [ 4803, 4642, 4590, 4592, 4632 ]
    - [ 4849, 4653, 4658, 4673, 4673 ]
    - [ 4728, 4576, 4542, 4556, 4574 ]
    - [ 4735, 4536, 4531, 4555, 4542 ]
    - [ 4759, 4531, 4523, 4552, 4571 ]
    - [ 4857, 4679, 4708, 4687, 4702 ]
    - [ 4734, 4529, 4556, 4566, 4585 ]
    - [ 4714, 4511, 4506, 4543, 4598 ]
    - [ 4765, 4567, 4570, 4549, 4599 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 10735, 5267, 4718, 4924, 4715 ]
    - [ 11263, 5496, 4636, 4654, 4629 ]
    - [ 10659, 5319, 4820, 5001, 4746 ]
    - [ 10683, 5149, 4749, 4881, 4631 ]
    - [ 10665, 5537, 4674, 4706, 4667 ]
    - [ 10631, 5120, 4700, 4869, 4670 ]
    - [ 10636, 5156, 4708, 4905, 4671 ]
    - [ 10653, 5164, 4752, 4886, 4674 ]
    - [ 10670, 5146, 4990, 4715, 4691 ]
    - [ 10619, 5143, 4713, 4868, 4661 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3973, 3002, 2944, 2985, 2931 ]
    - [ 3978, 2949, 2888, 2863, 2853 ]
    - [ 4017, 3081, 2937, 2926, 2932 ]
    - [ 3988, 3000, 2980, 2951, 2937 ]
    - [ 4098, 3072, 2968, 2937, 2946 ]
    - [ 3960, 3002, 2916, 2878, 2865 ]
    - [ 3993, 3055, 2981, 2935, 2982 ]
    - [ 4022, 3033, 2958, 2944, 2924 ]
    - [ 4012, 3000, 2883, 2886, 2870 ]
    - [ 4107, 3058, 2942, 2952, 2946 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5052, 4042, 4424, 4093, 3994 ]
    - [ 5064, 4123, 4041, 4037, 4040 ]
    - [ 4898, 3977, 3943, 3959, 3977 ]
    - [ 5021, 4020, 4050, 3961, 3951 ]
    - [ 5046, 3990, 3959, 3971, 3929 ]
    - [ 5061, 4031, 3989, 4035, 3983 ]
    - [ 5018, 4035, 4011, 4015, 3980 ]
    - [ 4957, 4060, 4013, 3985, 4000 ]
    - [ 5014, 4027, 3990, 4034, 4024 ]
    - [ 5095, 4049, 4099, 4079, 3981 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3583, 2917, 3313, 2875, 2889 ]
    - [ 3602, 2907, 2853, 2829, 2850 ]
    - [ 3524, 2911, 2875, 2877, 2876 ]
    - [ 3486, 2875, 2861, 2846, 2863 ]
    - [ 3535, 2900, 2888, 2883, 2876 ]
    - [ 3511, 2886, 2867, 2889, 2862 ]
    - [ 3683, 2890, 2877, 2850, 2859 ]
    - [ 3550, 2920, 2862, 2887, 2860 ]
    - [ 3545, 2917, 2871, 2877, 3037 ]
    - [ 3508, 2888, 2870, 2872, 2855 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3696, 2984, 2940, 2966, 2924 ]
    - [ 3709, 2969, 2951, 2960, 2980 ]
    - [ 3712, 3010, 2951, 2945, 2970 ]
    - [ 3688, 2994, 2943, 2946, 2933 ]
    - [ 3672, 3137, 2940, 2959, 2937 ]
    - [ 3657, 2994, 2922, 2956, 2930 ]
    - [ 3668, 2991, 2971, 2964, 2966 ]
    - [ 3657, 2964, 2963, 2990, 2946 ]
    - [ 3769, 2988, 3006, 2971, 2946 ]
    - [ 3678, 2970, 2932, 2946, 2918 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3008, 2427, 2399, 2410, 2398 ]
    - [ 3035, 2453, 2427, 2437, 2433 ]
    - [ 3043, 2476, 2462, 2453, 2453 ]
    - [ 2982, 2427, 2412, 2404, 2436 ]
    - [ 3058, 2496, 2482, 2493, 2459 ]
    - [ 3033, 2500, 2470, 2457, 2433 ]
    - [ 3009, 2446, 2402, 2411, 2404 ]
    - [ 3025, 2450, 2406, 2412, 2415 ]
    - [ 3075, 2441, 2421, 2418, 2424 ]
    - [ 3035, 2460, 2409, 2404, 2410 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 4587, 3813, 3732, 3759, 3739, 3750, 3753, 3753, 3734, 3751 ]
    - [ 4606, 3905, 3826, 3769, 3742, 3777, 3790, 3732, 3726, 3733 ]
    - [ 4649, 3841, 3776, 3763, 3746, 3736, 3758, 3748, 3724, 3721 ]
    - [ 4642, 3779, 3772, 3730, 3704, 3671, 3685, 3750, 3697, 3732 ]
    - [ 4565, 3798, 3731, 3733, 3708, 3743, 3706, 3724, 3730, 3728 ]
    - [ 4601, 3779, 3767, 3781, 3735, 3724, 3759, 3755, 3768, 3800 ]
    - [ 4605, 3901, 3739, 3755, 3784, 3735, 3724, 3722, 3702, 3708 ]
    - [ 4502, 3768, 3754, 3726, 3745, 3729, 3732, 3715, 3695, 3702 ]
    - [ 4666, 3826, 3764, 3687, 3741, 3743, 3753, 3804, 3729, 3699 ]
    - [ 4640, 3839, 3703, 3723, 3747, 3745, 3767, 3748, 3738, 3736 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 10063, 5530, 4482, 4609, 4368, 4558, 4406, 4369, 4395, 4391 ]
    - [ 10712, 5841, 4530, 4440, 4485, 4295, 4323, 4371, 4414, 4324 ]
    - [ 10990, 5860, 4478, 4442, 4388, 4254, 4356, 4297, 4358, 4270 ]
    - [ 10009, 5847, 4508, 4466, 4338, 4277, 4338, 4370, 4406, 4359 ]
    - [ 10000, 5995, 4470, 4590, 4267, 4342, 4285, 4265, 4281, 4298 ]
    - [ 9954, 5976, 4503, 4387, 4354, 4229, 4260, 4244, 4346, 4277 ]
    - [ 10183, 5773, 4451, 4592, 4397, 4266, 4277, 4297, 4303, 4240 ]
    - [ 10403, 6196, 4493, 4630, 4306, 4554, 4333, 4359, 4321, 4368 ]
    - [ 10137, 5709, 4449, 4545, 4286, 4509, 4357, 4275, 4335, 4300 ]
    - [ 10948, 6038, 4491, 4501, 4376, 4306, 4303, 4294, 4368, 4349 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 7178, 5941, 5892, 5882, 5905, 5953, 5891, 5954, 5903, 5842 ]
    - [ 7210, 6052, 5972, 5941, 5870, 5955, 5963, 5930, 5903, 5920 ]
    - [ 7248, 5997, 5965, 5907, 5918, 5929, 5927, 5913, 5954, 5960 ]
    - [ 7189, 6080, 5968, 6005, 6055, 5946, 5990, 5918, 5913, 5938 ]
    - [ 7262, 6143, 6086, 6051, 6036, 6080, 6097, 6007, 5894, 5904 ]
    - [ 7232, 6099, 6025, 6047, 6019, 6019, 6024, 5996, 5987, 6018 ]
    - [ 7269, 6046, 6027, 6002, 5974, 5969, 5954, 5944, 5948, 5950 ]
    - [ 7190, 5951, 5983, 5920, 5880, 5923, 5931, 5900, 5898, 5879 ]
    - [ 7131, 6047, 5926, 5983, 5998, 5964, 5918, 5938, 5895, 5911 ]
    - [ 7272, 5954, 5948, 5986, 5939, 5965, 5942, 5927, 5931, 5926 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 14608, 8345, 6823, 6816, 6710, 6498, 6556, 6544, 6653, 6512 ]
    - [ 14261, 8282, 6888, 6936, 6781, 6602, 6608, 6641, 6751, 6605 ]
    - [ 14153, 8062, 6810, 6932, 6712, 6559, 6588, 6608, 6600, 6486 ]
    - [ 14105, 8071, 6817, 6747, 6921, 6515, 6567, 6614, 6663, 6650 ]
    - [ 14931, 8529, 6788, 7070, 6552, 6831, 6583, 6596, 6588, 6571 ]
    - [ 14412, 8158, 6823, 6865, 6467, 6521, 6572, 6888, 6507, 6546 ]
    - [ 14734, 8015, 6629, 6888, 6487, 6452, 6463, 6717, 6470, 6486 ]
    - [ 14077, 8133, 6588, 6855, 6678, 6571, 6514, 6619, 6534, 6573 ]
    - [ 14456, 8421, 6629, 7042, 6532, 6834, 6562, 6619, 6519, 6572 ]
    - [ 14039, 7975, 6754, 7095, 6566, 6865, 6558, 6595, 6617, 6665 ]
