#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 9679, 8259, 7526, 7620, 7327, 7506, 7509, 7584, 7909, 8284 ]
    - [ 10842, 9193, 8559, 9197, 9176, 7876, 7988, 8989, 8213, 9652 ]
    - [ 10645, 9233, 9628, 8574, 8934, 8758, 9664, 9155, 9216, 9665 ]
    - [ 9069, 8712, 8648, 8379, 8226, 8194, 8330, 7562, 8337, 8308 ]
    - [ 11151, 9737, 9293, 9208, 8927, 9105, 9077, 9012, 9258, 9520 ]
    - [ 9087, 7616, 7478, 7787, 7876, 7837, 7553, 7547, 7853, 8342 ]
    - [ 9283, 8139, 8253, 8209, 8155, 7972, 7851, 7885, 7910, 8086 ]
  2.0:
    - [ 2436, 1925, 1951, 1938, 1929, 1933, 1929, 1937, 1939, 1947 ]
    - [ 2589, 2004, 1998, 1986, 1982, 1975, 1973, 1964, 1928, 1898 ]
    - [ 2532, 1960, 1947, 1936, 1920, 1926, 1926, 1940, 1914, 1927 ]
    - [ 2647, 2026, 2014, 1994, 2001, 2013, 2005, 2013, 1949, 1932 ]
    - [ 2467, 1944, 1944, 1942, 1934, 1949, 1948, 1961, 1968, 1966 ]
    - [ 2558, 1997, 1978, 1960, 1949, 1946, 1956, 1954, 1935, 1920 ]
    - [ 2559, 2006, 2002, 1981, 2000, 1993, 1993, 1979, 1993, 1975 ]
    - [ 2544, 2029, 2026, 2018, 1958, 1945, 1959, 1964, 1914, 1929 ]
    - [ 2510, 1941, 1949, 1962, 1953, 1948, 1960, 1950, 1948, 1921 ]
    - [ 2520, 1987, 1967, 1955, 1952, 1952, 1936, 1946, 1936, 1904 ]
  3.0:
    - [ 2397, 1863, 1883, 1864, 1868, 1900, 1879, 1881, 1878, 1870 ]
    - [ 2307, 1887, 1879, 1882, 1869, 1869, 1866, 1876, 1879, 1879 ]
    - [ 2231, 1837, 1854, 1848, 1831, 1867, 1819, 1857, 1829, 1829 ]
    - [ 2350, 1826, 1848, 1830, 1811, 1843, 1830, 1823, 1825, 1828 ]
    - [ 2331, 1842, 1858, 1851, 1847, 1855, 1838, 1832, 1841, 1832 ]
    - [ 2548, 1844, 1862, 1882, 1889, 1880, 1883, 1883, 1847, 1831 ]
    - [ 2372, 1898, 1919, 1919, 1916, 1924, 1923, 1925, 1903, 1925 ]
    - [ 2406, 1882, 1892, 1873, 1861, 1893, 1873, 1877, 1861, 1846 ]
    - [ 2388, 1950, 1943, 1981, 1963, 1939, 1949, 1954, 1959, 1960 ]
    - [ 2375, 1895, 1898, 1887, 1885, 1887, 1885, 1887, 1876, 1878 ]
  4.0:
    - [ 2271, 1679, 1682, 1656, 1662, 1680, 1658, 1663, 1662, 1659 ]
    - [ 2308, 1750, 1725, 1706, 1705, 1730, 1706, 1694, 1706, 1698 ]
    - [ 2268, 1746, 1733, 1713, 1713, 1731, 1718, 1710, 1709, 1704 ]
    - [ 2274, 1740, 1723, 1706, 1702, 1727, 1704, 1700, 1698, 1698 ]
    - [ 2349, 1753, 1750, 1729, 1728, 1747, 1731, 1736, 1724, 1722 ]
    - [ 2233, 1746, 1730, 1715, 1714, 1739, 1714, 1704, 1702, 1704 ]
    - [ 2272, 1708, 1692, 1684, 1682, 1693, 1677, 1668, 1664, 1665 ]
    - [ 2242, 1695, 1679, 1655, 1655, 1678, 1655, 1654, 1652, 1644 ]
    - [ 2280, 1740, 1727, 1707, 1705, 1725, 1702, 1701, 1706, 1708 ]
    - [ 2312, 1775, 1743, 1733, 1724, 1751, 1716, 1727, 1723, 1723 ]
  5.0:
    - [ 2254, 1752, 1747, 1734, 1732, 1750, 1727, 1729, 1725, 1708 ]
    - [ 2305, 1712, 1669, 1656, 1650, 1669, 1652, 1649, 1643, 1643 ]
    - [ 2189, 1677, 1672, 1658, 1653, 1671, 1650, 1652, 1649, 1652 ]
    - [ 2286, 1745, 1708, 1699, 1698, 1721, 1693, 1694, 1703, 1694 ]
    - [ 2283, 1745, 1735, 1720, 1718, 1737, 1717, 1715, 1717, 1715 ]
    - [ 2244, 1702, 1679, 1668, 1663, 1679, 1655, 1664, 1665, 1650 ]
    - [ 2298, 1754, 1748, 1737, 1724, 1744, 1724, 1727, 1727, 1725 ]
    - [ 2210, 1738, 1726, 1711, 1707, 1732, 1703, 1707, 1701, 1701 ]
    - [ 2287, 1744, 1724, 1714, 1712, 1734, 1712, 1716, 1717, 1704 ]
    - [ 2517, 1730, 1698, 1684, 1682, 1707, 1679, 1687, 1677, 1676 ]
  6.0:
    - [ 2213, 1711, 1680, 1668, 1667, 1689, 1664, 1669, 1664, 1649 ]
    - [ 2219, 1735, 1710, 1699, 1700, 1716, 1698, 1694, 1691, 1684 ]
    - [ 2209, 1717, 1702, 1688, 1689, 1704, 1687, 1691, 1687, 1673 ]
    - [ 2224, 1698, 1671, 1657, 1656, 1676, 1658, 1659, 1655, 1647 ]
    - [ 2161, 1682, 1671, 1657, 1653, 1668, 1648, 1650, 1649, 1642 ]
    - [ 2184, 1705, 1680, 1672, 1668, 1685, 1665, 1669, 1663, 1660 ]
    - [ 2237, 1746, 1714, 1711, 1716, 1730, 1712, 1710, 1708, 1702 ]
    - [ 2181, 1698, 1673, 1669, 1666, 1684, 1668, 1668, 1659, 1659 ]
    - [ 2168, 1717, 1700, 1690, 1687, 1705, 1686, 1690, 1689, 1683 ]
    - [ 2203, 1718, 1679, 1667, 1669, 1683, 1657, 1671, 1655, 1654 ]
  7.0:
    - [ 2198, 1675, 1659, 1648, 1648, 1666, 1643, 1645, 1646, 1637 ]
    - [ 2221, 1716, 1694, 1684, 1679, 1699, 1678, 1681, 1678, 1668 ]
    - [ 2146, 1688, 1663, 1648, 1646, 1667, 1646, 1644, 1646, 1640 ]
    - [ 2157, 1680, 1678, 1663, 1660, 1679, 1655, 1656, 1655, 1645 ]
    - [ 2276, 1748, 1728, 1713, 1715, 1732, 1713, 1708, 1711, 1712 ]
    - [ 2236, 1730, 1719, 1706, 1700, 1723, 1695, 1699, 1690, 1694 ]
    - [ 2154, 1682, 1665, 1644, 1642, 1661, 1644, 1639, 1640, 1628 ]
    - [ 2139, 1664, 1651, 1632, 1636, 1650, 1632, 1631, 1635, 1619 ]
    - [ 2237, 1732, 1722, 1712, 1709, 1732, 1713, 1710, 1711, 1707 ]
    - [ 2271, 1732, 1691, 1690, 1681, 1700, 1682, 1677, 1680, 1673 ]
  8.0:
    - [ 2202, 1695, 1678, 1661, 1661, 1676, 1656, 1649, 1656, 1657 ]
    - [ 2206, 1644, 1638, 1622, 1614, 1640, 1618, 1617, 1611, 1607 ]
    - [ 2127, 1647, 1631, 1614, 1613, 1628, 1609, 1608, 1613, 1601 ]
    - [ 2206, 1728, 1710, 1695, 1694, 1710, 1689, 1693, 1693, 1686 ]
    - [ 2200, 1709, 1683, 1679, 1673, 1692, 1672, 1678, 1671, 1661 ]
    - [ 2143, 1651, 1632, 1619, 1615, 1634, 1615, 1615, 1622, 1606 ]
    - [ 2180, 1670, 1654, 1648, 1650, 1668, 1648, 1646, 1646, 1639 ]
    - [ 2256, 1723, 1708, 1694, 1690, 1710, 1689, 1689, 1682, 1681 ]
    - [ 2221, 1703, 1683, 1677, 1671, 1687, 1674, 1674, 1672, 1661 ]
    - [ 2184, 1689, 1681, 1667, 1665, 1689, 1664, 1666, 1664, 1655 ]
  9.0:
    - [ 2210, 1667, 1645, 1629, 1628, 1647, 1630, 1627, 1629, 1626 ]
    - [ 2241, 1748, 1730, 1720, 1714, 1736, 1717, 1714, 1716, 1705 ]
    - [ 2179, 1674, 1659, 1648, 1645, 1667, 1649, 1649, 1635, 1636 ]
    - [ 2239, 1694, 1686, 1664, 1660, 1683, 1649, 1654, 1662, 1654 ]
    - [ 2182, 1710, 1690, 1677, 1678, 1698, 1674, 1676, 1676, 1668 ]
    - [ 2157, 1677, 1668, 1653, 1646, 1667, 1648, 1651, 1645, 1637 ]
    - [ 2155, 1666, 1654, 1642, 1644, 1665, 1646, 1641, 1643, 1635 ]
    - [ 2214, 1724, 1705, 1688, 1685, 1708, 1685, 1675, 1685, 1675 ]
    - [ 2160, 1713, 1679, 1673, 1673, 1693, 1668, 1666, 1667, 1657 ]
    - [ 2144, 1681, 1652, 1643, 1641, 1659, 1646, 1640, 1640, 1629 ]
  10.0:
    - [ 2153, 1672, 1654, 1648, 1644, 1667, 1641, 1644, 1643, 1632 ]
    - [ 2215, 1712, 1689, 1678, 1675, 1701, 1671, 1678, 1677, 1664 ]
    - [ 2379, 1681, 1671, 1656, 1653, 1662, 1646, 1647, 1646, 1639 ]
    - [ 2209, 1706, 1681, 1660, 1656, 1675, 1656, 1657, 1656, 1644 ]
    - [ 2170, 1695, 1678, 1656, 1662, 1678, 1659, 1656, 1658, 1650 ]
    - [ 2159, 1707, 1684, 1670, 1666, 1682, 1664, 1665, 1667, 1659 ]
    - [ 2134, 1673, 1675, 1668, 1663, 1679, 1661, 1661, 1656, 1666 ]
    - [ 2184, 1719, 1708, 1686, 1686, 1705, 1684, 1687, 1682, 1679 ]
    - [ 2226, 1711, 1696, 1685, 1686, 1701, 1685, 1686, 1687, 1677 ]
    - [ 2227, 1719, 1698, 1681, 1676, 1695, 1666, 1675, 1680, 1661 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 36930, 24723, 23516, 21992, 21534, 21342, 21647, 21620, 21629, 21332 ]
    - [ 35336, 24690, 23446, 22002, 21059, 21360, 21259, 21090, 21231, 20960 ]
    - [ 34920, 24663, 23122, 21951, 21115, 21061, 21264, 21092, 21082, 21020 ]
    - [ 35520, 24205, 23356, 21872, 21088, 21256, 21094, 21385, 21139, 20970 ]
    - [ 36023, 25508, 23757, 22244, 21700, 21855, 21477, 21612, 21722, 21341 ]
    - [ 35373, 25536, 23243, 21795, 21407, 21195, 21476, 21344, 21015, 20974 ]
    - [ 35645, 25246, 23237, 22320, 21951, 21604, 21325, 21530, 21298, 21121 ]
    - [ 35974, 25067, 23044, 21729, 21428, 21346, 21465, 21040, 20993, 21042 ]
    - [ 36373, 25282, 23630, 22250, 21852, 21740, 21695, 21744, 21507, 21266 ]
    - [ 34706, 25135, 23208, 21836, 21228, 21009, 21198, 21132, 21038, 20812 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2541, 1890, 1845, 1837, 1829, 1834, 1835, 1832, 1836, 1844 ]
    - [ 2580, 1925, 1905, 1896, 1897, 1895, 1896, 1901, 1895, 1898 ]
    - [ 2576, 1882, 1872, 1856, 1860, 1861, 1860, 1858, 1853, 1864 ]
    - [ 2575, 1927, 1911, 1904, 1897, 1902, 1896, 1899, 1913, 1895 ]
    - [ 2610, 1916, 1901, 1891, 1885, 1883, 1883, 1891, 1893, 1888 ]
    - [ 2601, 1956, 1922, 1905, 1906, 1904, 1906, 1900, 1902, 1904 ]
    - [ 2617, 1927, 1914, 1888, 1890, 1893, 1897, 1897, 1898, 1896 ]
    - [ 2531, 1962, 1913, 1910, 1904, 1905, 1904, 1903, 1906, 1906 ]
    - [ 2570, 1919, 1897, 1888, 1882, 1883, 1884, 1875, 1891, 1885 ]
    - [ 2543, 1895, 1861, 1850, 1844, 1843, 1845, 1843, 1844, 1846 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3420, 2568, 2437, 2412, 2408, 2411, 2402, 2420, 2413, 2409 ]
    - [ 3343, 2609, 2476, 2453, 2455, 2460, 2470, 2452, 2460, 2450 ]
    - [ 3463, 2501, 2435, 2410, 2409, 2408, 2411, 2408, 2406, 2391 ]
    - [ 3461, 2584, 2526, 2451, 2454, 2439, 2451, 2453, 2429, 2422 ]
    - [ 3424, 2600, 2537, 2504, 2503, 2503, 2504, 2487, 2483, 2486 ]
    - [ 3468, 2544, 2476, 2448, 2448, 2447, 2447, 2448, 2457, 2442 ]
    - [ 3425, 2542, 2431, 2422, 2415, 2394, 2401, 2396, 2408, 2394 ]
    - [ 3413, 2648, 2523, 2502, 2503, 2496, 2489, 2490, 2504, 2484 ]
    - [ 3384, 2558, 2455, 2434, 2432, 2432, 2432, 2443, 2438, 2433 ]
    - [ 3436, 2550, 2469, 2445, 2436, 2436, 2446, 2432, 2447, 2438 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 7008, 6036, 5824, 4872, 4856, 4942, 4612, 4536, 4612, 4771 ]
    - [ 7026, 5982, 5749, 5300, 4960, 4479, 4697, 4637, 4272, 4212 ]
    - [ 7005, 5905, 5167, 4662, 4474, 4672, 4778, 4762, 4551, 4486 ]
    - [ 6969, 6039, 5893, 4795, 4835, 4557, 4752, 4355, 4667, 4750 ]
    - [ 7064, 5919, 5816, 5035, 4705, 4906, 4850, 4533, 4722, 4519 ]
    - [ 6926, 6024, 5119, 4909, 4915, 5061, 4825, 4669, 4543, 4392 ]
    - [ 6915, 6005, 5396, 4752, 4447, 4888, 4836, 4553, 4634, 4306 ]
    - [ 7067, 6015, 4798, 4940, 4671, 4783, 4637, 4404, 4547, 4405 ]
    - [ 6944, 5942, 5206, 4773, 4876, 4896, 4829, 4713, 4606, 4564 ]
    - [ 6991, 6073, 4673, 4824, 4636, 4562, 4537, 4654, 4586, 4560 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 29528, 27176, 26015, 25612, 24552, 24455, 24460, 24178, 24497, 24430 ]
    - [ 31521, 28221, 26537, 26158, 25254, 25486, 25434, 25264, 25364, 25571 ]
    - [ 30885, 28369, 27430, 25793, 25135, 25104, 24750, 24746, 24883, 24857 ]
    - [ 32078, 29379, 27515, 26375, 25397, 25887, 25684, 25359, 25360, 25478 ]
    - [ 31939, 28623, 27331, 26843, 25511, 25807, 26311, 25845, 25404, 25929 ]
    - [ 30223, 27484, 26150, 25518, 24817, 24875, 25010, 24802, 24666, 24825 ]
    - [ 32338, 29289, 27926, 26477, 26155, 26275, 25969, 25749, 25514, 25906 ]
    - [ 31521, 28966, 26822, 27349, 25722, 25485, 25827, 25498, 25159, 25301 ]
    - [ 31210, 28521, 26864, 26899, 25757, 25617, 25254, 25537, 25671, 25313 ]
    - [ 30362, 28430, 27047, 26421, 25987, 25434, 25591, 25653, 25885, 25659 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 17941, 16149, 14706, 14441, 13940, 13622, 13583, 13508, 13150, 13086 ]
    - [ 17318, 14869, 14973, 15185, 12493, 12604, 12504, 12490, 12202, 12281 ]
    - [ 20154, 17482, 15595, 14484, 14536, 14415, 14340, 14229, 14394, 14777 ]
    - [ 17410, 15490, 13126, 12535, 12619, 12595, 12724, 12614, 12943, 12558 ]
    - [ 16864, 15261, 15210, 14817, 12646, 12390, 12505, 12910, 12312, 12834 ]
    - [ 17299, 14971, 15075, 15064, 12631, 12752, 12773, 12388, 12797, 12746 ]
    - [ 17797, 15477, 15482, 15342, 12385, 12315, 13001, 12739, 12521, 12839 ]
    - [ 17619, 15274, 14919, 15152, 12597, 13025, 13058, 12707, 13078, 12771 ]
    - [ 17059, 14621, 15043, 15435, 12502, 12103, 12663, 12851, 12485, 12207 ]
    - [ 17251, 15564, 15517, 15156, 12886, 12458, 12602, 12524, 12147, 12615 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 28082, 24600, 24720, 24511, 24669 ]
    - [ 28285, 24951, 24890, 24775, 24813 ]
    - [ 28278, 24873, 24957, 24855, 24819 ]
    - [ 28144, 24739, 24859, 24823, 24857 ]
    - [ 28279, 24966, 24918, 24748, 25004 ]
    - [ 28284, 24643, 24756, 24714, 24661 ]
    - [ 28209, 24647, 24845, 24674, 24705 ]
    - [ 28245, 24822, 24811, 24779, 24704 ]
    - [ 28140, 24862, 24826, 24701, 24660 ]
    - [ 28334, 24945, 24965, 24917, 24942 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5375, 4269, 4275, 4274, 4274 ]
    - [ 5367, 4273, 4260, 4276, 4283 ]
    - [ 5391, 4233, 4242, 4242, 4252 ]
    - [ 5504, 4294, 4283, 4292, 4307 ]
    - [ 5402, 4284, 4259, 4285, 4297 ]
    - [ 5415, 4350, 4306, 4330, 4342 ]
    - [ 5378, 4268, 4268, 4276, 4295 ]
    - [ 5132, 4042, 4052, 4052, 4072 ]
    - [ 5426, 4259, 4253, 4259, 4263 ]
    - [ 5573, 4354, 4352, 4346, 4341 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4686, 4234, 4242, 4254, 4247 ]
    - [ 4654, 4237, 4217, 4228, 4236 ]
    - [ 4696, 4248, 4238, 4252, 4273 ]
    - [ 4637, 4226, 4198, 4212, 4211 ]
    - [ 4550, 4124, 4114, 4117, 4132 ]
    - [ 4737, 4320, 4293, 4292, 4331 ]
    - [ 4710, 4262, 4246, 4258, 4262 ]
    - [ 4523, 4101, 4101, 4101, 4114 ]
    - [ 4638, 4225, 4212, 4217, 4231 ]
    - [ 4620, 4184, 4191, 4193, 4202 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6896, 2250, 2151, 2146, 2164 ]
    - [ 6669, 2285, 2194, 2177, 2196 ]
    - [ 6714, 2260, 2177, 2165, 2180 ]
    - [ 6600, 2301, 2193, 2289, 2201 ]
    - [ 6634, 2274, 2170, 2162, 2183 ]
    - [ 6598, 2235, 2186, 2217, 2189 ]
    - [ 6648, 2254, 2154, 2173, 2170 ]
    - [ 6807, 2227, 2171, 2163, 2184 ]
    - [ 6288, 2250, 2176, 2173, 2187 ]
    - [ 6638, 2289, 2200, 2279, 2209 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2593, 2068, 2084, 2075, 2081 ]
    - [ 2525, 2019, 2036, 2037, 2010 ]
    - [ 2597, 2056, 2046, 2013, 2033 ]
    - [ 2564, 2030, 2005, 2002, 2015 ]
    - [ 2557, 2004, 1989, 1994, 2005 ]
    - [ 2627, 2058, 2055, 2039, 2042 ]
    - [ 2572, 2033, 2029, 2042, 2039 ]
    - [ 2568, 2041, 2032, 2022, 2026 ]
    - [ 2531, 2031, 2004, 2010, 2005 ]
    - [ 2587, 2091, 2092, 2076, 2075 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3856, 2453, 2339, 2239, 2128 ]
    - [ 3802, 2430, 2283, 2174, 2113 ]
    - [ 3679, 2414, 2297, 2182, 2099 ]
    - [ 3718, 2436, 2285, 2205, 2118 ]
    - [ 3670, 2445, 2287, 2190, 2110 ]
    - [ 3783, 2439, 2282, 2204, 2121 ]
    - [ 3706, 2409, 2271, 2164, 2078 ]
    - [ 3797, 2492, 2345, 2234, 2118 ]
    - [ 3662, 2416, 2288, 2192, 2090 ]
    - [ 3697, 2474, 2317, 2197, 2097 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 2545, 1990, 1985, 1977, 1979 ]
    - [ 2505, 1949, 1931, 1935, 1924 ]
    - [ 2470, 1917, 1924, 1930, 1934 ]
    - [ 2522, 1974, 1971, 1968, 1953 ]
    - [ 2544, 2011, 2006, 2007, 2019 ]
    - [ 2502, 1981, 1970, 1969, 1966 ]
    - [ 2591, 2022, 2042, 2044, 2040 ]
    - [ 2630, 2014, 2041, 2042, 2052 ]
    - [ 2553, 2011, 1985, 1984, 1991 ]
    - [ 2517, 2008, 1985, 1963, 1967 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3200, 2496, 2449, 2433, 2415 ]
    - [ 3185, 2485, 2425, 2374, 2376 ]
    - [ 3230, 2509, 2525, 2466, 2462 ]
    - [ 3343, 2611, 2552, 2501, 2480 ]
    - [ 3252, 2474, 2413, 2360, 2322 ]
    - [ 3168, 2495, 2386, 2341, 2336 ]
    - [ 3231, 2485, 2452, 2361, 2394 ]
    - [ 3186, 2486, 2465, 2421, 2428 ]
    - [ 3230, 2454, 2426, 2342, 2334 ]
    - [ 3170, 2459, 2410, 2353, 2339 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2435, 1912, 1904, 1883, 1890 ]
    - [ 2309, 1795, 1792, 1769, 1772 ]
    - [ 2359, 1834, 1794, 1784, 1779 ]
    - [ 2429, 1883, 1891, 1884, 1872 ]
    - [ 2655, 1919, 1896, 1887, 1878 ]
    - [ 2392, 1854, 1852, 1805, 1832 ]
    - [ 2303, 1769, 1780, 1744, 1750 ]
    - [ 2392, 1887, 1843, 1841, 1845 ]
    - [ 2469, 1883, 1851, 1841, 1851 ]
    - [ 2334, 1789, 1782, 1787, 1790 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 3308, 2221, 2017, 2063, 2093, 2093, 2096, 2112, 2093, 2103 ]
    - [ 3496, 2336, 2079, 2001, 2047, 2047, 2044, 2017, 2001, 2018 ]
    - [ 3473, 2198, 2006, 1978, 2063, 2045, 2070, 2036, 2027, 2023 ]
    - [ 3330, 2159, 1959, 2018, 2082, 2067, 2064, 2055, 2041, 2046 ]
    - [ 3341, 2167, 1983, 1991, 2063, 2018, 2046, 2042, 2013, 2063 ]
    - [ 3403, 2246, 2046, 1983, 2094, 2086, 2090, 2076, 1995, 2071 ]
    - [ 3347, 2200, 1991, 2022, 2063, 2058, 2051, 2042, 1998, 2070 ]
    - [ 3574, 2306, 2103, 2013, 2049, 2065, 2074, 2032, 2027, 2084 ]
    - [ 3294, 2220, 1976, 2032, 2060, 2062, 2077, 2082, 2012, 2103 ]
    - [ 3389, 2334, 2073, 2021, 2060, 2041, 2048, 2048, 2040, 2058 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 48770, 33394, 30516, 29233, 28234, 28242, 28291, 27719, 27891, 27614 ]
    - [ 48319, 33308, 31049, 28884, 28161, 28050, 28157, 28346, 27883, 27522 ]
    - [ 49369, 33699, 31048, 29530, 28401, 28147, 28286, 28297, 27962, 27716 ]
    - [ 50025, 33306, 30765, 29093, 28117, 28178, 28369, 27897, 27960, 27657 ]
    - [ 47943, 32979, 29627, 27988, 27181, 27012, 26779, 26803, 26548, 26466 ]
    - [ 49111, 33607, 31197, 29079, 28056, 28048, 28137, 28392, 28206, 27678 ]
    - [ 49658, 33631, 30553, 29273, 28163, 28181, 28160, 28121, 27875, 27674 ]
    - [ 49066, 33162, 31889, 28893, 28478, 28402, 28300, 28483, 28170, 27916 ]
    - [ 49195, 33095, 30598, 28978, 28491, 28163, 27932, 28229, 27865, 27447 ]
    - [ 49868, 33315, 30729, 29378, 28209, 28556, 28104, 28044, 27961, 27795 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 5484, 4542, 4577, 4628, 4438, 4463, 4421, 4428, 4383, 4428 ]
    - [ 5356, 4531, 4483, 4461, 4436, 4445, 4418, 4342, 4414, 4519 ]
    - [ 5457, 4723, 4643, 4695, 4685, 4656, 4647, 4719, 4475, 4442 ]
    - [ 5478, 4535, 4516, 4532, 4527, 4526, 4558, 4531, 4309, 4386 ]
    - [ 5391, 4494, 4476, 4466, 4412, 4508, 4452, 4403, 4433, 4519 ]
    - [ 5507, 4627, 4624, 4665, 4594, 4683, 4696, 4626, 4656, 4647 ]
    - [ 5627, 4711, 4646, 4666, 4675, 4667, 4687, 4702, 4699, 4675 ]
    - [ 5600, 4679, 4626, 4633, 4604, 4597, 4595, 4528, 4607, 4558 ]
    - [ 5690, 4669, 4633, 4649, 4732, 4763, 4752, 4684, 4707, 4773 ]
    - [ 5482, 4557, 4509, 4489, 4359, 4261, 4353, 4335, 4347, 4310 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 73014, 48186, 45369, 41369, 41066, 40890, 41109, 40651, 40428, 40165 ]
    - [ 70584, 48854, 42790, 40526, 40012, 39886, 40237, 39738, 39595, 39815 ]
    - [ 70682, 47343, 43939, 40559, 39562, 39957, 39928, 39982, 39968, 39294 ]
    - [ 71149, 48530, 43744, 40980, 40461, 39608, 39637, 39456, 39781, 38908 ]
    - [ 72241, 48057, 43865, 40299, 39879, 40021, 40448, 39988, 40093, 39498 ]
    - [ 70981, 49719, 42799, 40448, 40020, 39702, 40019, 39860, 39881, 40174 ]
    - [ 70387, 49063, 42701, 40718, 40304, 38785, 40236, 39770, 39776, 39190 ]
    - [ 71450, 49105, 44154, 40613, 40304, 40454, 40707, 40429, 40491, 40092 ]
    - [ 68517, 44671, 41618, 39835, 39315, 38254, 38603, 38280, 38116, 38096 ]
    - [ 71314, 48836, 44101, 41130, 40689, 40257, 40053, 39761, 39903, 40528 ]
