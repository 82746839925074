#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5573, 5340, 5187, 5180, 5191, 5182, 5189, 5087, 5085, 5090 ]
    - [ 5578, 5359, 5175, 5193, 5188, 5185, 5176, 5094, 5096, 5182 ]
    - [ 5562, 5367, 5165, 5187, 5190, 5193, 5090, 5090, 5092, 5087 ]
    - [ 5560, 5293, 5185, 5190, 5192, 5088, 5097, 5103, 5092, 5096 ]
    - [ 5662, 5378, 5185, 5182, 5188, 5293, 5095, 5185, 5394, 5343 ]
  2.0:
    - [ 5497, 5287, 5164, 5164, 5168, 5166, 5170, 5162, 5169, 5161 ]
    - [ 5554, 5306, 5186, 5182, 5091, 5096, 5085, 5093, 5088, 5088 ]
    - [ 5510, 5313, 5190, 5092, 5094, 5091, 5093, 5099, 5094, 5090 ]
    - [ 5530, 5259, 5184, 5188, 5188, 5094, 5092, 5093, 5090, 5088 ]
    - [ 5443, 5289, 5182, 5185, 5186, 5086, 5187, 5088, 5089, 5088 ]
    - [ 5528, 5302, 5292, 5090, 5097, 5101, 5097, 5095, 5097, 5092 ]
    - [ 5535, 5298, 5188, 5184, 5188, 5091, 5090, 5091, 5187, 5092 ]
    - [ 5552, 5291, 5190, 5184, 5187, 5094, 5090, 5096, 5090, 5095 ]
    - [ 5540, 5239, 5091, 5194, 5092, 5099, 5091, 5097, 5104, 5090 ]
    - [ 5533, 5276, 5161, 5168, 5176, 5166, 5171, 5163, 5170, 5173 ]
  3.0:
    - [ 5583, 5276, 5190, 5090, 5095, 5093, 5094, 5098, 5095, 5096 ]
    - [ 5530, 5315, 5178, 5184, 5089, 5191, 5085, 5088, 5090, 5091 ]
    - [ 5610, 5274, 5185, 5183, 5088, 5091, 5090, 5091, 5093, 5091 ]
    - [ 5548, 5318, 5183, 5088, 5094, 5092, 5094, 5089, 5294, 5091 ]
    - [ 5566, 5303, 5159, 5166, 5173, 5164, 5172, 5164, 5067, 5069 ]
    - [ 5574, 5298, 5184, 5092, 5091, 5090, 5096, 5095, 5089, 5093 ]
    - [ 5519, 5307, 5180, 5182, 5084, 5092, 5097, 5088, 5086, 5082 ]
    - [ 5520, 5312, 5185, 5088, 5085, 5090, 5088, 5092, 5098, 5095 ]
    - [ 5560, 5299, 5180, 5086, 5090, 5088, 5090, 5090, 5090, 5089 ]
    - [ 5528, 5318, 5182, 5291, 5086, 5096, 5091, 5094, 5094, 5092 ]
  4.0:
    - [ 5485, 5267, 5151, 5159, 5165, 5156, 5161, 5161, 5159, 5155 ]
    - [ 5502, 5286, 5093, 5089, 5092, 5092, 5094, 5099, 5094, 5094 ]
    - [ 5535, 5295, 5186, 5085, 5094, 5090, 5288, 5087, 5089, 5088 ]
    - [ 5532, 5274, 5183, 5185, 5090, 5085, 5086, 5089, 5090, 5087 ]
    - [ 5540, 5291, 5160, 5160, 5164, 5260, 5167, 5158, 5063, 5159 ]
    - [ 5598, 5307, 5281, 5189, 5086, 5093, 5092, 5093, 5093, 5089 ]
    - [ 5541, 5337, 5181, 5091, 5091, 5094, 5092, 5292, 5094, 5094 ]
    - [ 5567, 5298, 5177, 5086, 5088, 5086, 5088, 5089, 5095, 5094 ]
    - [ 5541, 5301, 5180, 5092, 5089, 5091, 5093, 5090, 5098, 5094 ]
    - [ 5511, 5294, 5089, 5091, 5094, 5090, 5090, 5095, 5090, 5090 ]
  5.0:
    - [ 5495, 5301, 5181, 5090, 5092, 5092, 5091, 5098, 5094, 5092 ]
    - [ 5517, 5339, 5181, 5088, 5185, 5092, 5093, 5097, 5090, 5093 ]
    - [ 5541, 5246, 5182, 5082, 5092, 5087, 5086, 5090, 5091, 5088 ]
    - [ 5613, 5285, 5152, 5159, 5158, 5261, 5161, 5159, 5155, 5064 ]
    - [ 5567, 5316, 5160, 5169, 5267, 5172, 5172, 5168, 5071, 5073 ]
    - [ 5581, 5293, 5087, 5093, 5095, 5091, 5095, 5097, 5095, 5102 ]
    - [ 5524, 5299, 5153, 5165, 5166, 5173, 5163, 5163, 5158, 5158 ]
    - [ 5504, 5311, 5146, 5152, 5165, 5159, 5156, 5153, 5155, 5153 ]
    - [ 5577, 5274, 5252, 5160, 5165, 5161, 5161, 5155, 5161, 5162 ]
    - [ 5481, 5242, 5177, 5184, 5082, 5085, 5090, 5086, 5089, 5090 ]
  6.0:
    - [ 5500, 5283, 5135, 5160, 5160, 5162, 5162, 5161, 5156, 5154 ]
    - [ 5510, 5283, 5188, 5087, 5087, 5091, 5190, 5091, 5094, 5090 ]
    - [ 5547, 5275, 5151, 5153, 5162, 5161, 5162, 5152, 5151, 5154 ]
    - [ 5542, 5295, 5183, 5186, 5091, 5089, 5088, 5092, 5090, 5095 ]
    - [ 5496, 5312, 5148, 5169, 5164, 5170, 5171, 5163, 5073, 5063 ]
    - [ 5563, 5300, 5169, 5171, 5084, 5173, 5080, 5177, 5083, 5083 ]
    - [ 5438, 5308, 5191, 5182, 5089, 5090, 5096, 5095, 5096, 5095 ]
    - [ 5525, 5281, 5161, 5159, 5170, 5265, 5168, 5168, 5262, 5665 ]
    - [ 5486, 5297, 5187, 5183, 5087, 5090, 5089, 5090, 5092, 5090 ]
    - [ 5521, 5270, 5182, 5187, 5182, 5086, 5090, 5088, 5090, 5085 ]
  7.0:
    - [ 5471, 5285, 5187, 5092, 5091, 5089, 5090, 5089, 5092, 5089 ]
    - [ 5518, 5309, 5184, 5089, 5094, 5097, 5093, 5091, 5091, 5090 ]
    - [ 5516, 5281, 5158, 5163, 5163, 5165, 5167, 5164, 5160, 5158 ]
    - [ 5444, 5282, 5153, 5155, 5156, 5155, 5155, 5151, 5059, 5152 ]
    - [ 5544, 5297, 5188, 5089, 5086, 5089, 5089, 5093, 5091, 5088 ]
    - [ 5503, 5294, 5160, 5158, 5160, 5159, 5156, 5158, 5156, 5164 ]
    - [ 5596, 5313, 5181, 5186, 5091, 5189, 5093, 5292, 5085, 5087 ]
    - [ 5579, 5280, 5158, 5155, 5156, 5161, 5159, 5162, 5155, 5157 ]
    - [ 5568, 5281, 5176, 5182, 5084, 5090, 5089, 5090, 5089, 5085 ]
    - [ 5503, 5286, 5187, 5085, 5091, 5092, 5091, 5094, 5089, 5090 ]
  8.0:
    - [ 5488, 5320, 5183, 5290, 5090, 5087, 5087, 5087, 5091, 5093 ]
    - [ 5490, 5325, 5178, 5183, 5089, 5087, 5090, 5091, 5087, 5089 ]
    - [ 5486, 5294, 5154, 5148, 5158, 5163, 5153, 5161, 5160, 5150 ]
    - [ 5559, 5286, 5162, 5174, 5177, 5170, 5075, 5080, 5076, 5072 ]
    - [ 5575, 5293, 5152, 5163, 5164, 5162, 5165, 5155, 5156, 5164 ]
    - [ 5551, 5324, 5250, 5154, 5158, 5163, 5153, 5160, 5153, 5157 ]
    - [ 5561, 5290, 5184, 5086, 5090, 5187, 5091, 5093, 5093, 5090 ]
    - [ 5514, 5321, 5184, 5089, 5090, 5093, 5094, 5091, 5092, 5095 ]
    - [ 5517, 5279, 5183, 5184, 5085, 5091, 5090, 5090, 5088, 5092 ]
    - [ 5586, 5379, 5151, 5153, 5154, 5163, 5161, 5160, 5151, 5162 ]
  9.0:
    - [ 5532, 5293, 5157, 5153, 5157, 5154, 5158, 5164, 5153, 5161 ]
    - [ 5508, 5300, 5184, 5185, 5084, 5088, 5183, 5088, 5082, 5087 ]
    - [ 5574, 5292, 5190, 5089, 5091, 5092, 5095, 5098, 5092, 5096 ]
    - [ 5450, 5272, 5148, 5156, 5152, 5157, 5152, 5156, 5163, 5145 ]
    - [ 5536, 5268, 5157, 5160, 5161, 5159, 5150, 5161, 5161, 5157 ]
    - [ 5554, 5288, 5157, 5167, 5162, 5162, 5166, 5164, 5169, 5162 ]
    - [ 5488, 5174, 5183, 5090, 5089, 5093, 5093, 5090, 5093, 5090 ]
    - [ 5486, 5282, 5179, 5183, 5184, 5084, 5176, 5181, 5083, 5093 ]
    - [ 5538, 5234, 5183, 5185, 5186, 5085, 5093, 5089, 5085, 5087 ]
    - [ 5524, 5281, 5156, 5166, 5166, 5153, 5167, 5171, 5261, 5072 ]
  10.0:
    - [ 5453, 5278, 5151, 5158, 5154, 5159, 5163, 5160, 5149, 5160 ]
    - [ 5529, 5278, 5159, 5155, 5153, 5156, 5160, 5157, 5164, 5155 ]
    - [ 5543, 5292, 5176, 5181, 5083, 5086, 5085, 5089, 5089, 5084 ]
    - [ 5564, 5278, 5150, 5154, 5156, 5158, 5163, 5160, 5153, 5158 ]
    - [ 5485, 5271, 5157, 5151, 5151, 5153, 5160, 5154, 5255, 5160 ]
    - [ 5544, 5274, 5139, 5158, 5158, 5262, 5156, 5154, 5160, 5161 ]
    - [ 5540, 5290, 5184, 5085, 5087, 5089, 5091, 5092, 5092, 5091 ]
    - [ 5481, 5238, 5090, 5087, 5092, 5091, 5090, 5098, 5085, 5092 ]
    - [ 5504, 5298, 5151, 5159, 5157, 5162, 5167, 5160, 5151, 5154 ]
    - [ 5569, 5232, 5185, 5085, 5092, 5090, 5290, 5087, 5087, 5088 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 7286, 5827, 5409, 5513, 5526, 5544, 5447, 5428, 5450, 5414 ]
    - [ 7490, 5789, 5557, 5547, 5520, 5447, 5442, 5516, 5489, 5444 ]
    - [ 7405, 5826, 5530, 5653, 5515, 5441, 5419, 5447, 5444, 5451 ]
    - [ 7390, 5785, 5456, 5542, 5462, 5427, 5447, 5411, 5394, 5458 ]
    - [ 7530, 5780, 5565, 5526, 5519, 5552, 5443, 5455, 5469, 5450 ]
    - [ 7632, 5726, 5505, 5611, 5558, 5534, 5423, 5452, 5382, 5418 ]
    - [ 7366, 5825, 5610, 5550, 5532, 5449, 5438, 5410, 5382, 5457 ]
    - [ 7547, 5752, 5459, 5519, 5430, 5509, 5447, 5446, 5472, 5451 ]
    - [ 7319, 5737, 5589, 5548, 5521, 5432, 5448, 5443, 5441, 5451 ]
    - [ 7630, 5859, 5569, 5507, 5601, 5371, 5427, 5408, 5455, 5442 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5533, 5277, 5271, 5174, 5175, 5078, 5089, 5086, 5088, 5084 ]
    - [ 5544, 5283, 5178, 5178, 5178, 5079, 5080, 5083, 5082, 5090 ]
    - [ 5491, 5284, 5173, 5179, 5080, 5081, 5084, 5082, 5090, 5085 ]
    - [ 5443, 5308, 5170, 5177, 5176, 5176, 5180, 5079, 5080, 5078 ]
    - [ 5449, 5325, 5170, 5180, 5178, 5179, 5084, 5077, 5080, 5081 ]
    - [ 5522, 5300, 5180, 5079, 5085, 5079, 5082, 5086, 5084, 5090 ]
    - [ 5528, 5277, 5178, 5177, 5179, 5282, 5083, 5081, 5079, 5083 ]
    - [ 5512, 5297, 5181, 5187, 5077, 5086, 5095, 5085, 5182, 5084 ]
    - [ 5451, 5277, 5177, 5173, 5175, 5178, 5181, 5275, 5080, 5088 ]
    - [ 5434, 5287, 5276, 5180, 5183, 5081, 5085, 5185, 5077, 5086 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5527, 5280, 5168, 5167, 5170, 5153, 5166, 5168, 5268, 5156 ]
    - [ 5543, 5281, 5172, 5166, 5169, 5161, 5173, 5078, 5075, 5070 ]
    - [ 5479, 5271, 5150, 5166, 5166, 5163, 5153, 5171, 5159, 5067 ]
    - [ 5480, 5286, 5152, 5167, 5163, 5163, 5159, 5170, 5163, 5168 ]
    - [ 5529, 5286, 5156, 5163, 5273, 5164, 5164, 5073, 5073, 5073 ]
    - [ 5529, 5255, 5149, 5159, 5168, 5164, 5157, 5159, 5160, 5061 ]
    - [ 5544, 5320, 5151, 5173, 5173, 5163, 5154, 5168, 5168, 5075 ]
    - [ 5540, 5285, 5150, 5165, 5162, 5163, 5251, 5160, 5070, 5167 ]
    - [ 5532, 5271, 5163, 5164, 5167, 5173, 5158, 5168, 5067, 5166 ]
    - [ 5480, 5279, 5160, 5172, 5171, 5176, 5155, 5165, 5071, 5076 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 5541, 5301, 5185, 5182, 5200, 5283, 5413, 5358, 5381, 5312 ]
    - [ 5515, 5332, 5289, 5346, 5292, 5287, 5323, 5304, 5390, 5311 ]
    - [ 5548, 5271, 5189, 5186, 5290, 5299, 5418, 5384, 5390, 5405 ]
    - [ 5522, 5330, 5182, 5285, 5291, 5290, 5359, 5289, 5392, 5382 ]
    - [ 5554, 5331, 5178, 5289, 5385, 5328, 5298, 5365, 5396, 5295 ]
    - [ 5553, 5308, 5181, 5190, 5294, 5288, 5336, 5374, 5355, 5376 ]
    - [ 5541, 5345, 5289, 5304, 5380, 5353, 5377, 5305, 5354, 5366 ]
    - [ 5541, 5286, 5187, 5288, 5302, 5311, 5398, 5370, 5368, 5393 ]
    - [ 5526, 5284, 5156, 5262, 5307, 5371, 5369, 5376, 5402, 5379 ]
    - [ 5479, 5295, 5284, 5286, 5302, 5292, 5412, 5404, 5195, 5164 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 5524, 5268, 5175, 5165, 5090, 5166, 5172, 5168, 5166, 5167 ]
    - [ 5542, 5273, 5184, 5178, 5099, 5184, 5172, 5178, 5175, 5072 ]
    - [ 5551, 5284, 5293, 5179, 5189, 5178, 5086, 5089, 5081, 5083 ]
    - [ 5557, 5275, 5180, 5093, 5177, 5088, 5092, 5093, 5092, 5086 ]
    - [ 5563, 5279, 5185, 5186, 5085, 5091, 5087, 5090, 5182, 5084 ]
    - [ 5556, 5283, 5184, 5183, 5184, 5180, 5083, 5085, 5086, 5088 ]
    - [ 5542, 5279, 5175, 5180, 5192, 5195, 5178, 5080, 5170, 5079 ]
    - [ 5565, 5299, 5164, 5181, 5086, 5082, 5091, 5084, 5088, 5087 ]
    - [ 5525, 5279, 5174, 5175, 5174, 5176, 5079, 5167, 5186, 5075 ]
    - [ 5567, 5263, 5186, 5184, 5089, 5194, 5087, 5092, 5091, 5098 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 5573, 5293, 5173, 5175, 5173, 5182, 5175, 5076, 5076, 5075 ]
    - [ 5565, 5298, 5171, 5178, 5177, 5175, 5676, 5178, 5077, 5077 ]
    - [ 5461, 5263, 5271, 5171, 5179, 5176, 5176, 5172, 5079, 5171 ]
    - [ 5610, 5280, 5171, 5168, 5172, 5178, 5173, 5174, 5172, 5170 ]
    - [ 5591, 5265, 5173, 5174, 5180, 5175, 5177, 5175, 5080, 5076 ]
    - [ 5590, 5276, 5169, 5171, 5174, 5173, 5174, 5172, 5077, 5177 ]
    - [ 5583, 5287, 5167, 5268, 5166, 5173, 5166, 5172, 5167, 5166 ]
    - [ 5564, 5286, 5168, 5167, 5174, 5176, 5169, 5172, 5077, 5169 ]
    - [ 5575, 5298, 5170, 5174, 5173, 5674, 5177, 5178, 5074, 5076 ]
    - [ 5582, 5290, 5175, 5179, 5180, 5181, 5178, 5078, 5179, 5080 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 21803, 21653, 21542, 22118, 21643 ]
    - [ 21816, 21542, 22268, 21236, 21530 ]
    - [ 22033, 24940, 21791, 21582, 23019 ]
    - [ 22275, 21827, 21606, 21359, 21456 ]
    - [ 22592, 21745, 21702, 21706, 21547 ]
    - [ 21874, 21766, 21534, 21610, 22220 ]
    - [ 22087, 21189, 21840, 21759, 21540 ]
    - [ 21928, 22006, 21550, 21211, 21162 ]
    - [ 22165, 21455, 21681, 22106, 21703 ]
    - [ 21824, 21428, 25268, 21427, 21895 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7342, 5704, 5186, 5303, 6339 ]
    - [ 7345, 6117, 6361, 6260, 6152 ]
    - [ 7347, 6519, 6265, 6163, 6109 ]
    - [ 7369, 6009, 6266, 6261, 6160 ]
    - [ 7355, 6322, 6257, 6171, 6168 ]
    - [ 7367, 5719, 5179, 5308, 6382 ]
    - [ 7415, 6314, 6268, 6262, 6171 ]
    - [ 7381, 5701, 5181, 5305, 6451 ]
    - [ 7317, 6317, 6256, 6257, 6168 ]
    - [ 7389, 5717, 5173, 5301, 6394 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5522, 5272, 5197, 5179, 5308 ]
    - [ 5554, 5177, 5386, 5389, 5395 ]
    - [ 5517, 5158, 5212, 5180, 5296 ]
    - [ 5544, 5172, 5195, 5297, 5298 ]
    - [ 5502, 5156, 5205, 5207, 5292 ]
    - [ 5537, 5367, 5386, 5297, 5378 ]
    - [ 5489, 5372, 5195, 5301, 5300 ]
    - [ 5509, 5266, 5290, 5379, 5199 ]
    - [ 5467, 5171, 5197, 5193, 5287 ]
    - [ 5494, 5380, 5390, 5391, 5390 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 19491, 15450, 13881, 9223, 10080 ]
    - [ 19637, 16147, 14129, 9560, 5167 ]
    - [ 20105, 15711, 13979, 12015, 12200 ]
    - [ 19364, 15086, 13558, 13406, 11501 ]
    - [ 19414, 15829, 13578, 5359, 5170 ]
    - [ 19549, 16401, 14197, 9553, 5275 ]
    - [ 19363, 16190, 13695, 8452, 5165 ]
    - [ 20007, 15394, 13977, 5360, 5269 ]
    - [ 19827, 16263, 13407, 9860, 5168 ]
    - [ 19765, 16156, 14217, 9562, 5163 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5851, 5323, 5269, 5188, 5191 ]
    - [ 5886, 5331, 5291, 5274, 5300 ]
    - [ 5803, 5333, 5284, 5173, 5196 ]
    - [ 5908, 5343, 5377, 5170, 5292 ]
    - [ 5832, 5340, 5286, 5269, 5193 ]
    - [ 5855, 5351, 5392, 5363, 5191 ]
    - [ 5843, 5318, 5286, 5169, 5193 ]
    - [ 5849, 5321, 5284, 5175, 5294 ]
    - [ 5819, 5403, 5341, 5133, 5162 ]
    - [ 5833, 5346, 5385, 5171, 5193 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5878, 5391, 5374, 5375, 5181 ]
    - [ 5941, 5403, 5378, 5375, 5384 ]
    - [ 5902, 5405, 5375, 5177, 5379 ]
    - [ 5917, 5368, 5377, 5376, 5182 ]
    - [ 5945, 5385, 5176, 5185, 5188 ]
    - [ 5899, 5403, 5173, 5283, 5180 ]
    - [ 5895, 5369, 5186, 5184, 5193 ]
    - [ 5977, 5380, 5182, 5175, 5176 ]
    - [ 5932, 5359, 5180, 5183, 5191 ]
    - [ 5942, 5413, 5174, 5280, 5177 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5571, 5284, 5260, 5361, 5375 ]
    - [ 5637, 5379, 5360, 5361, 5363 ]
    - [ 5590, 5394, 5362, 5366, 5269 ]
    - [ 5480, 5336, 5289, 5187, 5190 ]
    - [ 5570, 5278, 5182, 5191, 5194 ]
    - [ 5552, 5311, 5179, 5186, 5191 ]
    - [ 5490, 5281, 5184, 5091, 5094 ]
    - [ 5509, 5304, 5181, 5179, 5088 ]
    - [ 5505, 5280, 5183, 5087, 5089 ]
    - [ 5495, 5306, 5184, 5188, 5084 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5573, 5292, 5183, 5180, 5179 ]
    - [ 5486, 5301, 5187, 5185, 5190 ]
    - [ 5517, 5327, 5282, 5181, 5184 ]
    - [ 5527, 5263, 5182, 5181, 5185 ]
    - [ 5532, 5285, 5181, 5285, 5186 ]
    - [ 5554, 5290, 5147, 5153, 5154 ]
    - [ 5544, 5281, 5159, 5162, 5163 ]
    - [ 5570, 5266, 5186, 5186, 5185 ]
    - [ 5520, 5293, 5142, 5155, 5158 ]
    - [ 5488, 5293, 5186, 5187, 5188 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 5408, 5162, 5106, 5108, 5106 ]
    - [ 5439, 5140, 5109, 5110, 5115 ]
    - [ 5394, 5237, 5090, 5094, 5097 ]
    - [ 5349, 5113, 5078, 5082, 5089 ]
    - [ 5432, 5235, 5104, 5106, 5107 ]
    - [ 5436, 5134, 5107, 5113, 5113 ]
    - [ 5455, 5320, 5078, 5082, 5084 ]
    - [ 5396, 5121, 5087, 5081, 5093 ]
    - [ 5455, 5319, 5078, 5085, 5076 ]
    - [ 5417, 5321, 5100, 5107, 5103 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 6265, 6205, 5932, 5927, 5931, 5829, 5835, 5932, 5832, 5825 ]
    - [ 6124, 6122, 6132, 5835, 6037, 5836, 5937, 5841, 5933, 5831 ]
    - [ 6282, 6062, 5835, 6036, 5836, 5840, 5842, 5838, 5834, 5831 ]
    - [ 6201, 6110, 5835, 5833, 5839, 5832, 6036, 5834, 5837, 5932 ]
    - [ 6220, 6124, 5833, 6139, 6031, 6032, 5939, 5833, 5838, 5834 ]
    - [ 6208, 6111, 6017, 6122, 5818, 5823, 5825, 6025, 5822, 5822 ]
    - [ 6290, 6195, 5923, 6027, 5927, 5920, 6035, 5826, 5829, 5828 ]
    - [ 6259, 6100, 5913, 6015, 5810, 5815, 5816, 6014, 6012, 5814 ]
    - [ 6264, 6106, 5830, 5934, 5834, 6033, 5934, 5831, 5833, 5833 ]
    - [ 6306, 6179, 6031, 5832, 5834, 6032, 5831, 5832, 6028, 5830 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 9148, 6591, 6467, 6265, 6296, 6249, 6242, 6152, 6094, 6073 ]
    - [ 9158, 6552, 6326, 6318, 6268, 6294, 6269, 6078, 6192, 6150 ]
    - [ 9032, 6602, 6340, 6298, 6221, 6242, 6244, 6053, 6150, 6208 ]
    - [ 9011, 6650, 6475, 6272, 6298, 6247, 6191, 6190, 6202, 6201 ]
    - [ 9349, 6634, 6381, 6280, 6160, 6298, 6150, 6190, 6168, 6103 ]
    - [ 9047, 6590, 6328, 6378, 6361, 6099, 6180, 6155, 6171, 6228 ]
    - [ 9285, 6532, 6360, 6382, 6266, 6361, 6228, 6293, 6199, 6150 ]
    - [ 9125, 6507, 6331, 6242, 6222, 6190, 6198, 6152, 6237, 6261 ]
    - [ 9263, 6686, 6376, 6397, 6263, 6176, 6195, 6183, 6192, 6193 ]
    - [ 9020, 6578, 6378, 6365, 6283, 6218, 6194, 6224, 6248, 6164 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 7181, 6392, 6266, 6287, 5987, 6300, 6187, 5998, 5985, 5989 ]
    - [ 7231, 6471, 6282, 6295, 6301, 5998, 6204, 6005, 5999, 5994 ]
    - [ 6941, 6386, 6276, 6302, 6294, 6298, 6301, 6292, 6200, 6196 ]
    - [ 7076, 6391, 6284, 5994, 6293, 6192, 6292, 6192, 5997, 5993 ]
    - [ 7214, 6475, 6281, 6288, 6288, 6301, 6297, 6189, 6193, 6294 ]
    - [ 7220, 6405, 6272, 6292, 5999, 6005, 6207, 5998, 6291, 5996 ]
    - [ 6995, 6404, 6279, 6290, 5999, 6001, 6301, 6189, 6194, 5998 ]
    - [ 7211, 6402, 6281, 6301, 6289, 5994, 6000, 5996, 6004, 5990 ]
    - [ 6974, 6377, 6277, 6299, 5993, 5999, 6201, 5995, 6193, 5992 ]
    - [ 7219, 6409, 6283, 6289, 6087, 5990, 5994, 5990, 5990, 6188 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 15535, 9558, 7438, 6851, 6547, 6578, 6516, 6563, 6522, 6457 ]
    - [ 15403, 9260, 7769, 7048, 6555, 6589, 6624, 6497, 6516, 6449 ]
    - [ 15950, 9872, 7656, 6832, 6603, 6525, 6458, 6578, 6534, 6617 ]
    - [ 15517, 9432, 7333, 6763, 6728, 6483, 6574, 6523, 6488, 6513 ]
    - [ 15890, 9487, 7580, 6745, 6613, 6741, 6531, 6461, 6429, 6489 ]
    - [ 15449, 9497, 7646, 7317, 6711, 6662, 6523, 6533, 6386, 6477 ]
    - [ 15204, 9741, 7411, 6896, 6621, 6589, 6496, 6466, 6422, 6470 ]
    - [ 15511, 9692, 7601, 7166, 6694, 6472, 6559, 6467, 6477, 6496 ]
    - [ 14886, 9655, 7813, 7012, 6693, 6538, 6458, 6467, 6422, 6537 ]
    - [ 15471, 9184, 7892, 7181, 6690, 6623, 6696, 6479, 6420, 6615 ]
