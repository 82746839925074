#
# This data is part of the DaCapo benchmark suite.
#
# Please refer to https://www.dacapobench.org/ for instructions on how to
# correctly cite this work.
#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered using the following DaCapo version:
#    dacapo-evaluation-git-071e5040
#
# Unless otherwise noted, the following JVM was used:
#    Temurin-21.0.4+7
#
# Unless otherwise noted, results were generated on the following platform:
#    AMD Ryzen 9 7950X 16/32 4.5GHz cores, Linux 6.8.0-40
#
# Intel and ARM results were generated on the following platforms:
#    Intel Core i9-12900KF Alder Lake 8/16 3.2GHz + 8/8 2.4GHz cores, Linux 6.8.0-40
#    Ampere Altra 80 3.0GHz cores, Linux 6.8.0-40
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 10823, 9306, 8319, 8374, 8495, 8217, 8196, 8337, 8309, 8333 ]
    - [ 10747, 9052, 8363, 8306, 8442, 8360, 8343, 8337, 8204, 8196 ]
    - [ 10892, 8704, 8701, 8322, 8306, 8354, 8296, 8291, 8204, 8244 ]
    - [ 11050, 8881, 8635, 8369, 8262, 8288, 8141, 8321, 8231, 8158 ]
    - [ 10876, 8977, 8358, 8255, 8537, 8460, 8346, 8418, 8341, 8253 ]
    - [ 11031, 9257, 8317, 8357, 8357, 8333, 8296, 8262, 8282, 8237 ]
    - [ 11073, 8805, 8525, 8419, 8384, 8249, 8295, 8173, 8339, 8288 ]
    - [ 11262, 8858, 8439, 8290, 8378, 8247, 8218, 8366, 8358, 8389 ]
    - [ 10946, 9235, 8360, 8258, 8296, 8269, 8281, 8100, 8110, 8111 ]
    - [ 11029, 8900, 8401, 8479, 8355, 8333, 8294, 8121, 8389, 8276 ]
  2.0:
    - [ 10110, 8064, 7405, 7280, 7333, 7246, 7223, 7270, 7288, 7213 ]
    - [ 10177, 7932, 7404, 7263, 7200, 7227, 7212, 7205, 7240, 7238 ]
    - [ 10235, 7787, 7410, 7325, 7264, 7282, 7252, 7281, 7218, 8194 ]
    - [ 10351, 7918, 7348, 7351, 7277, 7288, 7214, 7319, 7250, 7326 ]
    - [ 10144, 7785, 7324, 7260, 7160, 7146, 7155, 7210, 7226, 7179 ]
    - [ 10055, 7821, 7317, 7369, 7448, 7231, 7211, 7225, 7206, 7235 ]
    - [ 9977, 8340, 7467, 7415, 7342, 7316, 7342, 7288, 7245, 7314 ]
    - [ 10315, 8180, 7393, 7408, 7303, 7274, 7254, 7305, 7259, 7284 ]
    - [ 10429, 7825, 7526, 7422, 7315, 7261, 7318, 7284, 7241, 7198 ]
    - [ 10260, 7921, 7514, 7386, 7250, 7256, 7291, 7257, 7279, 7276 ]
  3.0:
    - [ 10134, 7785, 7538, 7206, 7171, 7156, 7261, 7187, 7176, 7085 ]
    - [ 10095, 7862, 7580, 7187, 7161, 7170, 7173, 7089, 7106, 7095 ]
    - [ 10162, 7993, 7365, 7238, 7180, 7204, 7181, 7240, 7188, 7146 ]
    - [ 10147, 8029, 7354, 7235, 7213, 7192, 7193, 7189, 7212, 7153 ]
    - [ 10249, 7778, 7341, 7305, 7212, 7161, 7195, 7168, 7155, 7109 ]
    - [ 10229, 8247, 7337, 7264, 7266, 7237, 7204, 7207, 7186, 7199 ]
    - [ 10459, 8243, 7414, 7324, 7306, 7542, 7250, 7226, 7257, 7256 ]
    - [ 10313, 8132, 7181, 7178, 7205, 7170, 7170, 7157, 7153, 7141 ]
    - [ 10113, 7951, 7448, 7204, 7133, 7177, 7158, 7176, 7123, 7099 ]
    - [ 10114, 7795, 7321, 7222, 7162, 7156, 7168, 7129, 7133, 7155 ]
  4.0:
    - [ 10171, 7822, 7315, 7524, 7163, 7113, 7140, 7116, 7147, 7032 ]
    - [ 10240, 7664, 7357, 7201, 7159, 7123, 7156, 7139, 7093, 7126 ]
    - [ 10095, 7897, 7310, 7213, 7192, 7166, 7134, 7088, 7072, 7072 ]
    - [ 10276, 7818, 7768, 7217, 7169, 7366, 7220, 7144, 7124, 7133 ]
    - [ 10331, 7703, 7369, 7463, 7162, 7160, 7091, 7097, 7071, 7089 ]
    - [ 10093, 7710, 7271, 7217, 7147, 7145, 7114, 7121, 7056, 7088 ]
    - [ 10151, 8001, 7323, 7257, 7227, 7183, 7157, 7098, 7057, 7077 ]
    - [ 10190, 7805, 7230, 7178, 7163, 7088, 7140, 7119, 7112, 7081 ]
    - [ 10383, 7721, 7271, 7176, 7131, 7144, 7134, 7102, 7117, 7066 ]
    - [ 10373, 7885, 7388, 7283, 7224, 7189, 7182, 7200, 7206, 7127 ]
  5.0:
    - [ 10227, 7689, 7397, 7291, 7252, 7282, 7243, 7224, 7205, 7222 ]
    - [ 10094, 7933, 7512, 7398, 7361, 7277, 7302, 7300, 7335, 7316 ]
    - [ 10086, 7698, 7360, 7282, 7195, 7236, 7174, 7180, 7214, 7192 ]
    - [ 10155, 7777, 7323, 7294, 7231, 7247, 7723, 7166, 7138, 7197 ]
    - [ 10226, 7926, 7383, 7291, 7245, 7230, 7221, 7199, 7179, 7190 ]
    - [ 10094, 7838, 7340, 7315, 7279, 7250, 7262, 7247, 7148, 7170 ]
    - [ 10151, 7806, 7330, 7249, 7218, 7246, 7245, 7214, 7219, 7171 ]
    - [ 10238, 7875, 7351, 7283, 7252, 7228, 7235, 7188, 7205, 7228 ]
    - [ 10302, 7825, 7293, 7330, 7256, 7229, 7208, 7216, 7188, 7175 ]
    - [ 10200, 7819, 7416, 7336, 7303, 7297, 7289, 7390, 7251, 7231 ]
  6.0:
    - [ 10262, 7724, 7304, 7213, 7147, 7155, 7138, 7145, 7110, 7093 ]
    - [ 10305, 7731, 7340, 7245, 7187, 7193, 7164, 7270, 7192, 7141 ]
    - [ 10280, 7806, 7312, 7242, 7165, 7166, 7267, 7131, 7149, 7141 ]
    - [ 10228, 7786, 7292, 7433, 7157, 7178, 7135, 7098, 7103, 7066 ]
    - [ 9997, 7821, 7242, 7250, 7254, 7156, 7097, 7152, 7119, 7115 ]
    - [ 10254, 7754, 7300, 7227, 7194, 7108, 7151, 7132, 7132, 7127 ]
    - [ 10301, 7765, 7259, 7163, 7147, 7137, 7121, 7109, 7086, 7089 ]
    - [ 10186, 7622, 7491, 7171, 7110, 7115, 7116, 7059, 7019, 7063 ]
    - [ 10173, 7690, 7505, 7252, 7396, 7178, 7143, 7077, 7136, 7116 ]
    - [ 10366, 7799, 7347, 7280, 7121, 7210, 7187, 7098, 7143, 7128 ]
  7.0:
    - [ 10129, 7675, 7282, 7169, 7100, 7110, 7099, 7093, 7057, 7064 ]
    - [ 10349, 7683, 7406, 7189, 7139, 7108, 7108, 7041, 7102, 7075 ]
    - [ 10343, 7718, 7329, 7279, 7202, 7271, 7193, 7170, 7120, 7142 ]
    - [ 10214, 7778, 7214, 7140, 7142, 7060, 7124, 7131, 7032, 7025 ]
    - [ 10345, 7645, 7237, 7137, 7121, 7059, 7041, 7044, 7054, 7042 ]
    - [ 10301, 7643, 7265, 7126, 7103, 7102, 7072, 7058, 7032, 7060 ]
    - [ 10254, 7801, 7200, 7127, 7141, 7074, 7073, 7078, 7064, 7033 ]
    - [ 10418, 7655, 7240, 7186, 7359, 7105, 7117, 7098, 7107, 7074 ]
    - [ 10079, 7818, 7219, 7108, 7109, 7095, 7095, 7085, 7064, 7084 ]
    - [ 10187, 7555, 7231, 7272, 7163, 7154, 7160, 7095, 7076, 7084 ]
  8.0:
    - [ 10388, 7756, 7471, 7191, 7185, 7171, 7140, 7125, 7128, 7113 ]
    - [ 10441, 7735, 7365, 7221, 7135, 7146, 7120, 7095, 7115, 7098 ]
    - [ 10295, 7782, 7299, 7244, 7191, 7130, 7158, 7154, 7113, 7154 ]
    - [ 10379, 8172, 7262, 7203, 7164, 7165, 7131, 7065, 7037, 7170 ]
    - [ 10368, 7768, 7329, 7202, 7110, 7140, 7121, 7086, 7076, 7055 ]
    - [ 10468, 7636, 7225, 7154, 7126, 7126, 7107, 7116, 7145, 7128 ]
    - [ 10458, 7665, 7207, 7192, 7126, 7100, 7112, 7110, 7074, 7038 ]
    - [ 10270, 7633, 7229, 7186, 7149, 7113, 7113, 7104, 7072, 7086 ]
    - [ 10136, 7740, 7298, 7162, 7157, 7154, 7118, 7119, 7137, 7215 ]
    - [ 10223, 7662, 7291, 7240, 7155, 7147, 7145, 7128, 7133, 7102 ]
  9.0:
    - [ 10430, 7760, 7297, 7165, 7164, 7153, 7132, 7133, 7112, 7131 ]
    - [ 10313, 7685, 7342, 7201, 7143, 7128, 7078, 7114, 7130, 7106 ]
    - [ 10294, 7840, 7255, 7201, 7156, 7203, 7303, 7158, 7118, 7109 ]
    - [ 10277, 7677, 7302, 7121, 7160, 7130, 7165, 7102, 7060, 7080 ]
    - [ 10348, 7756, 7281, 7187, 7183, 7135, 7130, 7147, 7164, 7131 ]
    - [ 10450, 7631, 7279, 7158, 7291, 7225, 7122, 7067, 7094, 7084 ]
    - [ 10327, 7756, 7232, 7162, 7130, 7088, 7081, 7080, 7073, 7054 ]
    - [ 10351, 7752, 7254, 7205, 7182, 7090, 7115, 7099, 7096, 7099 ]
    - [ 10309, 7767, 7307, 7181, 7134, 7321, 7217, 7137, 7105, 7098 ]
    - [ 10390, 7755, 7272, 7133, 7156, 7099, 7061, 7091, 7101, 7097 ]
  10.0:
    - [ 10359, 7795, 7201, 7137, 7102, 7060, 7082, 7090, 7037, 7058 ]
    - [ 10428, 7766, 7298, 7171, 7142, 7168, 7060, 7116, 7146, 7125 ]
    - [ 10624, 7699, 7252, 7168, 7128, 7410, 7254, 7294, 7261, 7207 ]
    - [ 10348, 7643, 7226, 7161, 7434, 7169, 7162, 7145, 7091, 7117 ]
    - [ 10384, 7697, 7251, 7225, 7160, 7134, 7128, 7118, 7092, 7101 ]
    - [ 10476, 7757, 7295, 7262, 7172, 7207, 7117, 7141, 7151, 7141 ]
    - [ 10319, 7813, 7454, 7143, 7120, 7102, 7090, 7090, 7076, 7058 ]
    - [ 10362, 7681, 7574, 7400, 7181, 7121, 7057, 7118, 7106, 7082 ]
    - [ 10382, 7738, 7232, 7168, 7154, 7102, 7134, 7096, 7130, 7096 ]
    - [ 10538, 8898, 7233, 7210, 7173, 7153, 7140, 7178, 7113, 7114 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 28590, 18716, 16435, 13558, 12203, 11159, 10883, 10728, 10696, 10778 ]
    - [ 29425, 19084, 16054, 13567, 12224, 11342, 10707, 10299, 10674, 10384 ]
    - [ 29454, 19164, 16116, 13598, 12655, 11156, 10856, 10749, 10532, 10881 ]
    - [ 28597, 19013, 16014, 13710, 12373, 11110, 10792, 10679, 10416, 10400 ]
    - [ 29511, 19522, 15478, 13513, 11944, 11232, 11068, 10730, 10526, 10914 ]
    - [ 28956, 19041, 16189, 13302, 12691, 11078, 10916, 10647, 10770, 10843 ]
    - [ 29461, 18367, 15352, 13356, 12151, 11087, 10346, 10482, 10448, 10763 ]
    - [ 28823, 18798, 15510, 13827, 11934, 11300, 11275, 10870, 10760, 10273 ]
    - [ 29127, 18655, 16249, 13374, 12343, 11397, 10544, 10837, 10699, 10590 ]
    - [ 28433, 19138, 16253, 14212, 12775, 10894, 10297, 10996, 10786, 10872 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 9783, 7696, 7392, 7297, 7281, 7177, 7287, 7263, 7246, 7283 ]
    - [ 9911, 7795, 7472, 7369, 7378, 7394, 7361, 7362, 7287, 7311 ]
    - [ 9957, 7795, 7432, 7321, 7309, 7301, 7258, 7260, 7270, 7384 ]
    - [ 9766, 7788, 7469, 7363, 7336, 7255, 7303, 7293, 7296, 7327 ]
    - [ 9960, 8022, 7404, 7323, 7318, 7306, 7253, 7278, 7282, 7299 ]
    - [ 9929, 7740, 7359, 7249, 7325, 7258, 7283, 7332, 7459, 7267 ]
    - [ 10069, 7714, 7343, 7373, 7278, 7275, 7253, 7284, 7253, 7296 ]
    - [ 9974, 7789, 7338, 7311, 7332, 7329, 7294, 7240, 7306, 7259 ]
    - [ 9951, 7755, 7449, 7408, 7379, 7363, 7324, 7279, 7319, 7302 ]
    - [ 9998, 7889, 7357, 7386, 7285, 7313, 7296, 7302, 7198, 7291 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 10704, 8455, 8217, 7921, 7850, 7904, 7974, 7863, 7857, 7816 ]
    - [ 10837, 8544, 8163, 8121, 7900, 7946, 7888, 7774, 7777, 7814 ]
    - [ 10820, 8617, 8205, 7967, 7980, 7917, 7883, 7921, 7935, 7982 ]
    - [ 10917, 8330, 7973, 7874, 7962, 7969, 7981, 7905, 7976, 7946 ]
    - [ 10606, 8374, 7998, 7907, 7857, 7829, 8103, 8030, 7883, 7940 ]
    - [ 10642, 8528, 8000, 7986, 7909, 8061, 7930, 7941, 7907, 7921 ]
    - [ 10644, 8588, 8136, 8007, 7957, 8129, 7947, 7938, 7989, 7947 ]
    - [ 10725, 8511, 8171, 7912, 7911, 7888, 7849, 7903, 7856, 7863 ]
    - [ 10729, 8445, 8108, 8030, 8061, 7886, 7863, 7837, 7916, 7904 ]
    - [ 10876, 8511, 8045, 7971, 7989, 8055, 8109, 8108, 7882, 8020 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 10731, 8349, 7883, 7698, 7669, 7626, 7679, 7674, 7690, 7605 ]
    - [ 10730, 8176, 7849, 7721, 7704, 7614, 7671, 7631, 7627, 7624 ]
    - [ 10721, 8359, 7918, 7754, 7671, 7616, 7634, 7626, 7658, 7646 ]
    - [ 10794, 8339, 7862, 7703, 7714, 7673, 7582, 7625, 7671, 7660 ]
    - [ 10708, 8535, 7779, 7719, 7703, 7621, 7691, 7767, 7574, 7608 ]
    - [ 10812, 8287, 7793, 7759, 7684, 7714, 7719, 7693, 7665, 7705 ]
    - [ 10928, 8266, 7851, 7764, 7725, 7650, 7865, 7696, 7648, 7622 ]
    - [ 10781, 8241, 7799, 7707, 7653, 7658, 7602, 7547, 7604, 7631 ]
    - [ 10745, 8422, 7901, 7871, 7686, 7735, 7799, 7729, 7791, 7703 ]
    - [ 10822, 8429, 7940, 7945, 7838, 7798, 7829, 7850, 7838, 7832 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 11061, 8667, 8214, 8216, 8152, 8099, 8120, 8134, 8089, 8040 ]
    - [ 11074, 8732, 8289, 8227, 8073, 8151, 8060, 8054, 8056, 8052 ]
    - [ 11046, 9009, 8480, 8158, 8145, 8175, 8173, 8163, 8187, 8131 ]
    - [ 10909, 8981, 8308, 8156, 8127, 8112, 8073, 8116, 8014, 8051 ]
    - [ 10992, 8808, 8237, 8202, 8048, 8145, 8115, 8181, 8099, 8120 ]
    - [ 10835, 8712, 8269, 8168, 8191, 8113, 8075, 8090, 8073, 8152 ]
    - [ 10931, 8731, 8300, 8317, 8148, 8243, 8155, 8174, 8172, 8211 ]
    - [ 10835, 8572, 8142, 8165, 8084, 8164, 8076, 7965, 8012, 8084 ]
    - [ 11152, 8724, 8274, 8249, 8212, 8088, 8074, 8145, 8435, 8253 ]
    - [ 10903, 8730, 8230, 8097, 8147, 8000, 8036, 8105, 8074, 8051 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 11198, 8885, 8264, 8226, 8128, 8205, 8116, 8080, 7980, 8133 ]
    - [ 11090, 8779, 8197, 8309, 8210, 8095, 8132, 8183, 8163, 7943 ]
    - [ 11153, 8964, 8341, 8157, 8209, 8198, 8217, 8074, 8092, 8007 ]
    - [ 11015, 9454, 8294, 8292, 8183, 8184, 8167, 8092, 8122, 8121 ]
    - [ 10914, 8824, 8413, 8173, 8387, 8252, 8335, 8242, 8112, 8203 ]
    - [ 11108, 9086, 8354, 8316, 8360, 8241, 8146, 8079, 8055, 8105 ]
    - [ 10833, 9117, 8348, 8083, 8189, 8212, 8266, 8109, 8104, 8121 ]
    - [ 11131, 8640, 8401, 8329, 8255, 8559, 8287, 8216, 8413, 8528 ]
    - [ 10780, 8608, 8271, 8086, 8118, 7946, 8102, 8094, 8021, 8011 ]
    - [ 11104, 8683, 8215, 8249, 8325, 8156, 8285, 8108, 8165, 8017 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 183570, 181566, 182817, 183569, 183398 ]
    - [ 185101, 182849, 184632, 182023, 180902 ]
    - [ 185845, 183577, 182155, 181490, 181880 ]
    - [ 187393, 184005, 183240, 184522, 183123 ]
    - [ 183568, 181390, 182432, 182494, 181441 ]
    - [ 185001, 182942, 183080, 180740, 183335 ]
    - [ 183362, 182542, 182737, 182204, 182579 ]
    - [ 184128, 181319, 182812, 180701, 181771 ]
    - [ 183867, 181075, 183943, 183280, 182079 ]
    - [ 185276, 184614, 183932, 182960, 183249 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 16059, 14399, 14544, 14657, 14349 ]
    - [ 16305, 14677, 14494, 14295, 14353 ]
    - [ 15999, 14763, 14554, 14598, 14889 ]
    - [ 16206, 14324, 13692, 14544, 14511 ]
    - [ 16183, 14732, 14677, 14477, 14507 ]
    - [ 16045, 14848, 14504, 14354, 14460 ]
    - [ 16026, 14727, 14544, 14534, 14623 ]
    - [ 16572, 14810, 14834, 14694, 14677 ]
    - [ 16208, 14469, 14579, 14574, 14754 ]
    - [ 16033, 14512, 14632, 14656, 14705 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 13426, 12874, 12758, 12806, 12878 ]
    - [ 13496, 12803, 12809, 12786, 12817 ]
    - [ 13557, 12863, 12844, 12870, 12821 ]
    - [ 13499, 12857, 12838, 12835, 12845 ]
    - [ 13370, 12808, 12809, 12860, 12714 ]
    - [ 13454, 12738, 12884, 12906, 12824 ]
    - [ 13402, 12835, 12871, 12866, 12862 ]
    - [ 13468, 12801, 12847, 12896, 12929 ]
    - [ 13498, 12868, 12853, 12842, 12960 ]
    - [ 13427, 12801, 12922, 12777, 12873 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 47163, 31354, 30375, 28612, 24988 ]
    - [ 45163, 27483, 27566, 29177, 26278 ]
    - [ 45384, 27105, 28874, 25585, 27251 ]
    - [ 44610, 28931, 28297, 27161, 21419 ]
    - [ 47287, 28097, 26689, 27904, 22547 ]
    - [ 45114, 28942, 27073, 25903, 26748 ]
    - [ 46055, 27504, 19901, 24513, 29031 ]
    - [ 45310, 30219, 27278, 23810, 19972 ]
    - [ 46298, 30579, 27698, 28357, 25256 ]
    - [ 46162, 29213, 19858, 28174, 28378 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 13368, 8985, 7940, 7750, 7676 ]
    - [ 13435, 8931, 7961, 7670, 7558 ]
    - [ 13461, 8905, 8367, 8107, 7699 ]
    - [ 13337, 8849, 7848, 7621, 7634 ]
    - [ 13303, 8872, 8081, 7669, 7555 ]
    - [ 13374, 8910, 8256, 7785, 7621 ]
    - [ 13315, 8777, 7853, 7603, 7539 ]
    - [ 13349, 8871, 8365, 8382, 8182 ]
    - [ 13426, 9070, 8498, 8335, 8088 ]
    - [ 13462, 9205, 8629, 8440, 7948 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 14164, 10051, 9309, 8962, 9061 ]
    - [ 14060, 10248, 9138, 9026, 8973 ]
    - [ 14051, 9878, 9278, 9150, 9021 ]
    - [ 14114, 10036, 9261, 9089, 9030 ]
    - [ 14232, 10392, 9312, 9096, 9061 ]
    - [ 14096, 10308, 9351, 9091, 9056 ]
    - [ 14137, 10357, 9337, 9019, 9022 ]
    - [ 13906, 10150, 9307, 9048, 8967 ]
    - [ 14194, 10418, 9303, 9082, 9111 ]
    - [ 13968, 10199, 9381, 9029, 9028 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 10295, 7845, 7357, 7306, 7283 ]
    - [ 10186, 7905, 7454, 7297, 7322 ]
    - [ 10464, 7791, 7401, 7342, 7436 ]
    - [ 10321, 7823, 7410, 7346, 7376 ]
    - [ 10210, 8051, 7392, 7411, 7351 ]
    - [ 10162, 7813, 7441, 7312, 7279 ]
    - [ 10125, 7732, 7469, 7930, 7286 ]
    - [ 10120, 8028, 7326, 7257, 7318 ]
    - [ 10125, 7971, 7404, 7306, 7270 ]
    - [ 10227, 7841, 7470, 7371, 7288 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 10949, 8381, 7933, 7814, 7714 ]
    - [ 10627, 8394, 7716, 7562, 7477 ]
    - [ 11125, 8232, 7825, 7765, 7685 ]
    - [ 11010, 8452, 7874, 7775, 7776 ]
    - [ 10763, 8209, 8075, 7763, 7725 ]
    - [ 10992, 8485, 7935, 7719, 7793 ]
    - [ 10883, 8661, 8002, 7866, 7786 ]
    - [ 10963, 8247, 7863, 7754, 7682 ]
    - [ 10810, 8250, 7950, 7939, 7749 ]
    - [ 10767, 8254, 7816, 7710, 7751 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 8842, 6638, 6234, 6264, 6078 ]
    - [ 8762, 6799, 6210, 6209, 6012 ]
    - [ 8813, 6758, 6362, 6151, 6212 ]
    - [ 8712, 6828, 6318, 6023, 6187 ]
    - [ 8768, 6688, 6311, 6251, 6242 ]
    - [ 8855, 6836, 6401, 6231, 6221 ]
    - [ 8834, 7004, 6415, 6118, 6221 ]
    - [ 8654, 6796, 6266, 6179, 6115 ]
    - [ 8762, 6711, 6374, 6152, 6123 ]
    - [ 8705, 6770, 6316, 6206, 6112 ]
open-jdk-21.server.G1.intel.t-32:
  2.0:
    - [ 14189, 10922, 10534, 10388, 10366, 10275, 10310, 10292, 10285, 10287 ]
    - [ 14207, 10929, 10476, 10293, 10397, 10219, 10338, 10343, 10158, 10232 ]
    - [ 14228, 11072, 10449, 10379, 10298, 10283, 10358, 10267, 10275, 10240 ]
    - [ 14257, 11053, 10488, 10379, 10268, 10250, 10251, 10240, 10215, 10238 ]
    - [ 14317, 11501, 10577, 10358, 10298, 10324, 10366, 10266, 10215, 10320 ]
    - [ 14274, 11390, 10623, 10400, 10354, 10248, 10190, 10177, 10236, 10215 ]
    - [ 14221, 11004, 10465, 10446, 10261, 10158, 10309, 10240, 10189, 10193 ]
    - [ 14159, 11042, 10494, 10358, 10328, 10320, 10295, 10383, 10281, 10250 ]
    - [ 14176, 10975, 10534, 10258, 10279, 10309, 10260, 10170, 10202, 10247 ]
    - [ 14345, 10978, 10506, 10422, 10330, 10237, 10259, 10304, 10192, 10329 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 39646, 25716, 21790, 19078, 16938, 15501, 14282, 14175, 14734, 14148 ]
    - [ 40485, 25689, 22272, 18436, 16566, 15711, 14633, 14840, 14146, 14872 ]
    - [ 39602, 26520, 21880, 18608, 16815, 14918, 14744, 14360, 14422, 14532 ]
    - [ 40280, 28231, 21653, 19165, 16887, 15316, 14179, 14375, 14454, 14840 ]
    - [ 40992, 27823, 21830, 19138, 16983, 15336, 15092, 14693, 14228, 14766 ]
    - [ 39521, 26178, 22647, 18508, 17458, 15195, 14994, 14657, 14786, 14590 ]
    - [ 40484, 28357, 22165, 18442, 17076, 15251, 14403, 14473, 14587, 14060 ]
    - [ 40100, 26215, 22323, 18786, 16864, 15487, 14714, 14826, 14665, 14627 ]
    - [ 39612, 25561, 22812, 19033, 16058, 15346, 14397, 14837, 14383, 14584 ]
    - [ 39416, 26465, 22455, 18431, 16701, 15118, 14959, 14989, 14586, 14602 ]
open-jdk-21.server.G1.arm.t-32:
  2.0:
    - [ 23009, 16937, 15943, 15652, 15728, 15771, 15634, 15587, 15655, 15568 ]
    - [ 23023, 17054, 15946, 15626, 15604, 15629, 15628, 15567, 15590, 15518 ]
    - [ 23158, 17541, 16034, 16117, 16007, 15896, 15704, 15794, 15823, 15958 ]
    - [ 23089, 16797, 15955, 15730, 15675, 15643, 15692, 15651, 15669, 15559 ]
    - [ 23221, 17115, 15991, 15993, 15801, 15710, 15732, 15666, 15601, 15736 ]
    - [ 23122, 16994, 16113, 15833, 15913, 15718, 15696, 15709, 15685, 15747 ]
    - [ 23046, 16999, 15921, 15790, 15818, 15791, 15578, 15520, 15565, 15617 ]
    - [ 23137, 16934, 15911, 15981, 15762, 15580, 15739, 15474, 15597, 15673 ]
    - [ 22843, 16674, 15890, 15603, 15415, 15835, 15674, 15661, 15560, 15660 ]
    - [ 23098, 17216, 15942, 15961, 15693, 15673, 15747, 15763, 15712, 15639 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 59603, 38121, 31050, 26372, 23647, 21209, 20624, 20537, 20539, 20440 ]
    - [ 62192, 38949, 31039, 25762, 23068, 21273, 20594, 20735, 20325, 20506 ]
    - [ 60826, 38378, 31117, 26187, 23526, 21954, 20320, 20161, 20400, 20906 ]
    - [ 61991, 40173, 30834, 26048, 22693, 22278, 21235, 20923, 20604, 19920 ]
    - [ 60905, 37868, 29506, 26123, 23051, 21630, 20827, 20525, 20625, 20667 ]
    - [ 60685, 38367, 30674, 26657, 23009, 21224, 21098, 20189, 20555, 20195 ]
    - [ 61831, 37845, 29819, 26038, 22841, 21560, 20914, 20131, 20649, 20675 ]
    - [ 60859, 37527, 30197, 27175, 22422, 20955, 20214, 19810, 20490, 20883 ]
    - [ 60300, 39210, 30925, 26729, 23307, 21148, 20781, 20251, 20904, 20271 ]
    - [ 61214, 37550, 29898, 26557, 23010, 21778, 20258, 20216, 20055, 21075 ]
